import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';

import { CoachInfo as CoachInfoModel } from '../../../../Model/CoachInfo';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { CardsContainer } from '../../../../components/v2/Card';
import TextEditor from '../../../../components/TextEditor';
import LoadingOverlay from '../../../components/LoadingOverlay';
import useToast from '../../../hooks/useToast';
import {
  ButtonRow,
  StyledButton,
  Container,
} from './styles';
import texts from './texts.json';

const CoachInfo = ({ coach: { id: coachId } }) => {
  const [coachInfoDoc, setCoachInfoDoc] = useState(null);
  const isComponentMountedRef = useComponentMounted();
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { showToast } = useToast();

  const DEFAULT_INFO_TEMPLATE = `<p><strong>Coach Name:</strong></p>
                <p><strong>Pronunciation:</strong></p>
                <p><strong>Coach Account Manager:</strong></p>
                <p><strong>Where is the coach from:</strong></p>
                <p><strong>Years as a coach:</strong></p>
                <p><strong>Product Offerings:</strong></p>
                <ul>
                  <li>Months</li>
                  <li>Prices</li>
                  <li>Comments</li>
                </ul>
                <p><strong>Commitment Period:</strong></p>`;

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      setCoachInfoDoc(null);
      if (coachId) {
        const coachInfoDocument = await CoachInfoModel.getCoachInfoById(coachId);
        if (isComponentMountedRef.current) {
          setCoachInfoDoc(coachInfoDocument);
          setNotes(coachInfoDocument?.coachInfoNote || DEFAULT_INFO_TEMPLATE);
          setIsLoading(false);
        }
      }
    };
    init();
  }, [DEFAULT_INFO_TEMPLATE, coachId, isComponentMountedRef]);

  const onSave = useCallback(async () => {
    setIsLoading(true);

    try {
      if (coachInfoDoc) {
        await coachInfoDoc.updateCoachInfoNote(notes);
      } else {
        const newCoachInfoDoc = await CoachInfoModel.createCoachInfo(coachId, notes);
        setCoachInfoDoc(newCoachInfoDoc);
      }
      showToast(texts.savedSuccessfully);
    } catch (error) {
      showToast(format(texts.errorSavingNotes, { error }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [coachInfoDoc, showToast, notes, coachId, isComponentMountedRef]);

  return (
    <>
      <CardsContainer $fullWidth>
        <Container>
          <TextEditor value={notes} onChange={setNotes} />
          <ButtonRow>
            <StyledButton onClick={onSave}>{texts.save}</StyledButton>
          </ButtonRow>
        </Container>
      </CardsContainer>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

CoachInfo.propTypes = {
  coach: PropTypes.object,
};

CoachInfo.defaultProps = {
  coach: {},
};

export default compose(
  observer,
)(CoachInfo);
