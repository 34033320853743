import React from 'react';
import { InstagramMediaContextProvider } from './InstagramMediaContextProvider';

const WithInstagramMediaContextProvider = (Component) => (props) => (
  <InstagramMediaContextProvider>
    <Component {...props} />
  </InstagramMediaContextProvider>
);

export {
  WithInstagramMediaContextProvider,
};
