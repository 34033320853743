import { useContext, useMemo } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { ConfigurationType, configurationPagesConfig } from '../../../../config/configurationPages';
import ConfigurationMenuContext from '../../../../context/ConfigurationMenuContext';
import { FeatureFlag } from '../../../../../utils/postHog';

const useConfigurationListConfig = () => {
  const { navigateToConfig } = useContext(ConfigurationMenuContext);
  const isInstagramPostTrackingEnabled = useFeatureFlagEnabled(FeatureFlag.INSTAGRAM_POST_TRACKING);

  const configurationListEntries = useMemo(() => {
    const entries = [
      {
        configurationId: ConfigurationType.PAYMENT,
        onClick: () => navigateToConfig(ConfigurationType.PAYMENT),
        icon: configurationPagesConfig[ConfigurationType.PAYMENT].icon,
        title: configurationPagesConfig[ConfigurationType.PAYMENT].title,
      },
      {
        configurationId: ConfigurationType.CHECKOUT_PAGE,
        onClick: () => navigateToConfig(ConfigurationType.CHECKOUT_PAGE),
        icon: configurationPagesConfig[ConfigurationType.CHECKOUT_PAGE].icon,
        title: configurationPagesConfig[ConfigurationType.CHECKOUT_PAGE].title,
      },
      {
        configurationId: ConfigurationType.WELCOME_MESSAGE,
        onClick: () => navigateToConfig(ConfigurationType.WELCOME_MESSAGE),
        icon: configurationPagesConfig[ConfigurationType.WELCOME_MESSAGE].icon,
        title: configurationPagesConfig[ConfigurationType.WELCOME_MESSAGE].title,
      },
      {
        configurationId: ConfigurationType.HABITS,
        onClick: () => navigateToConfig(ConfigurationType.HABITS),
        icon: configurationPagesConfig[ConfigurationType.HABITS].icon,
        title: configurationPagesConfig[ConfigurationType.HABITS].title,
      },
      {
        configurationId: ConfigurationType.CHECK_IN,
        onClick: () => navigateToConfig(ConfigurationType.CHECK_IN),
        icon: configurationPagesConfig[ConfigurationType.CHECK_IN].icon,
        title: configurationPagesConfig[ConfigurationType.CHECK_IN].title,
      },
      {
        configurationId: ConfigurationType.PRODUCTS,
        onClick: () => navigateToConfig(ConfigurationType.PRODUCTS),
        icon: configurationPagesConfig[ConfigurationType.PRODUCTS].icon,
        title: configurationPagesConfig[ConfigurationType.PRODUCTS].title,
      },
      {
        configurationId: ConfigurationType.PROFILE_SETTINGS,
        onClick: () => navigateToConfig(ConfigurationType.PROFILE_SETTINGS),
        icon: configurationPagesConfig[ConfigurationType.PROFILE_SETTINGS].icon,
        title: configurationPagesConfig[ConfigurationType.PROFILE_SETTINGS].title,
      },
      {
        configurationId: ConfigurationType.NOTIFICATION_SETTINGS,
        onClick: () => navigateToConfig(ConfigurationType.NOTIFICATION_SETTINGS),
        icon: configurationPagesConfig[ConfigurationType.NOTIFICATION_SETTINGS].icon,
        title: configurationPagesConfig[ConfigurationType.NOTIFICATION_SETTINGS].title,
      },
      {
        configurationId: ConfigurationType.INSTAGRAM_SETTINGS,
        onClick: () => navigateToConfig(ConfigurationType.INSTAGRAM_SETTINGS),
        icon: configurationPagesConfig[ConfigurationType.INSTAGRAM_SETTINGS].icon,
        title: configurationPagesConfig[ConfigurationType.INSTAGRAM_SETTINGS].title,
        disabled: !isInstagramPostTrackingEnabled,
      },
      {
        configurationId: ConfigurationType.OTHER_SETTINGS,
        onClick: () => navigateToConfig(ConfigurationType.OTHER_SETTINGS),
        icon: configurationPagesConfig[ConfigurationType.OTHER_SETTINGS].icon,
        title: configurationPagesConfig[ConfigurationType.OTHER_SETTINGS].title,
      },
    ];

    return entries;
  }, [isInstagramPostTrackingEnabled, navigateToConfig]);

  return {
    configurationListEntries,
  };
};

export default useConfigurationListConfig;
