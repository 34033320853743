import {
  StyledStripeConfigIcon,
  StyledWelcomeMessageConfigIcon,
  StyledHabitConfigIcon,
  StyledCheckInConfigIcon,
  StyledCheckoutPageConfigIcon,
  StyledProductConfigIcon,
  StyledProfileIcon,
  StyledSettingsIcon,
  StyledNotificationIcon,
  StyledInstagramIcon,
} from './styles';

import texts from './texts.json';

const ConfigurationType = {
  HABITS: 'HABITS',
  PAYMENT: 'PAYMENT',
  CHECKOUT_PAGE: 'CHECKOUT_PAGE',
  WELCOME_MESSAGE: 'WELCOME_MESSAGE',
  CHECK_IN: 'CHECK_IN',
  PRODUCTS: 'PRODUCTS',
  PROFILE_SETTINGS: 'PROFILE_SETTINGS',
  NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
  INSTAGRAM_SETTINGS: 'INSTAGRAM_SETTINGS',
  OTHER_SETTINGS: 'OTHER_SETTINGS',
};

const configurationPagesConfig = {
  [ConfigurationType.PAYMENT]: {
    id: ConfigurationType.PAYMENT,
    title: texts.paymentSettings,
    icon: StyledStripeConfigIcon,
    path: 'payment',
  },
  [ConfigurationType.HABITS]: {
    id: ConfigurationType.HABITS,
    title: texts.HABITS,
    icon: StyledHabitConfigIcon,
    path: 'habits',
  },
  [ConfigurationType.CHECK_IN]: {
    id: ConfigurationType.CHECK_IN,
    title: texts.CHECK_IN,
    icon: StyledCheckInConfigIcon,
    path: 'check-in',
  },
  [ConfigurationType.CHECKOUT_PAGE]: {
    id: ConfigurationType.CHECKOUT_PAGE,
    title: texts.CHECKOUT_PAGE,
    icon: StyledCheckoutPageConfigIcon,
    path: 'checkoutPage',
  },
  [ConfigurationType.WELCOME_MESSAGE]: {
    id: ConfigurationType.WELCOME_MESSAGE,
    title: texts.WELCOME_MESSAGE,
    icon: StyledWelcomeMessageConfigIcon,
    path: 'welcomeMessage',
  },
  [ConfigurationType.PRODUCTS]: {
    id: ConfigurationType.PRODUCTS,
    title: texts.productsPricing,
    icon: StyledProductConfigIcon,
    path: 'products',
  },
  [ConfigurationType.PROFILE_SETTINGS]: {
    id: ConfigurationType.PROFILE_SETTINGS,
    title: texts.profileSettings,
    icon: StyledProfileIcon,
    path: 'profileSettings',
  },
  [ConfigurationType.NOTIFICATION_SETTINGS]: {
    id: ConfigurationType.NOTIFICATION_SETTINGS,
    title: texts.notificationSettings,
    icon: StyledNotificationIcon,
    path: 'notificationSettings',
  },
  [ConfigurationType.INSTAGRAM_SETTINGS]: {
    id: ConfigurationType.INSTAGRAM_SETTINGS,
    title: texts.instagramSettings,
    icon: StyledInstagramIcon,
    path: 'instagramSettings',
  },
  [ConfigurationType.OTHER_SETTINGS]: {
    id: ConfigurationType.OTHER_SETTINGS,
    title: texts.otherSettings,
    icon: StyledSettingsIcon,
    path: 'otherSettings',
  },
};

export { ConfigurationType, configurationPagesConfig };
