import React from 'react';
import PropTypes from 'prop-types';
import {
  FastField,
  useFormikContext,
} from 'formik';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import fieldName, { heightUnitId, questionFieldName } from '../../../../formFields';
import externalText from '../../../../texts';
import {
  Container,
  SingleRowContainer,
  ShortFormInput,
  LongFormInput,
} from '../styles';

import { ParentContainer } from '../NameQuestion/styles';
import texts from './texts.json';

const LengthQuestion = ({ index }) => {
  const { setFieldValue } = useFormikContext();

  const fieldBaseName = `${fieldName.FIELDS}.${index}`;

  return (
    <Container>
      <ParentContainer>
        <SingleRowContainer>
          <ShortFormInput
            name={`${fieldBaseName}.${questionFieldName.UNITS}`}
            label={externalText.labels[questionFieldName.UNITS]}
            disabled
          />
          <FastField name={`${fieldBaseName}.${questionFieldName.REQUIRED}`}>
            {({ field }) => (
              <FormControlLabel
                key={`${fieldBaseName}-${index}-${questionFieldName.REQUIRED}`}
                control={<Checkbox checked={field.value} />}
                label={externalText.labels[questionFieldName.REQUIRED]}
                name={questionFieldName.REQUIRED}
                onChange={(event) => {
                  setFieldValue(`${fieldBaseName}.${questionFieldName.REQUIRED}`, event.target.checked);
                }}
              />
            )}
          </FastField>
        </SingleRowContainer>
        <LongFormInput
          name={`${fieldBaseName}.${questionFieldName.LABEL}`}
          label={externalText.labels[questionFieldName.LABEL]}
        />
        <SingleRowContainer>
          <ShortFormInput
            name={`${fieldBaseName}.${questionFieldName.UNIT_IDS}.${heightUnitId.FEET}`}
            label={texts.feet}
          />
          <ShortFormInput
            name={`${fieldBaseName}.${questionFieldName.UNIT_IDS}.${heightUnitId.INCHES}`}
            label={texts.inches}
          />
          <ShortFormInput
            name={`${fieldBaseName}.${questionFieldName.UNIT_IDS}.${heightUnitId.CM}`}
            label={texts.cm}
          />
        </SingleRowContainer>
      </ParentContainer>
    </Container>
  );
};

LengthQuestion.propTypes = {
  index: PropTypes.number.isRequired,
};

export default LengthQuestion;
