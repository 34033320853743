const DraggableID = 'Draggable-Todo';

// Create a portal container for the drag ghost
const portalContainer = document.createElement('div');
portalContainer.style.position = 'fixed';
portalContainer.style.zIndex = '1310';
document.body.appendChild(portalContainer);

export {
  DraggableID,
  portalContainer,
};
