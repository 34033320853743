import React from 'react';
import {
  Route,
  Switch,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { compose } from 'recompose';

import { ErrorType } from '../utils/error';
import Signup, { InternalSignup } from '../pages/Signup';
import Login from '../pages/Login';
import AuthLinkValidator from '../pages/AuthLinkValidator';
import StripeCallback from '../pages/StripeCallback';
import ErrorPage from '../pages/Error';
import PrivateRoute from '../components/PrivateRoute';
import HomeRoute from '../components/HomeRoute';
import withCloseInAppBrowserButton from '../components/withCloseInAppBrowserButton';
import AdminCoachRoute from '../components/AdminCoachRoute';
import AdminRoute from '../components/AdminRoute';
import { withFirebaseContextReady } from '../context/FirebaseContext';
import OnboardingRoutes from '../routes/OnboardingRoutes/onboardingRoutes';
import UserRoutes from '../routes/UserRoutes';
import CoachToolsRoutes from '../CoachTools/routes/ToolsRoutes';
import usePageLoad from '../hooks/usePageLoad';
import AdminToolsRoutes from '../CoachTools/routes/AdminToolsRoutes';
import useTimeTracker from '../hooks/useTimeTracker';
import InstagramAuthCallback from '../pages/InstagramAuthCallback';

import InstagramMedia from '../pages/InstagramMedia';
import withAuthentication from './Auth';
import GlobalStyles from './styles';

const App = () => {
  usePageLoad();
  useTimeTracker();
  const location = useLocation();
  const { search, state } = location;

  return (
    <>
      <GlobalStyles />
      <Switch>
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/int-signup" component={InternalSignup} />
        <Route exact path="/authLink" component={AuthLinkValidator} />
        <Route exact path="/login" component={Login} />
        <Route path="/onboarding" component={OnboardingRoutes} />
        <Route path="/error" component={ErrorPage} />
        <PrivateRoute exact path="/stripe-cb" component={StripeCallback} />
        <PrivateRoute exact path="/">
          <Redirect to={{ pathname: '/home', search, state }} />
        </PrivateRoute>
        <PrivateRoute exact path="/home">
          <HomeRoute />
        </PrivateRoute>
        <PrivateRoute path="/u/:userId" component={UserRoutes} />
        <AdminCoachRoute path="/c/:userId" component={CoachToolsRoutes} />
        <AdminRoute path="/a/:userId" component={AdminToolsRoutes} />
        <PrivateRoute path="/view-instagram-media/:mediaId" component={InstagramMedia} />
        <PrivateRoute exact path="/auth/instagram-callback" component={InstagramAuthCallback} />
        <Route path="*" component={() => <ErrorPage errorType={ErrorType.PAGE_NOT_FOUND} />} />
      </Switch>
    </>
  );
};

export default compose(
  withFirebaseContextReady,
  withAuthentication,
  withCloseInAppBrowserButton,
)(App);
