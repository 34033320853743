import styled from 'styled-components';
import { Button } from '@mui/material';
import colors from '../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  height: 125vh;
  background-color:${colors.shades.gamma5};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap:35px;
`;

const StyledButton = styled(Button)`
  width: fit-content;
  padding-inline: 25px;
`;

export {
  Container,
  HeaderContainer,
  StyledButton,
};
