import * as Yup from 'yup';

import { MeasurementUnit } from '../../../../../../../utils/measurement';

import unitTexts from './components/NutritionDetailsEditField/texts';
import texts from './texts.json';

const profileInfovalidationSchema = Yup.object({
  firstName: Yup.string()
    .required(texts.validations.firstNameRequired),
  lastName: Yup.string()
    .required(texts.validations.lastNameRequired),
  phoneNumber: Yup.string()
    .matches(/^[0-9+-]+$/, texts.validations.phoneNumberOnlyDigit)
    .required(texts.validations.phoneNumberRequired),
  birthdate: Yup.string()
    .required(texts.validations.birthdateRequired),
  checkInDay: Yup.number()
    .required(texts.validations.checkInDayRequired),
});

const nutritionInfoValidationSchema = Yup.object({
  height: Yup.mixed()
    .test('is-valid-height', function isValidHeight(value) {
      const { heightMeasurementUnit } = this.parent;
      const createHeightError = (path, message) => this.createError({ path, message });

      if (heightMeasurementUnit === MeasurementUnit.METRIC) {
        return value > 0 || createHeightError(unitTexts.cm, texts.validations.heightGreaterThan);
      }
      if (!value || value.ft <= 0) {
        return createHeightError(unitTexts.ft, texts.validations.heightFtGreaterThan);
      }
      if (value.in < 0) {
        return createHeightError(unitTexts.inches, texts.validations.heightInchesGreaterThan);
      }
      return true;
    }),
  currentWeight: Yup.number()
    .positive(texts.validations.weightGreaterThan)
    .required(texts.validations.currentWeightRequired),
});

export {
  profileInfovalidationSchema,
  nutritionInfoValidationSchema,
};
