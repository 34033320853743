import posthog from 'posthog-js';

const FeatureFlag = {
  INSIDE_SALES_ADMIN: 'InsideSalesAdmin',
  CRX_ADMIN: 'CRXAdmin',
  PRIMARY_COLOR: 'PrimaryColor',
  INSTAGRAM_POST_TRACKING: 'instagramPostTracking',
};

const isFeatureEnabled = (featureFlag) => posthog.isFeatureEnabled(featureFlag);

const isCRXAdmin = () => isFeatureEnabled(FeatureFlag.CRX_ADMIN);

const isInsideSalesAdmin = () => isFeatureEnabled(FeatureFlag.INSIDE_SALES_ADMIN);

const isPrimaryColorEnabled = () => isFeatureEnabled(FeatureFlag.PRIMARY_COLOR);

const isInstagramPostTrackingEnabled = () => isFeatureEnabled(FeatureFlag.INSTAGRAM_POST_TRACKING);

export {
  isInsideSalesAdmin,
  isCRXAdmin,
  isPrimaryColorEnabled,
  isInstagramPostTrackingEnabled,
  FeatureFlag,
};
