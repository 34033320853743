import styled from 'styled-components';
import Button from '../../../../components/Button';

const Container = styled.div`
  overflow-y: hidden;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  height: 30px;
  width: unset;
`;
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

export {
  StyledButton,
  ButtonRow,
  Container,
};
