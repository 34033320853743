import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import format from 'string-template';
import moment from 'moment';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useToast from '../../../../hooks/useToast';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useUserDoc from '../../../../hooks/useUserDoc';
import LoadingOverlay from '../../../LoadingOverlay';
import LeadsContext from '../../../../context/LeadsContext';
import ConfirmDialog from '../../../ConfirmDialog';
import { SubTitle } from '../../../../../components/v2/Header';
import LeadsModal from '../LeadModal';
import { sendDesktopNotification } from './utils';
import {
  ModalContent,
  Timer,
} from './styels';
import texts from './texts.json';

const NewLeadNotificationModal = () => {
  const {
    showNewLeadNotificationModal,
    setShowNewLeadNotificationModal,
    latestAssignedLeadDoc,
  } = useContext(LeadsContext);

  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLeadDetailsModal, setShowLeadDetailsModal] = useState(false);
  const timerRef = useRef(null);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { userId } = useSessionStore();
  const { isReady, userDoc } = useUserDoc(userId);

  /**
   * Calculates the remaining time (in seconds) for a lead to be claimed.
   * - Starts with a 10-second window.
   * - Subtracts the time elapsed since `assignedAt`.
   * - The remaining time does not go below 0.
   */
  useEffect(() => {
    const init = () => {
      const { assignedAt } = latestAssignedLeadDoc || {};

      if (!assignedAt) return;
      const elapsedTimeInSeconds = moment().diff(moment(assignedAt.toDate()), 'seconds');
      if (isComponentMountedRef.current) {
        setTimeLeft(Math.max(10 - elapsedTimeInSeconds, 0));
      }
    };

    init();
  }, [
    latestAssignedLeadDoc,
    isComponentMountedRef,
  ]);

  /**
   * Handles countdown for the new lead notification modal.
   *
   * - Closes the modal when `timeLeft` reaches 0.
   * - Decreases `timeLeft` by 1 every second.
   * - Cleans up the timer to prevent multiple instances.
   */
  useEffect(() => {
    if (timeLeft <= 0) {
      setShowNewLeadNotificationModal?.(false);
      return;
    }

    timerRef.current = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timerRef.current);
  }, [
    timeLeft,
    setShowNewLeadNotificationModal,
  ]);

  const onClaimLatestLead = useCallback(async () => {
    try {
      setIsLoading(true);
      if (isReady) {
        await latestAssignedLeadDoc.claimLead(userId, `${userDoc.firstName} ${userDoc.lastName}`);
        await userDoc.updateIsISOnCall(false);

        if (isComponentMountedRef.current) {
          showToast(texts.success);
          setShowNewLeadNotificationModal(false);
          setShowLeadDetailsModal(true);
        }
      }
    } catch (error) {
      showToast(format(texts.fail, { error }), { error: true });
    } finally {
      setIsLoading(false);
    }
  }, [
    userId,
    isReady,
    userDoc,
    showToast,
    latestAssignedLeadDoc,
    isComponentMountedRef,
    setShowLeadDetailsModal,
    setShowNewLeadNotificationModal,
  ]);

  useEffect(() => {
    // send notification when the modal is opened
    if (showNewLeadNotificationModal) {
      sendDesktopNotification();
    }
  }, [showNewLeadNotificationModal]);

  const handleCloseLeadModal = (isOutcomeRecorded = false) => {
    if (!isOutcomeRecorded) {
      showToast(texts.outcomeNotRecorded, { error: true });
      return;
    }
    setShowLeadDetailsModal(false);
  };
  return (
    <>
      <ConfirmDialog
        isOpen={timeLeft > 0 && showNewLeadNotificationModal}
        onCancel={() => setShowNewLeadNotificationModal?.(false)}
        onConfirm={onClaimLatestLead}
        dialogTexts={{
          title: texts.title,
          confirm: texts.claim,
          content: (
            <ModalContent>
              <SubTitle>{texts.description}</SubTitle>
              <Timer>{format(texts.timeLeft, { timeLeft })}</Timer>
            </ModalContent>
          ),
        }}
      />
      {showLeadDetailsModal && (
        <LeadsModal
          lead={latestAssignedLeadDoc}
          showModal={showLeadDetailsModal}
          userId={userId}
          userName={userDoc.name}
          onClose={handleCloseLeadModal}
          isInsideSalesView
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default NewLeadNotificationModal;
