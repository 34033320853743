import { SalesStage, FinalStatusSalesStages } from '../../Model/Lead';

const TRYRAZ_DASHBOARD_URL = 'https://dashboard.tryraz.com';
const FRONT_URL = 'https://app.frontapp.com';

const SalesStagesOptions = {
  NEW_LEADS: 'NEW_LEADS',
  INVALID_CONTACT: 'INVALID_CONTACT',
  FIRST_MESSAGE: 'FIRST_MESSAGE',
  SECOND_MESSAGE: 'SECOND_MESSAGE',
  IN_DIALOGUE: 'IN_DIALOGUE',
  CALENDLY_SCHEDULED: 'CALENDLY_SCHEDULED',
  MANUAL: 'MANUAL',
  FOLLOW_UP: 'FOLLOW_UP',
  FINAL_STATUS: 'FINAL_STATUS',
  ALL: 'ALL',
};

const salesStagesFilterChecks = {
  [SalesStagesOptions.ALL]: () => true,
  [SalesStagesOptions.NEW_LEADS]: ({ salesStage }) => SalesStage.NEW_LEAD === salesStage,
  [SalesStagesOptions.INVALID_CONTACT]: ({ salesStage }) => SalesStage.INVALID_CONTACT === salesStage,
  [SalesStagesOptions.FIRST_MESSAGE]: ({ salesStage }) => SalesStage.FIRST_MESSAGE === salesStage,
  [SalesStagesOptions.SECOND_MESSAGE]: ({ salesStage }) => SalesStage.SECOND_MESSAGE === salesStage,
  [SalesStagesOptions.IN_DIALOGUE]: ({ salesStage }) => SalesStage.IN_DIALOGUE === salesStage,
  [SalesStagesOptions.DUPLICATE]: ({ salesStage }) => SalesStage.DUPLICATE === salesStage,
  [SalesStagesOptions.MANUAL]: ({ salesStage }) => SalesStage.MANUAL === salesStage,
  [SalesStagesOptions.FOLLOW_UP]: ({ salesStage }) => SalesStage.FOLLOW_UP === salesStage,
  [SalesStagesOptions.CALENDLY_SCHEDULED]: ({ salesStage }) => SalesStage.CALENDLY_SCHEDULED === salesStage,
  [SalesStagesOptions.FINAL_STATUS]: ({ salesStage }) => FinalStatusSalesStages.includes(salesStage),
};

const createTryRazContact = async (remote, leadId) => {
  try {
    const response = await remote('createContact', { leadId });
    const { success, error } = await response.json();
    return { success, error };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getTryRazConversationLink = (contactNo) => (
  `${TRYRAZ_DASHBOARD_URL}/conversations?id=${encodeURIComponent(contactNo)}`
);

const getFrontConversationId = async (remote, contactId) => {
  try {
    const response = await remote(`getFrontConversationId?contactId=${contactId}`, null, { method: 'GET' });
    const { success, error, conversationId } = await response.json();
    return { success, error, conversationId };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

const getFrontLink = (conversationId) => (
  `${FRONT_URL}/open/${conversationId}`
);

export {
  SalesStagesOptions,
  salesStagesFilterChecks,
  FinalStatusSalesStages,
  createTryRazContact,
  getTryRazConversationLink,
  getFrontConversationId,
  getFrontLink,
};
