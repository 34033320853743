import React, { useCallback, useState } from 'react';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

import {
  LeadCallOutcome,
  LeadHistoryType,
  SalesStage,
} from '../../../../../../../../Model/Lead';
import useComponentMounted from '../../../../../../../../../hooks/useComponentMounted';
import useToast from '../../../../../../../../hooks/useToast';
import InputLabel from '../../../../../../../../../components/v2/InputLabel';
import LoadingOverlay from '../../../../../../../LoadingOverlay';
import {
  StyledTextField,
  CallLogButton,
} from '../../styles';

import {
  StyledSelectField,
  RecordOutcomeSection,
} from './styles';
import texts from './texts';

const EMPTY_VALUE = '';

const leadOutcomeOptions = Object.entries(LeadCallOutcome).map(([key, outcome]) => (
  <MenuItem key={key} value={outcome}>{texts.leadCallOutcome[outcome]}</MenuItem>
));

const CallOutcome = ({
  lead,
  isUserDocReady,
  userDoc,
  onCloseLeadModal,
}) => {
  const [leadCallOutcome, setLeadCallOutcome] = useState(LeadCallOutcome.WON);
  const [callOutcomeNote, setCallOutcomeNote] = useState(EMPTY_VALUE);
  const [showCallOutcomeFields, setShowCallOutcomeFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const onSaveCallOutcome = useCallback(async () => {
    try {
      if (!showCallOutcomeFields && isComponentMountedRef.current) {
        setShowCallOutcomeFields(true);
        return;
      }

      setIsLoading(true);
      if (isUserDocReady) {
        await lead.addItemToHistory({
          value: leadCallOutcome,
          createdAt: new Date(),
          activityType: LeadHistoryType.CALL_OUTCOME,
          createdBy: userDoc.id,
          createdByUserName: `${userDoc.firstName} ${userDoc.lastName}`,
          reason: callOutcomeNote,
        });

        switch (leadCallOutcome) {
          case LeadCallOutcome.LOST:
            await lead.setSalesStage(SalesStage.LOST, {
              userId: userDoc.id,
              userName: userDoc.name,
              reason: callOutcomeNote,
            });
            break;
          case LeadCallOutcome.NOT_ANSWERED:
            await lead.setSalesStage(SalesStage.NEW_LEAD);
            break;
          case LeadCallOutcome.FOLLOW_UP:
            await lead.setSalesStage(SalesStage.FOLLOW_UP, {
              userId: userDoc.id,
              userName: userDoc.name,
            });
            break;
          default:
            break;
        }
        await userDoc.updateIsISOnCall(true);
        if (isComponentMountedRef.current) {
          showToast(texts.success);
          onCloseLeadModal(true);
        }
      }
    } catch (error) {
      showToast(texts.somethingWentWrong, { error: true });
    } finally {
      setIsLoading(false);
    }
  }, [
    lead,
    userDoc,
    isUserDocReady,
    showToast,
    leadCallOutcome,
    callOutcomeNote,
    isComponentMountedRef,
    showCallOutcomeFields,
    onCloseLeadModal,
  ]);

  return (
    <>
      {showCallOutcomeFields && (
        <RecordOutcomeSection>
          <InputLabel>{texts.leadOutcome}</InputLabel>
          <StyledSelectField value={leadCallOutcome} onChange={(e) => setLeadCallOutcome(e.target.value)}>
            {leadOutcomeOptions}
          </StyledSelectField>
          <InputLabel>{texts.outcomeNote}</InputLabel>
          <StyledTextField
            fullWidth
            multiline
            rows={3}
            value={callOutcomeNote}
            onChange={(e) => setCallOutcomeNote(e.target.value)}
          />
        </RecordOutcomeSection>
      )}
      <CallLogButton
        variant="contained"
        onClick={onSaveCallOutcome}
      >
        {showCallOutcomeFields ? texts.saveRecord : texts.recordOutcome}
      </CallLogButton>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

CallOutcome.propTypes = {
  lead: PropTypes.object.isRequired,
  isUserDocReady: PropTypes.bool.isRequired,
  userDoc: PropTypes.object.isRequired,
  onCloseLeadModal: PropTypes.func.isRequired,
};

export default CallOutcome;
