import moment from 'moment';
import { StripeErrorType } from '../SubscriptionMetadata';

const extractError = (errorResponse) => {
  let error;
  if (errorResponse.error) {
    error = errorResponse.error;
  } else {
    error = errorResponse.raw || errorResponse;
  }
  return {
    error: {
      type: error.type || StripeErrorType.UNKNOWN_ERROR,
      message: error.message || errorResponse.message,
      code: error.code,
    },
  };
};

const cancelSubscription = async ({
  subscriptionId,
  remote,
  processResponse,
  active,
  cancellationReason,
  cancelType,
}) => {
  try {
    const apiResponse = await remote('cancelStripeSubscription', {
      subscriptionId,
      active,
      cancellationReason,
      cancelType,
    });
    const responseToJson = await apiResponse.json();
    if (processResponse) {
      processResponse(responseToJson);
    }
    return responseToJson;
  } catch (error) {
    if (processResponse) {
      processResponse(extractError(error));
    }
    return error;
  }
};

const updateSubscription = async ({
  subscriptionId,
  remote,
  processResponse,
  updateParams,
  cancellationReason,
  cancelType,
}) => {
  try {
    const apiResponse = await remote('updateStripeSubscription', {
      subscriptionId,
      updateParams,
      cancellationReason,
      cancelType,
    });
    const responseToJson = await apiResponse.json();
    if (processResponse) {
      processResponse(responseToJson);
    }
    return responseToJson;
  } catch (error) {
    if (processResponse) {
      processResponse(extractError(error));
    }
    return error;
  }
};

const listInvoices = async (
  userId,
  remote,
  processResponse,
) => {
  try {
    const apiResponse = await remote('listInvoicesByUserId', { userId });
    const responseToJson = await apiResponse.json();
    if (processResponse) {
      processResponse(responseToJson);
    }
    return responseToJson;
  } catch (error) {
    if (processResponse) {
      processResponse(extractError(error));
    }
    return error;
  }
};

const retryInvoicePayment = async ({
  invoiceId,
  stripeAccountId,
  remote,
  processResponse,
}) => {
  try {
    const apiResponse = await remote('retryInvoicePayment', {
      invoiceId,
      stripeAccountId,
    });
    const responseToJson = await apiResponse.json();
    if (processResponse) {
      processResponse(responseToJson);
    }
    return responseToJson;
  } catch (error) {
    if (processResponse) {
      processResponse(extractError(error));
    }
    return error;
  }
};

const createSubscription = async ({
  planCode,
  email,
  paymentMethodId,
  remote,
  processSubscriptionFlowResponse,
  referralCode,
  startAtDate,
  cancelAt,
  coupon,
  contractId,
  leadId,
}) => {
  try {
    let apiResponse;
    const startAt = startAtDate?.valueOf();

    // Send the UTC offset to the server so we can calculate the correct start date.
    const clientUtcOffset = moment().utcOffset();

    if (contractId) {
      apiResponse = await remote('createStripeSubscriptionFromContract', {
        email,
        paymentMethodId,
        contractId,
        leadId,
        startAt,
        coupon,
        clientUtcOffset,
      });
    } else {
      apiResponse = await remote('createStripeFixedPriceSubscription', {
        planCode,
        email,
        paymentMethodId,
        referralCode,
        startAt,
        cancelAt: cancelAt?.valueOf(),
        coupon,
        clientUtcOffset,
      });
    }
    const stripeResponse = await apiResponse.json();
    if (stripeResponse.error) {
      processSubscriptionFlowResponse(extractError(stripeResponse));
    } else {
      processSubscriptionFlowResponse(stripeResponse);
    }
  } catch (error) {
    processSubscriptionFlowResponse(extractError(error));
  }
};

const submitNewPaymentMethod = async ({
  email,
  paymentMethodId,
  invoiceId,
  remote,
  processSubscriptionFlowResponse,
  stripeAccountId,
}) => {
  try {
    const apiResponse = await remote('retryFixedPriceSubscriptionInvoice', {
      email,
      paymentMethodId,
      invoiceId,
      stripeAccountId,
    });
    const stripeResponse = await apiResponse.json();
    if (stripeResponse.error) {
      processSubscriptionFlowResponse(extractError(stripeResponse));
    } else {
      processSubscriptionFlowResponse(stripeResponse);
    }
  } catch (error) {
    processSubscriptionFlowResponse(extractError(error));
  }
};

const authenticatePayment = async ({
  paymentIntent,
  invoiceId,
  remote,
  processSubscriptionFlowResponse,
  stripe,
  stripeAccountId,
}) => {
  try {
    const stripeResponse = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: paymentIntent.payment_method,
      },
    );
    if (stripeResponse.error) {
      processSubscriptionFlowResponse(extractError(stripeResponse));
    } else {
      const apiResponse = await remote('getUpdatedSubscriptionByInvoiceId', { invoiceId, stripeAccountId });
      const responseToJson = await apiResponse.json();
      processSubscriptionFlowResponse(responseToJson);
    }
  } catch (error) {
    processSubscriptionFlowResponse(extractError(error));
  }
};

const pauseStripeSubscription = async ({
  subscriptionId,
  contractId,
  remote,
  pausingReason,
  breakEndDate,
}) => {
  try {
    const apiResponse = await remote('pauseStripeSubscription', {
      subscriptionId,
      contractId,
      pausingReason,
      breakEndDate,
    });
    const responseToJson = await apiResponse.json();
    return responseToJson;
  } catch (error) {
    return error;
  }
};

const unpauseStripeSubscription = async ({
  subscriptionId,
  contractId,
  remote,
}) => {
  try {
    const apiResponse = await remote('unpauseStripeSubscription', {
      subscriptionId,
      contractId,
    });
    const responseToJson = await apiResponse.json();
    return responseToJson;
  } catch (error) {
    return error;
  }
};

const voidStripeInvoice = async ({
  invoiceId,
  reason,
  stripeAccountId,
  remote,
}) => {
  const apiResponse = await remote('voidStripeInvoice', {
    invoiceId,
    reason,
    stripeAccountId,
  });
  const responseToJson = await apiResponse.json();
  return responseToJson;
};

export {
  cancelSubscription,
  updateSubscription,
  listInvoices,
  retryInvoicePayment,
  createSubscription,
  submitNewPaymentMethod,
  authenticatePayment,
  pauseStripeSubscription,
  unpauseStripeSubscription,
  voidStripeInvoice,
};
