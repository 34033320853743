import styled from 'styled-components';

import colors from '../../styles/colors';

const Container = styled.div`
  width: 24px;
  height: 24px;
`;

const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => (color || colors.base.secondary2)};
  background-color: ${({ fill }) => (fill || colors.base.alpha)};
`;

export { Container, Circle };
