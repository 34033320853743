import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { ChatChannelListContext } from '@fitmoola/system2-chat';

import useSessionStore from '../../../../../hooks/useSessionStore';
import FeedContext from '../../../../context/FeedContext';
import ChatContext from '../../../../context/ChatContext';
import feedType from '../../../../context/FeedContext/config/feedTypes';
import ExternalCoachContext from '../../../../context/ExternalCoachContext';
import useMealPlanNavigation from '../../../../hooks/useMealPlanNavigation';
import { actionBarType } from '../../../../context/FeedContext/config/actionBarTypes';

import {
  FeedActionButton,
  StyledWorkoutIcon,
  StyledMealIcon,
  StyledVideoReviewIcon,
  StyledChatIcon,
  CheckinActionContainer,
  StyledSmartChatIcon,
} from './styles';
import texts from './texts.json';

const BirthdayAlert = 'BIRTHDAY';

const ActivityAction = ({ activity, onActivityActionClick }) => {
  const {
    selectedFeedType,
    setSelectedActionBarType,
    setSelectedActivity,
    setIsSmartFeedbackSelected,
  } = useContext(FeedContext);
  const {
    onQuickChatUserSelected,
    setChatModalOpen,
  } = useContext(ChatContext);
  const { navigateToNewAssignmentPage, navigateToRefreshPage } = useMealPlanNavigation();
  const { isCoachAssistant } = useSessionStore();
  const { coachDoc } = useContext(ExternalCoachContext);
  const { setActiveUserId } = useContext(ChatChannelListContext);

  const {
    feedbackGenerationConfig: {
      isEnabled: isAIGenerationEnabled = false,
    } = {},
  } = coachDoc;

  const handleCheckInFeedbackClick = (isSmart = false) => {
    setIsSmartFeedbackSelected(false);
    setChatModalOpen(false);
    setSelectedActionBarType(actionBarType.CHECK_IN_FEEDBACK);
    setSelectedActivity(activity);
    if (isSmart) {
      setIsSmartFeedbackSelected(true);
    }
  };

  const handleNeedNewMealPlanClick = () => {
    setChatModalOpen(false);
    setSelectedActivity(activity);
    if (activity.isRefresh) {
      navigateToRefreshPage(activity.user, true, { state: { isRoutedFromFeed: true } });
    } else {
      navigateToNewAssignmentPage(activity.user, true, { state: { isRoutedFromFeed: true } });
    }
  };

  const ChatButton = () => (
    <FeedActionButton
      startIcon={<StyledChatIcon />}
      onClick={() => {
        onQuickChatUserSelected(activity.user);
        setActiveUserId(activity.user);
      }}
    >
      {texts.CHAT}
    </FeedActionButton>
  );

  switch (selectedFeedType) {
    case feedType.WORKOUT_FEEDBACK_REQUEST:
      if (!activity.workoutVideoAvailable && !activity.isExerciseVideoFeedback) {
        return null;
      }
      return (
        <FeedActionButton
          startIcon={<StyledVideoReviewIcon />}
          variant="outlined"
          color="success"
          onClick={() => {
            const {
              user,
              workoutAssignmentId,
              isExerciseVideoFeedback: isV2ExerciseVideoFeedback,
              workoutVideoAvailable: isV1WorkoutVideoAvailable,
            } = activity;

            // This is the app v1 feedback and we need wokoutAssignmentId and user
            if (isV1WorkoutVideoAvailable && (!user || !workoutAssignmentId)) {
              const message = `Feed type ${feedType.WORKOUT_COMPLETE} activity doesn't have`
                + ' "user" and/or "workoutAssignmentId" when workout video is available';

              Sentry.captureMessage(message, {
                extra: {
                  user: user || 'undefined',
                  workoutAssignmentId: workoutAssignmentId || 'undefined',
                },
              });
            }

            // If the activity is a exercise video feedback from App V2,
            // we need to set the action bar type to EXERCISE_VIDEO_FEEDBACK
            const currentActionBarType = isV2ExerciseVideoFeedback
              ? actionBarType.EXERCISE_VIDEO_FEEDBACK
              : actionBarType.MOBILE_VIEW;
            setChatModalOpen(false);
            setSelectedActionBarType(currentActionBarType);
            setSelectedActivity(activity);
          }}
        >
          {texts.VIDEO_REVIEW}
        </FeedActionButton>
      );
    case feedType.SPECIAL:
      if (activity.alertType === BirthdayAlert) {
        return (
          <ChatButton />
        );
      }
      return null;
    case feedType.NEED_NEW_WORKOUTS:
      return (
        <>
          <FeedActionButton
            startIcon={<StyledWorkoutIcon />}
            variant="outlined"
            color="success"
            onClick={() => onActivityActionClick(feedType.NEED_NEW_WORKOUTS, activity.user)}
          >
            {texts.ASSIGN_WORKOUT}
          </FeedActionButton>
        </>
      );
    case feedType.CHECK_IN:
      return (
        <CheckinActionContainer>
          <FeedActionButton
            startIcon={<StyledChatIcon />}
            variant="outlined"
            color="success"
            onClick={() => handleCheckInFeedbackClick()}
          >
            {texts.CHECK_IN_FEEDBACK}
          </FeedActionButton>
          {isCoachAssistant && isAIGenerationEnabled && (
            <FeedActionButton
              startIcon={<StyledSmartChatIcon />}
              variant="outlined"
              color="success"
              onClick={() => handleCheckInFeedbackClick(true)}
            >
              {texts.GENERATE_FEEDBACK}
            </FeedActionButton>
          )}
        </CheckinActionContainer>
      );
    case feedType.NEED_NEW_MEAL_PLAN:
      return (
        <>
          <FeedActionButton
            startIcon={<StyledMealIcon />}
            variant="outlined"
            color="success"
            onClick={() => handleNeedNewMealPlanClick()}
          >
            { activity.isRefresh ? texts.REFRESH_MEAL_PLAN : texts.ASSIGN_MEAL_PLAN}
          </FeedActionButton>
        </>
      );
    default:
      return null;
  }
};

ActivityAction.propTypes = {
  activity: PropTypes.object,
  onActivityActionClick: PropTypes.func.isRequired,
};

ActivityAction.defaultProps = {
  activity: {},
};

export default ActivityAction;
