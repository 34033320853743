import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useToast from '../../../../../hooks/useToast';
import CoachConfig from '../../../../../Model/CoachConfig';
import SaveButton from '../../../../../../components/Button/ActionButtons/SaveButton';
import { SettingsAction } from '../styles';
import { StyledTextField } from '../../../../../../components/Inputs';
import {
  Card,
  HeaderContainer,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from '../../../../../../components/v2/Card';
import InputLabel from '../../../../../../components/v2/InputLabel';
import PrimaryButton from '../../../../../../components/Button/ActionButtons/PrimaryButton';
import texts from './texts.json';

// eslint-disable-next-line max-len
const notionDocLink = 'https://www.notion.so/system2fitness/How-to-Add-Keys-for-Google-Analytics-for-Coaches-and-Connect-to-BigQuery-1a6fe9cbfa7080cda194c3f23f914c4a';

const GoogleAnalyticsSettings = ({ coachConfig }) => {
  const { measurementId: coachConfigMeasurementId } = coachConfig;
  const [requestInFlight, setRequestInFlight] = useState(false);
  const [apiSecret, setApiSecret] = useState('');
  const [measurementId, setMeasurementId] = useState(coachConfigMeasurementId ?? '');
  const { showToast } = useToast();

  const handleSave = async () => {
    setRequestInFlight(true);
    try {
      await coachConfig.setGoogleAnalyticsConfig(measurementId, apiSecret);
      showToast(texts.gaConfigUpdateSuccess, { success: true });
    } catch (error) {
      showToast(texts.gaConfigUpdateFailure, { error: true });
    } finally {
      setRequestInFlight(false);
    }
  };

  const handleReset = async () => {
    setRequestInFlight(true);
    try {
      setApiSecret('');
      setMeasurementId('');
      await coachConfig.removeGoogleAnalyticsConfig(coachConfigMeasurementId);
      showToast(texts.gaConfigResetSuccess, { success: true });
    } catch (error) {
      showToast(texts.gaConfigResetFailure, { error: true });
    } finally {
      setRequestInFlight(false);
    }
  };

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.googleAnalyticsConfig}</Title>
          <SubTitle>
            {texts.googleAnalyticsConfigDescription}
            {' '}
            <a
              href={notionDocLink}
              target="_blank"
              rel="noreferrer"
            >
              (Notion Document)
            </a>
          </SubTitle>
        </TitleContainer>
      </HeaderContainer>

      <Container>
        <SettingsAction>
          <InputLabel>{texts.measurementId}</InputLabel>
          <StyledTextField
            disabled={!!coachConfigMeasurementId}
            value={measurementId}
            onChange={(e) => setMeasurementId(e.target.value)}
            placeholder="G-XXXXXXX123"
          />
        </SettingsAction>

        <SettingsAction>
          <InputLabel>{texts.apiSecret}</InputLabel>
          <StyledTextField
            disabled={!!coachConfigMeasurementId}
            value={coachConfigMeasurementId ? '•••••••••••••••••' : apiSecret}
            onChange={(e) => setApiSecret(e.target.value)}
            placeholder="XXXXXXXXXXXXXX"
          />
        </SettingsAction>

        {!coachConfigMeasurementId ? (
          <SaveButton
            onClick={handleSave}
            disabled={requestInFlight || !apiSecret || !measurementId}
            $fitToContent
          >
            {texts.save}
          </SaveButton>
        ) : (
          <PrimaryButton onClick={handleReset} $fitToContent variant="dimmed" disabled={requestInFlight}>
            Reset Configuration
          </PrimaryButton>
        )}
      </Container>
    </Card>
  );
};

GoogleAnalyticsSettings.propTypes = {
  coachConfig: PropTypes.instanceOf(CoachConfig).isRequired,
};

export default compose(observer)(GoogleAnalyticsSettings);
