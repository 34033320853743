import MessageTemplates from '../MessageTemplates';
import texts from './texts.json';
import {
  LeadsIcon,
  EmailIcon,
  MessageTempIcon,
  CoachInfoIcon,
} from './styles';
import LeadConfigurations from './LeadConfigurations';
import EmailTemplates from './EmailTemplates';
import CoachInfo from './CoachInformation';

const tabs = {
  LEADS: 'LEADS',
  EMAIL_TEMPLATES: 'EMAIL_TEMPLATES',
  MESSAGE_TEMPLATES: 'MESSAGE_TEMPLATES',
  COACH_INFO: 'COACH_INFO',
};

const tabsConfig = {
  [tabs.LEADS]: {
    label: texts.tabLabels[tabs.LEADS],
    icon: LeadsIcon,
    component: LeadConfigurations,
  },
  [tabs.EMAIL_TEMPLATES]: {
    label: texts.tabLabels[tabs.EMAIL_TEMPLATES],
    icon: EmailIcon,
    component: EmailTemplates,
  },
  [tabs.MESSAGE_TEMPLATES]: {
    label: texts.tabLabels[tabs.MESSAGE_TEMPLATES],
    icon: MessageTempIcon,
    component: MessageTemplates,
  },
  [tabs.COACH_INFO]: {
    label: texts.tabLabels[tabs.COACH_INFO],
    icon: CoachInfoIcon,
    component: CoachInfo,
  },
};

export {
  tabs,
  tabsConfig,
};
