import React from 'react';
import PropTypes from 'prop-types';

import DialogModal from '../../../../components/DialogModal';
import { FormType } from '../../../Model/Form';
import {
  HeaderRow,
  TitleContainer,
  Title,
} from '../../../../components/v2/Header';

import {
  Container,
  ButtonContainer,
  StyledButton,
} from './styles';
import texts from './texts.json';

const TypeSelectModal = ({
  showModal,
  onClose,
  onConfirm,
}) => (
  <DialogModal
    open={showModal}
    onClose={onClose}
  >
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>{texts.type}</Title>
        </TitleContainer>
      </HeaderRow>
      <ButtonContainer>
        <StyledButton
          variant="contained"
          onClick={() => onConfirm(FormType.LEAD)}
        >
          {texts.lead}
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={() => onConfirm(FormType.POST_PAYMENT)}
        >
          {texts.postPayment}
        </StyledButton>
      </ButtonContainer>
    </Container>
  </DialogModal>
);

TypeSelectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default TypeSelectModal;
