import { Collection } from 'firestorter';

import Collections from '../utils/collections';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';

class InternalAssignment extends BaseDocument {
  get userId() {
    return this.data.userId;
  }

  get userName() {
    return this.data.userName;
  }

  get coach() {
    return this.data.coach;
  }

  get role() {
    return this.data.role;
  }

  get createdAt() {
    return this.data.createdAt.toDate();
  }

  disableAssignment = async () => (
    this.update({
      current: false,
    })
  );

  static async getAllCurrentAssignments(userId = null, role = null, coachId = null) {
    const internalAssignmentCollection = new Collection(firestorePaths.INTERNAL_ASSIGNMENT,
      {
        query: (ref) => {
          let query = ref.where('current', '==', true);
          if (userId) {
            query = query.where('userId', '==', userId);
          }
          if (role) {
            query = query.where('role', '==', role);
          }
          if (coachId) {
            query = query.where('coach', '==', coachId);
          }
          return query;
        },
        createDocument: (source, options) => new InternalAssignment(source, options),
      });
    await InternalAssignment.initCollection(internalAssignmentCollection);
    return internalAssignmentCollection;
  }

  static async getAllCurrentAssignmentsByUserId(userId) {
    return InternalAssignment.getAllCurrentAssignments(userId);
  }

  static async getAllCurrentAssignmentsByRole(role) {
    return InternalAssignment.getAllCurrentAssignments(null, role);
  }

  static async addInternalAssignment(coachId, userId, firstName, role, createdBy) {
    const internalAssignmentCollection = new Collection(Collections.INTERNAL_ASSIGNMENT);
    await internalAssignmentCollection.add({
      coach: coachId,
      userId,
      role,
      createdBy,
      current: true,
      createdAt: new Date(),
      userName: firstName,
    });
  }
}

export default InternalAssignment;
