import React from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';

import {
  Container,
  Card,
  HeaderContainer,
  SubTitle,
  Title,
  TitleContainer,
} from '../../../../../../components/v2/Card';
import { ReactComponent as InstagramIcon } from '../../../../../../assets/icons/instagram.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';

import InstagramUser, { accountStatus } from '../../../../../../Model/InstagramUser';
import texts from './texts.json';
import { instagramAuthUrl } from './constants';

/**
 * Instagram Configuration component
 * @param {Object} props - Component props
 * @param {InstagramUser|null} props.instaUser - Instagram user data
 * @returns {JSX.Element} Instagram configuration card
 */
function InstagramConfiguration({ instaUser }) {
  const connectedStatus = instaUser?.accountStatus;

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
          <SubTitle>{texts.description}</SubTitle>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <PrimaryButton
          disabled={connectedStatus === accountStatus.AUTHORIZED}
          onClick={() => {
            window.location.href = instagramAuthUrl;
          }}
          isLoading
          $fitToContent
          icon={<InstagramIcon />}
        >
          {connectedStatus === accountStatus.AUTHORIZED
            ? format(texts.instagramAccountConnected, { handle: instaUser?.username })
            : texts.connectInstagramAccount}
        </PrimaryButton>
      </Container>
    </Card>
  );
}

InstagramConfiguration.propTypes = {
  instaUser: PropTypes.instanceOf(InstagramUser),
};

InstagramConfiguration.defaultProps = {
  instaUser: undefined,
};

export default InstagramConfiguration;
