import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import NewLeadNotificationModal from '../components/Leads/components/NewLeadNotificationModal';
import useSessionStore from '../../hooks/useSessionStore';
import useUserDoc from '../hooks/useUserDoc';
import ActiveRoute from './ActiveRoute';

const ToolsRoute = ({
  path,
  component: RenderComponent,
  pageId,
  props,
}) => {
  const { userId } = useSessionStore();
  const { isReady, userDoc } = useUserDoc(userId);

  const onRender = () => (
    <ActiveRoute pageId={pageId}>
      <RenderComponent {...props} />
    </ActiveRoute>
  );

  return (
    <>
      <Route
        path={path}
        render={onRender}
      />
      {(isReady && userDoc.instaCallIS) && <NewLeadNotificationModal />}
    </>
  );
};

ToolsRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  pageId: PropTypes.string.isRequired,
  props: PropTypes.object,
};

ToolsRoute.defaultProps = {
  props: {},
};

export default ToolsRoute;
