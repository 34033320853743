import React from 'react';
import PropTypes from 'prop-types';
import { MEDIA_TYPES } from '../constants';
import {
  CarouselControlButton,
  CarouselControls,
  MediaDataContainer,
  DataLabel,
  DataSection,
  DataSections,
  MediaDataLayout,
} from './styles';
import InstagramUserDetails from './InstagramUserDetails';
import text from './text.json';

const InstagramMediaData = ({
  mediaData, onCarouselNext, onCarouselPrev, carouselIndex,
}) => {
  const isCarousel = mediaData?.mediaType === MEDIA_TYPES.CAROUSEL_ALBUM;
  return (
    <MediaDataLayout>
      <MediaDataContainer>
        <DataSections>
          <DataSection>
            <DataLabel>{text.labels.caption}</DataLabel>
            <div>{mediaData?.caption ? mediaData.caption : <code>{text.noCaptionFound}</code>}</div>
          </DataSection>
          <DataSection>
            <DataLabel>{text.labels.mediaProductType}</DataLabel>
            <div>{mediaData?.mediaProductType}</div>
          </DataSection>
          <DataSection>
            <DataLabel>{text.labels.timestamp}</DataLabel>
            <time dateTime={mediaData?.timestamp.toDate().toISOString()}>
              {mediaData?.timestamp.toDate().toString()}
            </time>
          </DataSection>
          {mediaData?.mediaProductType !== 'STORY' && (
            <DataSection>
              <DataLabel>{text.labels.permalink}</DataLabel>
              <div>
                <a href={mediaData.permalink} target="_blank" rel="noreferrer">
                  {mediaData.permalink}
                </a>
              </div>
            </DataSection>
          )}
          {mediaData?.instagramUserId && <InstagramUserDetails userId={mediaData.instagramUserId} />}
        </DataSections>
      </MediaDataContainer>
      {isCarousel && (
        <CarouselControls>
          <div>
            <CarouselControlButton size="small" onClick={onCarouselPrev}>
              {text.carouselControls.previous}
            </CarouselControlButton>
            <CarouselControlButton size="small" onClick={onCarouselNext}>
              {text.carouselControls.next}
            </CarouselControlButton>
          </div>
          <div>
            {carouselIndex + 1}
            {' of '}
            {mediaData.children.length}
          </div>
        </CarouselControls>
      )}
    </MediaDataLayout>
  );
};

InstagramMediaData.propTypes = {
  mediaData: PropTypes.object.isRequired,
  carouselIndex: PropTypes.number.isRequired,
  onCarouselNext: PropTypes.func.isRequired,
  onCarouselPrev: PropTypes.func.isRequired,
};

export default InstagramMediaData;
