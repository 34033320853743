import React from 'react';
import PropTypes from 'prop-types';
import InstagramMediaItem from './InstagramMediaItem';

const InstagramMediaCarousel = ({ mediaItems, activeIndex }) => (
  <InstagramMediaItem mediaId={mediaItems[activeIndex].id} mediaType={mediaItems[activeIndex].mediaType} />
);

InstagramMediaCarousel.propTypes = {
  mediaItems: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default InstagramMediaCarousel;
