import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: start;
  gap: 20px;
`;

export const TextBlock = styled.div`
  font-size: 14px;
  max-width: 190px;        
  word-wrap: break-word;
  overflow-wrap: break-word;  
  white-space: normal;
`;

export const BtnContainer = styled.div`
  width: fit-content;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  align-items: ${(props) => (props.row ? 'center' : 'start')};
  gap: 10px;
`;
