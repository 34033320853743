import React from 'react';

const initialValues = {
  isLoading: false,
  mediaUrl: null,
  error: null,
};

const InstagramMediaContext = React.createContext(initialValues);

export default InstagramMediaContext;
export {
  initialValues,
};
