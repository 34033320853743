import React from 'react';
import PropTypes from 'prop-types';

import texts from './texts.json';
import { StyledFilter } from './styles';

const TableFilter = ({
  onSelect,
  initialSelection,
  options,
  title,
}) => (
  <StyledFilter
    inputLabel={title}
    options={options}
    initialValues={initialSelection}
    onValuesSelected={onSelect}
  />
);

TableFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  initialSelection: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
};

TableFilter.defaultProps = {
  title: texts.title,
};

export default TableFilter;
