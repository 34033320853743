import { useContext, useMemo } from 'react';
import { formatCurrency } from '../../utils/formatters';
import InternalAssignmentsContext from '../context/InternalAssignmentsContext';
import { Preset } from '../components/Commissions/presets';

const COMMISSION_CURRENCY = 'usd';

const useCalculateCommissionByUser = ({
  role, selectedUserList, rows, byUser,
}) => {
  const {
    crxUsers,
    isUsers,
    camUsers,
    cpsUsers,
  } = useContext(InternalAssignmentsContext);
  let roleUserList;
  switch (role) {
    case Preset.CRX:
      roleUserList = crxUsers;
      break;
    case Preset.INSIDE_SALES:
      roleUserList = isUsers;
      break;
    case Preset.CAM:
      roleUserList = camUsers;
      break;
    case Preset.CPS:
      roleUserList = cpsUsers;
      break;
    default:
      roleUserList = [];
  }

  const userList = useMemo(
    () => roleUserList.filter((user) => selectedUserList.includes(user.userId)), [roleUserList, selectedUserList],
  );

  const data = useMemo(() => {
    if (!byUser) {
      return rows;
    }
    const result = {};
    userList.forEach(({ userId: id, userName: name }) => {
      let totalCommission = 0;
      rows.forEach((ele) => {
        const item = ele.data;
        switch (role) {
          case Preset.CRX:
            if (item.assignments.CRX?.includes(id)) {
              totalCommission += item.commissions.crxCommission;
            }
            break;
          case Preset.INSIDE_SALES:
            if (item.sellerId === id) {
              totalCommission += item.commissions.insideSalesCommission;
            }
            break;
          case Preset.CAM:
            if (item.assignments.CAM?.includes(id)) {
              totalCommission += item.commissions.camCommission;
            }
            break;
          case Preset.CPS:
            if (item.assignments.CPS?.includes(id)) {
              totalCommission += item.commissions.cpsCommission;
            }
            break;
          default:
            break;
        }
      });
      if (totalCommission > 0) {
        result[id] = { name, totalCommission };
      }
    });

    const resultArray = Object.entries(result).map(([id, { name, totalCommission }]) => ({
      id,
      name,
      totalCommission: formatCurrency(totalCommission, COMMISSION_CURRENCY, { minimumFractionDigits: 2 }),
    }));

    return resultArray;
  }, [
    byUser,
    rows,
    role,
    userList,
  ]);

  return data;
};

export default useCalculateCommissionByUser;
