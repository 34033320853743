import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import format from 'string-template';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  ArrowBack,
  ArrowForward,
} from '@mui/icons-material';

import {
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
} from '@mui/lab';
import {
  IonAvatar,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
} from '@ionic/react';

import {
  combineDateAndTimeWithUTCOffset,
  DateFormat,
  isToday,
} from '../../../../../../../utils/date';
import BroadcastMessageRequest, {
  BroadcastMessageRequestStatus,
} from '../../../../../../../Model/BroadcastMessageRequest';
import useToast from '../../../../../../hooks/useToast';
import useStorage, { StorageProcessState } from '../../../../../../../hooks/useStorage';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import { MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from '../../../../util/fileUpload';
import { getUTCOffset } from '../../../../../../../utils/time';
import { pathPlaceholder, storagePaths } from '../../../../../../../utils/firebasePaths';
import UserContext from '../../../../../../../context/UserContext';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import FileAttachmentContainer from '../../../../../../components/AttachmentsContainer';
import Coach from '../../../../../../../Model/Coach';
import defaultUser from '../../../../assets/icon/default-user.svg';

import { StyledTextField } from '../../styles';
import ChatContext from '../../../../../../context/ChatContext';
import {
  ItemContainer,
  MessageDate,
  MessageText,
  ActionsContainer,
  InfoContainer,
  EditContainer,
  InfoText,
  StyledAlert,
  StyledListContainer,
  MenuContainer,
  SearchBar,
  NavigationContainer,
  StyledButton,
  StyledText,
} from './styles';
import texts from './texts.json';

const ItemStatus = {
  INITIAL: 'INITIAL',
  EDITING: 'EDITING',
  DELETING: 'DELETING',
};

const RECIPIENTS_PER_PAGE = 5;

const ScheduledMessageItem = ({
  broadcastMessageRequestDoc,
  onMessageDelete,
}) => {
  const {
    message,
    sendAt,
    attachmentsRefs,
    recipients,
  } = broadcastMessageRequestDoc;
  const {
    sendBroadcastMessage,
    setIsBroadcastModalOpen,
  } = useContext(ChatContext);

  const { coachDoc } = useContext(ExternalCoachContext);
  const timezoneOffset = getUTCOffset(coachDoc.timeZone || moment.tz.guess());
  // remove uuid from file name
  const sanitizeFileName = (encodedName) => encodedName
    .replace(/-[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/i, '');

  const [sendDate, setSendDate] = useState(moment(sendAt).utcOffset(timezoneOffset));
  const [sendTime, setSendTime] = useState(moment(sendAt).utcOffset(timezoneOffset));
  const [messageText, setMessageText] = useState(message);
  const [itemStatus, setItemStatus] = useState(ItemStatus.INITIAL);
  const [attachments, setAttachments] = useState(attachmentsRefs.map((ref) => ({
    name: sanitizeFileName(ref.split('/').pop()),
    ref,
    isUploaded: true,
  })));
  const [isUploading, setIsUploading] = useState(false);
  const { showToast } = useToast();
  const { userId } = useContext(UserContext);
  const { uploadAttachments, deleteAttachments } = useStorage();
  const isComponentMountedRef = useComponentMounted();

  // States for recipient selection
  const [allRecipients, setAllRecipients] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState(recipients || []);
  const [isReady, setIsReady] = useState(false);
  const [userSearchValue, setUserSearchValue] = useState('');
  const [recipientIndex, setRecipientIndex] = useState(0);

  useEffect(() => {
    const init = async () => {
      const usersListOfCoach = await Coach.getBroadcastMessageUsers(userId);
      const sortedUsersListOfCoach = [...usersListOfCoach].sort((a, b) => (a.name > b.name ? 1 : -1));
      const clientList = sortedUsersListOfCoach.map((user) => ({
        id: user.id,
        userDoc: user,
      }));

      if (isComponentMountedRef.current) {
        setAllRecipients(clientList);
        setSelectedRecipients(
          recipients.map((recipient) => (
            clientList.find((client) => client.id === recipient.id) || recipient
          )),
        );
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [isReady, userId, isComponentMountedRef, recipients]);

  const filterUsersByName = useCallback((users) => {
    if (!userSearchValue) {
      return users;
    }
    return users.filter((user) => user.userDoc.name.toLowerCase().includes(userSearchValue.toLowerCase()));
  }, [userSearchValue]);

  const handleCheckChange = useCallback((checked, id) => {
    if (checked) {
      const newUser = allRecipients.find((recipient) => recipient.id === id);
      if (newUser) {
        setSelectedRecipients([...selectedRecipients, newUser]);
      }
    } else {
      setSelectedRecipients([...selectedRecipients.filter((recipient) => recipient.id !== id)]);
    }
  }, [selectedRecipients, allRecipients]);

  const onNavigationClick = useCallback((nameFilteredRecipients, forward = true) => {
    if (forward) {
      setRecipientIndex((prevIndex) => {
        if (nameFilteredRecipients.length > (prevIndex + 1) * RECIPIENTS_PER_PAGE) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    } else {
      setRecipientIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      });
    }
  }, []);

  const onChangeSearch = useCallback((e) => {
    setUserSearchValue(e.target.value);
    setRecipientIndex(0);
  }, []);

  const uploadMessageAttachments = useCallback(async () => {
    // adding already uploaded files to the array
    const fileAttachmentRefs = attachments.reduce((arr, current) => {
      if (current.isUploaded) {
        arr.push(current.ref);
      }
      return arr;
    }, []);

    // filtering files that are not uploaded yet
    const filesToUpload = attachments.filter((file) => !file.isUploaded);
    const storagePath = format(storagePaths.COACH_BROADCAST_MESSAGES_ATTACHMENTS, {
      [pathPlaceholder.COACH_ID]: userId,
    });

    setIsUploading(true);
    try {
      const result = await uploadAttachments(filesToUpload, storagePath);

      let storageTaskFailed = false;
      const successfulUploads = [];
      result.forEach((res) => {
        if (res.state === StorageProcessState.SUCCESS) {
          successfulUploads.push(res.fileRef);
        } else {
          storageTaskFailed = true;
        }
      });
      if (storageTaskFailed) {
        // If some upload fails, we should stop the saving process
        // and delete the files that were uploaded (if there is any)
        if (fileAttachmentRefs.length) {
          const filesToDelete = successfulUploads.map((ref) => ({ storagePath: ref }));
          await deleteAttachments(filesToDelete);
        }
      }
      fileAttachmentRefs.push(...successfulUploads);
      if (isComponentMountedRef.current) {
        setIsUploading(false);
      }
      return { fileAttachmentRefs, storageTaskFailed };
    } catch (error) {
      if (isComponentMountedRef.current) {
        setIsUploading(false);
      }
      Sentry.captureException(error, {
        extra: {
          coachId: userId,
        },
      });
      // Returning this to handle error messages from the caller function
      return { storageTaskFailed: true };
    }
  }, [
    attachments,
    userId,
    uploadAttachments,
    deleteAttachments,
    isComponentMountedRef,
  ]);

  const getAttachmentRefs = useCallback(async () => {
    let fileAttachments = [];
    let error = false;
    if (attachments.length) {
      const { storageTaskFailed, fileAttachmentRefs } = await uploadMessageAttachments();
      if (storageTaskFailed) {
        error = true;
      }
      fileAttachments = fileAttachmentRefs;
    }
    return {
      fileAttachments,
      error,
    };
  }, [
    attachments,
    uploadMessageAttachments,
  ]);

  const sendNow = useCallback(async () => {
    const usersListOfCoach = await Coach.getBroadcastMessageUsers(userId);
    const results = usersListOfCoach.reduce((prev, user) => {
      // eslint-disable-next-line no-param-reassign
      prev[user.id] = user;
      return prev;
    }, {});

    const additionalFields = {};
    const { fileAttachments, error } = await getAttachmentRefs();
    if (error) {
      showToast(texts.uploadError, { error: true });
      return;
    }
    additionalFields.attachmentsRefs = fileAttachments;

    const mappedRecipients = recipients.map((recipient) => ({ id: recipient.id, userDoc: results[recipient.id] }));

    await sendBroadcastMessage(mappedRecipients, messageText, additionalFields);
    await broadcastMessageRequestDoc.updateStatus(BroadcastMessageRequestStatus.IN_PROGRESS);
    showToast(texts.broadcastMessageSendNow);
    setIsBroadcastModalOpen(false);
  }, [
    broadcastMessageRequestDoc,
    getAttachmentRefs,
    messageText,
    recipients,
    sendBroadcastMessage,
    showToast,
    userId,
    setIsBroadcastModalOpen,
  ]);

  const onSave = useCallback(async () => {
    const { fileAttachments, error } = await getAttachmentRefs();
    if (error) {
      showToast(texts.uploadError, { error: true });
      return;
    }
    // Convert to UTC after adding date and time
    const newSendAt = combineDateAndTimeWithUTCOffset(sendDate, sendTime, timezoneOffset).valueOf();
    await broadcastMessageRequestDoc.update({
      message: messageText,
      sendAt: newSendAt,
      attachmentsRefs: fileAttachments,
      recipients: selectedRecipients.map(({ id, userDoc }) => ({
        id,
        firstName: userDoc.firstName,
      })),
    });

    setAttachments(fileAttachments.map((ref) => ({
      name: sanitizeFileName(ref.split('/').pop()),
      ref,
      isUploaded: true,
    })));
    setSendTime(moment(newSendAt).local().utcOffset(timezoneOffset));
    setSendDate(moment(newSendAt).local().utcOffset(timezoneOffset));
    setItemStatus(ItemStatus.INITIAL);
  }, [
    broadcastMessageRequestDoc,
    messageText,
    sendDate,
    sendTime,
    getAttachmentRefs,
    showToast,
    timezoneOffset,
    selectedRecipients,
  ]);

  const onDelete = useCallback(async () => {
    await broadcastMessageRequestDoc.delete();
    onMessageDelete(broadcastMessageRequestDoc.id);
    setItemStatus(ItemStatus.INITIAL);
  }, [
    broadcastMessageRequestDoc,
    onMessageDelete,
  ]);

  // If the selected date is today, then the user cannot select a time in the past
  const minTime = useMemo(() => (
    isToday(moment(sendDate).utcOffset(timezoneOffset)) ? moment().utcOffset(timezoneOffset) : null
  ), [
    sendDate,
    timezoneOffset,
  ]);

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const filesArray = [...files];
      const validFiles = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
      if (validFiles) {
        const formattedFiles = filesArray.map((file) => {
          const lastDotIndex = file.name.lastIndexOf('.');
          return {
            dataUrl: URL.createObjectURL(file),
            format: file.name.slice(lastDotIndex + 1),
            fileName: file.name.slice(0, lastDotIndex),
            fileType: file.type,
            name: file.name,
            isUploaded: false,
          };
        });
        setAttachments((prev) => [...prev, ...formattedFiles]);
      } else {
        showToast(format(texts.fileSizeError, {
          maxFileSize: MAX_FILE_UPLOAD_SIZE_MB, // Bytes to MB
        }), { error: true });
      }
    }
  }, [
    showToast,
  ]);

  const onFileRemove = useCallback(async (index) => {
    const resultAttachments = [...attachments];
    resultAttachments.splice(index, 1);
    setAttachments(resultAttachments);
  }, [
    attachments,
  ]);

  const onClearSearch = useCallback(() => {
    setUserSearchValue('');
    setRecipientIndex(0);
  }, []);

  const renderRecipientList = useCallback(() => {
    if (!isReady) {
      return <IonItem><IonSpinner /></IonItem>;
    }

    const nameFilteredRecipients = filterUsersByName(allRecipients);
    if (allRecipients.length > 0) {
      return (
        <StyledListContainer>
          <MenuContainer>
            <SearchBar
              clearSearch={onClearSearch}
              value={userSearchValue}
              onChange={onChangeSearch}
              placeholder={texts.searchUser}
            />
            <NavigationContainer>
              <StyledButton onClick={() => onNavigationClick(nameFilteredRecipients, false)}>
                <ArrowBack fontSize="16" />
              </StyledButton>
              <StyledText>
                {format(texts.pageLabel, {
                  pageNumber: recipientIndex + 1,
                  pageCount: Math.ceil(nameFilteredRecipients.length / RECIPIENTS_PER_PAGE),
                })}
              </StyledText>
              <StyledButton onClick={() => onNavigationClick(nameFilteredRecipients)}>
                <ArrowForward fontSize="16" />
              </StyledButton>
            </NavigationContainer>
          </MenuContainer>
          <IonList>
            {nameFilteredRecipients
              .slice(recipientIndex * RECIPIENTS_PER_PAGE, (recipientIndex + 1) * RECIPIENTS_PER_PAGE)
              .map((recipient) => (
                <IonItem key={recipient.id}>
                  <IonAvatar slot="start">
                    <img
                      src={recipient.userDoc.avatarUrl || defaultUser}
                      alt={recipient.userDoc.name}
                    />
                  </IonAvatar>
                  <IonLabel>{recipient.userDoc.name}</IonLabel>
                  <IonCheckbox
                    onClick={(e) => handleCheckChange(e.target.checked, recipient.id)}
                    checked={selectedRecipients.map((user) => user.id).includes(recipient.id)}
                    slot="end"
                  />
                </IonItem>
              ))}
          </IonList>
        </StyledListContainer>
      );
    }

    return <IonItem><IonLabel>{texts.noActiveClients}</IonLabel></IonItem>;
  }, [isReady,
    filterUsersByName,
    allRecipients,
    onClearSearch,
    userSearchValue,
    onChangeSearch,
    recipientIndex,
    onNavigationClick,
    selectedRecipients,
    handleCheckChange]);

  const renderItemContent = useCallback(() => {
    switch (itemStatus) {
      case ItemStatus.EDITING:
        return (
          <EditContainer>
            {renderRecipientList()}
            <StyledTextField
              label={texts.message}
              value={messageText}
              fullWidth
              multiline
              rows={4}
              onChange={(e) => setMessageText(e.target.value)}
            />
            {!!coachDoc.timeZone && (
              <StyledAlert severity="info">{texts.scheduleWarningMessage}</StyledAlert>
            )}
            <DatePicker
              label={texts.selectDate}
              value={sendDate}
              onChange={(newValue) => setSendDate(newValue)}
              minDate={moment().utcOffset(timezoneOffset)}
              maxDate={moment().utcOffset(timezoneOffset).add(3, 'months')}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
            <TimePicker
              label={texts.selectTime}
              value={sendTime}
              onChange={(newValue) => setSendTime(newValue)}
              minTime={minTime}
              minutesStep={5}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
            <FileAttachmentContainer
              fileAttachments={attachments}
              onFileRemoved={onFileRemove}
              onFilesSelected={onFilesSelected}
              isUploading={isUploading}
            />
            <ActionsContainer>
              <Button
                variant="contained"
                onClick={onSave}
                color="success"
              >
                {texts.button.save}
              </Button>
              <Button
                variant="contained"
                onClick={sendNow}
                color="primary"
              >
                {texts.button.send}
              </Button>
            </ActionsContainer>
          </EditContainer>
        );
      case ItemStatus.DELETING:
        return (
          <InfoContainer>
            <InfoText>{texts.deleteConfirm}</InfoText>
          </InfoContainer>
        );
      case ItemStatus.INITIAL:
      default:
        return (
          <InfoContainer>
            <MessageDate>{moment(sendDate).format(DateFormat.DATE_MONTH_YEAR_TIME_SHORT_DISPLAY_FORMAT)}</MessageDate>
            <MessageText>{messageText}</MessageText>
            {attachments.length !== 0 && (
              <Tooltip title={texts.tooltip.attachments}>
                <AttachFileIcon />
              </Tooltip>
            )}
          </InfoContainer>
        );
    }
  }, [
    itemStatus,
    messageText,
    onSave,
    minTime,
    sendDate,
    sendTime,
    attachments,
    onFileRemove,
    onFilesSelected,
    isUploading,
    timezoneOffset,
    coachDoc,
    sendNow,
    renderRecipientList,
  ]);

  const renderActionButtons = useCallback(() => {
    switch (itemStatus) {
      case ItemStatus.EDITING:
        return <></>;
      case ItemStatus.DELETING:
        return (
          <ActionsContainer>
            <Button
              onClick={() => setItemStatus(ItemStatus.INITIAL)}
              color="info"
            >
              {texts.button.cancel}
            </Button>
            <Button
              onClick={onDelete}
              color="warning"
            >
              {texts.button.delete}
            </Button>
          </ActionsContainer>
        );
      case ItemStatus.INITIAL:
      default:
        return (
          <ActionsContainer>
            <Tooltip title={texts.tooltip.edit}>
              <IconButton
                onClick={() => setItemStatus(ItemStatus.EDITING)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={texts.tooltip.delete}>
              <IconButton
                onClick={() => setItemStatus(ItemStatus.DELETING)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ActionsContainer>
        );
    }
  }, [
    itemStatus,
    onDelete,
  ]);

  return (
    <ItemContainer>
      {renderItemContent()}
      {renderActionButtons()}
    </ItemContainer>
  );
};

ScheduledMessageItem.propTypes = {
  broadcastMessageRequestDoc: PropTypes.instanceOf(BroadcastMessageRequest).isRequired,
  onMessageDelete: PropTypes.func.isRequired,
};

export default observer(ScheduledMessageItem);
