import { useContext, useEffect } from 'react';
import InstagramMediaContext from '../context/InstagramMediaContext';

const useInstagramMedia = (mediaId) => {
  const {
    mediaUrl, isLoading, error, setMediaId,
  } = useContext(InstagramMediaContext);

  useEffect(() => {
    if (setMediaId && mediaId !== null) {
      setMediaId(mediaId);
    }
  }, [mediaId, setMediaId]);

  return {
    mediaUrl,
    isLoading,
    error,
    setMediaId,
  };
};

export default useInstagramMedia;
