import {
  Box,
  Button,
} from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../styles/colors';
import { sansSerifHeadlineFontStyles } from '../../../../../styles/text';
import Tabs from '../../../Tabs';

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const LeadInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.secondary3};
  padding: 50px 50px 20px;
`;

const ModalContent = styled.div`
  padding: 12px 0 12px 50px;
`;

const Title = styled.div`
  ${sansSerifHeadlineFontStyles}
  font-size: 36px;
  font-weight: 400;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  place-content: space-between;
  margin-bottom: 15px;
`;

const ContactInfoSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const ActionButton = styled(Button)`
  color: ${colors.base.alpha};
  :hover {
    background-color: ${colors.base.secondary};
  }
  border-radius: 84px;
  height: 30px;
  font-weight: 400;
  font-size: 16px;
  text-transform: none;
  text-wrap: nowrap;
  min-width: unset;

  /* TODO: We have to standardize all buttons and colors, to avoid having hardcoded values */
  background-color: ${({ provider }) => {
    switch (provider) {
      case 'msg':
        return '#0FB904';
      case 'email':
        return '#FF7E06';
      case 'chat':
        return '#299CFC';
      case 'sentToReschedule':
        return '#4C03C2';
      case 'rescheduledManually':
        return '#A106FF';
      case 'noShow':
        return colors.shades.danger1;
      case 'paymentLink':
        return colors.shades.gamma10;
      case 'front':
        return colors.shades.secondary1;
      default:
        return colors.shades.alpha1;
    }
  }};
`;

const QuestionsContainer = styled.li`
  margin: 0 0 15px;
  font-size: 18px;
`;

const Question = styled.div`
  font-size: 1em;
  margin: 0 0 5px;
  font-weight: 600;
`;

const Answer = styled.p`
  font-size: 0.8em;
  margin: 0;
  white-space: pre-wrap;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const RightSection = styled.div`
  display: flex;
  width: 35%;
  background-color: ${colors.shades.gamma5};
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  padding: 0;
  width: 50%;
  border-color: ${colors.base.secondary3};
  border-bottom: 1px solid ${colors.base.secondary3};

  .MuiTab-root {
    min-height: 48px;
    min-width: 100px;
  }
`;

const TabHeaderRow = styled.div`
  margin:4%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export {
  Answer,
  ContactInfoSection,
  ModalContent,
  LeadInfoSection,
  Question,
  Title,
  TitleSection,
  ActionButton,
  QuestionsContainer,
  MainSection,
  RightSection,
  Container,
  ButtonsContainer,
  TabHeaderRow,
  StyledTabs,
};
