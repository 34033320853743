import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Alert } from '@mui/material';

import {
  HeaderRow,
  SubTitle,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Header';
import ExternalCoachContext, {
  withCoachProductsContextReady,
  withExternalCoachContextReady,
} from '../../../../context/ExternalCoachContext';
import { CardsContainer } from '../../../../../components/v2/Card';
import useSessionStore from '../../../../../hooks/useSessionStore';
import LabelCheckbox from '../../../../components/LabelCheckbox';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { ButtonContainer, StyledAddIcon, StyledButton } from './styles';
import AddProductModal from './components/AddProductModal';
import ProductView from './components/ProductView';
import texts from './texts.json';
import FreeProductSignUpLink from './components/FreeProductSignUpLink';
import InternalConfigs from './components/InternalConfigs';

const ProductsConfig = () => {
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [productCollection, setProductCollection] = useState({ docs: [] });
  const [isLoading, setIsLoading] = useState(true);
  const { externalCoachDoc, coachDoc } = useContext(ExternalCoachContext);
  const isMissingConfig = (!externalCoachDoc.baseFee && !coachDoc.feesConfig.baseFee?.value)
    || !externalCoachDoc.postPaymentFormLink;
  const { isAdmin } = useSessionStore();
  const isComponentMountedRef = useComponentMounted();

  // Hide default products ,hidden products & archived products
  const filteredProducts = productCollection.docs.filter((product) => !product.isDefault && !product.isHidden
    && (showArchived || !product.isArchived));

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const productCol = await externalCoachDoc.getProducts(true);
      if (isComponentMountedRef.current) {
        setProductCollection(productCol);
        setIsLoading(false);
      }
    };
    init();
  }, [externalCoachDoc, isComponentMountedRef]);

  return (
    <CardsContainer>
      <FreeProductSignUpLink />

      <HeaderRow>
        <TitleContainer>
          <Title>{texts.title}</Title>
          <SubTitle>{isMissingConfig && isAdmin ? texts.missingConfigSubTitle : texts.subTitle}</SubTitle>
        </TitleContainer>
        {!isMissingConfig && (
          <ButtonContainer>
            <LabelCheckbox
              description={texts.showArchived}
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
            />
            <StyledButton onClick={() => setShowAddProductModal(true)}>
              <StyledAddIcon />
              {texts.addNewProduct}
            </StyledButton>
          </ButtonContainer>
        )}
      </HeaderRow>
      {!isMissingConfig && (
        <>
          {filteredProducts.map((product) => (
            <ProductView product={product} key={product.id} showArchived={showArchived} />
          ))}
          {!filteredProducts.length && (<Alert severity="info">{texts.noProducts}</Alert>)}
        </>
      )}
      {/* show error if user is not admin */}
      {isMissingConfig && !isAdmin && <Alert severity="error">{texts.missingConfigError}</Alert>}
      {/* show configs if user is admin */}
      {isMissingConfig && isAdmin && <InternalConfigs />}
      {showAddProductModal && (
        <AddProductModal
          open={showAddProductModal}
          onClose={() => setShowAddProductModal(false)}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </CardsContainer>
  );
};

export default compose(
  withExternalCoachContextReady,
  withCoachProductsContextReady,
  observer,
)(ProductsConfig);
