import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';
import CoachHistory from './CoachHistory';

class CoachOffboardingNote extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.COACH_OFFBOARDING_NOTE}/${id}`, opts);
  }

  get note() {
    return this.data.note;
  }

  get coach() {
    return this.data.coach;
  }

  get coachId() {
    return this.data.coachId;
  }

  /**
   * Fetches the offboarding note for a given coachId.
   * @param {string} coachId - The coach's ID.
   * @returns {Promise<string | null>} - The found note or null if not found.
   */
  static async getByCoachId(coachId) {
    const offboardingCollection = new Collection(Collections.COACH_OFFBOARDING_NOTE, {
      createDocument: (source, opts) => new CoachOffboardingNote(source.id, opts),
      query: (ref) => ref.where('coachId', '==', coachId).limit(1),
    });
    await offboardingCollection.fetch();
    return offboardingCollection.docs.length > 0 ? offboardingCollection.docs[0].data.note : null;
  }

  /**
   * Adds or updates an offboarding note for a given coachId.
   * @param {Object} data - The note data.
   * @param {string} data.coachId - The coach's ID.
   * @param {string} data.note - The note content.
   * @param {string} data.authUserId - The user performing the action.
   * @param {string} data.authUserName - The name of the user performing the action.
   * @param {string} data.historyTypeAddNote - The history event type.
   * @param {string} data.historyTypeEditNote - The history event type.
   * @returns {Promise<string>} - The ID of the created/updated note.
   */
  static async addOrUpdateOffboardingNote(data) {
    const {
      coachId, note, authUserId, authUserName, historyTypeAddNote, historyTypeEditNote,
    } = data;

    // Query the collection to find if there is already an offboarding note for the given coachId
    const offboardingCollection = new Collection(Collections.COACH_OFFBOARDING_NOTE, {
      createDocument: (source, opts) => new CoachOffboardingNote(source.id, opts),
      query: (ref) => ref.where('coachId', '==', coachId).limit(1),
    });

    await offboardingCollection.fetch();

    if (offboardingCollection.docs.length > 0) {
      // If an existing note is found, get the first document (since we're limiting to 1) and update it
      const existingNote = offboardingCollection.docs[0];
      // Update the existing note with the new data
      await existingNote.update({
        note,
        updatedAt: new Date(),
      });
      await CoachHistory.addCoachHistoryItem({
        type: historyTypeEditNote,
        coachId,
        actionedBy: authUserId,
        actionedByUserName: authUserName,
        createdAt: new Date(),
      });
      return existingNote.id;
    }

    // If no note exists for the given coachId, create a new offboarding note
    const formsCollection = new Collection(Collections.COACH_OFFBOARDING_NOTE);
    const { id } = await formsCollection.add({
      coachId,
      note,
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    await CoachHistory.addCoachHistoryItem({
      type: historyTypeAddNote,
      coachId,
      actionedBy: authUserId,
      actionedByUserName: authUserName,
      createdAt: new Date(),
    });

    return id;
  }
}

export default CoachOffboardingNote;
