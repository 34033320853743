import React from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';
import { MEDIA_TYPES } from '../constants';
import useInstagramMedia from '../hooks/useInstagramMedia';
import {
  MediaItemWrapper, MediaItemError, MediaImage, MediaVideo,
} from './styles';

const InstagramMediaItem = ({ mediaId, mediaType }) => {
  const {
    mediaUrl, isLoading, error,
  } = useInstagramMedia(mediaId);

  return (
    <MediaItemWrapper>
      {isLoading && <IonSpinner name="crescent" />}
      {error && !isLoading && (
        <MediaItemError>
          Error loading media:
          {error.message}
        </MediaItemError>
      )}
      {!isLoading && !error && mediaUrl && (
        <>
          {mediaType === MEDIA_TYPES.IMAGE && <MediaImage src={mediaUrl} alt="media" />}
          {mediaType === MEDIA_TYPES.VIDEO && (
            <MediaVideo controls>
              <source src={mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </MediaVideo>
          )}
        </>
      )}
    </MediaItemWrapper>
  );
};

InstagramMediaItem.propTypes = {
  mediaId: PropTypes.string.isRequired,
  mediaType: PropTypes.oneOf([MEDIA_TYPES.IMAGE, MEDIA_TYPES.VIDEO]).isRequired,
};

export default InstagramMediaItem;
