import { Collection } from 'firestorter';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';

import BaseDocument from './BaseDocument';

/**
 * Class representing a user role.
 *
 * @class Role
 * @extends BaseDocument
 */
class Role extends BaseDocument {
  /**
   * Get the role name.
   * @return {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Get role permissions.
   * @return {Object} An object mapping feature keys to an array of permissions.
   */
  get permissions() {
    return this.data?.permissions || {};
  }

  // Methods

  /**
   * Creates a new Role
   * @param {string} roleName The name of the role to be created
   * @return {Promise<string>} The id of the created role document
   */
  static async createRole(roleName) {
    const roleCollection = new Collection(firestorePaths.ROLES);
    const newRoleRef = await roleCollection.add({
      name: roleName,
    });

    const newRole = new Role(newRoleRef.path);
    await newRole.init(); // Load data

    return newRole;
  }

  /**
   * Get all roles.
   * @return {Promise<Collection>} The collection of user roles.
   */
  static async getAllRoles() {
    const roleCollection = new Collection(firestorePaths.ROLES, {
      createDocument: (source, options) => new Role(source, { ...options, disableObserverCountRef: true }),
    });
    await Role.initCollection(roleCollection);
    return roleCollection;
  }

  /**
   * Get role by id.
   * @param {string} id - The role id.
   * @return {Promise<User>} The role document.
   */
  static async getById(id) {
    const roleDoc = new Role(format(firestorePaths.ROLE_DOC, {
      [pathPlaceholder.ROLE_ID]: id,
    }));
    await roleDoc.init();
    return roleDoc.exists ? roleDoc : null;
  }

  /**
   * Add or remove custom permissions for a role.
   * @param {string} selectedFeature - The feature for which permissions are being set.
   * @param {Array<string>} selectedPermissions - The list of permissions to be assigned.
   * @param {boolean} [removePermission=false] - Whether to remove the permissions for the selected feature.
   * @return {Promise<Object>} The updated custom permissions object.
   */
  async setPermissions(selectedFeature, selectedPermissions, removePermission = false) {
    const existingPermissions = this.permissions;

    const updatedPermissions = { ...existingPermissions };

    if (removePermission) {
      delete updatedPermissions[selectedFeature];
    } else {
      updatedPermissions[selectedFeature] = selectedPermissions;
    }

    await this.update({ permissions: updatedPermissions });
    this.data.permissions = updatedPermissions;
  }
}

export default Role;
