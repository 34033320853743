import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import format from 'string-template';
import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import InputLabel from '../../../../../components/v2/InputLabel';
import { StyledTextFieldContainer } from '../../../../../components/Inputs';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import CoachesListContext from '../../../../context/CoachesListContext';
import { SettingOption } from '../../utils';

import texts from './texts.json';

const radioButtonOptions = [
  { label: texts.enabled, value: SettingOption.ENABLED },
  { label: texts.disabled, value: SettingOption.DISABLED },
];

const InstantLeadCalls = ({ coachId }) => {
  const [coachDoc, setCoachDoc] = useState(null);
  const [isInstantCallEnabled, setIsInstantCallEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    coachesCollection,
  } = useContext(CoachesListContext);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const isReadOnly = !isInsideSalesAdmin();

  useEffect(() => {
    const init = async () => {
      setCoachDoc(null);
      if (coachId) {
        if (isComponentMountedRef.current) {
          const coachDocument = coachesCollection.docs.find(({ id }) => id === coachId);
          setCoachDoc(coachDocument);
          setIsInstantCallEnabled(coachDocument?.instaCallEnrolled);
        }
      }
    };
    init();
  }, [coachId, isComponentMountedRef, coachesCollection]);

  const onSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await coachDoc.updateInstantCallEnrolled(isInstantCallEnabled);
      showToast(texts.saved);
    } catch (error) {
      showToast(format(texts.error, { error }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [coachDoc, isInstantCallEnabled, showToast, isComponentMountedRef]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        <StyledTextFieldContainer $withMargin>
          <InputLabel>{texts.instaLeadCalls}</InputLabel>
          <RadioButtonGroup
            options={radioButtonOptions}
            selectedOption={isInstantCallEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(val) => setIsInstantCallEnabled(val === SettingOption.ENABLED)}
            disabled={isReadOnly}
          />
        </StyledTextFieldContainer>
        <SaveButton
          onClick={onSave}
          disabled={isReadOnly}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Container>
      <LoadingOverlay isLoading={!coachDoc || isLoading} />
    </Card>
  );
};

InstantLeadCalls.propTypes = {
  coachId: PropTypes.string,
};

InstantLeadCalls.defaultProps = {
  coachId: '',
};

export default compose(
  observer,
)(InstantLeadCalls);
