import React, { useState, useContext, useEffect } from 'react';
import { Button as MuiButton } from '@mui/material';
import PropTypes from 'prop-types';

import { StyledTextField } from '../../../../../../../components/Inputs/styles';
import Button from '../../../../../../../components/Button/Button';
import CoachOffboardingNote from '../../../../../../Model/CoachOffboardingNote';
import useToast from '../../../../../../hooks/useToast';
import LoggedInUserContext from '../../../../../../../context/LoggedInUserContext';
import { OffboardingChecklistEnum } from '../OffboardingChecklist/utils';
import {
  Container, BtnContainer, SubContainer, TextBlock,
} from './styles';
import texts from './texts.json';

function OffboardingNote({
  coachDoc, loadCoachHistory, refetch,
}) {
  const { showToast } = useToast();
  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNote, setIsLoadingNote] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState('');

  // Fetch existing offboarding note when the component mounts
  useEffect(() => {
    const fetchNote = async () => {
      setIsLoadingNote(true);
      try {
        const existingNote = await CoachOffboardingNote.getByCoachId(coachDoc.id);
        if (existingNote) {
          setNote(existingNote);
          setIsLoadingNote(false);
        } else {
          setIsEditing(true);
          setIsLoadingNote(false);
        }
      } catch (error) {
        setIsLoadingNote(false);
        showToast(error.message || texts.errorMessage, { error: true });
      }
    };

    fetchNote();
  }, [coachDoc.id, showToast]);

  const onNoteAdd = async (offboardingNote) => {
    try {
      setIsLoading(true);
      await CoachOffboardingNote.addOrUpdateOffboardingNote({
        coachId: coachDoc.id,
        note: offboardingNote,
        authUserId,
        authUserName,
        historyTypeAddNote: OffboardingChecklistEnum.COACH_OFFBOARDING_NOTE_ADDED,
        historyTypeEditNote: OffboardingChecklistEnum.COACH_OFFBOARDING_NOTE_EDITED,
      });
      await loadCoachHistory();
      refetch();
      showToast(texts.successMessage);
      setIsLoading(false);
    } catch (error) {
      showToast(error.message || texts.errorMessage, { error: true });
      setIsLoading(false);
    }
  };

  if (!isEditing) {
    return (
      <Container>
        <div>{texts.title}</div>
        {(isLoadingNote || isLoadingNote === null) ? <TextBlock>{texts.Loading}</TextBlock> : (
          <SubContainer row>
            <TextBlock>{note}</TextBlock>
            <BtnContainer>
              <Button
                variant="contained"
                size="small"
                onClick={() => setIsEditing(true)}
                component={MuiButton}
              >
                {(isLoading) ? texts.actionLoading : texts.actionEdit}
              </Button>
            </BtnContainer>
          </SubContainer>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <div>{texts.title}</div>
      <SubContainer>
        <StyledTextField
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          placeholder={texts.placeholder}
          onChange={(e) => setNote(e.target.value)}
          disabled={isLoading}
          value={note}
        />
        <BtnContainer>
          <Button
            variant="contained"
            size="small"
            disabled={isLoading || !note}
            onClick={() => onNoteAdd(note)}
            component={MuiButton}
          >
            {(isLoading) ? texts.actionLoading : texts.action}
          </Button>
        </BtnContainer>
      </SubContainer>
    </Container>
  );
}

OffboardingNote.propTypes = {
  coachDoc: PropTypes.object.isRequired,
  loadCoachHistory: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default OffboardingNote;
