import fieldName, { questionFieldName } from './formFields';
import QuestionType, { HiddenFieldType } from './questionType';

const texts = {
  editForm: 'Edit Form',
  createForm: 'Create Form',
  back: 'Back',
  selectDescription: 'Coach',
  formPublished: 'Form has been successfully saved and published',
  validation: {
    coach: 'You need to select a coach',
    formNameRequired: 'The name of the form is required',
    endScreenContentRequired: 'You cannot keep the end screen content empty',
    questionsRequired: 'At least one question is required for a form',
    financialCommitment: {
      type: `Financial commitment question (ID: "financialCommitment") must be configured as a
        multiple-selection question. Please review it and try again`,
      options: 'Financial commitment question requires at least 2 options. Please review it and try again',
      requiredYesAndNo: `Financial commitment question expects one option to start with "Yes ..." and
        another with "No ...". Please review it and try again`,
    },
    promptInputNotInOptions: `One or more multiple selection questions have a prompt input field
      that is not one of the defined options. Please review it and try again`,
    postPaymentFormExists: 'The coach already has an active post payment form.',
  },
  yes: 'yes',
  no: 'no',
  submitButton: 'Save Form',
  field: {
    [fieldName.COACH]: {
      label: 'Coach Name',
    },
    [fieldName.NAME]: {
      label: 'Form Name',
    },
  },
  labels: {
    [questionFieldName.ID]: 'Field ID',
    [questionFieldName.CUSTOM_CLASS]: 'Custom Class name',
    [questionFieldName.LABEL]: 'Question title',
    [questionFieldName.PLACEHOLDER]: 'Placeholder text',
    [questionFieldName.REQUIRED]: 'Is Required',
    [questionFieldName.ALLOW_MULTIPLE]: 'Allow Multiple',
    [questionFieldName.PROMPT_INPUT]: 'Input prompt option',
    [questionFieldName.OPTIONS]: 'Options',
    [questionFieldName.VALUE]: 'Value',
    [questionFieldName.UNITS]: 'Units',
  },
  questionTypeLabel: {
    [QuestionType.TEXT]: 'Text',
    [QuestionType.NUMBER]: 'Number',
    [QuestionType.OPTIONS]: 'Multiple Selection',
    [QuestionType.EMAIL]: 'Email',
    [QuestionType.PHONE]: 'Phone',
    [QuestionType.NAME]: 'Name',
    [QuestionType.LENGTH]: 'Length',
    [QuestionType.WEIGHT]: 'Weight',
    [QuestionType.DATE]: 'Date',
    [HiddenFieldType.TEXT]: 'Hidden Field',
  },
  defaultFields: {
    source: 'source',
  },
};

export default texts;
