import styled, { css } from 'styled-components';
import { FormHelperText } from '@mui/material';
import {
  Smartphone,
  Notifications,
  Person,
  Textsms,
} from '@mui/icons-material';

import LoadingPage from '../../../components/LoadingPage';
import { ReactComponent as BillingIcon } from '../../../assets/icons/billing.svg';
import {
  ReactComponent as AnalyticsIcon,
} from '../../../assets/icons/menuIcons/feedMenuIcons/daily-summary.svg';

const iconStyles = css`
  height: 15px;
  width: 15px;
  margin-right: 8px;
`;

const MobileAppIcon = styled(Smartphone)`
  ${iconStyles};
`;
const ThemeSectionContainer = styled.div`
  display: flex;
  gap: 25px;
`;
const SlackNotificationsIcon = styled(Notifications)`
  ${iconStyles};
`;

const CoachSettingsIcon = styled(Person)`
  ${iconStyles};
`;

// Child component common styles
const HelperText = styled(FormHelperText)`
  font-size: 13px;
`;

const UpdatedSettings = styled.ul`
  display: flex;
  flex-direction: column;
`;

const UpdatedSettingItem = styled.li`
  width: 100%;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 100px;
`;

const MessageTempIcon = styled(Textsms)`
  ${iconStyles}
`;

const FeesIcon = styled(BillingIcon)`
  ${iconStyles}
`;

const StyledAnalyticsIcon = styled(AnalyticsIcon)`
  ${iconStyles};
`;

export {
  MobileAppIcon,
  SlackNotificationsIcon,
  CoachSettingsIcon,
  HelperText,
  UpdatedSettings,
  UpdatedSettingItem,
  StyledLoadingPage,
  MessageTempIcon,
  FeesIcon,
  ThemeSectionContainer,
  StyledAnalyticsIcon,
};
