import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  Alert,
  Avatar,
  Checkbox,
  FormHelperText,
  Divider,
  TextField,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import {
  DatePicker,
  TimePicker,
} from '@mui/lab';
import { diffWordsWithSpace } from 'diff';
import { v4 as uuidv4 } from 'uuid';
import { ChatChannelContext } from '@fitmoola/system2-chat';

import { storagePaths, pathPlaceholder } from '../../../../../../utils/firebasePaths';
import { getProcessedAttachmentData } from '../../../../../../utils/attachments';
import {
  combineDateAndTimeWithUTCOffset,
  DateFormat,
  isToday,
} from '../../../../../../utils/date';
import { getUTCOffset, roundToNext5Minutes } from '../../../../../../utils/time';
import useSessionStore from '../../../../../../hooks/useSessionStore';
import useStorage, { StorageProcessState } from '../../../../../../hooks/useStorage';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import ExternalCoachContext from '../../../../../context/ExternalCoachContext';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import ToDoList from '../../../../../components/ToDoList';
import CheckinFeedback from '../../../../../Model/CheckinFeedback';
import UserConfig from '../../../../../../Model/UserConfig';
import FeedContext from '../../../../../context/FeedContext';
import useUpdateInteraction from '../../../../../hooks/useUpdateInteraction';
import useToast from '../../../../../hooks/useToast';
import useLogger from '../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../utils/log';

import ReviewRequestModal from '../ReviewRequestModal';
import {
  StyledButton,
  StyledTextArea,
  FeedbackContainer,
  StyledChatIcon,
  StyledInfoText,
  StyledGenerateButton,
  StyledGenerateIcon,
  HeaderContainer,
  HeaderTime,
  HeaderTitle,
  NameContainer,
  Container,
  StyledIconButton,
  StyledSpinner,
  SmartResponseTitle,
  StyledS2Icon,
  FeedbackDiffContainer,
  FeedbackDiffText,
  StyledDiffButton,
  ActionsContainer,
  StyledAttachmentsContainer,
  ScheduleWrapper,
  DateSelectorContainer,
  ButtonContainer,
  CheckBoxContainer,
} from './styles';
import texts from './texts.json';
import { scheduleSingleMessage } from './util';

const MILLISECONDS_IN_SECOND = 1000;
const MAX_FILE_UPLOAD_SIZE = 50000000; // 50MB
const MAX_FILE_UPLOAD_SIZE_MB = MAX_FILE_UPLOAD_SIZE / 1000000; // bytes to MB
const MAX_MONTH_OF_DATE_SELECTION = 3;

const CheckInFeedback = () => {
  const { coachDoc } = useContext(ExternalCoachContext);
  const timezoneOffset = getUTCOffset(coachDoc.timeZone || moment.tz.guess());

  const [feedbackText, setFeedbackText] = useState('');
  const [currentCheckInFeedbackDoc, setCurrentCheckInFeedbackDoc] = useState({});
  const [showFeedbackDifferences, setShowFeedbackDifferences] = useState(false);
  const [showAttachmentSaveModal, setShowAttachmentSaveModal] = useState(false);
  const [shouldForceSend, setShouldForceSend] = useState(false);
  const [sendDate, setSendDate] = useState(moment().utcOffset(timezoneOffset));
  const [sendTime, setSendTime] = useState(roundToNext5Minutes(moment().utcOffset(timezoneOffset)));
  const [isLoading, setIsLoading] = useState(false);
  const [showReviewRequestModal, setShowReviewRequestModal] = useState(false);
  const [userConfigDoc, setUserConfigDoc] = useState({});

  const { firebase: { remote } } = useContext(FirebaseContext);
  const {
    onMarkAsRead,
    selectedActivity: activity,
    setSelectedActionBarType,
    isSmartFeedbackSelected,
  } = useContext(FeedContext);
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const {
    uploadAttachments,
    deleteAttachment,
    deleteAttachments,
    createFilesFromDataUrls,
    getBlobUrls,
  } = useStorage();
  const {
    authUser: {
      uid: authUserId,
    } = {},
    isCoachAssistant,
  } = useSessionStore();
  const { updateLastInteraction } = useUpdateInteraction(activity.user);

  const [attachments, setAttachments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [exitPath, setExitPath] = useState('');
  const { logCoachingActivity } = useLogger();
  const history = useHistory();
  const { sendMessageToUser, getUserChannelId } = useContext(ChatChannelContext);
  const [channelId, setChannelId] = useState();

  const {
    time,
    userName,
    userAvatar,
    user: clientId,
  } = activity;

  // save time spent on each entry to this component + total time spent per check-in
  useEffect(() => {
    const now = new Date();
    const startTime = now.getTime();
    const entry = {
      enteredAt: now,
    };
    return () => {
      const timeSpent = Date.now() - startTime;
      entry.timeSpent = timeSpent / MILLISECONDS_IN_SECOND;
      if (currentCheckInFeedbackDoc instanceof CheckinFeedback) {
        currentCheckInFeedbackDoc.update({
          entriesAt: [
            ...currentCheckInFeedbackDoc.entriesAt,
            entry,
          ],
          totalTimeSpent: currentCheckInFeedbackDoc.totalTimeSpent + entry.timeSpent,
        });
      }
    };
  }, [
    currentCheckInFeedbackDoc,
  ]);

  // Block navigation if there are unsaved attachments and show the attachment save confirmation modal
  useEffect(() => {
    const unblock = history.block((location) => {
      const newSelectedFile = attachments?.find((file) => !file.storagePath);
      if (!newSelectedFile) {
        return true;
      }
      setShowAttachmentSaveModal(true);
      setExitPath(location.pathname);
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    attachments,
    history,
  ]);

  const activityTime = useMemo(() => (
    moment.utc(time?.toDate()).local().fromNow()
  ), [
    time,
  ]);

  // Load the feedback doc for the current check-in activity
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      setShowFeedbackDifferences(false);
      setFeedbackText('');
      setCurrentCheckInFeedbackDoc({});
      try {
        if (activity.checkInId) {
          const checkInFeedbackDoc = await CheckinFeedback.getById(activity.checkInId);
          const userChannelId = await getUserChannelId(activity.user);
          const clientConfigDoc = await UserConfig.getConfigById(activity.user);
          if (userChannelId) {
            setChannelId(userChannelId);
          }
          if (isComponentMountedRef.current) {
            setUserConfigDoc(clientConfigDoc);

            if (checkInFeedbackDoc) {
              setCurrentCheckInFeedbackDoc(checkInFeedbackDoc);
              if (isSmartFeedbackSelected) {
                setFeedbackText(checkInFeedbackDoc.feedbackText);
              } else {
                setFeedbackText(checkInFeedbackDoc.customFeedbackText || '');
              }
              if (checkInFeedbackDoc?.attachments?.length) {
                const blobUrls = await getBlobUrls(checkInFeedbackDoc.attachments);
                if (isComponentMountedRef.current) {
                  setAttachments(blobUrls);
                }
              }
            }
          }
        }
      } catch (error) {
        showToast(`${texts.feedbackError}: ${error}`, {
          error: true,
        });
      }
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    };
    init();
  }, [
    activity.checkInId,
    showToast,
    isComponentMountedRef,
    isSmartFeedbackSelected,
    getBlobUrls,
    getUserChannelId,
    activity.user,
  ]);

  // update feedback text when the feedback doc text changes
  // this can only happen in the case of a regenerated feedback
  useEffect(() => {
    if (isSmartFeedbackSelected && currentCheckInFeedbackDoc?.feedbackText) {
      setFeedbackText(currentCheckInFeedbackDoc.feedbackText);
    } else if (currentCheckInFeedbackDoc?.customFeedbackText) {
      setFeedbackText(currentCheckInFeedbackDoc.customFeedbackText);
    }
  }, [
    currentCheckInFeedbackDoc,
    currentCheckInFeedbackDoc.feedbackText,
    currentCheckInFeedbackDoc.customFeedbackText,
    isSmartFeedbackSelected,
  ]);

  const minTime = useMemo(() => (
    isToday(moment(sendDate).utcOffset(timezoneOffset)) ? moment().utcOffset(timezoneOffset) : null
  ), [
    sendDate,
    timezoneOffset,
  ]);

  const onFeedbackRegenerate = useCallback(async () => {
    setIsLoading(true);
    try {
      const apiResponse = await remote('aiFeedbackGeneration', {
        checkInId: activity.checkInId,
      });
      if (!apiResponse.ok) {
        throw new Error(apiResponse.statusText);
      }
      const responseToJson = await apiResponse.json();
      const { aiFeedbackText } = responseToJson;
      // we need to save regenrated feedback info to the checkin feedback doc + analytics
      if (aiFeedbackText && currentCheckInFeedbackDoc instanceof CheckinFeedback) {
        await currentCheckInFeedbackDoc.update({
          aiFeedbackText,
          feedbackText: aiFeedbackText,
          regeneratedCount: currentCheckInFeedbackDoc.regeneratedCount + 1,
          lastFeedbackGeneratedAt: new Date(),
        });
      }
    } catch (error) {
      showToast(`${texts.generateError}: ${error.message}`, {
        error: true,
      });
    }
    setIsLoading(false);
  }, [
    remote,
    activity.checkInId,
    showToast,
    currentCheckInFeedbackDoc,
  ]);

  const processAttachments = useCallback(async (attachmentsToProcess) => {
    const uploadedAttachments = [];

    const filesToAddById = attachmentsToProcess.reduce((acc, { file, id }) => {
      const lastDotIndex = file.name.lastIndexOf('.');
      acc[id] = {
        dataUrl: URL.createObjectURL(file),
        format: file.name.slice(lastDotIndex + 1),
        fileName: file.name.slice(0, lastDotIndex),
        fileType: file.type,
        fileId: id,
      };
      return acc;
    }, {});

    const filesToAdd = Object.values(filesToAddById);
    const storagePath = format(storagePaths.CHAT_COLLECTION, {
      [pathPlaceholder.CHANNEL_ID]: channelId,
    });
    setIsUploading(true);
    const results = await uploadAttachments(
      filesToAdd,
      storagePath,
    );

    const failedFiles = [];
    results.forEach((res) => {
      if (res.state === StorageProcessState.SUCCESS) {
        uploadedAttachments.push(res);
      } else {
        failedFiles.push(res);
      }
    });
    if (failedFiles.length > 0) {
      // If some upload fails, we should stop the sending process
      // and delete the files that were uploaded (if there is any)
      if (uploadedAttachments.length) {
        const filesToDelete = uploadedAttachments.map(({ fileRef }) => ({ storagePath: fileRef }));
        await deleteAttachments(filesToDelete);
      }
      const errorMessage = `Failed to upload ${failedFiles.length} file(s)`;
      setIsUploading(false);
      throw new Error(errorMessage);
    } else {
      setIsUploading(false);
      // format the attachment data to be saved in the message document
      return uploadedAttachments.map(({
        fileRef,
        metadata: {
          contentType,
          size,
        },
        fileId,
      }) => getProcessedAttachmentData({
        fileName: filesToAddById[fileId].fileName,
        contentType,
        pathRef: fileRef,
        fileSize: size,
      }));
    }
  }, [
    deleteAttachments,
    uploadAttachments,
    channelId,
  ]);

  // send coach feedback to client via in-app chat
  const sendFeedback = useCallback(async (isScheduleMessage) => {
    const sendAt = combineDateAndTimeWithUTCOffset(sendDate, sendTime, timezoneOffset);
    if (isScheduleMessage && sendAt < moment().utcOffset(timezoneOffset)) {
      showToast(texts.passedDateTimeWarning, { error: true });
      return;
    }

    setIsLoading(true);
    try {
      const attachmentsInStorage = attachments.filter((file) => file.storagePath);
      const otherFiles = attachments.filter((file) => !file.storagePath);
      const storageFiles = await createFilesFromDataUrls(attachmentsInStorage);
      const storageFilesAttachments = storageFiles.map((attachment) => ({
        file: attachment.file,
        id: attachment.fileId,
        url: URL.createObjectURL(attachment.file),
      }));

      const processedAttachments = await processAttachments([...otherFiles, ...storageFilesAttachments]);
      const formattedMsg = format(texts.feedbackMessage, {
        date: moment(activity.time?.toDate()).format(DateFormat.DEFAULT_DATE_FORMAT),
        feedbackText,
      });

      if (isScheduleMessage) {
        await scheduleSingleMessage({
          channelId,
          text: formattedMsg,
          userId: activity.coach,
          initiatedBy: authUserId,
          sendAt: sendAt.toDate(),
          activityId: activity.id,
          shouldForceSend,
        }, processedAttachments);
      } else {
        await sendMessageToUser(activity.user, formattedMsg, processedAttachments, {}, true);
      }
      await onMarkAsRead(activity);

      // save final feedback CA submitted along with timestamp
      if (currentCheckInFeedbackDoc instanceof CheckinFeedback) {
        const updateData = {
          submittedAt: new Date(),
          submittedBy: authUserId,
        };
        if (isSmartFeedbackSelected) {
          updateData.feedbackText = feedbackText;
          updateData.isSmartFeedbackSelected = true;
        } else {
          updateData.customFeedbackText = feedbackText;
        }
        await currentCheckInFeedbackDoc.update({
          updateData,
          attachments: [],
        });
        if (isComponentMountedRef.current) {
          setAttachments([]);
        }
        // delete files uploaded to the storage as don't need them anymore
        await deleteAttachments(attachmentsInStorage);
        // Update user's last interaction with the logged in user info
        await updateLastInteraction();
      }
      showToast(isScheduleMessage ? texts.scheduleSuccess : texts.feedbackSuccess);

      // Show a modal to the AC for requesting app review from the client
      if (isCoachAssistant && userConfigDoc.requestAppReview === undefined && isComponentMountedRef.current) {
        setShowReviewRequestModal(true);
      } else {
        // Only close the action bar if we're not showing the review modal
        setSelectedActionBarType(null);
      }

      logCoachingActivity(CoachingActivity.CHECKIN_FEEDBACK_SENT, {
        checkinId: activity.checkInId,
        checkinSubmittedAt: activity.checkInData.current.submittedAt.toDate(),
        clientId: activity.user,
      });
    } catch (error) {
      showToast(isScheduleMessage ? texts.feedbackSchedulingError : texts.feedbackSendingError, {
        error: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    feedbackText,
    activity,
    onMarkAsRead,
    showToast,
    currentCheckInFeedbackDoc,
    setSelectedActionBarType,
    authUserId,
    updateLastInteraction,
    isSmartFeedbackSelected,
    logCoachingActivity,
    deleteAttachments,
    createFilesFromDataUrls,
    processAttachments,
    attachments,
    isComponentMountedRef,
    sendDate,
    sendTime,
    timezoneOffset,
    channelId,
    sendMessageToUser,
    shouldForceSend,
    isCoachAssistant,
    userConfigDoc,
  ]);

  const handleOnBlur = useCallback(async () => {
    if (currentCheckInFeedbackDoc instanceof CheckinFeedback) {
      if (isSmartFeedbackSelected) {
        await currentCheckInFeedbackDoc.update({
          feedbackText,
        });
      } else {
        await currentCheckInFeedbackDoc.update({
          customFeedbackText: feedbackText,
        });
      }
    }
  }, [
    currentCheckInFeedbackDoc,
    feedbackText,
    isSmartFeedbackSelected,
  ]);

  const getDifferences = useCallback(
    (currentText) => diffWordsWithSpace(currentCheckInFeedbackDoc.aiFeedbackText || '', currentText).filter(
      ({ removed }) => !removed,
    ),
    [
      currentCheckInFeedbackDoc.aiFeedbackText,
    ],
  );

  const onAddAttachments = useCallback(async (event) => {
    const { files } = event.target;
    const validFiles = Object.values(files).every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
    if (validFiles) {
      const finalFileList = Object.values(files).map((file) => (
        {
          file,
          id: `${channelId}-${uuidv4()}`,
          url: URL.createObjectURL(file),
        }
      ));
      setAttachments([...attachments, ...finalFileList]);
    } else {
      const errorMessage = format(texts.fileSizeError, {
        sizeLimit: MAX_FILE_UPLOAD_SIZE_MB,
      });
      showToast(errorMessage, { error: true });
    }
  }, [
    attachments,
    showToast,
    channelId,
  ]);

  const removeFileByIndex = useCallback(async (index) => {
    const fileToRemove = attachments[index];
    const { storagePath = '' } = fileToRemove;

    /**
     * If the file is not in storage, remove it from the attachments list directly otherwise,
     * delete the file from storage and then remove it from the attachments list
     */
    if (!storagePath) {
      const resultAttachments = [...attachments];
      resultAttachments.splice(index, 1);
      setAttachments(resultAttachments);
    } else {
      // Delete the file from storage
      const deleteResult = await deleteAttachment(storagePath);
      if (deleteResult.state === StorageProcessState.SUCCESS) {
        // Remove the file from the attachments list
        const resultAttachments = [...attachments];
        resultAttachments.splice(index, 1);

        if (isComponentMountedRef.current) {
          setAttachments(resultAttachments);
        }

        // Update the document
        const updatedAttachments = currentCheckInFeedbackDoc.attachments.filter(
          (attachment) => attachment.fileRef !== storagePath,
        ).map(({ fileRef, fileId }) => ({
          fileRef,
          fileId,
        }));

        await currentCheckInFeedbackDoc.update({
          attachments: updatedAttachments,
        });
      } else {
        showToast(texts.fileRemoveError, { error: true });
      }
    }
  }, [
    attachments,
    setAttachments,
    currentCheckInFeedbackDoc,
    deleteAttachment,
    showToast,
    isComponentMountedRef,
  ]);

  const onAttachmentSaveAsDraft = useCallback(async () => {
    setIsLoading(true);
    try {
      // Filter out the files that are already uploaded to storage
      const filteredFileDetails = attachments.filter((file) => !file.storagePath).map(({ file, id }) => ({
        dataUrl: URL.createObjectURL(file),
        format: file.name.split('.').pop(),
        fileName: file.name.split('.')[0],
        fileType: file.type,
        fileId: id,
      }));
      // Upload the files to storage
      const uploadResult = await uploadAttachments(filteredFileDetails, storagePaths.CHECK_IN_FEEDBACK);
      const storagePathsOfAttachments = uploadResult.map((file) => ({
        fileRef: file.fileRef,
        fileId: file.fileId,
      }));

      // Update the document with the new attachments
      await currentCheckInFeedbackDoc.update({
        attachments: [...(currentCheckInFeedbackDoc.attachments || []), ...storagePathsOfAttachments],
      });
      if (isComponentMountedRef.current) {
        setShowAttachmentSaveModal(false);
        setIsLoading(false);
      }
      // Unblock navigation and navigate to exitPath
      history.block(() => true);
      history.push(exitPath);
    } catch (error) {
      showToast(texts.fileUploadError, { error: true });
    }
  }, [
    attachments,
    uploadAttachments,
    currentCheckInFeedbackDoc,
    showToast,
    isComponentMountedRef,
    history,
    exitPath,
  ]);

  const onCancelAttachmentSaveAsDraft = useCallback(() => {
    setShowAttachmentSaveModal(false);
    // Unblock navigation and navigate to exitPath
    history.block(() => true);
    history.push(exitPath);
  }, [
    history,
    exitPath,
  ]);

  const onReviewRequestModalClose = useCallback(() => {
    setShowReviewRequestModal(false);
    setSelectedActionBarType(null);
  }, [setSelectedActionBarType]);

  return (
    <FeedbackContainer>
      <HeaderContainer>
        {userAvatar ? <Avatar src={userAvatar} /> : (<Avatar>{userName?.substring(0, 1)}</Avatar>)}
        <NameContainer>
          <Container>
            <HeaderTitle>{userName}</HeaderTitle>
          </Container>
          <HeaderTime>{`${activityTime}`}</HeaderTime>
        </NameContainer>
        <StyledIconButton onClick={() => setSelectedActionBarType(null)}>
          <CloseOutlined />
        </StyledIconButton>
      </HeaderContainer>
      {isSmartFeedbackSelected && (
        <ActionsContainer>
          <SmartResponseTitle>
            <StyledS2Icon />
            {texts.title}
          </SmartResponseTitle>
          <StyledDiffButton
            onClick={() => setShowFeedbackDifferences(!showFeedbackDifferences)}
            disabled={feedbackText === currentCheckInFeedbackDoc.aiFeedbackText}
          >
            {showFeedbackDifferences ? texts.hideDiff : texts.showDiff}
          </StyledDiffButton>
        </ActionsContainer>
      )}
      {
        showFeedbackDifferences
          ? (
            <FeedbackDiffContainer>
              {getDifferences(feedbackText).map((part) => (
                <FeedbackDiffText key={part} highlight={!!part.added}>
                  {part.value}
                </FeedbackDiffText>
              ))}
            </FeedbackDiffContainer>
          )
          : (
            <StyledTextArea
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              onBlur={handleOnBlur}
              minRows={10}
              maxRows={20}
              disabled={isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText}
              placeholder={(isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText)
                ? texts.generatingResponse
                : texts.feedbackPlaceholder}
              multiline
              variant="outlined"
              InputProps={{
                endAdornment: (!isSmartFeedbackSelected || currentCheckInFeedbackDoc.feedbackText)
                  ? (
                    <StyledGenerateButton
                      onClick={onFeedbackRegenerate}
                    >
                      <StyledGenerateIcon />
                      {texts.newResponse}
                    </StyledGenerateButton>
                  ) : <StyledSpinner />,
              }}
            />
          )
      }
      <StyledAttachmentsContainer
        fileAttachments={attachments}
        onFilesSelected={onAddAttachments}
        onFileRemoved={removeFileByIndex}
        isUploading={isUploading}
      />
      {isSmartFeedbackSelected && (
        <StyledInfoText>
          {texts.disclaimer}
        </StyledInfoText>
      )}
      <ScheduleWrapper>
        {!!coachDoc.timeZone && (
          <Alert severity="warning">
            {texts.scheduleWarningMessage}
          </Alert>
        )}
        <CheckBoxContainer>
          <Checkbox
            checked={shouldForceSend}
            onChange={() => setShouldForceSend((prev) => !prev)}
          />
          <FormHelperText>{texts.forceSendLabel}</FormHelperText>
        </CheckBoxContainer>
        <DateSelectorContainer>
          <DatePicker
            label={texts.selectDate}
            value={sendDate}
            onChange={setSendDate}
            minDate={moment().utcOffset(timezoneOffset)}
            maxDate={moment().utcOffset(timezoneOffset).add(MAX_MONTH_OF_DATE_SELECTION, 'months')}
            renderInput={(params) => (
              <TextField {...params} />
            )}
          />
          <TimePicker
            label={texts.selectTime}
            value={sendTime}
            onChange={setSendTime}
            minTime={minTime}
            minutesStep={5}
            renderInput={(params) => (
              <TextField {...params} />
            )}
          />
        </DateSelectorContainer>
        <ButtonContainer>
          <StyledButton
            onClick={() => sendFeedback(false)}
            disabled={!feedbackText || (isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText)}
          >
            <StyledChatIcon />
            {texts.sendFeedBack}
          </StyledButton>
          <StyledButton
            onClick={() => sendFeedback(true)}
            disabled={!feedbackText || (isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText)}
          >
            <StyledChatIcon />
            {texts.scheduleMessage}
          </StyledButton>
        </ButtonContainer>
        <Divider flexItem />
        <ToDoList clientId={clientId} />
      </ScheduleWrapper>
      <ConfirmDialog
        isOpen={showAttachmentSaveModal}
        onCancel={onCancelAttachmentSaveAsDraft}
        onConfirm={onAttachmentSaveAsDraft}
        dialogTexts={{
          title: texts.attachmentSaveTitle,
        }}
      />
      {showReviewRequestModal && (
        <ReviewRequestModal
          clientConfigDoc={userConfigDoc}
          activity={activity}
          showModal={showReviewRequestModal}
          onClose={onReviewRequestModalClose}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </FeedbackContainer>
  );
};

export default compose(
  observer,
)(CheckInFeedback);
