import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Container as DraggableContainer } from 'react-smooth-dnd';

import ToDo from '../../Model/ToDo';
import useComponentMounted from '../../../hooks/useComponentMounted';
import { ReactComponent as CreateIcon } from '../../../assets/icons/v2/creation-plus-circle.svg';
import { ReactComponent as SelectedIcon } from '../../../assets/icons/selected-icon.svg';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import ToDoModal from '../ToDoModal';

import ToDoItem from './ToDoItem';
import { DraggableID, portalContainer } from './utils';
import {
  StyledLoadingPage,
  EmptyToDoText,
  StyledContainer,
  StyledHeaderRow,
  StyledTitle,
  ButtonContainer,
} from './styles';
import texts from './texts.json';

const ToDoList = ({ clientId, fromQAP }) => {
  const [activeTodoCollection, setActiveTodoCollection] = useState({ docs: [] });
  const [completedTodoCollection, setCompletedTodoCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTodoModal, setShowTodoModal] = useState(false);
  const [isShowCompletedTodo, setIsShowCompletedTodo] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  // This useEffect handles fetching active todos
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const activeTodoCol = await ToDo.getClientToDosByCompletion(clientId);

      if (isComponentMountedRef.current) {
        setActiveTodoCollection(activeTodoCol);
        setIsLoading(false);
      }
    };

    init();
  }, [
    clientId,
    isComponentMountedRef,
  ]);

  // This useEffect handles fetching completed todos
  // It only fetches if isCompleted is true and completedTodoCollection is not already set
  useEffect(() => {
    const init = async () => {
      if (isShowCompletedTodo && !completedTodoCollection) {
        setIsLoading(true);
        const completedTodoCol = await ToDo.getClientToDosByCompletion(clientId, true);

        if (isComponentMountedRef.current) {
          setCompletedTodoCollection(completedTodoCol);
          setIsLoading(false);
        }
      }
    };

    init();
  }, [
    clientId,
    isShowCompletedTodo,
    isComponentMountedRef,
    completedTodoCollection,
  ]);

  const filteredToDos = useMemo(() => {
    if (isShowCompletedTodo && completedTodoCollection) {
      return completedTodoCollection.docs;
    }
    return activeTodoCollection.docs;
  },
  [
    isShowCompletedTodo,
    completedTodoCollection,
    activeTodoCollection,
  ]);

  const moveTodoItem = useCallback(async ({ removedIndex, addedIndex }) => {
    const movedItem = filteredToDos[removedIndex];
    filteredToDos.splice(removedIndex, 1);
    filteredToDos.splice(addedIndex, 0, movedItem);

    await Promise.all(
      filteredToDos.map(async (todoDoc, orderIndex) => {
        todoDoc.updateTodoIndex(orderIndex);
      }),
    );
  }, [filteredToDos]);

  const renderTodoItems = useMemo(() => {
    if (isLoading) {
      return <StyledLoadingPage />;
    }
    if (!filteredToDos || !filteredToDos.length) {
      return <EmptyToDoText>{!isShowCompletedTodo ? texts.emptyActiveToDo : texts.emptyCompletedToDo}</EmptyToDoText>;
    }
    return (
      <DraggableContainer
        dragHandleSelector={`.${DraggableID}`}
        lockAxis="y"
        onDrop={moveTodoItem}
        getGhostParent={() => portalContainer}
        render={(setRender) => (
          <StyledContainer ref={setRender}>
            {filteredToDos.map((doc) => (
              <ToDoItem key={doc.id} todoItem={doc} fromQAP={fromQAP} />
            ))}
          </StyledContainer>
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromQAP,
    isLoading,
    filteredToDos,
    filteredToDos.length,
    isShowCompletedTodo,
  ]);

  return (
    <>
      <StyledHeaderRow>
        <StyledTitle>{texts.toDos}</StyledTitle>
        <ButtonContainer>
          {fromQAP && (
            <PrimaryButton
              icon={<SelectedIcon />}
              onClick={() => setIsShowCompletedTodo((prev) => !prev)}
              size="medium"
            >
              {!isShowCompletedTodo ? texts.showCompletedTodos : texts.showActiveTodos}
            </PrimaryButton>
          )}
          <PrimaryButton icon={<CreateIcon />} onClick={() => setShowTodoModal(true)}>
            {texts.newTodo}
          </PrimaryButton>
        </ButtonContainer>
      </StyledHeaderRow>
      {renderTodoItems}
      {showTodoModal && (
        <ToDoModal
          clientId={clientId}
          showModal={showTodoModal}
          onClose={() => setShowTodoModal(false)}
        />
      )}
    </>
  );
};

ToDoList.propTypes = {
  clientId: PropTypes.string.isRequired,
  fromQAP: PropTypes.bool,
};

ToDoList.defaultProps = {
  fromQAP: false,
};

export default compose(
  observer,
)(ToDoList);
