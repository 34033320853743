import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CoachHistory from '../../../../../../Model/CoachHistory';
import StripeDeauthorization from '../StripeDeauthorization';
import LeadFormArchiver from '../LeadFormArchiver/LeadFormArchiver';
import OffboardingNote from '../OffboardingNotes/OffboardingNote';
import SlackChannelArchiver from '../SlackChannelArchiver';
import {
  CheckList,
  CheckListItem,
  Container,
  Heading,
  StyledLoadingPage,
} from './styles';
import texts from './texts.json';
import { offboardingChecklistItems } from './utils';
import ActionButton from './ActionButton';

const OffboardingChecklist = ({ coachDoc, onboardingDoc, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [coachHistory, setCoachHistory] = useState([]);

  const loadCoachHistory = useCallback(async () => {
    setLoading(true);
    const history = await CoachHistory.getCoachHistoryById(coachDoc.id);
    setCoachHistory(history.docs || []);
    setLoading(false);
  }, [coachDoc]);

  useEffect(() => {
    loadCoachHistory();
  }, [loadCoachHistory]);

  const checkActionStatus = (action) => {
    const found = coachHistory.find((h) => h.type === action);
    return !!found;
  };

  if (loading) {
    return <StyledLoadingPage />;
  }

  return (
    <Container>
      <Heading>{texts.inactiveCoach}</Heading>
      <CheckList>
        <StripeDeauthorization
          coachDoc={coachDoc}
          onboardingDoc={onboardingDoc}
          refetch={refetch}
          coachHistory={coachHistory}
        />
        <LeadFormArchiver
          coachDoc={coachDoc}
          loadCoachHistory={loadCoachHistory}
          coachHistory={coachHistory}
          refetch={refetch}
        />
        <SlackChannelArchiver
          coachDoc={coachDoc}
          loadCoachHistory={loadCoachHistory}
          coachHistory={coachHistory}
          refetch={refetch}
        />
        {offboardingChecklistItems.map((item) => (
          <CheckListItem key={item}>
            {texts.checklistItems[item]}
            <ActionButton
              coachId={coachDoc.id}
              action={item}
              done={checkActionStatus(item)}
              loadCoachHistory={loadCoachHistory}
            />
          </CheckListItem>
        ))}
        <OffboardingNote
          coachDoc={coachDoc}
          loadCoachHistory={loadCoachHistory}
          refetch={refetch}
        />
      </CheckList>
    </Container>
  );
};

OffboardingChecklist.propTypes = {
  coachDoc: PropTypes.object.isRequired,
  onboardingDoc: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default OffboardingChecklist;
