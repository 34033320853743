const offboardingChecklistItems = [
  'POST_PAYMENT_FORM_DELETED',
  'COACH_WEBSITE_DELETED',
  'APP_ICON_DELETED',
  'ZOHO_EMAIL_REMOVED',
];

const OffboardingChecklistEnum = Object.freeze({
  POST_PAYMENT_FORM_DELETED: 'POST_PAYMENT_FORM_DELETED',
  COACH_WEBSITE_DELETED: 'COACH_WEBSITE_DELETED',
  APP_ICON_DELETED: 'APP_ICON_DELETED',
  ZOHO_EMAIL_REMOVED: 'ZOHO_EMAIL_REMOVED',
  LEAD_FORM_ARCHIVED: 'LEAD_FORM_ARCHIVED',
  COACH_OFFBOARDING_NOTE_ADDED: 'COACH_OFFBOARDING_NOTE_ADDED',
  COACH_OFFBOARDING_NOTE_EDITED: 'COACH_OFFBOARDING_NOTE_EDITED',
  SLACK_CHANNEL_ARCHIVED: 'SLACK_CHANNEL_ARCHIVED',
});

export { offboardingChecklistItems, OffboardingChecklistEnum };
