/**
 * Constants for ACExperiments page
 */

export const INSIGHT_TYPES = {
  INJURY: 'INJURY',
  ACHIEVEMENT: 'ACHIEVEMENT',
  GOAL: 'GOAL',
  PREFERENCE: 'PREFERENCE',
  DEFAULT: 'DEFAULT',
};

export const INSIGHT_EMOJIS = {
  [INSIGHT_TYPES.INJURY]: '🤕',
  [INSIGHT_TYPES.ACHIEVEMENT]: '🎯',
  [INSIGHT_TYPES.GOAL]: '🎯',
  [INSIGHT_TYPES.PREFERENCE]: '👍',
  DEFAULT: '💡',
};
