import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { initFirestorter } from 'firestorter';
import * as Sentry from '@sentry/browser';

import Firebase from '../../firebase';

import FirebaseContext from './FirebaseContext';

const firebase = new Firebase();

const FirebaseContextProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      try {
        initFirestorter({ firebase: firebase.app });
      } catch (error) {
        // If Firestorter is already initialized, we can safely ignore the error
        if (!error.message?.includes('Firestorter already initialized')) {
          Sentry.captureException(error, {
            tags: {
              module: 'FirebaseContextProvider',
              action: 'initFirestorter',
            },
            extra: {
              message: 'Error initializing Firestorter',
            },
          });
          return;
        }
      }
      setIsReady(true);
    }
  }, [
    isReady,
  ]);

  const context = useMemo(() => ({
    isReady,
    firebase,
  }), [
    isReady,
  ]);

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseContextProvider;
