import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';

import ChatContext from '../../../../../../context/ChatContext';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';

import { StyledLabel } from '../../styles';
import ScheduledMessageItem from './ScheduledMessageItem';
import {
  Container,
  StyledIonSpinner,
  InfoText,
} from './styles';
import texts from './texts.json';

const ScheduledMessagesList = () => {
  const { getScheduledMessages } = useContext(ChatContext);

  const [isReady, setIsReady] = useState(false);
  const [messageRequests, setMessageRequests] = useState([]);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const result = await getScheduledMessages();
      if (isComponentMountedRef.current) {
        setMessageRequests(result);
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    isReady,
    getScheduledMessages,
    isComponentMountedRef,
  ]);

  const handleMessageDelete = useCallback((deletedMessageId) => {
    setMessageRequests((prevMessages) => prevMessages.filter((message) => message.id !== deletedMessageId));
  }, []);

  const renderContent = useCallback(() => {
    if (isReady) {
      if (messageRequests.length) {
        return (
          messageRequests.map((requestDoc) => (
            <ScheduledMessageItem
              key={requestDoc.id}
              broadcastMessageRequestDoc={requestDoc}
              onMessageDelete={handleMessageDelete}
            />
          ))
        );
      }
      return (
        <InfoText>{texts.noMessages}</InfoText>
      );
    }
    return (
      <StyledIonSpinner name="crescent" />
    );
  }, [
    isReady,
    messageRequests,
    handleMessageDelete,
  ]);

  return (
    <Container>
      <StyledLabel>{texts.scheduledMessageDelivery}</StyledLabel>
      {renderContent()}
    </Container>
  );
};

export default observer(ScheduledMessagesList);
