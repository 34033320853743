import React, { useContext, useState, useEffect } from 'react';
import format from 'string-template';
import * as Sentry from '@sentry/browser';
import clipboardCopy from 'clipboard-copy';
import { CircularProgress } from '@mui/material';
import UserContext from '../../../../context/UserContext';
import useSmartContext from '../../../../utils/smartContext';
import useLogger from '../../../../hooks/useLogger';
import {
  HeaderRow, TitleContainer, SubTitle, Title,
} from '../../../../components/v2/Header';
import {
  Container,
  ContentWrapper,
  InputContainer,
  URLInput,
  SubmitButton,
  ResultsContainer,
  ErrorMessage,
  ClientInfo,
  ClientLink,
  InsightSection,
  InsightItem,
  InsightEmoji,
  InsightContent,
  InsightDate,
  InsightEvidence,
  QuestionsSection,
  QuestionItem,
  QuestionText,
  AnswerText,
  ReasoningText,
  QuestionsAddressedWrapper,
  QuestionsAddressedTitle,
  QuestionAddressedItem,
  ChatSection,
  ChatContent,
  ChatMessage,
  ChatMeta,
  ProposedMessage,
  CopyButton,
  JsonSection,
  JsonContent,
  LoadingOverlay,
} from './styles';
import texts from './texts.json';
import { INSIGHT_EMOJIS } from './constants';

// Helper to validate URL format and extract client ID
const extractClientIdFromUrl = (url) => {
  if (!url || typeof url !== 'string' || url.trim() === '') return null;

  const trimmedUrl = url.trim();

  try {
    // Check if it's a valid URL
    let urlObj;
    try {
      urlObj = new URL(trimmedUrl);
    } catch (e) {
      // Try adding https:// if not present
      if (!trimmedUrl.match(/^https?:\/\//i)) {
        try {
          urlObj = new URL(`https://${trimmedUrl}`);
        } catch (e2) {
          // Not a valid URL even with https prefix
          throw new Error('Invalid URL');
        }
      } else {
        throw e;
      }
    }

    // Extract client ID from the end of the URL
    const pathParts = urlObj.pathname.split('/').filter(Boolean);
    const potentialId = pathParts[pathParts.length - 1];

    // Basic validation that it looks like a Firebase ID (alphanumeric, -, _)
    if (potentialId && /^[a-zA-Z0-9_-]+$/.test(potentialId)) {
      return potentialId;
    }

    // If no valid ID in path, check if it might be just a client ID
    if (trimmedUrl.match(/^[a-zA-Z0-9_-]{20,}$/)) {
      return trimmedUrl;
    }

    return null;
  } catch (err) {
    // If URL is invalid, check if it might be just a client ID
    if (trimmedUrl.match(/^[a-zA-Z0-9_-]{20,}$/)) {
      return trimmedUrl;
    }
    return null;
  }
};

const getInsightEmoji = (type) => INSIGHT_EMOJIS[type] || INSIGHT_EMOJIS.DEFAULT;

const formatTimestamp = (timestamp) => {
  try {
    const date = new Date(timestamp);
    if (Number.isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } catch (err) {
    Sentry.captureException(err, {
      tags: { component: 'smartContext', type: 'date_parsing_error' },
      extra: { timestamp },
    });
    return 'Invalid date';
  }
};

const formatDate = (dateStr) => {
  try {
    const date = new Date(dateStr);
    if (Number.isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  } catch (err) {
    Sentry.captureException(err, {
      tags: { component: 'smartContext', type: 'date_parsing_error' },
      extra: { dateStr },
    });
    return 'Invalid date';
  }
};

const ACExperiments = () => {
  const [clientUrl, setClientUrl] = useState('');
  const [clientId, setClientId] = useState(null);
  const [urlError, setUrlError] = useState('');
  const [smartContext, setSmartContext] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showRawJson, setShowRawJson] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [selectedCommIndex, setSelectedCommIndex] = useState(null);
  const { userDoc } = useContext(UserContext);
  const { generateSmartContext } = useSmartContext();
  const { logEvent } = useLogger();

  // Validate URL when it changes
  useEffect(() => {
    setUrlError('');
    const extractedId = extractClientIdFromUrl(clientUrl);
    setClientId(extractedId);

    if (clientUrl && !extractedId) {
      setUrlError(texts.invalidUrlError);
    }
  }, [clientUrl]);

  const handleSubmit = async () => {
    // Clear previous state
    setError('');
    setSmartContext(null);

    if (!clientId) {
      setUrlError(texts.invalidUrlError);
      return;
    }

    try {
      setLoading(true);

      // Track the attempt to generate Smart Context - direct logging
      logEvent('smartContextGenerateAttempt', {
        clientId,
        coachId: userDoc?.id,
      });

      const response = await generateSmartContext({ clientId });

      if (!response || !response.ok) {
        let errorMessage = 'Failed to generate smart context';

        // Try to parse error from response
        try {
          const data = await response?.json();
          if (data?.error) {
            errorMessage = data.error;
          }
        } catch (parseError) {
          // Ensure we're passing an Error object to Sentry
          const parsedError = parseError instanceof Error ? parseError : new Error(String(parseError));
          Sentry.captureException(parsedError, {
            tags: { component: 'ACExperiments', type: 'response_parse_error' },
            extra: { clientId, responseStatus: response?.status },
          });
        }

        // Track the error - direct logging
        logEvent('smartContextGenerateError', {
          clientId,
          coachId: userDoc?.id,
          errorMessage,
        });

        throw new Error(errorMessage);
      }

      // Parse successful response
      try {
        const data = await response.json();

        if (!data || !data.smartContext) {
          throw new Error('Invalid response format');
        }

        // Track successful Smart Context generation
        try {
          logEvent('smartContextGenerated', {
            clientId,
          });
        } catch (trackingError) {
          // Failed to track event but continue with state update
        }

        // Set state directly without setTimeout
        setSmartContext(data.smartContext);
      } catch (parseError) {
        const parsedError = parseError instanceof Error ? parseError : new Error(String(parseError));
        Sentry.captureException(parsedError, {
          tags: { component: 'ACExperiments', type: 'response_parse_error' },
          extra: { clientId, responseStatus: response.status },
        });
        throw new Error('Could not parse smart context data');
      }
    } catch (err) {
      const apiError = err instanceof Error ? err : new Error(String(err));
      Sentry.captureException(apiError, {
        tags: { component: 'ACExperiments', type: 'api_error' },
        extra: { clientId },
      });
      setError(apiError.message || 'Failed to generate smart context');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyMessage = (index) => {
    // Handle both new and old API response formats
    const proposedComms = smartContext?.proposedComms || [];
    const legacyProposedComm = smartContext?.proposedComm;

    // Convert legacy format to new format if needed
    let allProposedComms = [];

    if (proposedComms.length) {
      allProposedComms = proposedComms;
    } else if (legacyProposedComm) {
      allProposedComms = [
        {
          ...legacyProposedComm,
          reasoning: 'Generated using the previous format',
        },
      ];
    }

    if (!allProposedComms.length) return;

    if (index >= 0 && index < allProposedComms.length) {
      try {
        clipboardCopy(allProposedComms[index].message)
          .then(() => {
            setCopySuccess(true);
            setSelectedCommIndex(index);

            // Track message copy event
            logEvent('smartContextMessageCopied', { clientId });

            setTimeout(() => {
              setCopySuccess(false);
              setSelectedCommIndex(null);
            }, 2000);
          })
          .catch((err) => {
            // Ensure we're passing an Error object to Sentry
            const clipboardError = err instanceof Error ? err : new Error(String(err));
            Sentry.captureException(clipboardError, {
              tags: { component: 'ACExperiments', type: 'clipboard_error' },
            });
          });
      } catch (err) {
        const catchError = err instanceof Error ? err : new Error(String(err));
        Sentry.captureException(catchError, {
          tags: { component: 'ACExperiments', type: 'clipboard_error' },
        });
      }
    }
  };

  // Track when the component mounts
  useEffect(() => {
    logEvent('smartContextToolViewed');
  }, [logEvent]);

  // Toggle raw JSON view without tracking
  const handleToggleRawJson = () => {
    setShowRawJson(!showRawJson);
  };

  const renderClientInfo = () => {
    if (!smartContext?.clientName) return null;

    // Create sanitized URL for client profile
    const profileUrl = clientUrl && clientUrl.startsWith('http') ? clientUrl : '#';

    return (
      <ClientInfo>
        <h3>{smartContext.clientName}</h3>
        {profileUrl !== '#' && (
          <ClientLink href={profileUrl} target="_blank" rel="noopener noreferrer">
            {texts.viewClientProfile}
          </ClientLink>
        )}
      </ClientInfo>
    );
  };

  const renderInsights = () => {
    if (!smartContext?.context || !Array.isArray(smartContext.context)) return null;

    return (
      <InsightSection>
        <h3>{texts.keyInsightsTitle}</h3>
        {smartContext.context.map((insight) => (
          <InsightItem key={`insight-${insight.type}-${insight.learnedAt}-${insight.content.substring(0, 10)}`}>
            <InsightEmoji>{getInsightEmoji(insight.type)}</InsightEmoji>
            <InsightContent>
              {insight.content}
              <InsightDate>{formatDate(insight.learnedAt)}</InsightDate>
              {insight.evidence && (
                <InsightEvidence>
                  <strong>{texts.evidenceLabel}</strong>
                  {' '}
                  {insight.evidence}
                </InsightEvidence>
              )}
            </InsightContent>
          </InsightItem>
        ))}
      </InsightSection>
    );
  };

  const renderExplicitQuestions = () => {
    if (
      !smartContext?.explicitQuestions
      || !Array.isArray(smartContext.explicitQuestions)
      || smartContext.explicitQuestions.length === 0
    ) {
      return null;
    }

    return (
      <QuestionsSection>
        <h3>{texts.explicitQuestionsTitle}</h3>
        {smartContext.explicitQuestions.map((item) => (
          <QuestionItem key={`question-${item.question.substring(0, 10)}`}>
            <QuestionText>
              <strong>{texts.questionLabel}</strong>
              {' '}
              {item.question}
            </QuestionText>
            <AnswerText>
              <strong>{texts.answerLabel}</strong>
              {' '}
              {item.answer}
            </AnswerText>
            <ReasoningText>
              <strong>{texts.reasoningLabel}</strong>
              {' '}
              {item.reasoning}
            </ReasoningText>
            {item.evidence && (
              <InsightEvidence>
                <strong>{texts.evidenceQuestionLabel}</strong>
                {' '}
                {item.evidence}
              </InsightEvidence>
            )}
          </QuestionItem>
        ))}
      </QuestionsSection>
    );
  };

  const renderChat = () => {
    if (!smartContext?.recentMessages || !Array.isArray(smartContext.recentMessages)) return null;

    const reversedMessages = [...smartContext.recentMessages].reverse();

    // Handle both new and old API response formats
    const availableProposedComms = smartContext?.proposedComms || [];
    const legacyProposedCommInChat = smartContext?.proposedComm;

    // Convert legacy format to new format if needed
    let allProposedCommsForChat = [];

    if (availableProposedComms.length) {
      allProposedCommsForChat = availableProposedComms;
    } else if (legacyProposedCommInChat) {
      allProposedCommsForChat = [
        {
          ...legacyProposedCommInChat,
          reasoning: 'Generated using the previous format',
        },
      ];
    }

    return (
      <ChatSection>
        <h3>{texts.recentChatTitle}</h3>
        <ChatContent>
          {reversedMessages.map((message) => (
            <ChatMessage key={`message-${message.sender}-${message.timestamp}-${message.content.substring(0, 10)}`}>
              <strong>{message.sender}</strong>
              {': '}
              {message.content}
              <ChatMeta>{formatTimestamp(message.timestamp)}</ChatMeta>
            </ChatMessage>
          ))}
        </ChatContent>

        {allProposedCommsForChat.length > 0 && (
          <div style={{ marginTop: '24px' }}>
            <h3>{texts.suggestedResponsesLabel || 'Suggested Responses'}</h3>
            {allProposedCommsForChat.map((comm) => {
              // Generate a stable key using message content rather than index
              const messageKey = `proposed-comm-${comm.message.substring(0, 20).replace(/\s+/g, '-')}`;
              const isMultipleOptions = allProposedCommsForChat.length > 1;
              const optionIndex = allProposedCommsForChat.indexOf(comm) + 1;
              const isCopied = copySuccess && selectedCommIndex === allProposedCommsForChat.indexOf(comm);

              return (
                <ProposedMessage key={messageKey}>
                  <ChatMessage>
                    <strong>{isMultipleOptions ? `Option ${optionIndex}` : texts.suggestedResponseLabel}</strong>
                    {' '}
                    {comm.message}
                    <CopyButton
                      onClick={() => handleCopyMessage(allProposedCommsForChat.indexOf(comm))}
                      success={isCopied}
                    >
                      {isCopied ? texts.copyButtonSuccess : texts.copyButtonDefault}
                    </CopyButton>
                  </ChatMessage>
                  {comm.reasoning && (
                    <div
                      style={{
                        marginTop: '12px',
                        color: '#595959',
                        fontSize: '14px',
                        borderTop: '1px dashed #f0f0f0',
                        paddingTop: '12px',
                      }}
                    >
                      <strong style={{ color: '#8c8c8c' }}>Reasoning:</strong>
                      {' '}
                      {comm.reasoning}
                    </div>
                  )}

                  {comm.questionsAddressed && comm.questionsAddressed.length > 0 && smartContext.explicitQuestions && (
                    <QuestionsAddressedWrapper>
                      <QuestionsAddressedTitle>{texts.questionsAddressedTitle}</QuestionsAddressedTitle>
                      {comm.questionsAddressed.map((item) => {
                        const question = smartContext.explicitQuestions[item.questionIndex];
                        return question ? (
                          <QuestionAddressedItem key={`addressed-${item.questionIndex}`}>
                            {question.question.substring(0, 80)}
                            {question.question.length > 80 ? '...' : ''}
                            {item.howAddressed
                              && item.howAddressed
                                !== 'Required - all explicit questions must be answered in each communication' && (
                                <span
                                  style={{
                                    color: '#8c8c8c',
                                    marginLeft: '4px',
                                  }}
                                >
                                  (
                                  {item.howAddressed}
                                  )
                                </span>
                            )}
                          </QuestionAddressedItem>
                        ) : null;
                      })}
                      {smartContext.explicitQuestions.length > 0 && (
                        <div
                          style={{
                            fontSize: '12px',
                            marginTop: '6px',
                            color: '#52c41a',
                            fontStyle: 'italic',
                          }}
                        >
                          All client questions are addressed in this response
                        </div>
                      )}
                    </QuestionsAddressedWrapper>
                  )}

                  <ChatMeta>{/* Timestamp or other metadata could go here if needed */}</ChatMeta>
                </ProposedMessage>
              );
            })}
          </div>
        )}
      </ChatSection>
    );
  };

  const renderJsonView = () => {
    if (!smartContext) return null;

    return (
      <JsonSection>
        <h3>
          {texts.rawResponseTitle}
          <button type="button" onClick={handleToggleRawJson}>
            {showRawJson ? texts.hideRawResponse : texts.showRawResponse}
          </button>
        </h3>
        {showRawJson && <JsonContent>{JSON.stringify({ smartContext }, null, 2)}</JsonContent>}
      </JsonSection>
    );
  };

  return (
    <Container>
      <HeaderRow>
        <TitleContainer>
          <Title>{format(texts.title, { name: userDoc?.firstName || 'User' })}</Title>
          <SubTitle>{texts.subTitle}</SubTitle>
        </TitleContainer>
      </HeaderRow>

      <ContentWrapper>
        <InputContainer>
          <URLInput
            placeholder={texts.urlInputPlaceholder}
            value={clientUrl}
            onChange={(e) => setClientUrl(e.target.value)}
            error={!!urlError}
          />
          <SubmitButton onClick={handleSubmit} disabled={loading || !clientId || !!urlError}>
            {loading ? texts.generateButtonLoading : texts.generateButtonDefault}
          </SubmitButton>
        </InputContainer>

        {urlError && <ErrorMessage>{urlError}</ErrorMessage>}

        {loading && (
          <LoadingOverlay>
            <CircularProgress size={40} />
            <p>{texts.loadingMessage}</p>
          </LoadingOverlay>
        )}

        {smartContext && (
          <ResultsContainer>
            {renderClientInfo()}
            {renderInsights()}
            {renderExplicitQuestions()}
            {renderChat()}
            {renderJsonView()}
          </ResultsContainer>
        )}

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ContentWrapper>
    </Container>
  );
};

export default ACExperiments;
