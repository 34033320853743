import React, { useContext } from 'react';
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import ConfigurationMenuContext from '../../../../context/ConfigurationMenuContext';

import useConfigurationListConfig from './useConfigurationListConfig';
import {
  drawerStyle,
  StyledList,
} from './styles';

const ConfigurationList = () => {
  const { selectedConfiguration } = useContext(ConfigurationMenuContext);
  const { configurationListEntries } = useConfigurationListConfig();

  return (
    <Drawer
      variant="permanent"
      open
      sx={drawerStyle}
    >
      <StyledList>
        {configurationListEntries
          .filter((entry) => !entry.disabled)
          .map(({
            title,
            icon: IconComponent,
            onClick,
            configurationId,
          }) => (
            <React.Fragment key={configurationId}>
              <ListItem
                button
                onClick={onClick}
                selected={selectedConfiguration === configurationId}
              >
                <ListItemIcon>
                  <IconComponent />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
            </React.Fragment>
          ))}
      </StyledList>
    </Drawer>
  );
};

export default ConfigurationList;
