import React, { useEffect, useState } from 'react';
import PermissionTable from '../../../components/PermissionTable';
import Role from '../../../../Model/Role';
import LoadingOverlay from '../../../components/LoadingOverlay';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import SelectRole from './components/SelectRoleModal/SelectRole';
import AddNewRole from './components/AddNewRoleModal/AddNewRole';
import {
  Container,
  HeaderContainer,
  StyledButton,
} from './styles';
import texts from './texts.json';

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleDoc, setSelectedRoleDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialogModal, setShowDialogModal] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true);
      try {
        const roleDocList = await Role.getAllRoles();
        const transformedRoleList = roleDocList.docs.map((doc) => ({
          id: doc?.id,
          label: doc?.data?.name,
        }));
        if (isComponentMountedRef.current) {
          setRoles(transformedRoleList);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchRoles();
  }, [isComponentMountedRef]);

  return (
    <Container>
      <HeaderContainer>
        <SelectRole
          roles={roles}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          setSelectedRoleDoc={setSelectedRoleDoc}
          setIsLoading={setIsLoading}
        />
        <StyledButton variant="contained" onClick={() => setShowDialogModal(true)}>
          {texts.addNewRole}
        </StyledButton>
      </HeaderContainer>
      <PermissionTable selectedRoleDoc={selectedRoleDoc} />
      <AddNewRole
        open={showDialogModal}
        onClose={() => setShowDialogModal(false)}
        setRoles={setRoles}
        roles={roles}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setSelectedRole={setSelectedRole}
        setSelectedRoleDoc={setSelectedRoleDoc}
      />
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};
export default Roles;
