import React, { useState, useEffect } from 'react';
import { StyledEngineProvider, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IonSpinner } from '@ionic/react';
import InstagramMediaDocument from '../../Model/InstagramMedia';
import theme from '../../CoachTools/layout/theme';

import { WithInstagramMediaContextProvider } from './context/InstagramMediaContext/WithInstagramMediaContextProvider';
import { Container, MediaWrapper, MediaDataWrapper } from './styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import InstagramMediaItem from './components/InstagramMediaItem';
import InstagramMediaCarousel from './components/InstagramMediaCarousel';
import InstagramMediaData from './components/InstagramMediaData';
import { MEDIA_TYPES } from './constants';

const InstagramMedia = () => {
  const {
    params: { mediaId },
  } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const [mediaData, setMediaData] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    setCarouselIndex(0);
  }, [mediaId]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await InstagramMediaDocument.fetchMediaById(mediaId);
        if (response) {
          setMediaData(response.data);
        } else {
          throw new Error('Media item not found');
        }
      } catch (error) {
        toast.error('Error fetching media data:', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [mediaId]);

  return (
    <ScopedCssBaseline>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container>
            <MediaWrapper>
              {isLoading && <IonSpinner name="crescent" />}
              {mediaData
                && (mediaData.mediaType === MEDIA_TYPES.CAROUSEL_ALBUM ? (
                  <InstagramMediaCarousel mediaItems={mediaData.children} activeIndex={carouselIndex} />
                ) : (
                  <InstagramMediaItem mediaId={mediaId} mediaType={mediaData.mediaType} />
                ))}
            </MediaWrapper>
            <MediaDataWrapper>
              {mediaData && (
                <InstagramMediaData
                  mediaData={mediaData}
                  carouselIndex={carouselIndex}
                  onCarouselNext={() => {
                    setCarouselIndex((prevIndex) => (prevIndex + 1) % mediaData.children.length);
                  }}
                  onCarouselPrev={() => {
                    setCarouselIndex((prevIndex) => (prevIndex === 0 ? mediaData.children.length - 1 : prevIndex - 1));
                  }}
                />
              )}
            </MediaDataWrapper>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </ScopedCssBaseline>
  );
};

export default WithInstagramMediaContextProvider(InstagramMedia);
