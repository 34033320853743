import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../styles/colors';
import DialogModal from '../DialogModal';

const RoundedModal = styled(DialogModal)`
  .MuiDialogContent-root, .MuiPaper-root {
    border-radius: 8px;
  }
`;

const ModalContentWrapper = styled.div`
  outline: 0;
  height: fit-content;
  background-color: ${colors.shades.secondary10};
  border-radius: 8px;
  border: 1px solid ${colors.shades.secondary7};
  position:${(props) => props.position || 'relative'};
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  margin-top: 20px;
  padding: 28px 50px 40px 50px;
  border-top: 1px solid ${colors.shades.secondary7};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 66px 50px 14px 50px;
  color: ${colors.shades.secondary3};
  border-bottom: 1px solid ${colors.shades.secondary7};
`;

const Title = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`;

const Description = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.shades.gamma2};
  margin-top: 5px;
`;

const ModalBody = styled.div`
  position: relative;
  padding: 44px 50px;
  overflow: auto;
`;

export {
  Title,
  Description,
  ActionsContainer,
  RoundedModal,
  ModalContentWrapper,
  HeaderContainer,
  ModalBody,
};
