import React, {
  useMemo,
  useState,
} from 'react';
import {
  tabs,
  tabsConfig,
} from './utils';

import {
  StyledHeader,
  Title,
  StyledTabs,
} from './styles';
import texts from './texts.json';

const UserRoleManagement = () => {
  const [selectedTab, setSelectedTab] = useState(tabs.USER_SELECT);
  const tabContent = useMemo(() => {
    const { component: TabContent } = tabsConfig[selectedTab];

    return (
      <TabContent />
    );
  }, [selectedTab]);

  return (
    <>
      <StyledHeader>
        <Title>{texts.title}</Title>
      </StyledHeader>
      <StyledTabs
        tabsConfig={tabsConfig}
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
      />
      {tabContent}
    </>
  );
};

export default UserRoleManagement;
