import React, {
  useMemo,
  useState,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import useToolsNavigation from '../../../../hooks/useToolsNavigation';
import MealPlanContext, { withMealPlanContextReady } from '../../../../context/MealPlanContext';
import ExternalCoachContext from '../../../../context/ExternalCoachContext';
import { getClientPagePath } from '../../../../../utils/routes';
import ChatButton from '../../../ChatButton';
import ClientQuickInfoButton from '../../../ClientQuickInfoButton';
import colors from '../../../../../styles/colors';
import { getUserStatus } from '../../../../utils/userStatus';
import { DateFormat } from '../../../../../utils/date';
import ProfileDetailsSection from './components/ProfileDetailsSection';
import ClientTagsSection from './components/ClientTagsSection';
import SubscriptionDetailsSection from './components/SubscriptionDetailsSection';

import { accordionId } from './utils';
import {
  Container,
  StyledLink,
  StyledAvatar,
  ButtonContainer,
  StyledButton,
  StyledMealPlanIcon,
  StyledMobileIcon,
  Name,
  StatusSection,
  StatusText,
  StatusValue,
} from './styles';
import texts from './texts.json';
import subscriptionTexts from './components/SubscriptionDetailsSection/texts.json';

const EMPTY_ACCORDION = '';
const EMPTY_CELL = '-';

const UserInfoSection = ({
  clientId,
  userDoc,
  showMobileViewModal,
  isQAP,
}) => {
  const {
    name,
    avatarUrl,
    customTags,
  } = userDoc;

  const [expanded, setExpanded] = useState(accordionId.PROFILE_DETAILS_ACCORDION);

  const { navigateToClientMealPlanPage } = useToolsNavigation();
  const { mealPlanAssignments } = useContext(MealPlanContext);
  const { externalCoachDoc: { id: coachId } } = useContext(ExternalCoachContext);

  const isMealPlanAvailable = useMemo(() => (
    !!mealPlanAssignments?.find((assignment) => assignment.id === clientId)
  ), [
    clientId,
    mealPlanAssignments,
  ]);

  const clientStatus = useMemo(() => computed(() => {
    const { status, associatedDateString } = getUserStatus(userDoc, DateFormat.DEFAULT_DATE_FORMAT);
    let statusText = status;
    if (associatedDateString) {
      statusText += ` (${associatedDateString})`;
    }
    return statusText;
  }), [
    userDoc,
  ]).get();

  // Allow only one accordion to be open at a time
  const handleExpandSection = useCallback((accordion) => (event, isExpanded) => {
    setExpanded(isExpanded ? accordion : EMPTY_ACCORDION);
  }, []);

  return (
    <Container id="quickAccessPanel" isQAP={isQAP}>
      <StyledAvatar src={avatarUrl} />
      {isQAP ? (
        <StyledLink to={getClientPagePath(coachId, clientId)}>
          {name}
        </StyledLink>
      ) : (
        <Name>{name}</Name>
      )}
      <StatusSection>
        <StatusText>
          {subscriptionTexts.clientStatus}
          :
          {' '}
          <StatusValue statusColor={clientStatus === subscriptionTexts.active
            ? colors.shades.success6 : colors.shades.danger3}
          >
            {clientStatus || EMPTY_CELL}
          </StatusValue>
        </StatusText>
      </StatusSection>
      <ButtonContainer>
        <ChatButton
          userId={userDoc.id}
          text={texts.chatLabel}
          popperPlacement="right-start"
          textIconButton
        />
        {!isQAP && <ClientQuickInfoButton userId={userDoc.id} textIconButton />}
        {isMealPlanAvailable && (
          <StyledButton
            onClick={() => navigateToClientMealPlanPage(clientId)}
          >
            <StyledMealPlanIcon />
            {texts.mealPlan}
          </StyledButton>
        )}
        <StyledButton
          onClick={() => showMobileViewModal(true)}
        >
          <StyledMobileIcon />
          {texts.AppView}
        </StyledButton>
      </ButtonContainer>
      <ProfileDetailsSection
        clientId={clientId}
        userDoc={userDoc}
        handleExpand={handleExpandSection}
        expanded={expanded}
        isQAP={isQAP}
      />
      <ClientTagsSection
        userId={clientId}
        customTags={customTags}
        handleExpand={handleExpandSection}
        expanded={expanded}
        isQAP={isQAP}
      />
      <SubscriptionDetailsSection
        userDoc={userDoc}
        handleExpand={handleExpandSection}
        expanded={expanded}
        isQAP={isQAP}
      />
    </Container>
  );
};

UserInfoSection.propTypes = {
  clientId: PropTypes.string.isRequired,
  userDoc: PropTypes.object.isRequired,
  showMobileViewModal: PropTypes.func.isRequired,
  isQAP: PropTypes.bool.isRequired,
};

export default compose(
  withMealPlanContextReady,
  observer,
)(UserInfoSection);
