import styled from 'styled-components';

const ModalContent = styled.div`
  margin-bottom: 20px;
  padding: 10px 40px;
`;

const FieldContainer = styled.div`
  margin: 20px 0;
`;

export {
  ModalContent,
  FieldContainer,
};
