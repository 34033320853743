import styled from 'styled-components';
import colors from '../../styles/colors';

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  gap: 0px; 
  padding: 16px;
  justify-content: center;
`;

const MediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 682px;
  aspect-ratio: 1 / 1;
  background-color: ${colors.shades.beta2};
  color: ${colors.base.gamma};
`;

const MediaDataWrapper = styled.div`
  position: relative;
  width: 400px;
  background-color: ${colors.shades.beta2};
  border-left: 1px solid white;
  color: ${colors.base.gamma};
  max-height: 682px;
`;

export {
  Container,
  MediaWrapper,
  MediaDataWrapper,
};
