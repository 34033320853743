import { useContext } from 'react';
import * as Sentry from '@sentry/browser';
import FirebaseContext from '../context/FirebaseContext';

const SmartContextType = {
  CLIENT: 'client',
  COACH: 'coach',
  TEAM: 'team',
};

const SmartContextFields = {
  CLIENT_ID: 'clientId',
  COACH_ID: 'coachId',
  TEAM_ID: 'teamId',
};

const RemoteFunctions = {
  GENERATE_SMART_CONTEXT: 'generateSmartContext',
};

/**
 * Hook to generate smart context using Firebase remote function
 * @param {Object} params - Parameters for generating smart context
 * @param {string} params.clientId - Client ID to generate context for
 * @returns {Promise<Object>} The generated smart context data
 */
const useSmartContext = () => {
  const { firebase: { remote } } = useContext(FirebaseContext);

  const generateSmartContext = async ({ clientId }) => {
    if (!clientId) {
      const error = new Error('Client ID is required');
      Sentry.captureException(error, {
        tags: {
          component: 'smartContext',
          type: 'validation_error',
        },
      });
      throw error;
    }

    try {
      const response = await remote(RemoteFunctions.GENERATE_SMART_CONTEXT, { clientId });
      return response;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          component: 'smartContext',
          type: 'api_error',
        },
        extra: {
          clientId,
          functionName: RemoteFunctions.GENERATE_SMART_CONTEXT,
        },
      });
      throw error;
    }
  };

  return { generateSmartContext };
};

export {
  SmartContextType,
  SmartContextFields,
  RemoteFunctions,
  useSmartContext as default,
};
