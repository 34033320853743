const texts = {
  toolbarPlaceholder: 'Search for coach',
  emptyCell: '-',
  headers: {
    formId: 'Form ID',
    formName: 'Form Name',
    formType: 'Form Type',
    coach: 'Coach Name',
    results: 'Results',
    partials: 'Partials',
    action: 'Action',
  },
  archivedCheckbox: 'Show Archived Forms',
  leadFormCheckbox: 'Show Lead Forms',
  postPaymentFormCheckbox: 'Show Post Payment Forms',
};

export default texts;
