import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import moment from 'moment';

import { StyledTabs } from '../../components/ClientInfo/styles';
import CoachSelect from '../../components/Leads/components/CoachSelect';
import { HeaderRow, TitleContainer, Title } from '../../../components/v2/Header';
import InternalAssignment from '../../../Model/InternalAssignment';
import { AssignmentRole } from '../../../utils/internalAssignments';
import LoadingOverlay from '../../components/LoadingOverlay';
import Table from '../../../components/v2/Table';
import { DateFormat } from '../../../utils/date';
import Button from '../../../components/Button';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import useComponentMounted from '../../../hooks/useComponentMounted';
import ConfirmationModal from '../../../components/v2/ConfirmationModal/ConfirmationModal';
import AddAssignmentModal from './AddAssignmentModal';
import { ButtonContainer } from './styles';

import texts from './texts';

const InternalAssignments = () => {
  const [selectedRole, setSelectedRole] = useState(AssignmentRole.INSIDE_SALES);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [internalAssignments, setInternalAssignments] = useState({ docs: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [openAddAssignmentModal, setOpenAddAssignmentModal] = useState(false);
  const [assignmentToRemove, setAssignmentToRemove] = useState(null);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const fetchInternalAssignments = async () => {
      if (selectedCoach?.id) {
        setIsLoading(true);
        const assignments = await InternalAssignment.getAllCurrentAssignments(null, selectedRole, selectedCoach.id);
        if (isComponentMountedRef.current) {
          setInternalAssignments(assignments);
          setIsLoading(false);
        }
      } else {
        setInternalAssignments({ docs: [] });
      }
    };
    fetchInternalAssignments();
  }, [isComponentMountedRef, selectedCoach, selectedRole]);

  const onRemoveAssignment = async (assignment) => {
    setIsLoading(true);
    await assignment.disableAssignment();
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      setAssignmentToRemove(null);
    }
  };

  return (
    <>
      <HeaderRow>
        <TitleContainer>
          <Title>{texts.internalAssignments}</Title>
        </TitleContainer>
      </HeaderRow>
      <HeaderRow>
        <CoachSelect
          onChange={({ id: coachId, label: coachName }) => setSelectedCoach({ id: coachId, name: coachName })}
        />
      </HeaderRow>
      <StyledTabs
        tabsConfig={{
          [AssignmentRole.INSIDE_SALES]: { label: texts.tabLabels[AssignmentRole.INSIDE_SALES] },
          [AssignmentRole.ASSISTANT_COACH]: { label: texts.tabLabels[AssignmentRole.ASSISTANT_COACH] },
          [AssignmentRole.CAM]: { label: texts.tabLabels[AssignmentRole.CAM] },
          [AssignmentRole.CPS]: { label: texts.tabLabels[AssignmentRole.CPS] },
        }}
        selectedTab={selectedRole}
        onSelectTab={setSelectedRole}
      />
      <LoadingOverlay isLoading={isLoading} />
      <ButtonContainer>
        <Button
          full={false}
          onClick={() => setOpenAddAssignmentModal(true)}
          disabled={!selectedCoach}
        >
          {texts.addAssignment}
        </Button>
      </ButtonContainer>
      <Table
        rows={internalAssignments.docs.slice()}
        columns={[
          {
            field: 'userName',
            headerName: texts.tableColumns.user,
            quickSearch: true,
            flex: 1,
          },
          {
            field: 'createdAt',
            headerName: texts.tableColumns.createdAt,
            renderCell: ({ row }) => (
              <div>{moment(row.createdAt).format(DateFormat.DEFAULT_DATE_FORMAT)}</div>
            ),
            flex: 1,
          },
          {
            field: 'userId',
            headerName: texts.tableColumns.userId,
            flex: 1,
          },
          {
            field: 'actions',
            headerName: texts.tableColumns.actions,
            flex: 1,
            renderCell: ({ row }) => (
              <PrimaryButton
                onClick={() => setAssignmentToRemove(row)}
                variant="info"
                size="medium"
              >
                {texts.removeAssignment}
              </PrimaryButton>
            ),
          },
        ]}
        isLoading={isLoading}
      />
      {openAddAssignmentModal && (
        <AddAssignmentModal
          role={selectedRole}
          coachName={selectedCoach?.name}
          coachId={selectedCoach?.id}
          isOpen={openAddAssignmentModal}
          onClose={() => setOpenAddAssignmentModal(false)}
          internalAssignments={internalAssignments.docs}
        />
      )}
      {assignmentToRemove && (
        <ConfirmationModal
          open={!!assignmentToRemove}
          onClose={() => setAssignmentToRemove(null)}
          onConfirm={() => onRemoveAssignment(assignmentToRemove)}
          title={texts.removeAssignmentTitle}
          description={assignmentToRemove.userName}
        />
      )}
    </>
  );
};

export default compose(
  observer,
)(InternalAssignments);
