import React from 'react';
import PropTypes from 'prop-types';
import {
  FastField,
  useFormikContext,
} from 'formik';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import fieldName, { questionFieldName } from '../../../../formFields';
import externalText from '../../../../texts';
import {
  Container,
  SingleRowContainer,
  ShortFormInput,
  LongFormInput,
  StyledTypography,
} from '../styles';

import texts from './texts.json';

const OptionsQuestion = ({ index }) => {
  const { setFieldValue } = useFormikContext();

  const fieldBaseName = `${fieldName.FIELDS}.${index}`;

  return (
    <Container>
      <SingleRowContainer>
        <ShortFormInput
          name={`${fieldBaseName}.${questionFieldName.CUSTOM_CLASS}`}
          label={externalText.labels[questionFieldName.CUSTOM_CLASS]}
        />
        <FastField name={`${fieldBaseName}.${questionFieldName.REQUIRED}`}>
          {({ field }) => (
            <FormControlLabel
              key={`${fieldBaseName}-${index}-${questionFieldName.REQUIRED}`}
              control={<Checkbox checked={field.value} />}
              label={externalText.labels[questionFieldName.REQUIRED]}
              name={questionFieldName.REQUIRED}
              onChange={(event) => {
                setFieldValue(`${fieldBaseName}.${questionFieldName.REQUIRED}`, event.target.checked);
              }}
            />
          )}
        </FastField>
      </SingleRowContainer>
      <SingleRowContainer>
        <ShortFormInput
          name={`${fieldBaseName}.${questionFieldName.ID}`}
          label={externalText.labels[questionFieldName.ID]}
        />
        <FastField name={`${fieldBaseName}.${questionFieldName.ALLOW_MULTIPLE}`}>
          {({ field }) => (
            <FormControlLabel
              key={`${fieldBaseName}-${index}-${questionFieldName.ALLOW_MULTIPLE}`}
              control={<Checkbox checked={field.value} />}
              label={externalText.labels[questionFieldName.ALLOW_MULTIPLE]}
              name={questionFieldName.ALLOW_MULTIPLE}
              onChange={(event) => {
                setFieldValue(`${fieldBaseName}.${questionFieldName.ALLOW_MULTIPLE}`, event.target.checked);
              }}
            />
          )}
        </FastField>
      </SingleRowContainer>
      <LongFormInput
        name={`${fieldBaseName}.${questionFieldName.LABEL}`}
        label={externalText.labels[questionFieldName.LABEL]}
      />
      <LongFormInput
        name={`${fieldBaseName}.${questionFieldName.OPTIONS}`}
        label={externalText.labels[questionFieldName.OPTIONS]}
      />
      <StyledTypography $withMargin>{texts.optionsDescription}</StyledTypography>
      <ShortFormInput
        name={`${fieldBaseName}.${questionFieldName.PROMPT_INPUT}`}
        label={externalText.labels[questionFieldName.PROMPT_INPUT]}
      />
      <StyledTypography>{texts.promptInputDescription}</StyledTypography>
    </Container>
  );
};

OptionsQuestion.propTypes = {
  index: PropTypes.number.isRequired,
};

export default OptionsQuestion;
