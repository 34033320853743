import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import CoachConfig from '../../../Model/CoachConfig';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../components/LoadingOverlay';
import GoogleAnalyticsSettings from './components/GoogleAnalyticsSettings';
import { Container } from './styles';

/**
 * Analytics Settings component for coach configuration
 * @param {Object} props - Component props
 * @param {Object} props.coach - Coach information
 * @param {string} props.coach.id - Unique identifier for the coach
 * @param {string} props.coach.label - Display name of the coach
 * @returns {React.ReactElement} Analytics settings component
 */
const AnalyticsSettings = ({ coach }) => {
  const isComponentMountedRef = useComponentMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [coachConfig, setCoachConfig] = useState(null);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const coachConfigDoc = await CoachConfig.getCoachConfigByCoachId(coach.id);
      if (isComponentMountedRef.current) {
        setCoachConfig(coachConfigDoc);
        setIsLoading(false);
      }
    };
    if (coach.id) {
      init();
    }
  }, [coach, isComponentMountedRef]);

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
        <GoogleAnalyticsSettings coachConfig={coachConfig} />
      )}
    </Container>
  );
};

AnalyticsSettings.propTypes = {
  coach: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(observer)(AnalyticsSettings);
