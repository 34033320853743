import styled from 'styled-components';

import Select from '../../../../../../../../../components/v2/Select';

const RecordOutcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 40px;
`;

const StyledSelectField = styled(Select)`
  align-self: end;
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
`;

export {
  StyledSelectField,
  RecordOutcomeSection,
};
