import { Collection } from 'firestorter';
import Collections from '../utils/collections';
import BaseDocument from './BaseDocument';

/**
 * Instagram account status values
 * @enum {string}
 */
export const accountStatus = {
  AUTHORIZED: 'authorized',
  UNAUTHORIZED: 'unauthorized',
};

class InstagramUser extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.INSTAGRAM_USER}/${id}`, opts);
  }

  /**
   * Get the account's status
   * @typedef {accountStatus.AUTHORIZED | accountStatus.UNAUTHORIZED} AccountStatus
   * @returns {AccountStatus} The account status
   */
  get accountStatus() {
    return this.data.accountStatus;
  }

  /**
   * Get the account's username
   * @returns {string} The account's instagram handle
   */
  get username() {
    return this.data.username;
  }

  /**
   * Get the account name
   * @returns {string} The account's instagram name
   */
  get name() {
    return this.data.name;
  }

  /**
   * Get the account's profile picture
   * @returns {string} The profile picture URL
   */
  get profilePictureUrl() {
    return this.data.profilePictureUrl;
  }

  /**
   * Get the associated coach ID
   * @returns {string | undefined} The associated coach ID
   */
  get coachId() {
    return this.data.coachId;
  }

  static async getInstaUserById(documentId) {
    const instagramUser = new InstagramUser(documentId);
    await instagramUser.init();

    return instagramUser.exists ? instagramUser : null;
  }

  static async getInstaUserByCoachId(coachId) {
    const instgramUserCollection = new Collection(Collections.INSTAGRAM_USER, {
      createDocument: ({ id }, opts) => new InstagramUser(id, opts),
      query: (ref) => ref.where('coachId', '==', coachId).limit(1),
    });
    await instgramUserCollection.fetch();
    return instgramUserCollection.hasDocs ? instgramUserCollection.docs[0] : null;
  }
}

export default InstagramUser;
