import format from 'string-template';
import { Document } from 'firestorter';
import Collections from '../../utils/collections';

class CoachAnalyticsConfig {
  static async createCoachAnalyticsConfig(coachId, measurementId, apiSecret) {
    const doc = new Document(`${format(Collections.COACH_ANALYTICS_CONFIG, { coachId })}/${measurementId}`);
    await doc.set({ apiSecret });
  }

  static async deleteCoachAnalyticsConfig(coachId, measurementId) {
    const doc = new Document(`${format(Collections.COACH_ANALYTICS_CONFIG, { coachId })}/${measurementId}`);
    await doc.delete();
  }
}

export default CoachAnalyticsConfig;
