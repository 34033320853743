const QuestionType = {
  TEXT: 'text',
  NUMBER: 'number',
  OPTIONS: 'multi-select',
  EMAIL: 'email',
  PHONE: 'phone',
  NAME: 'name',
  LENGTH: 'length',
  WEIGHT: 'weight',
  DATE: 'date',
};

const LeadQuestionType = [
  QuestionType.TEXT,
  QuestionType.OPTIONS,
  QuestionType.EMAIL,
  QuestionType.PHONE,
  QuestionType.NAME,
];

const PostPaymentQuestionType = [
  QuestionType.TEXT,
  QuestionType.NUMBER,
  QuestionType.OPTIONS,
  QuestionType.LENGTH,
  QuestionType.WEIGHT,
  QuestionType.DATE,
];

const HiddenFieldType = {
  TEXT: 'hidden',
};

export default QuestionType;
export {
  HiddenFieldType,
  LeadQuestionType,
  PostPaymentQuestionType,
};
