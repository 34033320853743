import styled from 'styled-components';
import colors from '../../../../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  height: 125vh;
  background-color:${colors.shades.gamma5}
`;

export {
  Container,
};
