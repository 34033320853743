import styled from 'styled-components';
import InputLabel from '../../../../../../components/v2/InputLabel';
import { StyledTextField } from '../../../../../../components/Inputs';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;

const StyledFormInput = styled(StyledTextField)`
  width: 75%;
`;
const StyledTextLabel = styled(InputLabel)`
  font-size: 12px;
`;
const InputContainer = styled.div`
  display: flex;
  padding: 20px 50px;
  gap: 10px;
  align-items: center;
`;

export {
  InputContainer,
  StyledTextLabel,
  StyledFormInput,
  ButtonContainer,
};
