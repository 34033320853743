import { useContext } from 'react';
import useSessionStore from '../../hooks/useSessionStore';
import ActivityFeed from '../pages/ActivityFeed';
import Chat from '../pages/Chat';
import pages, { pagesConfig } from '../config/pages';
import LeadsPage from '../pages/Leads';
import ISLeads from '../pages/InsideSalesDashboard/ISLeads';
import CRXDashboardPage from '../pages/CRXDashboard';
import CRXCoachOnboardingPage from '../pages/CRXCoachOnboarding';
import CRXCoachConfigurations from '../pages/CRXCoachConfigurations';
import ISCoachConfigurations from '../pages/ISCoachConfigurations';
import RecipeEditor from '../pages/MealsPlanner/RecipeEditor/RecipeEditor';
import Analytics from '../pages/Analytics/Analytics';
import NavigationContext, { NavigationRouteType } from '../context/NavigationContext';

import CrxCommissions from '../pages/CRXDashboard/CrxCommissions';
import CpsCommissions from '../pages/CRXDashboard/CpsCommissions';
import CamCommissions from '../pages/CRXDashboard/CamCommissions';
import InsideSales from '../pages/InsideSalesDashboard/InsideSales';
import InsideSalesCommissions from '../pages/InsideSalesDashboard/InsideSalesCommissions';
import ContractsManager from '../components/ContractsManager/ContractsManager';
import ReusableContracts from '../pages/ReusableContracts';
import ACDashboardPage from '../pages/ACDashboard/CoachingDashboard';
import ACExperimentsPage from '../pages/ACDashboard/ACExperiments';
import CommissionsPage from '../pages/InsideSalesDashboard/CommissionsPage';
import CRXUsers from '../pages/CRXUsers';
import ISUsers from '../pages/ISUsers';
import InternalAssignments from '../pages/InternalAssignments';
import UserRoleManagement from '../pages/UserRoleManagement';
import MealPlannerRoutes from './mealPlanner/mealPlannerRoutes';
import WorkoutsManagerRoutes from './workoutsManager/workoutsManagerRoutes';
import RecipesRoutes from './recipes';
import FormsRoutes from './forms';
import ConfigurationRoutes from './configurations/ConfigurationRoutes';
import ManageClientsRoutes from './manageClients/ManageClientsRoutes';

const COACH_ROUTES = [
  {
    pageId: pages.MEAL_PLANNER,
    path: pagesConfig[pages.MEAL_PLANNER].path,
    component: MealPlannerRoutes,
  },
  {
    pageId: pages.ACTIVITY_FEED,
    path: pagesConfig[pages.ACTIVITY_FEED].path,
    component: ActivityFeed,
    defaultRoute: true,
  },
  {
    pageId: pages.CHAT,
    path: pagesConfig[pages.CHAT].path,
    component: Chat,
  },
  {
    pageId: pages.WORKOUTS_MANAGER,
    path: pagesConfig[pages.WORKOUTS_MANAGER].path,
    component: WorkoutsManagerRoutes,
  },
  {
    pageId: pages.LEADS,
    path: pagesConfig[pages.LEADS].path,
    component: LeadsPage,
  },
  {
    pageId: pages.CONFIG,
    path: pagesConfig[pages.CONFIG].path,
    component: ConfigurationRoutes,
  },
  {
    pageId: pages.MANAGE_CLIENTS,
    path: pagesConfig[pages.MANAGE_CLIENTS].path,
    component: ManageClientsRoutes,
  },
];

const ADMIN_ROUTES = [
  // CRX routes
  {
    pageId: pages.CRX_DASHBOARD,
    path: pagesConfig[pages.CRX_DASHBOARD].path,
    component: CRXDashboardPage,
  },
  {
    pageId: pages.CRX_COACH_ONBOARDING,
    path: pagesConfig[pages.CRX_COACH_ONBOARDING].path,
    component: CRXCoachOnboardingPage,
  },
  {
    pageId: pages.ANALYTICS,
    path: pagesConfig[pages.ANALYTICS].path,
    component: Analytics,
  },
  {
    pageId: pages.CRX_COMMISSIONS,
    path: pagesConfig[pages.CRX_COMMISSIONS].path,
    component: CrxCommissions,
  },
  {
    pageId: pages.CPS_COMMISSIONS,
    path: pagesConfig[pages.CPS_COMMISSIONS].path,
    component: CpsCommissions,
  },
  {
    pageId: pages.CAM_COMMISSIONS,
    path: pagesConfig[pages.CAM_COMMISSIONS].path,
    component: CamCommissions,
  },
  {
    pageId: pages.FORMS,
    path: pagesConfig[pages.FORMS].path,
    component: FormsRoutes,
  },
  {
    pageId: pages.CRX_USER_MANAGEMENT,
    path: pagesConfig[pages.CRX_USER_MANAGEMENT].path,
    component: CRXUsers,
  },
  {
    pageId: pages.CRX_COACH_CONFIGS,
    path: pagesConfig[pages.CRX_COACH_CONFIGS].path,
    component: CRXCoachConfigurations,
  },
  // AC routes
  {
    pageId: pages.AC_DASHBOARD,
    path: pagesConfig[pages.AC_DASHBOARD].path,
    component: ACDashboardPage,
  },
  {
    pageId: pages.AC_EXPERIMENTS,
    path: pagesConfig[pages.AC_EXPERIMENTS].path,
    component: ACExperimentsPage,
  },
  // IS routes
  {
    pageId: pages.INSIDE_SALES_LEADS,
    path: pagesConfig[pages.INSIDE_SALES_LEADS].path,
    component: ISLeads,
  },
  {
    pageId: pages.INSIDE_SALES,
    path: pagesConfig[pages.INSIDE_SALES].path,
    component: InsideSales,
  },
  {
    pageId: pages.INSIDE_SALES_COMMISSIONS,
    path: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].path,
    component: InsideSalesCommissions,
  },
  {
    pageId: pages.INSIDE_SALES_CONTRACTS_MANAGER,
    path: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].path,
    component: ContractsManager,
  },
  // TODO: Move this to an admin-only route. We will limit access to this app for now.
  {
    pageId: pages.COMMISSIONS,
    path: pagesConfig[pages.COMMISSIONS].path,
    component: CommissionsPage,
  },
  {
    pageId: pages.IS_COACH_CONFIGS,
    path: pagesConfig[pages.IS_COACH_CONFIGS].path,
    component: ISCoachConfigurations,
  },
  {
    pageId: pages.IS_USER_MANAGEMENT,
    path: pagesConfig[pages.IS_USER_MANAGEMENT].path,
    component: ISUsers,
  },
  // support
  {
    pageId: pages.RECIPE_EDITOR,
    path: pagesConfig[pages.RECIPE_EDITOR].path,
    component: RecipeEditor,
  },
  {
    pageId: pages.RECIPES,
    path: pagesConfig[pages.RECIPES].path,
    component: RecipesRoutes,
  },
  {
    pageId: pages.REUSABLE_CONTRACTS,
    path: pagesConfig[pages.REUSABLE_CONTRACTS].path,
    component: ReusableContracts,
  },
  {
    pageId: pages.INTERNAL_ASSIGNMENTS,
    path: pagesConfig[pages.INTERNAL_ASSIGNMENTS].path,
    component: InternalAssignments,
  },
  // User Management
  {
    pageId: pages.USER_MANAGEMENT,
    path: pagesConfig[pages.USER_MANAGEMENT].path,
    component: UserRoleManagement,
  },
];

const RoutesMap = {
  [NavigationRouteType.ADMIN]: ADMIN_ROUTES,
};

const useRoutesConfig = () => {
  const { routeType } = useContext(NavigationContext);
  const { assignedPermissions } = useSessionStore();

  // Get the routes for the current type (Admin, Coach, etc.)
  let routes = RoutesMap[routeType] || COACH_ROUTES;

  if (routeType === NavigationRouteType.ADMIN) {
    routes = routes.filter((route) => {
      const permissions = assignedPermissions[route.pageId];
      return permissions && permissions.includes('EDIT');
    });
  }

  return routes;
};

export default useRoutesConfig;
