import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogRoundedModal from '../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../components/Button/ActionButtons';
import AutoComplete from '../AutoComplete';
import Role from '../../../Model/Role';
import useComponentMounted from '../../../hooks/useComponentMounted';
import {
  ButtonContainer,
  InputContainer,
} from './styles';
import texts from './texts.json';

const AssignRole = ({
  open, onClose, selectedUserDoc, setIsLoading, showToast,
}) => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    if (open) {
      const fetchRoles = async () => {
        setIsLoading(true);
        try {
          const roleDocList = await Role.getAllRoles();
          const transformedRoleList = roleDocList.docs.map((doc) => ({
            id: doc?.id,
            label: doc?.data?.name,
          }));
          if (isComponentMountedRef.current) {
            setRoles(transformedRoleList);
          }
        } finally {
          if (isComponentMountedRef.current) {
            setIsLoading(false);
          }
        }
      };
      fetchRoles();
    }
  }, [open, setIsLoading, isComponentMountedRef]);

  const onSaveAddRoleClick = async () => {
    if (!selectedRole) {
      showToast(texts.selectRoleError, { error: true });
      return;
    }

    if (selectedUserDoc.roles.includes(selectedRole.label)) {
      showToast(texts.roleAlreadyAssignedError, { error: true });
      return;
    }
    setIsLoading(true);
    try {
      await selectedUserDoc.setRoles(selectedRole.label);
      showToast(texts.addRoleSuccess);
    } catch (error) {
      showToast(texts.addRoleError, { error: true });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <DialogRoundedModal
      open={open}
      onClose={onClose}
      title={texts.assignNewRole}
      fullWidth
      maxWidth="sm"
      actionButtons={(
        <ButtonContainer>
          <SaveButton variant="contained" onClick={onSaveAddRoleClick}>
            {texts.addButton}
          </SaveButton>
          <CancelButton onClick={onClose}>{texts.cancelButton}</CancelButton>
        </ButtonContainer>
      )}
      modalContentWrapperPosition="static"
    >
      <InputContainer>
        <AutoComplete options={roles} onChange={setSelectedRole} label={texts.selectRole} />
      </InputContainer>
    </DialogRoundedModal>
  );
};

AssignRole.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUserDoc: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default AssignRole;
