import styled from 'styled-components';

const CalendarWrapper = styled.div`
  overflow: auto;
  padding: 20px;
  
  .fc-toolbar-title, .fc-icon{
    font-size: 1rem !important;
  }
  .fc-button{
    line-height: 1 !important;
    margin: 5px;
  }
  .fc-toolbar {
    margin-bottom: 0.5em !important;
  }
  .fc-media-screen {
    background: #DDECFF;
    padding: 10px;
    height: 100%;
  }
  .fc-day-past {
    background: #EAF2FB;
  }
  .fc-daygrid-day-events {
    display: flex;
    flex-direction: column;
    max-height: 66px;
    overflow-x: hidden;
    margin-bottom: 2px;
    position: absolute;
    bottom: 0;
    min-height: 0 !important;
  }
  .fc-daygrid-day-number{
    width: 100%;
    height: 35px;
  }
  .fc-daygrid-day-events::-webkit-scrollbar {
    width: 5px;
  }
  .fc-daygrid-day-events::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .fc-daygrid-day-events::-webkit-scrollbar-thumb {
    background: #888;
  }
  .fc-daygrid-day-events::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export {
  CalendarWrapper,
};
