import Collections from '../utils/collections';
import BaseDocument from './BaseDocument';

class InstagramMediaDocument extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.INSTAGRAM_MEDIA}/${id}`, opts);
  }

  /**
   * Fetches the Instagram media item by its document ID.
   * @param {string} documentId - The ID of the document in Firestore.
   * @returns {Promise<InstagramMediaItem|null>} The fetched media item or null if not found.
   */
  static async fetchMediaById(documentId) {
    const mediaItem = new InstagramMediaDocument(documentId);
    await mediaItem.init();

    return mediaItem.exists ? mediaItem : null;
  }
}

export default InstagramMediaDocument;
