import React from 'react';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';

import texts from '../../texts';
import InitialQuestions from '../../initialQuestions';

import {
  Container,
  StyledAddButton,
} from './styles';

const AddButtons = ({
  push,
  options,
}) => {
  const renderAddButton = (questionType) => (
    <StyledAddButton
      key={questionType}
      startIcon={<Add />}
      onClick={() => push(InitialQuestions[questionType])}
    >
      {texts.questionTypeLabel[questionType]}
    </StyledAddButton>
  );

  return (
    <Container>
      {options.map((option) => renderAddButton(option))}
    </Container>
  );
};

AddButtons.propTypes = {
  push: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default AddButtons;
