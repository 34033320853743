import {
  HeightUnit,
  WeightUnit,
  MeasurementUnit,
} from '../../../utils/measurement';
import { FormType } from '../../Model/Form';

import fieldName, {
  endScreenContentFieldName,
  questionFieldName,
  heightUnitId,
  weightUnitId,
} from './formFields';
import QuestionType, { HiddenFieldType } from './questionType';
import defaultFields from './defaultFields';

const initialValues = (type) => ({
  [fieldName.COACH]: '',
  [fieldName.COACH_ID]: '',
  [fieldName.NAME]: '',
  [fieldName.FIELDS]: defaultFields[fieldName.FIELDS],
  // Fields exclusive to lead forms
  ...(type === FormType.LEAD && {
    [fieldName.HIDDEN_FIELDS]: defaultFields[fieldName.HIDDEN_FIELDS],
    [fieldName.END_SCREEN_CONTENT]: {
      [endScreenContentFieldName.LINE_1]: '',
      [endScreenContentFieldName.LINE_2]: '',
    },
  }),
});

const commonInputFieldValues = {
  [questionFieldName.ID]: '',
  [questionFieldName.CUSTOM_CLASS]: '',
  [questionFieldName.LABEL]: '',
  [questionFieldName.REQUIRED]: false,
};

const initialTextFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.TEXT,
  [questionFieldName.PLACEHOLDER]: '',
};

const initialNumberFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.NUMBER,
  [questionFieldName.PLACEHOLDER]: '',
};

const initialEmailFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.EMAIL,
  [questionFieldName.PLACEHOLDER]: '',
};

const initialOptionsFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.OPTIONS,
  [questionFieldName.OPTIONS]: '',
  [questionFieldName.ALLOW_MULTIPLE]: false,
  [questionFieldName.PROMPT_INPUT]: '',
};

const initialPhoneFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.PHONE,
};

const initialNameFieldValues = {
  [questionFieldName.TYPE]: QuestionType.NAME,
  [questionFieldName.FIELDS]: [
    {
      ...(({ [questionFieldName.REQUIRED]: _, ...restOfValues }) => restOfValues)(commonInputFieldValues),
      [questionFieldName.TYPE]: QuestionType.TEXT,
      [questionFieldName.PLACEHOLDER]: '',
    },
    {
      ...commonInputFieldValues,
      [questionFieldName.TYPE]: QuestionType.TEXT,
      [questionFieldName.PLACEHOLDER]: '',
    },
  ],
};

const initialLengthFieldValues = {
  [questionFieldName.LABEL]: '',
  [questionFieldName.REQUIRED]: false,
  [questionFieldName.TYPE]: QuestionType.LENGTH,
  [questionFieldName.UNITS]: `${HeightUnit[MeasurementUnit.IMPERIAL]}, ${HeightUnit[MeasurementUnit.METRIC]}`,
  [questionFieldName.UNIT_IDS]: {
    [heightUnitId.FEET]: '',
    [heightUnitId.INCHES]: '',
    [heightUnitId.CM]: '',
  },
};

const initialWeightFieldValues = {
  [questionFieldName.LABEL]: '',
  [questionFieldName.REQUIRED]: false,
  [questionFieldName.TYPE]: QuestionType.WEIGHT,
  [questionFieldName.UNITS]: `${WeightUnit[MeasurementUnit.IMPERIAL]}, ${WeightUnit[MeasurementUnit.METRIC]}`,
  [questionFieldName.UNIT_IDS]: {
    [weightUnitId.POUNDS]: '',
    [weightUnitId.KILOGRAMS]: '',
  },
};

const initialDateFieldValues = {
  ...commonInputFieldValues,
  [questionFieldName.TYPE]: QuestionType.DATE,
};

const initialHiddenFieldValues = {
  [questionFieldName.TYPE]: HiddenFieldType.TEXT,
  [questionFieldName.ID]: '',
};

export {
  initialValues,
  initialTextFieldValues,
  initialNumberFieldValues,
  initialEmailFieldValues,
  initialOptionsFieldValues,
  initialPhoneFieldValues,
  initialNameFieldValues,
  initialDateFieldValues,
  initialHiddenFieldValues,
  initialLengthFieldValues,
  initialWeightFieldValues,
};
