import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;
const InputContainer = styled.div`
  display: flex;
  padding: 20px 50px;
  gap: 10px;
  align-items: center;
`;

export {
  ButtonContainer,
  InputContainer,
};
