import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledDialog,
  StyledDialogTitle,
  CloseButton,
  StyledCloseIcon,
  StyledDialogContent,
} from './styles';

const DialogModal = ({
  open,
  onClose,
  children,
  ...otherProps
}) => (
  <StyledDialog
    open={open}
    onClose={() => onClose()}
    {...otherProps}
  >
    <StyledDialogTitle>
      <CloseButton
        onClick={() => onClose()}
        $fullScreen={otherProps.fullScreen}
        id="close-dialog"
      >
        <StyledCloseIcon />
      </CloseButton>
    </StyledDialogTitle>
    <StyledDialogContent>
      {children}
    </StyledDialogContent>
  </StyledDialog>
);

DialogModal.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DialogModal;
