import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'firebase/functions';
import { DoubleArrow } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useToolsNavigation from '../../CoachTools/hooks/useToolsNavigation';
import useSessionStore from '../../hooks/useSessionStore';
import FirebaseContext from '../../context/FirebaseContext';
import { ReactComponent as S2Logo } from '../../assets/s2-logo.svg';
import InstagramLogo from '../../assets/icons/instagram-logo.png';
import { withPageAnalytics } from '../../components/withAnalytics';
import { withUserContextProvider, withUserContextReady } from '../../context/UserContext';
import {
  Container, Body, StyledButton, StyledSpinner, ErrorMessage, IconsContainer, Icon,
} from './styles';
import texts from './texts.json';

const InstagramAuthCallback = () => {
  const [isReady, setIsReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const { navigateToAnalyticsConfigPage } = useToolsNavigation();
  const { userId } = useSessionStore();
  const {
    firebase: { remote },
  } = useContext(FirebaseContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (!code) {
      setErrorMessage('code not found in query params');
      return;
    }

    const fetchAccessToken = async () => {
      setIsReady(true);
      try {
        const response = await remote(`instagram-login?code=${code}&coachId=${userId}`);
        const { error } = await response.json();
        if (error) {
          setErrorMessage(error);
        } else {
          navigateToAnalyticsConfigPage(userId);
        }
      } catch (error) {
        setErrorMessage(error.toString());
      }
    };

    if (!isReady && userId) {
      fetchAccessToken();
    }
  }, [location.search, userId, remote, isReady, navigateToAnalyticsConfigPage]);

  const renderMessage = () => {
    if (errorMessage) {
      return (
        <>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <StyledButton onClick={() => navigateToAnalyticsConfigPage(userId)}>{texts.goBack}</StyledButton>
        </>
      );
    }

    return <Body>{texts.connecting}</Body>;
  };

  return (
    <Container>
      <IconsContainer>
        <Icon src={InstagramLogo} />
        <DoubleArrow width={60} />
        <S2Logo height={35} width={48} />
      </IconsContainer>
      {renderMessage()}
      {!errorMessage && <StyledSpinner name="crescent" />}
    </Container>
  );
};

export default compose(
  withPageAnalytics('instagramAuthCallback'),
  withUserContextProvider,
  withUserContextReady,
  observer,
)(InstagramAuthCallback);
