import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import useToast from '../../../../../../hooks/useToast';
import Coach from '../../../../../../../Model/Coach';
import FormikInput from '../../../../../../../components/FormikInput';

import { initialValues, validationSchema } from '../validation';
import FirebaseContext from '../../../../../../../context/FirebaseContext';

import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import {
  ButtonContainer,
  FormContainer,
  InputContainer,
  StyledButton,
  Info,
  InfoContainer,
} from './styles';

import texts from './texts.json';

const OffboardingForm = ({ coachId, onboardingId, onDeactivate }) => {
  const [showForm, setShowForm] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();

  const handleOnConfirm = () => {
    setIsDialogOpen(false);
    setShowForm(true);
  };

  const handleSubmit = async ({ reason }) => {
    if (!reason) return;

    const activeUsersCount = await Coach.getAllActiveClientsCount(coachId);

    if (activeUsersCount > 0) {
      showToast(texts.activeUsers, { error: true });
      return;
    }

    const apiResponse = await remote('offboardCoach', { coachId, onboardingId, reason });

    if (apiResponse.ok) {
      onDeactivate();
      showToast(texts.offboardSuccess);
      setShowForm(false);
    } else {
      showToast(texts.offboardError, { error: true });
    }
  };

  return (
    <>
      <InfoContainer>
        <Info>
          <span>
            {texts.coachIsActive}
          </span>

          <StyledButton onClick={() => setIsDialogOpen(true)}>
            {texts.deactivateCoach}
          </StyledButton>
        </Info>
      </InfoContainer>
      { showForm
        && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormContainer>
                <InputContainer>
                  <FormikInput
                    name="reason"
                    placeholder={texts.enterReason}
                    error={errors.reason}
                    touched={touched.reason}
                    type="textarea"
                  />
                </InputContainer>

                <ButtonContainer>
                  <StyledButton onClick={() => setShowForm(false)}>{texts.cancel}</StyledButton>
                  <StyledButton
                    disabled={isSubmitting || !!errors.reason}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {texts.deactivate}
                  </StyledButton>
                </ButtonContainer>
              </FormContainer>
            </Form>
          )}
        </Formik>
        )}
      <ConfirmDialog
        isOpen={isDialogOpen}
        onConfirm={handleOnConfirm}
        onCancel={() => setIsDialogOpen(false)}
        dialogTexts={{
          title: texts.confirmCoachDeactivation,
        }}
      />
    </>
  );
};

OffboardingForm.propTypes = {
  coachId: PropTypes.string.isRequired,
  onboardingId: PropTypes.string.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

export default OffboardingForm;
