import styled, { css } from 'styled-components';
import { Alert, IconButton, Typography } from '@mui/material';
import { IonSpinner } from '@ionic/react';

import colors from '../../../../../../../styles/colors';
import QuickSearchToolbar from '../../../../../../components/QuickSearchToolbar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 50vh;
  padding: 0 4px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  padding: 15px 13px;
  border-radius: 5px;
  border: 1px solid ${colors.shades.secondary4};
  margin-bottom: 12px;
`;

const baseTextCss = css`
  font-size: 14px;
  text-align: left;
`;

const MessageText = styled.div`
  ${baseTextCss};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30vw;
`;

const MessageDate = styled.div`
  ${baseTextCss};
  flex-shrink: 0;
  width: 130px;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
`;

const InfoText = styled.div`
  ${baseTextCss};
  width: 100%;
`;

const StyledIonSpinner = styled(IonSpinner)`
  align-self: center;
  justify-self: center;
`;

const StyledAlert = styled(Alert)`
  margin: 10px 0;
  width: 600px;
`;

const StyledListContainer = styled.div`
  max-height: 230px;
  overflow-y: scroll;
  border: 1px solid ${colors.shades.secondary4};
  border-radius: 5px;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.base.alpha};
`;

const SearchBar = styled(QuickSearchToolbar)`
  width: unset;
  background: white;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 10px 0 auto;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  color: ${colors.shades.primary1};
  padding: 10px;
  font-weight: bold;
`;

const StyledButton = styled(IconButton)`
  border-radius: 50%;
  border: 1px solid;
  color: ${colors.shades.gamma2};
  margin: 10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
`;

export {
  Container,
  ItemContainer,
  MessageText,
  MessageDate,
  ActionsContainer,
  InfoContainer,
  EditContainer,
  InfoText,
  StyledIonSpinner,
  StyledAlert,
  StyledListContainer,
  MenuContainer,
  SearchBar,
  NavigationContainer,
  StyledText,
  StyledButton,
};
