import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import format from 'string-template';

import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import LoadingPage from '../../../../../../components/LoadingPage';
import { DateFormat } from '../../../../../../utils/date';
import {
  cancelSubscription,
  listInvoices,
  updateSubscription,
  retryInvoicePayment,
  pauseStripeSubscription,
  unpauseStripeSubscription,
} from '../../../../../../pages/Onboarding/Subscription/stripe/SubscriptionRemoteRequestHandler';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import User from '../../../../../../Model/User';
import UserContract from '../../../../../../Model/UserContract';
import Subscription, { subscriptionStatus as activeSubscriptionStatus } from '../../../../../../Model/Subscription';
import { StripeSubscriptionState } from '../../../../../../pages/Onboarding/Subscription/SubscriptionMetadata';
import useSessionStore from '../../../../../../hooks/useSessionStore';
import { formatCurrencyCents } from '../../../../../../utils/formatters';
import useLogger from '../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../utils/log';
import ReonboardingContractModal from '../../../../ContractModal/Modals/ReonboardingContractModal';
import LoadingOverlay from '../../../../LoadingOverlay';
import ConfirmDialog from '../../../../ConfirmDialog';
import CopyButton from '../../../../../../components/CopyButton';
import useToast from '../../../../../hooks/useToast';
import { ClientStatus, filterChecks } from '../../../../../utils/statusFilter';
import { getContractObj } from '../../../../../utils/userContract';

import EmptyView from '../../EmptyView';

import VoidInvoiceModal from '../VoidInvoiceModal';
import SubscriptionCancelDialog from '../SubscriptionCancelModal';
import StartDateUpdateModal from '../StartDateUpdateModal';
import SubscriptionPauseModal from '../SubscriptionPauseModal';
import {
  Container,
  HeaderContainer,
  DataContainer,
  Title,
  StyledTableContainer,
  StyledTableCell,
  StyledButton,
  StyledCancelSubscriptionIcon,
  StyledLink,
  EditSubscriptionIcon,
  StyledPauseSubscriptionIcon,
  StyledUnpauseSubscriptionIcon,
  StyledReonboardIcon,
} from './styles';
import texts from './texts.json';

const BillingSummary = ({
  userDoc,
  userContractDoc,
  subscriptionDoc,
}) => {
  const [subscriptionData] = useState(subscriptionDoc?.subscriptionData);
  const [invoiceList, setInvoiceList] = useState([]);
  const [error, setError] = useState();
  const [isReady, setIsReady] = useState(false);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);
  const [showConfirmPauseDialog, setShowConfirmPauseDialog] = useState(false);
  const [showStartDateModal, setShowStartDateModal] = useState(false);
  const [showUserContractModal, setShowUserContractModal] = useState(false);
  const [isUpdatingInProgress, setIsUpdatingInProgress] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState();
  const [showRetryPaymentDialog, setShowRetryPaymentDialog] = useState(false);
  const [showVoidInvoiceDialog, setShowVoidInvoiceDialog] = useState(false);
  const [isRetryingInProgress, setIsRetryingInProgress] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState();
  const isComponentMountedRef = useComponentMounted();
  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();
  const { isCoach, isAdmin } = useSessionStore();
  const { logCoachingActivity } = useLogger();
  const [localUserDoc, setLocalUserDoc] = useState(userDoc);

  useEffect(() => {
    const init = async () => {
      let invoices;
      if (subscriptionData) {
        invoices = await listInvoices(userDoc.id, remote);
      }

      if (isComponentMountedRef.current) {
        if (subscriptionDoc) {
          setStripeAccountId(subscriptionDoc.stripeAccountId);
          if (invoices && Array.isArray(invoices)) {
            setInvoiceList(invoices);
          } else {
            setError(invoices?.error.message || invoices?.error);
          }
        }
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    userDoc,
    isReady,
    isComponentMountedRef,
    subscriptionData,
    remote,
    subscriptionDoc,
  ]);

  const cancelClientSubscription = useCallback(async (cancellationReason, cancelType) => {
    setShowConfirmCancelDialog(false);
    setIsUpdatingInProgress(true);
    const { isSuccess } = await cancelSubscription({
      subscriptionId: subscriptionData.id,
      active: true,
      remote,
      cancellationReason,
      cancelType,
    });
    if (isSuccess) {
      showToast(format(texts.cancelSuccess, {
        subscriptionId: subscriptionData.id,
      }));

      logCoachingActivity(CoachingActivity.CANCELED_USER, {
        cancelType,
        cancellationReason,
        clientId: userDoc.id,
      });
    } else {
      showToast(format(texts.cancelError, {
        subscriptionId: subscriptionData.id,
      }), { error: true });
    }
    setIsUpdatingInProgress(false);
  }, [
    subscriptionData,
    remote,
    showToast,
    userDoc,
    logCoachingActivity,
  ]);

  const updateClientSubscription = useCallback(async (updateParams, cancellationReason, cancelType) => {
    setShowConfirmCancelDialog(false);
    setIsUpdatingInProgress(true);
    setShowStartDateModal(false);

    const { updatedSubscription, error: apiError } = await updateSubscription({
      subscriptionId: subscriptionData.id,
      updateParams,
      remote,
      cancellationReason,
      cancelType,
    });
    if (updatedSubscription) {
      const successText = cancellationReason ? texts.futureCancelSuccess : texts.updateSuccess;

      /* We only execute this part if we are updating the start date, not when cancelling the subscription
        It updates the contract terms when changing start date */
      if (!cancelType) {
        const contractObj = getContractObj(userContractDoc);
        // Update the startDate
        contractObj.startDate = moment.unix(updateParams.trial_end);
        // Update the doc
        await userContractDoc.updateContractTermsFromContractData(contractObj);
      }

      showToast(format(successText, {
        subscriptionId: subscriptionData.id,
      }));
    } else if (apiError) {
      showToast(format(texts.updateError, {
        subscriptionId: subscriptionData.id,
        error: apiError.message || '',
      }), { error: true });
    }
    setIsUpdatingInProgress(false);
  }, [
    subscriptionData,
    remote,
    showToast,
    userContractDoc,
  ]);

  const pauseClientSubscription = useCallback(async (pausingReason, breakEndDate, pausedPeriodInMonths) => {
    setShowConfirmPauseDialog(false);
    setIsUpdatingInProgress(true);
    const { isSuccess } = await pauseStripeSubscription({
      subscriptionId: subscriptionData.id,
      contractId: userContractDoc.id,
      remote,
      pausingReason,
      breakEndDate,
    });
    if (isSuccess) {
      setLocalUserDoc((prev) => ({
        ...prev,
        breakEndDate,
      }));
      showToast(format(texts.pauseSuccess, { subscriptionId: subscriptionData.id }));
      logCoachingActivity(CoachingActivity.PAUSED_USER, {
        pausingReason,
        pausedPeriodInMonths,
        clientId: userDoc.id,
      });
    } else {
      showToast(format(texts.pauseError, { subscriptionId: subscriptionData.id }), { error: true });
    }
    if (isComponentMountedRef) {
      setIsUpdatingInProgress(false);
    }
  }, [subscriptionData, remote, showToast, isComponentMountedRef, logCoachingActivity, userDoc, userContractDoc]);

  const unpauseClientSubscription = useCallback(async () => {
    setIsUpdatingInProgress(true);
    const { isSuccess } = await unpauseStripeSubscription({
      subscriptionId: subscriptionData.id,
      contractId: userContractDoc.id,
      remote,
    });
    if (isSuccess) {
      setLocalUserDoc((prev) => ({
        ...prev,
        breakEndDate: new Date(),
      }));
      showToast(format(texts.unpauseSuccess, { subscriptionId: subscriptionData.id }));
    } else {
      showToast(format(texts.unpauseError, { subscriptionId: subscriptionData.id }), { error: true });
    }
    if (isComponentMountedRef) {
      setIsUpdatingInProgress(false);
    }
  }, [subscriptionData, remote, isComponentMountedRef, showToast, userContractDoc]);

  const retryCurrentInvoicePayment = useCallback(async () => {
    setShowRetryPaymentDialog(false);
    setIsRetryingInProgress(true);
    const { invoice, error: stripeError } = await retryInvoicePayment({
      invoiceId: currentInvoice?.id,
      stripeAccountId,
      remote,
    });

    const invoiceDate = (currentInvoice
      && moment.unix(currentInvoice.created).format(DateFormat.MONTH_NAME_DATE_FORMAT)) || '';
    if (stripeError) {
      showToast(format(texts.retryError, {
        invoiceDate,
        error: stripeError.message || '',
      }), { error: true });
    } else if (invoice) {
      showToast(format(texts.retrySuccess, {
        invoiceDate,
      }));
      setIsReady(false);
    }
    setIsRetryingInProgress(false);
  }, [
    stripeAccountId,
    remote,
    showToast,
    currentInvoice,
  ]);

  const handleRetryButtonClick = useCallback((invoice) => {
    setCurrentInvoice(invoice);
    setShowRetryPaymentDialog(true);
  }, []);

  const handleVoidInvoiceButtonClick = useCallback((invoice) => {
    setCurrentInvoice(invoice);
    setShowVoidInvoiceDialog(true);
  }, []);

  const onInvoiceVoided = useCallback(async () => {
    setShowVoidInvoiceDialog(false);
    const invoices = await listInvoices(userDoc.id, remote);
    if (isComponentMountedRef.current) {
      setInvoiceList(invoices);
    }
  }, [
    userDoc,
    remote,
    isComponentMountedRef,
  ]);

  const shouldShowModal = useMemo(() => (
    !error
    && !!userDoc.subscriptionStatus
    && Object.keys(activeSubscriptionStatus).includes(userDoc.subscriptionStatus)
    && (isCoach || isAdmin)
  ), [
    error,
    userDoc.subscriptionStatus,
    isCoach,
    isAdmin,
  ]);

  const shouldShowCancellationOption = useMemo(() => (
    !error
    && !!userDoc.subscriptionStatus
    && [...Object.keys(activeSubscriptionStatus), StripeSubscriptionState.UNPAID.toUpperCase()]
      .includes(userDoc.subscriptionStatus)
    && (isCoach || isAdmin)
  ), [
    error,
    userDoc.subscriptionStatus,
    isCoach,
    isAdmin,
  ]);

  const shouldShowNewContractOption = useMemo(() => (
    !error
    && !!userDoc.subscriptionStatus
    && (userDoc.subscriptionStatus === StripeSubscriptionState.CANCELED.toUpperCase()
    || userDoc.subscriptionStatus === StripeSubscriptionState.INCOMPLETE_EXPIRED.toUpperCase())
    && (isCoach || isAdmin)
  ), [
    error,
    userDoc.subscriptionStatus,
    isCoach,
    isAdmin,
  ]);
  const shouldShowPauseOption = useMemo(() => {
    const pifPeriodEndDate = moment(userDoc.serviceStartAt)
      .add(userContractDoc?.initialTerm, 'months');
    const isAfterPifPeriod = moment().isAfter(pifPeriodEndDate);
    const hasTheSubscriptionStarted = moment().isAfter(moment(userDoc.serviceStartAt));
    return (!error
      && !!userDoc.subscriptionStatus
      && [...Object.keys(activeSubscriptionStatus), StripeSubscriptionState.UNPAID.toUpperCase()]
        .includes(userDoc.subscriptionStatus)
      /*
        We should not show the pause button when user is still in PIF period or when client got a discount on the first
        month and we are still in first month of the subscription. We should also hide the button if the user has not
        started the subscription yet (future start date)
      */
      && (
        isAfterPifPeriod
        || (hasTheSubscriptionStarted
          && userContractDoc?.initialTerm === 1
          && userContractDoc?.initialPaymentInCents === userContractDoc?.totalPriceInCents
        )
      )
      && moment().isAfter(moment(localUserDoc.breakEndDate))
    );
  }, [
    error,
    userDoc.subscriptionStatus,
    userDoc.serviceStartAt,
    userContractDoc,
    localUserDoc.breakEndDate,
  ]);

  const shouldShowUnpauseOption = useMemo(() => {
    const { breakEndDate } = localUserDoc;
    if (breakEndDate && moment(breakEndDate).isAfter(moment())
      && (userDoc.subscriptionStatus === StripeSubscriptionState.ACTIVE.toUpperCase())) {
      return true;
    }
    return false;
  }, [localUserDoc, userDoc.subscriptionStatus]);

  if (!isReady) {
    return <LoadingPage />;
  }

  if (!subscriptionData) {
    return <EmptyView text={texts.noSubscription} />;
  }

  return (
    <Container>
      <HeaderContainer>
        {shouldShowModal
          && filterChecks[ClientStatus.FUTURE_START](userDoc)
          && (
            <StyledButton
              startIcon={<EditSubscriptionIcon />}
              onClick={() => setShowStartDateModal(true)}
            >
              {texts.updateStartDate}
            </StyledButton>
          )}
        {shouldShowCancellationOption
          && (
            <StyledButton
              startIcon={<StyledCancelSubscriptionIcon />}
              onClick={() => setShowConfirmCancelDialog(true)}
            >
              {texts.cancelSubscription}
            </StyledButton>
          )}
        {shouldShowPauseOption && !shouldShowUnpauseOption
          && (
          <StyledButton
            startIcon={<StyledPauseSubscriptionIcon />}
            onClick={() => setShowConfirmPauseDialog(true)}
          >
            {texts.pauseSubscription}
          </StyledButton>
          )}
        {shouldShowUnpauseOption
          && (
          <StyledButton
            startIcon={<StyledUnpauseSubscriptionIcon />}
            onClick={() => unpauseClientSubscription()}
          >
            {texts.unpauseSubscription}
          </StyledButton>
          )}
        {shouldShowNewContractOption
          && (
            <StyledButton
              startIcon={<StyledReonboardIcon />}
              onClick={() => setShowUserContractModal(true)}
            >
              {texts.reonboardClient}
            </StyledButton>
          )}
      </HeaderContainer>
      <DataContainer>
        <Title>{texts.invoices}</Title>
        {invoiceList.length === 0 && <EmptyView text={error || texts.noInvoices} />}
        {invoiceList.length > 0 && (
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>{texts.invoiceNo}</StyledTableCell>
                  <StyledTableCell>{texts.amount}</StyledTableCell>
                  <StyledTableCell>{texts.dateCreated}</StyledTableCell>
                  <StyledTableCell>{texts.paymentDate}</StyledTableCell>
                  <StyledTableCell>{texts.status}</StyledTableCell>
                  <StyledTableCell>{texts.action}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceList.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <StyledTableCell>
                      <StyledLink href={invoice.hosted_invoice_url} target="_blank">
                        {invoice.number}
                      </StyledLink>
                      <CopyButton value={invoice.hosted_invoice_url} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatCurrencyCents(invoice.total, invoice.currency)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment.unix(invoice.created).format(DateFormat.MONTH_NAME_DATE_FORMAT)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.status_transitions?.paid_at
                        ? moment.unix(invoice.status_transitions?.paid_at).format(DateFormat.MONTH_NAME_DATE_FORMAT)
                        : texts.emptyText}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.status}
                    </StyledTableCell>
                    <StyledTableCell>
                      {(invoice.status === 'open' && subscriptionData.status !== StripeSubscriptionState.CANCELED) ? (
                        <>
                          <StyledButton onClick={() => handleRetryButtonClick(invoice)}>
                            {texts.retryPayment}
                          </StyledButton>
                          <StyledButton onClick={() => handleVoidInvoiceButtonClick(invoice)}>
                            {texts.voidInvoice}
                          </StyledButton>
                        </>
                      ) : texts.emptyText}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </DataContainer>
      <LoadingOverlay isLoading={isUpdatingInProgress || isRetryingInProgress} />
      <SubscriptionCancelDialog
        isOpen={showConfirmCancelDialog}
        onCancel={() => setShowConfirmCancelDialog(false)}
        dialogTexts={{
          title: texts.confirmCancel.title,
          content: format(texts.confirmCancel.content, {
            userName: userDoc.name,
          }),
        }}
        subscription={subscriptionData}
        updateClientSubscription={updateClientSubscription}
        cancelClientSubscription={cancelClientSubscription}
        userContractDoc={userContractDoc}
        userDoc={userDoc}
      />
      <StartDateUpdateModal
        isOpen={showStartDateModal}
        onCancel={() => setShowStartDateModal(false)}
        dialogTexts={{
          title: texts.confirmNewStartDate.title,
          content: format(texts.confirmNewStartDate.content, {
            userName: userDoc.name,
          }),
        }}
        currentStartDate={moment(userDoc.serviceStartAt)}
        updateClientSubscription={updateClientSubscription}
      />
      <ConfirmDialog
        isOpen={showRetryPaymentDialog}
        onConfirm={retryCurrentInvoicePayment}
        onCancel={() => setShowRetryPaymentDialog(false)}
        dialogTexts={{
          title: texts.confirmRetry.title,
          content: format(texts.confirmRetry.content, {
            userName: userDoc.name,
            month: currentInvoice && moment.unix(currentInvoice.created).format(DateFormat.MONTH_NAME),
          }),
        }}
      />
      {!!showVoidInvoiceDialog && (
        <VoidInvoiceModal
          isOpen={showVoidInvoiceDialog}
          invoice={currentInvoice}
          onClose={() => setShowVoidInvoiceDialog(false)}
          onInvoiceVoided={onInvoiceVoided}
          stripeAccountId={stripeAccountId}
        />
      )}
      <SubscriptionPauseModal
        isOpen={showConfirmPauseDialog}
        onCancel={() => setShowConfirmPauseDialog(false)}
        subscription={subscriptionData}
        userDoc={userDoc}
        pauseClientSubscription={pauseClientSubscription}
      />
      {!!showUserContractModal && (
        <ReonboardingContractModal
          user={userDoc}
          coachId={userDoc.assignedCoach}
          showModal={showUserContractModal}
          onClose={() => setShowUserContractModal(false)}
        />
      )}
    </Container>
  );
};

BillingSummary.propTypes = {
  userDoc: PropTypes.instanceOf(User).isRequired,
  subscriptionDoc: PropTypes.instanceOf(Subscription),
  userContractDoc: PropTypes.instanceOf(UserContract),
};

BillingSummary.defaultProps = {
  userContractDoc: null,
  subscriptionDoc: null,
};

export default compose(
  observer,
)(BillingSummary);
