import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  MailOutline as EmailIcon,
  PhoneInTalk as PhoneIcon,
  PersonPinCircle as LocationIcon,
  AccessTime as TimeZoneIcon,
} from '@mui/icons-material';

import OnCallActions from '../../../../../components/OnCallActions';
import LoadingPage from '../../../../../components/LoadingPage';
import { ReactComponent as MealPlanIcon } from '../../../../../assets/icons/v2/modal-meal-plate.svg';
import { ReactComponent as MobileIcon } from '../../../../../assets/icons/v2/mobile-blue.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow.svg';
import { Title, SubTitle } from '../../../../../components/v2/Header';
import Button from '../../../../../components/Button';
import Accordion from '../../../../../components/Accordion';
import colors from '../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  cursor: ${({ isQAP }) => (isQAP ? 'move' : 'cursor')};
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 30vh;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  margin: 7px 25px;
  font-weight: 500;
  font-size: 22px;
  align-self: center;
  text-align: center;
  color: ${colors.shades.secondary8};
`;

const Name = styled.div`
  margin: 7px 25px;
  font-weight: 500;
  font-size: 22px;
  align-self: center;
  text-align: center;
  color: ${colors.shades.secondary8};
`;

const StatusSection = styled.div`
  margin: 0px 25px 7px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const StatusText = styled.div`
  font-size: 14px;
`;

const StatusValue = styled.span`
  color: ${(props) => props.statusColor}; 
`;

const StyledTitle = styled(Title)`
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 0;
`;

const StyledSubTitle = styled(SubTitle)`
  margin-right: 8px;
  font-size: 14px;
  font-weight: 400;
`;

const StyledAvatar = styled(Avatar)`
  display: flex;
  width: 70px;
  height: 70px;
  min-height: 40px;
  min-width: 40px;
  align-self: center;
`;

const StyledEmailIcon = styled(EmailIcon)`
  height: 16px;
  width: 16px;
`;

const smallIconStyles = css`
  height: 16px;
  width: 16px;
`;

const mediumIconStyles = css`
  height: 25px;
  width: 25px;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  ${smallIconStyles};
`;

const StyledTimeZoneIcon = styled(TimeZoneIcon)`
  ${smallIconStyles};
`;

const StyledLocationIcon = styled(LocationIcon)`
  ${smallIconStyles};
`;

const StyledArrowIcon = styled(ArrowIcon)`
  ${smallIconStyles};

  transform: rotate(90deg);
  > path {
    fill: ${colors.base.secondary2};
  }
`;

const StyledMealPlanIcon = styled(MealPlanIcon)`
  ${mediumIconStyles};
`;

const StyledMobileIcon = styled(MobileIcon)`
  ${mediumIconStyles};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 8px 10px 15px 0;
  justify-content: space-between;
`;

const ActivateUserModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 50px 0;
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding: 0 30px 10px 0;
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  padding: 5px 33px;
  background-color: transparent;
  color: ${colors.base.beta};
  text-transform: capitalize;
  width: 50px;
  margin-left: 2.5px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 8px; 
  cursor: pointer;
  white-space: nowrap;
  gap: 0.5px;

  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  margin: 5px 0 5px 8px;
`;

export {
  Container,
  InfoContainer,
  InfoWrapper,
  StyledLink,
  StyledAvatar,
  ImageContainer,
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledLocationIcon,
  StyledArrowIcon,
  ButtonContainer,
  StyledTimeZoneIcon,
  ActivateUserModalContent,
  StyledButton,
  StyledMealPlanIcon,
  StyledMobileIcon,
  StyledLoadingPage,
  StyledTitle,
  StyledSubTitle,
  StyledOnCallActions,
  StyledAccordion,
  Name,
  StatusSection,
  StatusText,
  StatusValue,
};
