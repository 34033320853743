import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import {
  ConfigurationType,
  configurationPagesConfig,
} from '../../config/configurationPages';
import CoachToolsRoute from '../ToolsRoute';
import routes from './routes';

const ConfigurationRoutes = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      {routes.filter((route) => !route.disabled).map(({
        pageId,
        configPath,
        component,
      }) => (
        <CoachToolsRoute
          key={pageId}
          pageId={pageId}
          path={`${path}/${configPath}`}
          component={component}
        />
      ))}

      <Route exact path={`${path}`}>
        <Redirect to={{ pathname: `${url}/${configurationPagesConfig[ConfigurationType.PAYMENT].path}` }} />
      </Route>
    </Switch>
  );
};

export default ConfigurationRoutes;
