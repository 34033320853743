import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import InstagramUser from '../../../Model/InstagramUser';
import { DataSection, UserInfo } from './styles';
import text from './text.json';

const InstagramUserDetails = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(true);
  /** @type {[import('../../../Model/InstagramUser').default|undefined, Function]} */
  const [userData, setUserData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await InstagramUser.getInstaUserById(userId);
        setUserData(response);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId]);

  return (
    <DataSection>
      {isLoading && <small>{text.loadingUserData}</small>}
      {!isLoading && error && <div>{error.message}</div>}
      {userData && !isLoading && !error && (
        <>
          <UserInfo>
            <Avatar alt={userData.name || userData.username} src={userData.profilePictureUrl} />
            <div>
              {userData.name && <div>{userData.name}</div>}
              <a href={`https://instagram.com/${userData.username}`} target="_blank" rel="noreferrer">
                {`@${userData.username}`}
              </a>
              {userData.coachId && (
                <small>
                  <code>{userData.coachId}</code>
                </small>
              )}
            </div>
          </UserInfo>
        </>
      )}
    </DataSection>
  );
};

InstagramUserDetails.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default InstagramUserDetails;
