import { PayoutConfig } from '../../utils/payoutReconciliation';
import { AssignmentRole } from '../../../utils/internalAssignments';

const Preset = {
  CRX: 'CRX',
  CRX_LEAD: 'CRX_LEAD',
  INSIDE_SALES: 'INSIDE_SALES',
  INSIDE_SALES_LEAD: 'INSIDE_SALES_LEAD',
  CAM: 'CAM',
  CPS: 'CPS',
};

const presetOptions = [
  {
    id: Preset.CRX,
    label: 'CRX',
  },
  {
    id: Preset.CRX_LEAD,
    label: 'CRX Lead',
  },
  {
    id: Preset.INSIDE_SALES,
    label: 'Inside Sales',
  },
  {
    id: Preset.INSIDE_SALES_LEAD,
    label: 'Inside Sales Lead',
  },
  {
    id: Preset.CAM,
    label: 'CAM',
  },
  {
    id: Preset.CPS,
    label: 'CPS',
  },
];
const PayoutConfigByPreset = {
  [Preset.CRX]: PayoutConfig[AssignmentRole.CRX],
  [Preset.CRX_LEAD]: PayoutConfig[AssignmentRole.CRX],
  [Preset.INSIDE_SALES]: PayoutConfig[AssignmentRole.INSIDE_SALES],
  [Preset.INSIDE_SALES_LEAD]: PayoutConfig[AssignmentRole.INSIDE_SALES],
  [Preset.CAM]: PayoutConfig[AssignmentRole.CAM],
  [Preset.CPS]: PayoutConfig[AssignmentRole.CPS],
};

export {
  Preset,
  presetOptions,
  PayoutConfigByPreset,
};
