const AssignmentRole = {
  CRX: 'CRX',
  ASSISTANT_COACH: 'AC',
  INSIDE_SALES: 'IS',
  CAM: 'CAM',
  CPS: 'CPS',
};

export {
  AssignmentRole,
};
