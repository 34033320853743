import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import format from 'string-template';

import DialogRoundedModal from '../../../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../../../components/Button/ActionButtons';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import useToast from '../../../../../../hooks/useToast';
import useSessionStore from '../../../../../../../hooks/useSessionStore';
import LoadingOverlay from '../../../../../LoadingOverlay';
import { changeUserEmail, NamedProperties } from '../../../../../../utils/user';
import { MeasurementUnit } from '../../../../../../../utils/measurement';
import { NutritionProperties, BiologicalSex } from '../../../../../../utils/macroGoals';

import FirebaseContext from '../../../../../../../context/FirebaseContext';
import { isValidEmail } from '../../../../../../../utils/validators';
import NutritionDetailsEditField from './components/NutritionDetailsEditField';
import ClientDetailsEditField from './components/ClientDetailsEditField';
import {
  profileInfovalidationSchema,
  nutritionInfoValidationSchema,
} from './validation';
import {
  RowContainer,
  ModalContent,
  Container,
} from './styles';
import texts from './texts.json';

const EMPTY_VALUE = '';
const ZERO_VALUE = 0;

const ProfileEditModal = ({
  userDoc,
  userNutritionProfileDoc,
  showModal,
  onClose,
}) => {
  const {
    id,
    firstName,
    lastName,
    email,
    address,
    phoneNumber,
    birthdate,
    checkInDay,
  } = userDoc;

  const { userId, isOnCallUser } = useSessionStore();
  const {
    firebase: { remote },
  } = useContext(FirebaseContext);

  const [profileFormData, setProfileFormData] = useState({
    [NamedProperties.FIRST_NAME]: firstName,
    [NamedProperties.LAST_NAME]: lastName || EMPTY_VALUE,
    [NamedProperties.EMAIL]: email,
    [NamedProperties.PHONE_NUMBER]: phoneNumber || EMPTY_VALUE,
    [NamedProperties.ADDRESS]: address || EMPTY_VALUE,
    [NamedProperties.BIRTHDATE]: birthdate || EMPTY_VALUE,
    [NamedProperties.CHECK_IN_DAY]: checkInDay,
  });
  const [nutritionProfileFormData, setNutritionProfileFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  useEffect(() => {
    if (userNutritionProfileDoc) {
      const {
        biologicalSex,
        heightMeasurementUnit,
        height,
        weightMeasurementUnit,
        currentWeight,
      } = userNutritionProfileDoc;
      setNutritionProfileFormData({
        [NutritionProperties.BIOLOGICAL_SEX]: biologicalSex || BiologicalSex.MALE,
        [NutritionProperties.HEIGHT_MEASUREMENT_UNIT]: heightMeasurementUnit || MeasurementUnit.METRIC,
        [NutritionProperties.HEIGHT]: height || ZERO_VALUE,
        [NutritionProperties.WEIGHT_MEASUREMENT_UNIT]: weightMeasurementUnit || MeasurementUnit.METRIC,
        [NutritionProperties.CURRENT_WEIGHT]: currentWeight || ZERO_VALUE,
      });
    }
  }, [
    userNutritionProfileDoc,
  ]);

  const onSaveChanges = useCallback(async () => {
    try {
      // validate user profile form data
      profileInfovalidationSchema.validateSync(profileFormData, { abortEarly: false });

      let currentNutritionProfileData;

      if (nutritionProfileFormData) {
        // validate nutrition profile form data
        nutritionInfoValidationSchema.validateSync(nutritionProfileFormData, { abortEarly: false });

        const isHeightMetric = nutritionProfileFormData.heightMeasurementUnit === MeasurementUnit.METRIC;
        const isWeightMetric = nutritionProfileFormData.weightMeasurementUnit === MeasurementUnit.METRIC;

        currentNutritionProfileData = {
          biologicalSex: nutritionProfileFormData.biologicalSex,
          heightCm: isHeightMetric ? nutritionProfileFormData.height : firebase.firestore.FieldValue.delete(),
          heightFt: !isHeightMetric ? nutritionProfileFormData.height.ft : firebase.firestore.FieldValue.delete(),
          heightIn: !isHeightMetric ? nutritionProfileFormData.height.in : firebase.firestore.FieldValue.delete(),
          currentWeightKgs: isWeightMetric
            ? nutritionProfileFormData.currentWeight
            : firebase.firestore.FieldValue.delete(),
          currentWeightLbs: !isWeightMetric
            ? nutritionProfileFormData.currentWeight
            : firebase.firestore.FieldValue.delete(),
          lastUpdatedBy: userId,
          lastUpdatedAt: new Date(),
        };
      }
      setErrors({});

      if (isOnCallUser && email !== profileFormData.email) {
        if (!isValidEmail(profileFormData.email)) {
          showToast(texts.invalidEmailError, { error: true });
          return;
        }

        const { success } = await changeUserEmail(remote, id, profileFormData.email);

        if (success) {
          showToast(texts.emailChangeSuccess);
        } else {
          showToast(texts.emailChangeError);
        }
      }

      const {
        [NamedProperties.EMAIL]: _,
        ...currentProfileFormData
      } = profileFormData;

      setIsLoading(true);
      await userDoc.ref.update({
        ...currentProfileFormData,
        lastUpdatedBy: userId,
        lastUpdatedAt: new Date(),
        address: profileFormData.address || firebase.firestore.FieldValue.delete(),
      });
      if (nutritionProfileFormData) {
        await userNutritionProfileDoc.ref.update(currentNutritionProfileData);
      }

      if (isComponentMountedRef.current) {
        showToast(texts.successfullyUpdated);
        onClose();
      }
    } catch (err) {
      if (err.inner) {
        const errorMessages = err.inner.reduce((messages, errorObj) => ({
          ...messages,
          [errorObj.path]: errorObj.message,
        }), {});
        setErrors(errorMessages);
      } else {
        showToast(format(texts.fail, { error: err }), { error: true });
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    userDoc,
    userNutritionProfileDoc,
    userId,
    id,
    profileFormData,
    nutritionProfileFormData,
    isComponentMountedRef,
    showToast,
    onClose,
    remote,
    isOnCallUser,
    email,
  ]);

  return (
    <>
      <DialogRoundedModal
        title={texts.title}
        fullWidth
        maxWidth="sm"
        actionButtons={(
          <RowContainer>
            <SaveButton onClick={onSaveChanges}>{texts.save}</SaveButton>
            <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
          </RowContainer>
        )}
        open={showModal}
        onClose={onClose}
      >
        <ModalContent>
          <Container>
            <ClientDetailsEditField
              profileFormData={profileFormData}
              setProfileFormData={setProfileFormData}
              errors={errors}
              emailEditable={isOnCallUser}
            />
            {nutritionProfileFormData && (
              <NutritionDetailsEditField
                nutritionProfileFormData={nutritionProfileFormData}
                setNutritionProfileFormData={setNutritionProfileFormData}
                errors={errors}
                setErrors={setErrors}
              />
            )}
          </Container>
        </ModalContent>
        <LoadingOverlay isLoading={isLoading} />
      </DialogRoundedModal>
    </>
  );
};

ProfileEditModal.propTypes = {
  userDoc: PropTypes.object.isRequired,
  userNutritionProfileDoc: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProfileEditModal;
