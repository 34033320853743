import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  overflow-y: auto;
  gap: 10px;
`;

export {
  ModalContent,
  Container,
};
