import { Collection } from 'firestorter';

import Collections from '../../utils/collections';

import BaseDocument from '../../Model/BaseDocument';
import CoachAnalyticsConfig from './CoachAnalyticsConfig';

/**
 * lead configuration option attribute names enum
 */
const LeadConfigOptions = {
  IS_EMAIL_RESPONSE_ENABLED: 'isEmailResponseEnabled',
  IS_TEXT_BACK_ENABLED: 'isTextBackEnabled',
  CONTACT_NO: 'contactNo',
  EMAIL_ADDRESS: 'emailAddress',
  IS_LEAD_SUBMISSION_EMAIL_ENABLED: 'isLeadSubmissionEmailEnabled',
  LEAD_SUBMISSION_EMAIL: 'leadSubmissionEmail',
  IS_WELCOME_EMAIL_ENABLED: 'sendWelcomeEmail',
};

const orderType = {
  ASC: 'ASC',
  DESC: 'DESC',
};

/**
 * Options of activity feed auto archival setting
 */
const AutoArchiveDays = {
  TWO_WEEKS: 14,
  ONE_MONTH: 30,
  TWO_MONTHS: 60,
  THREE_MONTHS: 90,
};

/**
 * Options of workout reminder notification setting
 */
const WorkoutReminderDays = {
  ONE_DAY: 1,
  TWO_DAY: 2,
  THREE_DAY: 3,
  ONE_WEEK: 7,
  TWO_WEEKS: 14,
};

/**
 * class representing configuration attributes of a coach
 *
 * @class CoachConfig
 * @extends BaseDocument
 */
class CoachConfig extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.COACH_CONFIG}/${id}`, opts);
  }

  /**
   * Get coach's activity feed configurations
   * @return {object}
   * @property {object} defaultOrder  - Default order of activity feed
   */
  get activityFeedConfigs() {
    return this.data?.activityFeedConfigs;
  }

  /**
   * Get default order of each attribute of activity feed
   * @return {object}
   * @property {string} attributeName - attribute name of default order
   * @property {string} order - Value of order (ASC/DESC)
   */
  get activityFeedDefaultOrder() {
    return this.activityFeedConfigs?.defaultOrder;
  }

  /**
   * Get attribute name of default order set
   * @return {string}
   */
  get activityFeedDefaultOrderAttributeName() {
    return this.activityFeedDefaultOrder?.attributeName;
  }

  /**
   * Get default order of attribute (ASC/DESC)
   * @return {string}
   */
  get activityFeedDefaultOrderValue() {
    return this.activityFeedDefaultOrder?.order || orderType.ASC;
  }

  /**
   * Number of days coach activities will be considered to be active before automatically removing them.
   * @return {number}
   */
  get daysToAutoArchive() {
    return this.data.activityFeedConfigs?.daysToAutoArchive || AutoArchiveDays.TWO_WEEKS;
  }

  /**
   * Number of days before a client needs new workouts when the coach will be notified.
   * @return {number}
   */
  get daysToRemindWorkoutAssign() {
    return this.data.notificationConfigs?.daysToRemindWorkoutAssign || WorkoutReminderDays.ONE_DAY;
  }

  /**
   * The date when the notification settings were last changed
   * @return {Date}
   */
  get changedDate() {
    return this.data.notificationConfigs?.changedDate?.toDate() || null;
  }

  /**
   * Is workout and meal plan notification enabled for future start clients.
   * @return {boolean}
   */
  get isFutureStartNotificationEnabled() {
    return !!this.data.notificationConfigs?.isFutureStartNotificationEnabled;
  }

  /**
   * Get coach's lead configurations
   * @return {object}
   */
  get leadConfigs() {
    return this.data.leadConfigs || {};
  }

  /**
   * Is text responding enabled for leads or not
   * @return {boolean}
   */
  get isTextBackEnabled() {
    return !!this.leadConfigs?.isTextBackEnabled;
  }

  /**
   * Is email responding enabled for leads or not
   * @return {boolean}
   */
  get isEmailResponseEnabled() {
    return !!this.leadConfigs?.isEmailResponseEnabled;
  }

  /**
   * Email address of the coach configured for leads.
   * @return {string}
   */
  get coachEmailAddress() {
    return this.leadConfigs?.emailAddress || '';
  }

  /**
   * Phone number of the coach configured for leads.
   * This is only available for non IS coaches, as IS enabled coaches are handled with internal numbers.
   * @return {string}
   */
  get coachContactNumber() {
    return this.leadConfigs?.contactNo || '';
  }

  /**
   * Is new lead submission email enabled or not
   * @return {boolean}
   */
  get isLeadSubmissionEmailEnabled() {
    return !!this.leadConfigs?.isLeadSubmissionEmailEnabled;
  }

  /**
   * Email address to send new lead notifications
   * @return {string}
   */
  get leadSubmissionEmail() {
    return this.leadConfigs?.leadSubmissionEmail || '';
  }

  /**
   * Is send welcome email enabled or not
   * @return {boolean}
   */
  get isLeadWelcomeEmailEnabled() {
    return !!this.leadConfigs?.sendWelcomeEmail;
  }

  /**
   * Get coach's meal plan configurations
   * @return {object}
   */
  get mealPlanConfigs() {
    return this.data.mealPlanConfigs || {};
  }

  /**
   * List of archived public recipes for the coach
   * @return {Array}
   */
  get archivedRecipes() {
    return this.mealPlanConfigs.archivedPublicRecipes || [];
  }

  /**
   * Is webhook setup for the coach or not
   * @return {boolean}
   */
  get isWebhookSetup() {
    return !!this.data.isWebhookSetup;
  }

  /**
   * Is email domain created for the coach or not
   * @return {boolean}
   */
  get isEmailDomainCreated() {
    return !!this.data.isEmailDomainCreated;
  }

  /**
   * Get the time when the warmup process is enabled for the coach email domain
   * @return {Date}
   */
  get warmupEnabledAt() {
    return this.data.warmupEnabledAt?.toDate();
  }

  /**
   * Get the measurement ID associated with the coach
   * @return {string | null}
   */
  get measurementId() {
    return this.data.measurementId || null;
  }

  // Method

  /**
   * Get coachConfig by coachId
   * @param {string} coachId - The coach id
   * @returns {object} - coachConfig doc
   */
  static async getCoachConfigByCoachId(coachId) {
    const coachConfigDoc = new CoachConfig(coachId);
    await coachConfigDoc.init();

    return coachConfigDoc;
  }

  /**
   * Get coach config by email
   * @param {string} email - The email address
   * @returns {array} - coachConfig docs
   */
  static async getCoachConfigByEmail(email) {
    const coachConfigCollection = new Collection(Collections.COACH_CONFIG, {
      createDocument: (src, opts) => new CoachConfig(src.id, opts),
      query: (ref) => ref
        .where('leadConfigs.emailAddress', '==', email),
    });
    await coachConfigCollection.fetch();
    return coachConfigCollection.docs;
  }

  /**
   * Update default order of activity feed based on the given attribute and value
   *
   * @param {string} property Which attribute going to be update
   * @param {string} order Order (ASC/DESC)
   * @return {Promise<void>}
   */
  setDefaultOrder = async (property, order) => {
    await this.set({
      activityFeedConfigs: {
        defaultOrder: {
          attributeName: property,
          order,
        },
      },
    }, { merge: true });
  };

  /**
   * Update activity feed archival time
   *
   * @param {number} daysToAutoArchive - Number of days coach activities will be regarded active
   *                                     before automatically removing them
   * @return {Promise<void>}
   */
  setDaysToAutoArchive = async (daysToAutoArchive) => {
    await this.set({
      activityFeedConfigs: {
        daysToAutoArchive,
      },
    }, { merge: true });
  }

  /**
   * Update workout assign reminder time
   *
   * @param {number} daysToRemindWorkoutAssign -  Number of days before a client needs new workouts
   *                                        when the coach will be notified.
   * @param {number} prevDaysToRemindWorkoutAssign -  Previouse value of the Number of days
   * before a client needs new workouts when the coach will be notified.
   * @param {Date} changedDate -  Notification settings change date.
   * @return {Promise<void>}
   */
  setDaysToRemindWorkoutAssign = async (
    prevDaysToRemindWorkoutAssign,
    currentDaysToRemindWorkoutAssign,
    changedDate,
  ) => {
    const currentValue = Object.values(WorkoutReminderDays).includes(currentDaysToRemindWorkoutAssign)
      ? currentDaysToRemindWorkoutAssign
      : WorkoutReminderDays.ONE_DAY;

    await this.set({
      notificationConfigs: {
        prevDaysToRemindWorkoutAssign,
        daysToRemindWorkoutAssign: currentValue,
        changedDate,
      },
    }, { merge: true });
  }

  /**
   * Update meal plan and workout assignment notification preference of future start clients.
   *
   * @param {boolean} isFutureStartNotificationEnabled - whether to enable notifications for
   *                                                    future start clients or not.
   * @return {Promise<void>}
   */
  async setIsFutureStartNotificationEnabled(isFutureStartNotificationEnabled) {
    return this.set({
      notificationConfigs: {
        isFutureStartNotificationEnabled,
      },
    }, { merge: true });
  }

  /**
   * Update coach's lead config settings
   *
   * @param {object} settings - object of attributes and their values to update
   * @param {string} [lastUpdatedByUserName] - user name of who updated the settings
   * @return {Promise<void>}
   */
  updateLeadConfig = async (settings, lastUpdatedByUserName) => {
    await this.set({
      leadConfigs: {
        ...settings,
      },
      ...(lastUpdatedByUserName && { lastUpdatedByUserName }),
    }, { merge: true });
  };

  /**
   * Add a recipe to the coach's archived public recipes list
   *
   * @param {string} recipeId - ID of the recipe
   * @return {Promise<void>}
   */
  archivePublicRecipe = async (recipeId) => {
    await this.set({
      mealPlanConfigs: {
        archivedPublicRecipes: [
          ...this.archivedRecipes,
          recipeId,
        ],
      },
    }, { merge: true });
  }

  /**
   * Remove a recipe from the archived public recipes list of the coach
   *
   * @param {string} recipeId - ID of the recipe
   * @return {Promise<void>}
   */
  unarchivePublicRecipe = async (recipeId) => {
    const archivedRecipes = this.archivedRecipes.filter((id) => id !== recipeId);
    await this.set({
      mealPlanConfigs: {
        archivedPublicRecipes: archivedRecipes,
      },
    }, { merge: true });
  }

  /**
   * Update webhook setup status as true for the coach
   * @return {Promise<void>}
   */
  markWebhookSetupCompletion = async (updatedByUserName) => {
    await this.set({
      isWebhookSetup: true,
      lastUpdatedByUserName: updatedByUserName,
    }, { merge: true });
  };

  /**
   * Sets the Google Analytics config for the coach
   * @param {string} measurementId
   * @param {string} apiSecret
   */
  setGoogleAnalyticsConfig = async (measurementId, apiSecret) => {
    await this.set({
      measurementId,
    }, { merge: true });

    await CoachAnalyticsConfig.createCoachAnalyticsConfig(this.id, measurementId, apiSecret);
  }

  /**
   * Removes the Google Analytics config associated with a measurement ID
   * @param {string} measurementId
   */
  removeGoogleAnalyticsConfig = async (measurementId) => {
    await this.set({
      measurementId: null,
    }, { merge: true });

    await CoachAnalyticsConfig.deleteCoachAnalyticsConfig(this.id, measurementId);
  }
}

export default CoachConfig;
export {
  LeadConfigOptions,
  orderType,
  AutoArchiveDays,
  WorkoutReminderDays,
};
