import {
  Button,
  TextField,
} from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../../../styles/colors';

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const HistorySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${colors.shades.gamma5};
`;

const HistoryNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.base.beta};
  background-color: ${colors.base.primary};
  border: 1px solid ${colors.shades.primary1};
  text-transform: none;
  padding: 9px 15px;

  .MuiButton-startIcon {
    color: ${colors.base.alpha};
  }

  &:hover {
    background: ${colors.base.primary};
  }
`;

const StyledNoteActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: auto;
  padding: 20px 40px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 10px;

  .MuiInputBase-multiline {
    padding: 0;
  }

  .MuiInputBase-input {
    border-radius: inherit;
    background-color: ${colors.base.alpha};
    padding: 4px;
  }
`;

const CallLogButton = styled(Button)`
  height: 32px;
  text-transform: none;
  align-self: flex-end;
  width: fit-content;
  margin: 0 20px 20px;
`;

export {
  HeaderRow,
  HistorySection,
  StyledButton,
  StyledTextField,
  StyledNoteActionContainer,
  HistoryNotesContainer,
  CallLogButton,
};
