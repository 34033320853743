import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';
import colors from '../../../styles/colors';
import InputLabel from '../../../components/v2/InputLabel';

import { StyledFormRow } from '../../pages/MealsPlanner/RecipeEditor/styles';

const InputContainer = styled.div`
  display: flex;
  padding: 20px 50px;
  gap: 10px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;
const StyledTextLabel = styled(InputLabel)`
  font-size: 12px;
`;
const TagContainer = styled(StyledFormRow)`
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
  border: 1px solid ${colors.shades.secondary7};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 20%;
  min-width: fit-content;
  .MuiCheckbox-root {
    padding: 2px 6px 2px 2px;
    &:not(.Mui-checked) {
      color: ${colors.shades.secondary7};
    }
  }
  .MuiFormControlLabel-label {
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: ${colors.shades.secondary8};
  }
`;

export {
  ButtonContainer,
  InputContainer,
  StyledTextLabel,
  TagContainer,
  StyledFormControlLabel,
};
