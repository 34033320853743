import { ToDoType } from '../../Model/ToDo';

const texts = {
  todoTypes: {
    [ToDoType.GENERAL]: 'General',
    [ToDoType.CHECKIN]: 'Check-in',
  },
  title: 'Add To-do',
  toDoItem: 'Description',
  toDoPlaceholder: 'Type out your to-do item',
  toDoType: 'Type',
  addToDo: 'Save To-do',
  itemIsRequired: 'A description is required',
  keepWindowOpen: 'Keep Window Open',
  successfullySaved: 'To-do item added successfully',
  fail: 'Error saving to-do item: {error}',
};

export default texts;
