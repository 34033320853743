import styled from 'styled-components';
import {
  Box,
  Button,
} from '@mui/material';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0 20px 0;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
  margin: 10px;
  align-self: flex-end;
`;

export {
  Container,
  ButtonContainer,
  StyledButton,
};
