import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Description,
  ActionsContainer,
  RoundedModal,
  ModalContentWrapper,
  HeaderContainer,
  ModalBody,
} from './styles';

const DialogRoundedModal = ({
  open,
  onClose,
  children,
  title,
  description,
  actionButtons,
  fullScreen,
  IconComponent,
  className,
  modalContentWrapperPosition,
  ...modalProps
}) => (
  <RoundedModal
    open={open}
    onClose={onClose}
    fullScreen={fullScreen}
    className={className}
    {...modalProps}
  >
    <ModalContentWrapper position={modalContentWrapperPosition}>
      <HeaderContainer>
        {IconComponent}
        <Title>{title}</Title>
        {!!description && <Description>{description}</Description>}
      </HeaderContainer>
      {children}
      {
        !!actionButtons
        && (
          <ActionsContainer>
            {actionButtons}
          </ActionsContainer>
        )
      }
    </ModalContentWrapper>
  </RoundedModal>
);

DialogRoundedModal.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actionButtons: PropTypes.element,
  description: PropTypes.string,
  fullScreen: PropTypes.bool,
  IconComponent: PropTypes.element,
  className: PropTypes.string,
  modalContentWrapperPosition: PropTypes.string,
};
DialogRoundedModal.defaultProps = {
  description: '',
  actionButtons: null,
  fullScreen: null,
  IconComponent: null,
  className: '',
  modalContentWrapperPosition: 'relative',
};

export default DialogRoundedModal;
export { ModalBody };
