import React, {
  useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, Tooltip } from '@mui/material';
import format from 'string-template';

import useToast from '../../../../../../hooks/useToast';
import Button from '../../../../../../../components/Button/Button';
import { HistoryEventType } from '../../../../../../Model/CoachHistory';
import FirebaseContext from '../../../../../../../context/FirebaseContext';
import { BtnContainer, Container, TextBlock } from './styles';
import texts from './texts.json';

const SlackChannelArchiver = ({
  coachDoc, coachHistory, loadCoachHistory, refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const {
    firebase: { remote },
  } = useContext(FirebaseContext);

  const handleSlackChannelArchive = async (coachId) => {
    try {
      const { slackChannelId } = coachDoc.data;
      if (!slackChannelId) {
        showToast(texts.slackChannelUnavailable, { error: true });
        return;
      }
      setIsLoading(true);
      const response = await remote('offboardCoach/archiveSlackChannel', {
        channelId: slackChannelId,
        coachId,
      });
      if (!response.ok) {
        throw new Error(texts.archiveError);
      }
      await loadCoachHistory();
      refetch();
      showToast(texts.archiveSuccess);
      setIsLoading(false);
    } catch (error) {
      showToast(error.message || texts.archiveError, { error: true });
      setIsLoading(false);
    }
  };

  const slackChannelArchived = coachHistory.find((h) => h.type === HistoryEventType.SLACK_CHANNEL_ARCHIVED);

  const renderLeadFormAction = () => {
    if (slackChannelArchived) {
      return (
        <TextBlock>
          {format(texts.slackChannelArchivedBy, {
            userName: slackChannelArchived.actionedByUserName,
          })}
        </TextBlock>
      );
    }
    return (
      <Tooltip title={texts.archiveSlackChannel} placement="right-start">
        <BtnContainer>
          <Button
            variant="contained"
            size="small"
            disabled={isLoading}
            onClick={() => handleSlackChannelArchive(coachDoc.id)}
            component={MuiButton}
          >
            {isLoading ? texts.archiveActionLoading : texts.archiveAction}
          </Button>
        </BtnContainer>
      </Tooltip>

    );
  };

  return (
    <Container>
      <div>{texts.title}</div>
      {renderLeadFormAction()}
    </Container>
  );
};

SlackChannelArchiver.propTypes = {
  coachDoc: PropTypes.object.isRequired,
  coachHistory: PropTypes.array.isRequired,
  loadCoachHistory: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default SlackChannelArchiver;
