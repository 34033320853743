import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../../../../../components/AutoComplete';
import Role from '../../../../../../Model/Role';

import texts from './texts.json';

const SelectRole = ({
  roles, selectedRole, setSelectedRole, setSelectedRoleDoc, setIsLoading,
}) => {
  const onRoleSelect = useCallback(async (value) => {
    setIsLoading(true);
    if (value) {
      const roleDoc = await Role.getById(value.id);
      setSelectedRole(value);
      setSelectedRoleDoc(roleDoc);
    } else {
      setSelectedRole(null);
      setSelectedRoleDoc(null);
    }
    setIsLoading(false);
  }, [setIsLoading, setSelectedRole, setSelectedRoleDoc]);

  return (
    <AutoComplete
      options={roles}
      label={texts.selectRole}
      value={selectedRole}
      onChange={onRoleSelect}
    />
  );
};

SelectRole.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedRole: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  setSelectedRole: PropTypes.func.isRequired,
  setSelectedRoleDoc: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

SelectRole.defaultProps = {
  selectedRole: null,
};

export default SelectRole;
