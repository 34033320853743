import { AssignmentRole } from '../../../utils/internalAssignments';

const texts = {
  internalAssignments: 'Internal Assignments',
  tabLabels: {
    [AssignmentRole.INSIDE_SALES]: 'IS Assignments',
    [AssignmentRole.ASSISTANT_COACH]: 'AC Assignments',
    [AssignmentRole.CAM]: 'CAM Assignments',
    [AssignmentRole.CPS]: 'CPS Assignments',
  },
  tableColumns: {
    user: 'User',
    userId: 'User ID',
    createdAt: 'Created At',
    actions: 'Actions',
  },
  addAssignment: 'Add Assignment',
  removeAssignment: 'Remove Assignment',
  removeAssignmentTitle: 'Are you sure you want to remove this assignment?',
};

export default texts;
