import React, { useState } from 'react';
import { NoteAddOutlined } from '@mui/icons-material';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Note from '../../components/Note';
import { LeadHistoryType } from '../../../../../../Model/Lead';
import useSessionStore from '../../../../../../../hooks/useSessionStore';
import useUserDoc from '../../../../../../hooks/useUserDoc';

import CallOutcome from './components/CallOutcome';
import {
  HeaderRow,
  HistorySection,
  StyledButton,
  StyledTextField,
  StyledNoteActionContainer,
  HistoryNotesContainer,
  CallLogButton,
} from './styles';
import texts from './texts.json';

const History = ({
  isInsideSalesView,
  lead,
  history,
  showCallLogModal,
  onCloseLeadModal,
}) => {
  const [note, setNote] = useState('');

  const { userId } = useSessionStore();
  const { isReady, userDoc } = useUserDoc(userId);

  const createNote = async () => {
    if (isReady) {
      await lead.addItemToHistory({
        value: note,
        createdAt: new Date(),
        activityType: LeadHistoryType.NOTE,
        createdBy: userId,
        createdByUserName: `${userDoc.firstName} ${userDoc.lastName}`,
      });
      setNote('');
    }
  };

  return (
    <HistorySection>
      <HeaderRow>
        {isInsideSalesView && (
          <CallLogButton
            variant="contained"
            onClick={showCallLogModal}
          >
            {texts.logACall}
          </CallLogButton>
        )}
      </HeaderRow>
      <CallOutcome
        lead={lead}
        isUserDocReady={isReady}
        userDoc={userDoc || {}}
        onCloseLeadModal={onCloseLeadModal}
      />
      <HistoryNotesContainer>
        {history.map(({
          id,
          activityType,
          value,
          reason,
          createdAt,
          createdByUserName,
          createdBy,
          coachId,
        }) => (
          <Note
            key={id}
            date={createdAt}
            type={activityType}
            value={value}
            reason={reason}
            name={createdByUserName}
            createdUserId={createdBy}
            coachId={coachId}
          />
        ))}
      </HistoryNotesContainer>
      <StyledNoteActionContainer>
        <StyledTextField
          size="medium"
          multiline
          rows={5}
          fullWidth
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <StyledButton
          disabled={!note}
          onClick={createNote}
          startIcon={<NoteAddOutlined />}
        >
          {texts.addNote}
        </StyledButton>
      </StyledNoteActionContainer>
    </HistorySection>
  );
};

History.propTypes = {
  lead: PropTypes.object.isRequired,
  isInsideSalesView: PropTypes.bool.isRequired,
  history: PropTypes.array.isRequired,
  showCallLogModal: PropTypes.func.isRequired,
  onCloseLeadModal: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(History);
