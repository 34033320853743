import React from 'react';
import PropTypes from 'prop-types';

import BaseContractModal from '../../BaseContractModal';
import LeadContractModalContent from './LeadContractModalContent';

const LeadContractModal = ({
  lead,
  coachId,
  showModal,
  onClose,
}) => (
  <BaseContractModal
    showModal={showModal}
    onClose={onClose}
  >
    <LeadContractModalContent
      lead={lead}
      coachId={coachId || lead.coach}
    />
  </BaseContractModal>
);

LeadContractModal.propTypes = {
  lead: PropTypes.object.isRequired,
  coachId: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

LeadContractModal.defaultProps = {
  coachId: null,
};

export default LeadContractModal;
