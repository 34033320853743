import styled from 'styled-components';
import {
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import {
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material';

import { ReactComponent as userIcon } from '../../../../../assets/icons/people-icon.svg';
import colors from '../../../../../styles/colors';
import Button from '../../../../../components/Button';

const ColumnContainer = styled.div`
  flex-direction: column;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  padding: 0 40px;
  border-bottom: 1px solid ${colors.shades.secondary4};
  background: ${colors.base.alpha};
  box-sizing: border-box;

  .MuiTabs-indicator {
    background-color: ${colors.shades.secondary3};
  }
`;

const ModalContent = styled.div`
  padding: 10px 0px;
  max-height: 20vh;
  overflow-y: auto;
`;

const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  padding: 0;
  text-transform: none;
  font-weight: 400;
  color: ${colors.shades.secondary3};

  &.Mui-selected {
    color: ${colors.shades.secondary3};
    border-bottom: ${colors.shades.secondary3};
  }
`;

const Container = styled.div`
  padding: 0px 40px;
  max-height: 95px;
`;

const Label = styled.p`
  color: ${colors.shades.secondary8};
  margin: 0;
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const StyledInput = styled(TextField)`
  width: 100%;
  margin-bottom: 30px;
  background-color: ${colors.base.alpha};
`;

const StyledUserIcon = styled(userIcon)`
  width: 27px;
  height: 29px;
  margin-bottom: 9px;
`;

const StyledCount = styled.span`
  color: ${colors.shades.primary1};
`;

const OptionsContainer = styled.div`
  border-bottom: 1px solid ${colors.shades.secondary7};
  padding: 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StyledTextField = styled(TextField)`
  background-color: ${colors.base.alpha};
  margin: 5px 0px;

  .MuiOutlinedInput-input {
    height: 10px;
    background-color: ${colors.base.alpha};
  }

  .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.shades.secondary8};
    }
  }
`;

const StyledDeleteOutlineOutlinedIcon = styled(DeleteOutlineOutlinedIcon)`
  color: ${colors.shades.secondary3};
  width: 15px;
  height: 15px;
`;

const TagDataContainer = styled.div``;

const EditDeleteIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const EditConfirmButton = styled(Button)`
  border-radius: 5px;
  background: ${colors.base.primary};
  color: ${colors.base.beta};
  height: fit-content;
  width: fit-content;
  padding: 6px 14px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: ${colors.shades.primary6};
  }
`;

export {
  ColumnContainer,
  RowContainer,
  StyledTabs,
  StyledTab,
  ModalContent,
  Container,
  Label,
  StyledInput,
  StyledUserIcon,
  OptionsContainer,
  StyledTextField,
  StyledCount,
  StyledDeleteOutlineOutlinedIcon,
  TagDataContainer,
  EditDeleteIconContainer,
  EditConfirmButton,
};
