import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { Checkbox } from '@mui/material';

import { ContractStatus, getContractObj } from '../../../../../CoachTools/utils/userContract';
import { generateTerms } from '../../../../../CoachTools/utils/userContractTerms';
import UserContractContext from '../../../../../context/UserContractContext';
import CoachAssignmentContext from '../../../../../context/CoachAssignmentContext';
import OnboardingFlowContext from '../../../../../context/OnboardingFlowContext';
import PlanContext from '../../../../../context/PlanContext';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { formatAmoutInCents } from '../../../../../utils/formatters';

import ConfirmDialog from '../../../../../CoachTools/components/ConfirmDialog';

import {
  SubscriptionCreationState,
} from '../../SubscriptionMetadata';

import SuccessfulPaymentNotificationCard from '../SuccessfulPaymentNotificationCard';
import FailureNotificationCard from '../FailureNotificationCard';
import PaymentForm from '../PaymentForm';

import ShareASale from '../ShareASale';
import PaymentExplainer from '../PaymentExplainer';
import CheckoutActions from '../CheckoutActions';

import AccountCreation from '../AccountCreation';

import {
  StyledButton as CheckoutButton,
} from '../CheckoutActions/styles';

import {
  Container,
  StyledOnboardingCard,
  FreeTitle,
  SignOutLink,
  InfoContainer,
  ClockIcon,
  WarningContainer,
  BellCrossedIcon,
  StepTitle,
  StepContainer,
  AgreementContainer,
  StyledContractDetails,
  CheckboxContainer,
  AgreementText,
  NoPaymentContainer,
  HeaderContainer,
  CoachHero,
  CoachTitle,
  HeaderTitle,
  SignOutInfo,
} from './styles';

import texts from './texts.json';

const SubscriptionCard = ({ subscriptionFormData }) => {
  const {
    subscriptionControllerState,
    subscriptionState,
    handleSubscriptionEndResult,
    applePayPaymentRequest,
    stripeErrorState,
    handleSubscriptionSubmissionWithCreditCard,
    submitAuthenticationConfirmation,
    handleNoPaymentSubscriptionSubmission,
    onAccountFormSubmitted,
    accountCreationState,
    isOnboardingCompleted,
    userDetail: {
      firstName,
      lastName,
      email,
      phoneNumber,
    },
  } = subscriptionFormData;

  const [showPaymentConfirmationModal, setShowPaymentConfirmationModal] = useState(false);
  const [authConfirmationDetails, setAuthConfirmationDetails] = useState(null);
  const [subscriptionFlowError, setErrorState] = useState({});

  // Contract-related state
  const { contractDoc, newTermsAvailable } = useContext(UserContractContext);
  const [contract, setContract] = useState(null);

  const confirmPaymentForSubscription = useCallback((isConfirmed) => {
    submitAuthenticationConfirmation({ isConfirmed });
  }, [submitAuthenticationConfirmation]);

  useEffect(() => {
    if (subscriptionState === SubscriptionCreationState.WAITING_FOR_INITIAL_PAYMENT_AUTHORIZATION) {
      setShowPaymentConfirmationModal(true);
    }
  }, [subscriptionState]);

  useEffect(() => {
    let errorTmp = {
      isError: false,
      responseErrorMsg: null,
    };
    if (subscriptionState === SubscriptionCreationState.WAITING_FOR_VALID_PAYMENT_METHOD) {
      errorTmp = {
        isError: true,
        responseErrorMsg: 'Subscription payment validation failed. Please use a different card.',
      };
    }
    if (stripeErrorState && stripeErrorState.message) {
      errorTmp = {
        isError: true,
        responseErrorMsg: stripeErrorState.message,
      };
    }
    setErrorState(errorTmp);
  }, [subscriptionState, stripeErrorState]);

  useEffect(() => {
    if (authConfirmationDetails) {
      setShowPaymentConfirmationModal(false);
      confirmPaymentForSubscription(authConfirmationDetails.isConfirmed);
      setAuthConfirmationDetails(null);
    }
  }, [authConfirmationDetails, confirmPaymentForSubscription]);

  const onAuthConfirmationResponse = useCallback((isConfirmed) => {
    setShowPaymentConfirmationModal(false);
    setAuthConfirmationDetails({ isConfirmed });
  }, [setShowPaymentConfirmationModal, setAuthConfirmationDetails]);

  const { authUser } = useSessionStore();
  const { signOut } = useAuthentication();

  const {
    currentCoach,
  } = useContext(CoachAssignmentContext);

  const [isCreditCardFormOpened, setIsCreditCardFormOpened] = useState(false);

  const {
    startAtDate,
  } = useContext(OnboardingFlowContext);

  const {
    plan: {
      totalPayOnSubscriptionCreation,
      initialPaymentInCents,
      isFree: isFreePlan,
      totalPriceInCents,
      recurringBillingMonths,
      cancelAt,
      offeringText,
      refundable,
      currency,
      cancelAtPeriodEnd,
      id: priceId,
      minSubscriptionMonths,
      initialTerm = recurringBillingMonths,
    },
    coupon: {
      percentOff,
    },
  } = useContext(PlanContext);

  const [areActionsEnabled, setActionsEnabled] = useState(false);

  // Create the contract object so that we can display the whole list of terms for every subscription.
  useEffect(() => {
    let contractObj;
    if (contractDoc) {
      contractObj = getContractObj(contractDoc);
    } else {
      // TODO: Remove this once we remove support for lagacy subscriptions (PIF and CHALLENGES using onboarding links).
      contractObj = {
        startDate: startAtDate || moment(),
        initialPayment: formatAmoutInCents(totalPriceInCents, { minimumFractionDigits: 2 }),
        monthlyPayment: formatAmoutInCents(totalPriceInCents, { minimumFractionDigits: 2 }),
        initialTerm: recurringBillingMonths || initialTerm,
        minSubscriptionMonths: minSubscriptionMonths || 3,
        currency,
        priceId,
        cancelAtPeriodEnd,
        coachConditions: currentCoach.termsAndConditionsText,
        /*
          If there is no contract, then it is a legacy plan, so we force the version to 1. Version 1 contracts allow
          for PIF contracts, for example, and challenges. In those cases, we create a contract in the server once the
          subscription is created.
        */
        version: 1,
      };
    }

    // If the start date is in the past, we set it to today in the checkout page, and we remove the upfront payment.
    if (contractObj.startDate.isBefore(moment())) {
      contractObj.startDate = moment();
      contractObj.upfrontPayment = 0;
    }

    setContract(contractObj);
  }, [
    contractDoc,
    currency,
    priceId,
    startAtDate,
    totalPriceInCents,
    cancelAtPeriodEnd,
    minSubscriptionMonths,
    currentCoach.termsAndConditionsText,
    initialTerm,
    recurringBillingMonths,
  ]);

  // Prepare form components based on plan pricing (free or not)
  const onSubmit = isFreePlan ? handleNoPaymentSubscriptionSubmission : handleSubscriptionSubmissionWithCreditCard;
  const triggerFormText = isFreePlan ? texts.registerNow : null;

  /*
    Subscription form is loading when subscription creation is not complete and the controller is busy, or when the
    subscription creation is complete but the user onboarding is not complete yet
  */
  const subscriptionIsLoading = useMemo(() => (
    (subscriptionState !== SubscriptionCreationState.SUCCESSFUL_COMPLETION && subscriptionControllerState.isBusy)
    || (subscriptionState === SubscriptionCreationState.SUCCESSFUL_COMPLETION && !isOnboardingCompleted)
  ), [
    subscriptionState,
    subscriptionControllerState,
    isOnboardingCompleted,
  ]);

  const renderPaymentExplainer = useCallback(() => {
    if (isFreePlan) {
      return <FreeTitle>{texts.freePlanDescription}</FreeTitle>;
    }

    const initialPayment = initialPaymentInCents || totalPayOnSubscriptionCreation;

    // Apply any discount if it exists.
    const discount = Math.round(initialPayment * (percentOff / 100));
    const initialPaymentWithDiscount = initialPayment - discount;
    const upfrontPaymentValue = parseFloat(contract?.upfrontPayment * 100) || 0;
    const initialPaymentWithUpfrontPayment = initialPaymentWithDiscount - upfrontPaymentValue;
    const totalPayToday = upfrontPaymentValue > 0 ? upfrontPaymentValue : initialPaymentWithDiscount;

    return (
      <PaymentExplainer
        refundablePlan={refundable}
        recurringBillingMonths={recurringBillingMonths}
        totalPriceInCents={totalPriceInCents}
        totalPayToday={totalPayToday}
        totalPayOnStartDate={upfrontPaymentValue ? initialPaymentWithUpfrontPayment : initialPaymentWithDiscount}
        offeringText={offeringText}
        currency={currency}
        cancelAtPeriodEnd={cancelAtPeriodEnd}
        initialTerm={initialTerm}
      />
    );
  }, [
    isFreePlan,
    totalPayOnSubscriptionCreation,
    initialPaymentInCents,
    percentOff,
    refundable,
    recurringBillingMonths,
    totalPriceInCents,
    offeringText,
    currency,
    cancelAtPeriodEnd,
    initialTerm,
    contract,
  ]);

  const renderAccountCreationStep = useCallback(() => {
    if (authUser) {
      return (
        <StepContainer $direction="row">
          <SignOutInfo>{format(texts.subscribeWithEmail, { email: authUser.email })}</SignOutInfo>
          <SignOutLink onClick={signOut}>{texts.signOut}</SignOutLink>
        </StepContainer>
      );
    }

    return (
      <StepContainer>
        <StepTitle>
          {ReactHtmlParser(format(texts.step, { number: '01', text: texts.createAccount }))}
        </StepTitle>
        <AccountCreation
          email={email}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          onSubmit={onAccountFormSubmitted}
          state={accountCreationState}
        />
      </StepContainer>
    );
  }, [
    authUser,
    email,
    firstName,
    lastName,
    phoneNumber,
    onAccountFormSubmitted,
    accountCreationState,
    signOut,
  ]);

  const renderCheckoutStep = useCallback(() => {
    if (authUser) {
      if (isCreditCardFormOpened) {
        return (
          <StepContainer>
            <StepTitle>
              {ReactHtmlParser(format(texts.step, { number: '02', text: texts.checkout }))}
            </StepTitle>
            <PaymentForm
              isLoading={subscriptionIsLoading}
              errorMessage={subscriptionFlowError?.responseErrorMsg}
              onSubmit={onSubmit}
            />
          </StepContainer>
        );
      }

      // Generate the contract terms dynamically, since we don't always have a contract document at this point.
      const contractTerms = generateTerms(contract);

      return (
        <StepContainer>
          <StepTitle>
            {ReactHtmlParser(format(texts.step, { number: '02', text: texts.checkout }))}
          </StepTitle>
          <AgreementContainer>
            {!!contract && (
              <StyledContractDetails
                contractTerms={contractTerms}
                coachConditions={contract.coachConditions}
              />
            )}
            <CheckboxContainer>
              <Checkbox
                checked={areActionsEnabled}
                onChange={(event) => setActionsEnabled(event.target.checked)}
              />
              <AgreementText>
                {cancelAtPeriodEnd ? texts.submitPayment : texts.enablePayments}
              </AgreementText>
            </CheckboxContainer>
          </AgreementContainer>
          {
            isFreePlan
              ? (
                <NoPaymentContainer>
                  <CheckoutButton
                    onClick={onSubmit}
                    isLoading={subscriptionIsLoading}
                  >
                    {triggerFormText}
                  </CheckoutButton>
                </NoPaymentContainer>
              ) : (
                <CheckoutActions
                  creditCardButtonText={triggerFormText}
                  applePayPaymentRequest={applePayPaymentRequest}
                  onCreditCardClicked={() => setIsCreditCardFormOpened(true)}
                  disableActions={!areActionsEnabled}
                />
              )
          }
        </StepContainer>
      );
    }
    return null;
  }, [
    authUser,
    areActionsEnabled,
    applePayPaymentRequest,
    subscriptionFlowError,
    onSubmit,
    isCreditCardFormOpened,
    triggerFormText,
    subscriptionIsLoading,
    isFreePlan,
    contract,
    cancelAtPeriodEnd,
  ]);

  if (subscriptionState === SubscriptionCreationState.FAILURE_COMPLETION
    || subscriptionState === SubscriptionCreationState.FAILURE_NETWORK) {
    return (
      <FailureNotificationCard
        message={texts.notification.defaultFailure}
        onConfirmingFinalResult={handleSubscriptionEndResult}
      />
    );
  }

  if (subscriptionState === SubscriptionCreationState.ACTIVE_SUBSCRIPTION_EXISTS) {
    return (
      <SuccessfulPaymentNotificationCard
        message={texts.notification.subscriptionExists}
        onConfirmingFinalResult={handleSubscriptionEndResult}
      />
    );
  }

  if ((contractDoc && contractDoc.exists)
    && (contractDoc.status === ContractStatus.FAILED)) {
    return (
      <FailureNotificationCard
        message={texts.notification.defaultFailure}
        onConfirmingFinalResult={handleSubscriptionEndResult}
      />
    );
  }

  if (isOnboardingCompleted) {
    return (
      <>
        <ShareASale
          userId={authUser.uid}
        />
        <SuccessfulPaymentNotificationCard
          texts={texts.notification.success}
          onConfirmingFinalResult={handleSubscriptionEndResult}
        />
      </>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>{texts.checkout}</HeaderTitle>
        <CoachTitle>{currentCoach.name}</CoachTitle>
      </HeaderContainer>
      <StyledOnboardingCard>
        <CoachHero src={currentCoach.avatar} />
        {renderPaymentExplainer()}
        {
          !!startAtDate && (
            <InfoContainer>
              <ClockIcon />
              <span>{format(texts.billingCycleStart, startAtDate.format('LL'))}</span>
            </InfoContainer>
          )
        }
        {
          !!cancelAt && (
            <WarningContainer>
              <BellCrossedIcon />
              <span>{format(texts.subscriptionEnds, cancelAt.format('LL'))}</span>
            </WarningContainer>
          )
        }
        {renderAccountCreationStep()}
        {renderCheckoutStep()}
      </StyledOnboardingCard>
      <ConfirmDialog
        isOpen={showPaymentConfirmationModal}
        onConfirm={() => onAuthConfirmationResponse(true)}
        onCancel={() => onAuthConfirmationResponse(false)}
        dialogTexts={{
          title: texts.confirmDialog.title,
          content: texts.confirmDialog.content,
        }}
      />
      <ConfirmDialog
        isOpen={newTermsAvailable}
        onConfirm={() => window.location.reload()}
        dialogTexts={{
          title: texts.refreshDialog.title,
          content: texts.refreshDialog.content,
          confirm: texts.refreshDialog.confirm,
        }}
      />
    </Container>
  );
};

SubscriptionCard.propTypes = {
  subscriptionFormData: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(SubscriptionCard);
