import config from '../../../../../../config';

const {
  webAppURL,
  instagram: { clientId, scope },
} = config;

export const instagramAuthUrl = `https://www.instagram.com/oauth/authorize
  ?client_id=${clientId}
  &redirect_uri=${webAppURL}/auth/instagram-callback
  &scope=${scope}
  &response_type=code`.replace(/\s+/g, '');
