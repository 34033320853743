import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { Avatar } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { ChatChannelContext } from '@fitmoola/system2-chat';

import useStorage from '../../../../../../hooks/useStorage';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import FeedContext from '../../../../../context/FeedContext';
import useUpdateInteraction from '../../../../../hooks/useUpdateInteraction';
import useToast from '../../../../../hooks/useToast';
import useLogger from '../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../utils/log';

import {
  StyledButton,
  StyledTextArea,
  FeedbackContainer,
  StyledChatIcon,
  StyledClientComment,
  HeaderContainer,
  HeaderTime,
  HeaderTitle,
  NameContainer,
  Container,
  StyledIconButton,
  ButtonContainer,
  UserExerciseVideo,
  StyledClientCommentLabel,
} from './styles';
import texts from './texts.json';

const ExerciseFeedback = () => {
  const [feedbackText, setFeedbackText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    onMarkAsRead,
    selectedActivity: activity,
    setSelectedActionBarType,
  } = useContext(FeedContext);
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const {
    getBlobUrl,
  } = useStorage();
  const { updateLastInteraction } = useUpdateInteraction(activity.user);

  const { logCoachingActivity } = useLogger();
  const { sendMessageToUser } = useContext(ChatChannelContext);
  const [videoUrl, setVideoUrl] = useState();

  const {
    time,
    userName,
    userAvatar,
    message: {
      text,
      messageId,
      internalText,
      attachments,
    },
  } = activity;

  useEffect(() => {
    let blobUrl;
    const setClientExerciseVideo = async () => {
      if (attachments?.length) {
        const attachment = attachments[0];
        blobUrl = await getBlobUrl(attachment.assetPath);
        if (isComponentMountedRef.current) {
          setVideoUrl(blobUrl);
        }
      }
    };
    setClientExerciseVideo();

    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [attachments, getBlobUrl, isComponentMountedRef]);

  const activityTime = useMemo(() => (
    moment.utc(time?.toDate()).local().fromNow()
  ), [
    time,
  ]);

  // send coach feedback to client via in-app chat
  const sendFeedback = useCallback(async () => {
    setIsLoading(true);
    try {
      const formattedMsg = feedbackText.trim();

      await sendMessageToUser(activity.user, formattedMsg, [], {
        parent: {
          id: messageId,
          text,
          internalText,
          attachments,
        },
      }, true);
      await onMarkAsRead(activity);

      showToast(texts.feedbackSuccess);
      setFeedbackText('');

      setSelectedActionBarType(null);
      updateLastInteraction();
      logCoachingActivity(CoachingActivity.EXERCISE_VIDEO_FEEDBACK_SENT, {
        exerciseId: activity.checkInId,
        clientId: activity.user,
      });
    } catch (error) {
      showToast(texts.feedbackError, {
        error: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    feedbackText,
    activity,
    onMarkAsRead,
    showToast,
    setSelectedActionBarType,
    updateLastInteraction,
    logCoachingActivity,
    sendMessageToUser,
    messageId,
    text,
    internalText,
    attachments,
  ]);

  return (
    <FeedbackContainer>
      <HeaderContainer>
        {userAvatar ? <Avatar src={userAvatar} /> : (<Avatar>{userName?.substring(0, 1)}</Avatar>)}
        <NameContainer>
          <Container>
            <HeaderTitle>{userName}</HeaderTitle>
          </Container>
          <HeaderTime>{activityTime}</HeaderTime>
        </NameContainer>
        <StyledIconButton onClick={() => setSelectedActionBarType(null)}>
          <CloseOutlined />
        </StyledIconButton>
      </HeaderContainer>
      {text && (
        <StyledClientComment>
          <StyledClientCommentLabel>Comment: </StyledClientCommentLabel>
          {text}
        </StyledClientComment>
      )}
      <UserExerciseVideo
        src={videoUrl}
        controls
      />
      <StyledTextArea
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
        minRows={5}
        maxRows={20}
        placeholder={texts.feedbackPlaceholder}
        multiline
        variant="outlined"
      />
      <ButtonContainer>
        <StyledButton
          onClick={() => sendFeedback()}
          disabled={!feedbackText}
        >
          <StyledChatIcon />
          {texts.sendFeedBack}
        </StyledButton>
      </ButtonContainer>
      <LoadingOverlay isLoading={isLoading} />
    </FeedbackContainer>
  );
};

export default observer(ExerciseFeedback);
