import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'react-html-parser';
import format from 'string-template';

import { CoachInfo as CoachInfoModel } from '../../../../../../../Model/CoachInfo';
import Coach from '../../../../../../../Model/Coach';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../../../LoadingOverlay';

import {
  InfoSection,
  InfoContainer,
} from './styles';
import texts from './texts.json';

const CoachInfo = ({
  coachId,
  coachName,
}) => {
  const [coachInfo, setCoachInfo] = useState('');
  const isComponentMountedRef = useComponentMounted();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadCoachInfo = async () => {
      setIsLoading(true);
      const coachInfoDoc = await CoachInfoModel.getCoachInfoById(coachId);

      if (isComponentMountedRef.current) {
        if (!coachInfoDoc && !coachName) {
          const coachDoc = await Coach.getCoachById(coachId);
          setCoachInfo(format(texts.coachName, { coachName: coachDoc.name }));
        } else {
          setCoachInfo(coachInfoDoc?.coachInfoNote || format(texts.coachName, { coachName }));
        }
      }
      setIsLoading(false);
    };

    loadCoachInfo();
  }, [coachId, coachName, isComponentMountedRef]);

  return (
    <>
      <InfoSection>
        <InfoContainer>
          {parse(coachInfo)}
        </InfoContainer>
      </InfoSection>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

CoachInfo.propTypes = {
  coachId: PropTypes.string.isRequired,
  coachName: PropTypes.string.isRequired,
};

export default CoachInfo;
