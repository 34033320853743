import { SalesStage } from '../Model/Lead';

const SalesStageWorkFlow = [
  SalesStage.NEW_LEAD,
  SalesStage.INVALID_CONTACT,
  SalesStage.FIRST_MESSAGE,
  SalesStage.SECOND_MESSAGE,
  SalesStage.CALENDLY_SCHEDULED,
];

const getNextSalesStage = (currentSalesStage) => {
  const currentIndex = SalesStageWorkFlow.indexOf(currentSalesStage);
  if (currentIndex >= 0 && currentIndex < SalesStageWorkFlow.length - 1) {
    return SalesStageWorkFlow[currentIndex + 1];
  }
  return null;
};

export {
  SalesStageWorkFlow,
  getNextSalesStage,
};
