import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

const ToDoType = {
  GENERAL: 'GENERAL',
  CHECKIN: 'CHECKIN',
};

/**
 * Class representing a Coach ToDo.
 *
 * @class ToDo
 * @extends BaseDocument
 */
class ToDo extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.TODO}/${id}`, opts);
  }

  /**
   * Get the item (task description) of the todo.
   * @return {string}
   */
  get item() {
    return this.data.item;
  }

  /**
   * Get the todo type. can be include one of the above types
   * Can be either 'Check-In' or 'General'.
   * @return {string}
   */
  get type() {
    return this.data.type;
  }

  /**
   * Get the todo status.
   * Indicates whether the todo is completed (`true`) or active (`false`).
   * @return {boolean}
   */
  get isCompleted() {
    return this.data.isCompleted;
  }

  /**
   * Get the creation timestamp of the todo.
   * @return {Date}
   */
  get createdAt() {
    return this.data.createdAt;
  }

  /**
   * Get the name of the user who created the todo.
   * @return {string} The creator's name.
   */
  get createdByUserName() {
    return this.data.createdByUserName;
  }

  /**
   * Get the avatar URL of the user who created the todo.
   * @return {string} The creator's avatar URL.
   */
  get createdUserAvatar() {
    return this.data.createdUserAvatar;
  }

  /**
   * Get the order index, which is used to prioritize the todo list.
   * @return {number} The order index of the todo item.
   */
  get orderIndex() {
    return this.data.orderIndex;
  }

  /**
   * Adds a new todo document to the Firestore collection.
   * @param {Object} data - The data object containing todo details.
   * @return {Promise<string>} The newly created document ID.
   */
  static async addDoc(data) {
    const ToDoCollection = new Collection(Collections.TODO);
    const { id } = await ToDoCollection.add(data);
    return id;
  }

  /**
   * Fetches todos associated with a specific client ID and completion status.
   * @param {string} clientId - The ID of the client whose todos are being fetched.
   * @param {boolean} fetchCompleted - Whether to fetch completed todos.
   * @return {Promise<Collection>} A collection of todo documents filtered by client ID and completion status.
   */
  static async getClientToDosByCompletion(clientId, fetchCompleted = false) {
    const ToDoCollection = new Collection(Collections.TODO, {
      createDocument: ({ id }, opts) => new ToDo(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => {
        let query = ref
          .where('user', '==', clientId)
          .where('isCompleted', '==', fetchCompleted);
        if (fetchCompleted) {
          query = query.orderBy('createdAt', 'desc');
        } else {
          query = query.orderBy('orderIndex', 'asc');
        }
        return query;
      },
    });
    await ToDoCollection.fetch();
    return ToDoCollection;
  }

  /**
   * Updates the order index of the todo item.
   * @param {number} orderIndex - The new order index for the todo item.
   */
  async updateTodoIndex(orderIndex) {
    return this.updateFields({
      orderIndex,
    });
  }

  /**
   * Updates the todo item as completed.
   * @return {Promise<void>} A promise that resolves once the status has been updated.
   */
  async setIsCompleted() {
    return this.updateFields({
      isCompleted: true,
    });
  }
}

export default ToDo;
export {
  ToDoType,
};
