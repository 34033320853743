import styled from 'styled-components';

import Button from '../../../../../components/Button';
import { Title } from '../../../../../components/v2/Header';

const Container = styled.div`
  overflow-y: auto;
  padding: 15px 20px;
  height: 100%;
`;

const StyledButton = styled(Button)`
  height: 30px;
  width: unset;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 15px;
  font-size: 20px;
`;

export {
  StyledButton,
  ButtonRow,
  Container,
  StyledTitle,
};
