import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

import { ReactComponent as ChatIcon } from '../../../../../../assets/icons/chat-icon.svg';
import Button from '../../../../../../components/Button';
import colors from '../../../../../../styles/colors';

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  padding: 0 10px;
  width: calc(100% - 20px);
  align-items: center;
  overflow: auto;
`;

const videoFrameStyles = css`
  object-fit: scale-down;
  width: 100%;
  aspect-ratio: 1.2;
  border-radius: 5px;
  vertical-align: top;

  &[alt]:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: ${colors.shades.secondary5};
    content: '';
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  column-gap: 8px;
  width: 100%;
`;

const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

const HeaderTime = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledChatIcon = styled(ChatIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  > path {
    stroke: ${colors.base.beta};
    fill: ${colors.shades.primary3};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .MuiIconButton-root {
    padding: 2px 10px;
    svg > path {
      stroke: ${colors.base.primary};
      fill: ${colors.shades.warning1};
    }
  }
`;

const StyledButton = styled(Button)`
  width: 200px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 5px;
  background: ${colors.base.primary};
`;

const StyledTextArea = styled(TextField)`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  width: 100%;
  margin: 16px 0;
`;

const StyledClientCommentLabel = styled.span`
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  color: ${colors.shades.gamma10};
`;

const StyledClientComment = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 4px 2px 16px;
  text-align: left;
  align-self: flex-start;
  color: ${colors.shades.gamma10};
`;

const StyledIconButton = styled(Button)`
  background-color: transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.gamma5};
    color: ${colors.base.secondary2};
    opacity: 1;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
`;

const UserExerciseVideo = styled.video`
  ${videoFrameStyles};
`;

export {
  FeedbackContainer,
  StyledButton,
  StyledTextArea,
  StyledChatIcon,
  StyledClientComment,
  HeaderContainer,
  HeaderTime,
  HeaderTitle,
  NameContainer,
  Container,
  StyledIconButton,
  ButtonContainer,
  UserExerciseVideo,
  StyledClientCommentLabel,
};
