import React from 'react';
import PropTypes, { object } from 'prop-types';

import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';

import { questionFieldName } from '../../formFields';
import AddButtons from '../AddButtons';
import QuestionStep from '../QuestionStep';

import { StyledFieldArray } from './styles';

const FormQuestions = ({
  questions,
  fieldName,
  addOptions,
  elementArray,
  draggable,
}) => (
  <StyledFieldArray
    name={fieldName}
    render={({
      remove,
      push,
      move,
    }) => (
      <>
        {questions.length > 0
        && (
          <DraggableContainer
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)}
          >
            {questions.map((question, index) => (
              // TODO: Change the key of the draggable to an actual ID (S2-2078)
              // eslint-disable-next-line react/no-array-index-key
              <Draggable key={index}>
                <QuestionStep
                  question={question}
                  removeRow={() => remove(index)}
                  draggable={draggable}
                  Component={elementArray[question[questionFieldName.TYPE]]}
                  extraProps={{ index }}
                />
              </Draggable>
            ))}
          </DraggableContainer>
        )}
        <AddButtons push={push} options={addOptions} />
      </>
    )}
  />
);

FormQuestions.propTypes = {
  questions: PropTypes.arrayOf(object),
  fieldName: PropTypes.string.isRequired,
  addOptions: PropTypes.array,
  elementArray: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
};

FormQuestions.defaultProps = {
  questions: [],
  addOptions: [],
  draggable: true,
};

export default FormQuestions;
