import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { StreamChat } from 'stream-chat';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import useComponentMounted from '../../../hooks/useComponentMounted';
import config from '../../../config';
import useChat from '../../hooks/useChat';
import BroadcastMessage from '../../../Model/BroadcastMessage';
import BroadcastMessageRequest from '../../../Model/BroadcastMessageRequest';
import User from '../../../Model/User';
import Product from '../../../Model/Product';
import ExternalCoachContext from '../ExternalCoachContext';

import LoggedInUserContext from '../../../context/LoggedInUserContext';
import { ChannelBuckets } from './buckets';
import ChatContext from './ChatContext';
import ChatState from './states';

const STREAM_TIMEOUT = 5000;

const ChatContextProvider = ({
  children,
}) => {
  const [chatClient, setChatClient] = useState(null);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [chatState, setChatState] = useState(ChatState.CHAT_INITIALIZED);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBucket, setSelectedBucket] = useState(ChannelBuckets.ACTIVE);
  const [quickChatUser, setQuickChatUser] = useState('');
  const [isChatListOpen, setChatListOpen] = useState(true);
  const { coachDoc } = useContext(ExternalCoachContext);
  const [isSmartResponseOpen, setIsSmartResponseOpen] = useState(true);
  const [isBroadcastModalOpen, setIsBroadcastModalOpen] = useState(false);

  const {
    readOnlyMode,
    multiChannelMode,
    userDocForChat: {
      id: userId,
      streamToken,
      firstName,
      avatarUrl = '',
      flags: userFlags,
    },
  } = useChat();

  const {
    userId: loggedInUserId,
  } = useContext(LoggedInUserContext);

  const [customActiveChannel, setCustomActiveChannel] = useState(null);
  const [initialChatMenuOpen, setInitialChatMenuOpen] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  const { chatClientName, chatClientAvatarUrl } = useMemo(() => {
    let assistantName = firstName;
    let assistantAvatarUrl = avatarUrl;
    if (coachDoc) {
      ({
        coachAssistant: {
          name: assistantName,
          avatarUrl: assistantAvatarUrl,
        } = {},
      } = coachDoc);
    }

    return {
      chatClientName: assistantName,
      chatClientAvatarUrl: assistantAvatarUrl,
    };
  }, [
    coachDoc,
    firstName,
    avatarUrl,
  ]);

  /**
   * It will make the state of the chat to be erroneous of any provided Stream error.
   * This will enable a refresh button so that the whole chat can be re-initialized.
   */
  const onChatError = useCallback(() => {
    setChatState(ChatState.CHAT_ERROR);
  }, []);

  /**
   * It makes the chat to transition again to the NOT_INITIALIZED state so the whole
   * initialization occurs again
   */
  const onChatRefresh = useCallback(() => {
    setChatState(ChatState.CHAT_NOT_INITIALIZED);
  }, []);

  /**
   * Opens Chat Modal.
   *
   * - If the chat is already opened, it won't do anything.
   * - For multi-channel view, it will set the custom active channel to be the one from the notification
   * - It will skip the chat list opening if it's the first time the chat gets rendered and when coming from a
   *   notification.
   */
  const openChatModal = useCallback((channelId) => {
    if (channelId && channelId !== customActiveChannel) {
      setCustomActiveChannel(channelId);
    }

    if (!isChatModalOpen) {
      setChatModalOpen(true);
    }

    if (!initialChatMenuOpen) {
      setInitialChatMenuOpen(true);
    }
  }, [
    initialChatMenuOpen,
    customActiveChannel,
    isChatModalOpen,
  ]);

  const shouldCheckForGreetingMessage = useMemo(() => !readOnlyMode
    && userFlags?.dayZero
    && userFlags?.greetingMessageSent
    && !multiChannelMode
    && chatState === ChatState.CHAT_INITIALIZED
    && totalUnreadCount === 1, [
    chatState,
    multiChannelMode,
    totalUnreadCount,
    userFlags,
    readOnlyMode,
  ]);

  /*
    Opens the chat if the user is dayZero and has received the greeting message from the coach
  */
  const showGreetingMessageIfAny = useCallback(async () => {
    const channels = await chatClient.queryChannels({ id: { $eq: userId } });
    if (channels.length === 0) {
      Sentry.captureException(
        new Error(`The user ${userId} doesn't have a channel`),
        {
          extra: {
            userId,
          },
        },
      );
      return;
    }
    const channel = await channels[0].query();
    const { messages } = channel;
    if (!messages || messages.length === 0) {
      return;
    }
    const message = messages[messages.length - 1];
    if (message && message.isGreetingMessage) {
      openChatModal();
    }
  }, [
    chatClient,
    userId,
    openChatModal,
  ]);

  /**
   *  This functions initialized the chat by getting the chat client instance and the number of initial unread
   *  counts.
   */
  const initializeChat = useCallback(async () => {
    let initData = {};

    if (!streamToken) {
      Sentry.captureException(
        new Error(`User ${userId} is not registered with Stream`),
        {
          extra: { userId },
        },
      );
      return initData;
    }

    try {
      const streamChatClient = StreamChat.getInstance(config.streamIO.apiKey, {
        timeout: STREAM_TIMEOUT,
        enableWSFallBack: true,
      });
      await streamChatClient.disconnectUser();
      const initResponse = await streamChatClient.connectUser(
        {
          id: userId,
          name: chatClientName,
          image: chatClientAvatarUrl,
        },
        streamToken,
      );

      initData = {
        chatClient: streamChatClient,
        // eslint-disable-next-line camelcase
        unreadCount: initResponse?.me?.total_unread_count || 0,
      };
    } catch (err) {
      Sentry.captureException(err, {
        extra: { userId },
      });
    }

    return initData;
  }, [
    userId,
    streamToken,
    chatClientName,
    chatClientAvatarUrl,
  ]);

  /**
   * Forces current user to be disconnected from chat
   */
  const disconnectUser = useCallback(async () => {
    if (chatClient) {
      await chatClient.disconnectUser(STREAM_TIMEOUT);
    }
  }, [
    chatClient,
  ]);

  useEffect(() => {
    const initChat = async () => {
      const {
        chatClient: streamChatClient,
        unreadCount,
      } = await initializeChat();
      if (isComponentMountedRef.current) {
        setTotalUnreadCount(unreadCount);
        if (!streamChatClient) {
          // An error ocurred during initialization
          setChatClient(null);
          setChatState(ChatState.CHAT_ERROR);
          return;
        }

        setChatClient(streamChatClient);
        setChatState(ChatState.CHAT_INITIALIZED);
      }
    };

    if (chatState === ChatState.CHAT_NOT_INITIALIZED) {
      // Set to initializing to guaranteed this is called only once
      setChatState(ChatState.CHAT_INITIALIZING);
      initChat();
    }
  }, [
    chatState,
    initializeChat,
    isComponentMountedRef,
  ]);

  /**
   * Whenever a new chatClient gets calculated we subscribe for event for unread count update.
   */
  useEffect(() => {
    let clientEventListenerUnsubscriptionHandler;
    if (chatClient) {
      clientEventListenerUnsubscriptionHandler = chatClient.on((event) => {
        const { total_unread_count: eventTotalUnreadCount } = event;
        if (typeof eventTotalUnreadCount === 'number') {
          setTotalUnreadCount(eventTotalUnreadCount);
        }
      });
    }

    return () => {
      if (clientEventListenerUnsubscriptionHandler) {
        clientEventListenerUnsubscriptionHandler.unsubscribe();
      }
    };
  }, [
    chatClient,
    userId,
  ]);

  // The chat is considered to be ready when it's in one of the final states, either initialized or in error state.
  const isChatReady = useMemo(() => chatState === ChatState.CHAT_INITIALIZED || chatState === ChatState.CHAT_ERROR, [
    chatState,
  ]);

  const onInitialChatMenuOpened = useCallback(() => {
    if (isComponentMountedRef.current) {
      setInitialChatMenuOpen(true);
    }
  }, [
    isComponentMountedRef,
  ]);

  const onChannelSelected = useCallback(async (channel) => {
    if (isComponentMountedRef.current) {
      setCustomActiveChannel(channel);
      if (!readOnlyMode) {
        if (channel.data.markedAsUnread) {
          await channel.updatePartial({
            set: {
              markedAsUnread: false,
            },
          });
        }
      }
    }
  }, [
    isComponentMountedRef,
    readOnlyMode,
  ]);

  const onMarkChannelAsRead = useCallback(async (channel) => {
    if (!readOnlyMode) {
      await channel.markRead();
      await channel.updatePartial({
        set: {
          markedAsUnread: false,
        },
      });
    }
  }, [readOnlyMode]);

  const onMarkChannelAsUnread = useCallback(async (channel) => {
    if (!readOnlyMode) {
      await channel.updatePartial({
        set: {
          markedAsUnread: true,
        },
      });
    }
  }, [readOnlyMode]);

  const onSearch = useCallback((query) => {
    if (isComponentMountedRef.current) {
      setIsSearching(query !== '');
      setSearchQuery(query);
    }
  }, [
    isComponentMountedRef,
  ]);

  const onBucketSelected = useCallback((bucket) => {
    if (isComponentMountedRef.current) {
      setSelectedBucket(bucket);
    }
  }, [
    isComponentMountedRef,
  ]);

  // sends a broadcast message to list of users
  const sendBroadcastMessage = useCallback(async (recipients, message, additionalFields = {}) => {
    const recipientsArray = recipients.map(({ id, userDoc }) => ({
      id,
      firstName: userDoc.firstName,
    }));
    await BroadcastMessage.create({
      recipients: recipientsArray,
      message,
      coach: userId,
      createdBy: loggedInUserId,
      ...additionalFields,
    });
  }, [
    userId,
    loggedInUserId,
  ]);

  // schedules a broadcast message for a specific time in the future
  const scheduleBroadcastMessage = useCallback(async (recipients, message, sendAt, additionalFields = {}) => {
    const recipientsArray = recipients.map(({ id, userDoc }) => ({
      id,
      firstName: userDoc.firstName,
    }));
    await BroadcastMessageRequest.create({
      recipients: recipientsArray,
      message,
      coach: userId,
      sendAt,
      createdBy: loggedInUserId,
      ...additionalFields,
    });
  }, [
    loggedInUserId,
    userId,
  ]);

  const getScheduledMessages = useCallback(async () => (
    BroadcastMessageRequest.getScheduledMessages(userId)
  ), [
    userId,
  ]);

  const onQuickChatUserSelected = useCallback((clientUserId) => {
    setQuickChatUser(clientUserId);
    setChatListOpen(false);
    setChatModalOpen(true);
  }, []);

  const queryUserForActiveness = useCallback(async (clientUserId) => {
    const { users } = await chatClient.queryUsers(
      { id: { $in: [clientUserId] } },
      { last_active: -1 },
      { presence: true },
    );
    return {
      user: users.find((user) => user.id === clientUserId),
    };
  }, [
    chatClient,
  ]);

  const isChatEnabledForUser = useCallback(async (clientUserId) => {
    if (!clientUserId) {
      return false;
    }
    const userDoc = await User.getById(clientUserId);
    const { product } = userDoc;
    const productDoc = new Product(product);
    await productDoc.init();

    const { isChatEnabled, isS2Product } = productDoc;

    return isS2Product || isChatEnabled;
  }, []);

  const sendMessage = useCallback(async (toUser, message, extras = {}) => {
    const userChannel = chatClient.channel('messaging', toUser);
    await userChannel.sendMessage({
      text: message,
      user_id: userId,
      ...extras,
    });
  }, [
    chatClient,
    userId,
  ]);

  const context = useMemo(() => ({
    userId,
    readOnlyMode,
    isMultiChannelView: (multiChannelMode || isChatModalOpen),
    isChatModalOpen,
    isChatReady,
    setChatModalOpen,
    chatClient,
    totalUnreadCount,
    setTotalUnreadCount,
    onChatRefresh,
    onChatError,
    chatState,
    initialChatMenuOpen,
    onInitialChatMenuOpened,
    customActiveChannel,
    onChannelSelected,
    disconnectUser,
    openChatModal,
    onMarkChannelAsRead,
    showGreetingMessageIfAny,
    isSearching,
    onSearch,
    searchQuery,
    shouldCheckForGreetingMessage,
    selectedBucket,
    onBucketSelected,
    sendBroadcastMessage,
    scheduleBroadcastMessage,
    getScheduledMessages,
    onQuickChatUserSelected,
    quickChatUser,
    queryUserForActiveness,
    isChatEnabledForUser,
    onMarkChannelAsUnread,
    sendMessage,
    isChatListOpen,
    setChatListOpen,
    isSmartResponseOpen,
    setIsSmartResponseOpen,
    isBroadcastModalOpen,
    setIsBroadcastModalOpen,
  }), [
    userId,
    readOnlyMode,
    multiChannelMode,
    isChatModalOpen,
    isChatReady,
    setChatModalOpen,
    chatClient,
    totalUnreadCount,
    setTotalUnreadCount,
    onChatRefresh,
    onChatError,
    chatState,
    initialChatMenuOpen,
    onInitialChatMenuOpened,
    customActiveChannel,
    onChannelSelected,
    disconnectUser,
    openChatModal,
    onMarkChannelAsRead,
    showGreetingMessageIfAny,
    isSearching,
    onSearch,
    searchQuery,
    shouldCheckForGreetingMessage,
    selectedBucket,
    onBucketSelected,
    sendBroadcastMessage,
    scheduleBroadcastMessage,
    getScheduledMessages,
    onQuickChatUserSelected,
    quickChatUser,
    queryUserForActiveness,
    isChatEnabledForUser,
    onMarkChannelAsUnread,
    sendMessage,
    isChatListOpen,
    setChatListOpen,
    isSmartResponseOpen,
    setIsSmartResponseOpen,
    isBroadcastModalOpen,
    setIsBroadcastModalOpen,
  ]);

  return (
    <ChatContext.Provider value={context}>
      {children}
    </ChatContext.Provider>
  );
};

ChatContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChatContextProvider;
