import React from 'react';
import PropTypes, { object } from 'prop-types';

import { FormType } from '../../../../Model/Form';
import QuestionType, {
  LeadQuestionType,
  PostPaymentQuestionType,
} from '../../questionType';
import fieldName from '../../formFields';
import FieldArray from '../FieldArray';

import QuestionElement from './questionElement';
import {
  Container,
  HeaderContainer,
  Title,
} from './styles';
import texts from './texts.json';

const FormQuestions = ({ questions, formType }) => {
  const questionTypes = Object.values(QuestionType);
  const addOptions = formType === FormType.LEAD
    ? questionTypes.filter((type) => LeadQuestionType.includes(type))
    : questionTypes.filter((type) => PostPaymentQuestionType.includes(type));

  return (
    <Container>
      <HeaderContainer>
        <Title>{texts.questions}</Title>
      </HeaderContainer>
      <FieldArray
        questions={questions}
        fieldName={fieldName.FIELDS}
        addOptions={addOptions}
        elementArray={QuestionElement}
      />
    </Container>
  );
};

FormQuestions.propTypes = {
  questions: PropTypes.arrayOf(object),
  formType: PropTypes.string.isRequired,
};

FormQuestions.defaultProps = {
  questions: [],
};

export default FormQuestions;
