import pages, { pagesConfig } from '../../config/pages';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import useSessionStore from '../../../hooks/useSessionStore';

const useAdminSideBarConfig = () => {
  const { navigateTo } = useToolsNavigation();
  const { assignedPermissions } = useSessionStore();

  let entries = [
    // CRX menu items
    {
      pageId: pages.CRX_COACH_ONBOARDING,
      icon: pagesConfig[pages.CRX_COACH_ONBOARDING].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_COACH_ONBOARDING].path),
      title: pagesConfig[pages.CRX_COACH_ONBOARDING].title,
    },
    {
      pageId: pages.ANALYTICS,
      icon: pagesConfig[pages.ANALYTICS].icon,
      onClick: () => navigateTo(pagesConfig[pages.ANALYTICS].path),
      title: pagesConfig[pages.ANALYTICS].title,
    },
    {
      pageId: pages.CRX_COMMISSIONS,
      icon: pagesConfig[pages.CRX_COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_COMMISSIONS].path),
      title: pagesConfig[pages.CRX_COMMISSIONS].title,
    },
    {
      pageId: pages.CPS_COMMISSIONS,
      icon: pagesConfig[pages.CPS_COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.CPS_COMMISSIONS].path),
      title: pagesConfig[pages.CPS_COMMISSIONS].title,
    },
    {
      pageId: pages.CAM_COMMISSIONS,
      icon: pagesConfig[pages.CAM_COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.CAM_COMMISSIONS].path),
      title: pagesConfig[pages.CAM_COMMISSIONS].title,
    },
    {
      pageId: pages.FORMS,
      icon: pagesConfig[pages.FORMS].icon,
      onClick: () => navigateTo(pagesConfig[pages.FORMS].path),
      title: pagesConfig[pages.FORMS].title,
    },
    {
      pageId: pages.CRX_USER_MANAGEMENT,
      icon: pagesConfig[pages.CRX_USER_MANAGEMENT].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_USER_MANAGEMENT].path),
      title: pagesConfig[pages.CRX_USER_MANAGEMENT].title,
    },
    {
      pageId: pages.CRX_COACH_CONFIGS,
      icon: pagesConfig[pages.CRX_COACH_CONFIGS].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_COACH_CONFIGS].path),
      title: pagesConfig[pages.CRX_COACH_CONFIGS].title,
    },
    // AC menu items
    {
      pageId: pages.AC_DASHBOARD,
      icon: pagesConfig[pages.AC_DASHBOARD].icon,
      onClick: () => navigateTo(pagesConfig[pages.AC_DASHBOARD].path),
      title: pagesConfig[pages.AC_DASHBOARD].title,
    },
    {
      pageId: pages.AC_EXPERIMENTS,
      icon: pagesConfig[pages.AC_EXPERIMENTS].icon,
      onClick: () => navigateTo(pagesConfig[pages.AC_EXPERIMENTS].path),
      title: pagesConfig[pages.AC_EXPERIMENTS].title,
    },
    // IS menu items
    {
      pageId: pages.INSIDE_SALES_LEADS,
      icon: pagesConfig[pages.INSIDE_SALES_LEADS].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES_LEADS].path),
      title: pagesConfig[pages.INSIDE_SALES_LEADS].title,
    },
    {
      pageId: pages.INSIDE_SALES,
      icon: pagesConfig[pages.INSIDE_SALES].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES].path),
      title: pagesConfig[pages.INSIDE_SALES].title,
    },
    {
      pageId: pages.INSIDE_SALES_COMMISSIONS,
      icon: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES_COMMISSIONS].path),
      title: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].title,
    },
    {
      pageId: pages.INSIDE_SALES_CONTRACTS_MANAGER,
      icon: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].icon,
      onClick: () => navigateTo(pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].path),
      title: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].title,
    },
    {
      pageId: pages.COMMISSIONS,
      icon: pagesConfig[pages.COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.COMMISSIONS].path),
      title: pagesConfig[pages.COMMISSIONS].title,
    },
    {
      pageId: pages.IS_USER_MANAGEMENT,
      icon: pagesConfig[pages.IS_USER_MANAGEMENT].icon,
      onClick: () => navigateTo(pagesConfig[pages.IS_USER_MANAGEMENT].path),
      title: pagesConfig[pages.IS_USER_MANAGEMENT].title,
    },
    {
      pageId: pages.IS_COACH_CONFIGS,
      icon: pagesConfig[pages.IS_COACH_CONFIGS].icon,
      onClick: () => navigateTo(pagesConfig[pages.IS_COACH_CONFIGS].path),
      title: pagesConfig[pages.IS_COACH_CONFIGS].title,
    },
    // Support menu items
    {
      pageId: pages.RECIPES,
      icon: pagesConfig[pages.RECIPES].icon,
      onClick: () => navigateTo(pagesConfig[pages.RECIPES].path),
      title: pagesConfig[pages.RECIPES].title,
    },
    {
      pageId: pages.RECIPE_EDITOR,
      icon: pagesConfig[pages.RECIPE_EDITOR].icon,
      onClick: () => navigateTo(pagesConfig[pages.RECIPE_EDITOR].path),
      title: pagesConfig[pages.RECIPE_EDITOR].title,
    },
    {
      pageId: pages.REUSABLE_CONTRACTS,
      icon: pagesConfig[pages.REUSABLE_CONTRACTS].icon,
      onClick: () => navigateTo(pagesConfig[pages.REUSABLE_CONTRACTS].path),
      title: pagesConfig[pages.REUSABLE_CONTRACTS].title,
    },
    {
      pageId: pages.INTERNAL_ASSIGNMENTS,
      icon: pagesConfig[pages.INTERNAL_ASSIGNMENTS].icon,
      onClick: () => navigateTo(pagesConfig[pages.INTERNAL_ASSIGNMENTS].path),
      title: pagesConfig[pages.INTERNAL_ASSIGNMENTS].title,
    },
    // Only for super users
    {
      pageId: pages.USER_MANAGEMENT,
      icon: pagesConfig[pages.USER_MANAGEMENT].icon,
      onClick: () => navigateTo(pagesConfig[pages.USER_MANAGEMENT].path),
      title: pagesConfig[pages.USER_MANAGEMENT].title,
    },
  ];

  entries = entries.filter((entry) => {
    const permissions = assignedPermissions[entry.pageId];
    return permissions && permissions.includes('EDIT');
  });

  return {
    entries,
  };
};

export default useAdminSideBarConfig;
