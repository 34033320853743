import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import TextEditor from '../../../../../components/TextEditor';
import ToDoList from '../../../ToDoList';
import User from '../../../../../Model/User';
import LoadingOverlay from '../../../LoadingOverlay';
import useToast from '../../../../hooks/useToast';
import useComponentMounted from '../../../../../hooks/useComponentMounted';

import {
  Container,
  ButtonRow,
  StyledButton,
  StyledTitle,
} from './styles';
import texts from './texts.json';

const Notes = ({
  userDoc,
}) => {
  const [notes, setNotes] = useState(userDoc.coachNotes || '');
  const [isSaving, setIsSaving] = useState(false);

  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const onSave = useCallback(async () => {
    setIsSaving(true);
    await userDoc.updateCoachNotes(notes);
    showToast(texts.savedSuccessfully);
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  }, [
    userDoc,
    notes,
    showToast,
    isComponentMountedRef,
  ]);

  return (
    <>
      <Container>
        <StyledTitle>{texts.notes}</StyledTitle>
        <TextEditor value={notes} onChange={setNotes} />
        <ButtonRow>
          <StyledButton onClick={onSave}>{texts.save}</StyledButton>
        </ButtonRow>
        <ToDoList clientId={userDoc.id} fromQAP />
      </Container>
      <LoadingOverlay isLoading={isSaving} />
    </>
  );
};

Notes.propTypes = {
  userDoc: PropTypes.instanceOf(User),
};

Notes.defaultProps = {
  userDoc: null,
};

export default compose(
  observer,
)(Notes);
