import React, {
  useCallback,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useToast from '../../../../hooks/useToast';
import useSessionStore from '../../../../../hooks/useSessionStore';
import { createEmailDomain, getZohoMailOrganizationDetails, initiateWarmup } from '../../../../utils/zohomail';
import FirebaseContext from '../../../../../context/FirebaseContext';
import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import CoachConfig from '../../../../Model/CoachConfig';
import {
  Card,
  Container,
  HeaderContainer,
  Title,
  TitleContainer,
} from '../../../../../components/v2/Card';
import { StyledTextField, StyledTextFieldContainer } from '../../../../../components/Inputs';
import InputLabel from '../../../../../components/v2/InputLabel';
import SaveButton from '../../../../../components/Button/ActionButtons/SaveButton';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog';

import { ButtonContainer } from './styles';
import texts from './texts.json';

const COACH_SERVER_DOMAIN = 'coachserver.com';

const CoachserverCreation = ({
  coachDoc,
  coachConfig,
}) => {
  const [coachEmailPrefix, setCoachEmailPrefix] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { isOnCall } = useSessionStore();
  const {
    firebase: { remote },
  } = useContext(FirebaseContext);
  const { userDoc } = useContext(LoggedInUserContext);

  const onCreateEmailDomain = useCallback(async () => {
    setIsLoading(true);
    try {
      // Validate email prefix
      if (/\s/.test(coachEmailPrefix.trim()) || /[^a-zA-Z0-9]/.test(coachEmailPrefix.trim())) {
        showToast(texts.invalidPrefixError, { error: true });
        return;
      }

      // Check if email prefix already exists
      const email = `${coachEmailPrefix.trim()}@${COACH_SERVER_DOMAIN}`;
      const coachConfigDocs = await CoachConfig.getCoachConfigByEmail(email);
      if (coachConfigDocs.length > 0) {
        showToast(texts.emailExistsMessage, { error: true });
        return;
      }

      // Check if there are available licenses to create account
      const organizationDetails = await getZohoMailOrganizationDetails(remote);
      if (organizationDetails) {
        const availableLicenseCount = organizationDetails.licenseCount - organizationDetails.usersCount;
        if (availableLicenseCount === 0) {
          showToast(texts.noLicenseMessage, { error: true });
          return;
        }
      }

      // Create email domain and initiate warmup
      const { success } = await createEmailDomain(
        remote, coachEmailPrefix.trim(), coachDoc.name, coachDoc.id, userDoc.name,
      );
      if (success) {
        showToast(texts.emailCreationSuccessMessage);
      }
    } catch (error) {
      showToast(texts.emailCreationErrorMessage, { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [
    remote,
    coachEmailPrefix,
    isComponentMountedRef,
    coachDoc,
    showToast,
    userDoc,
  ]);

  const onEnableWarmup = useCallback(async () => {
    setIsLoading(true);
    try {
      const { success } = await initiateWarmup(
        remote, coachConfig.coachEmailAddress, coachDoc.name, coachDoc.id, userDoc.name,
      );
      if (success) {
        showToast(texts.warmupSuccessMessage);
      }
    } catch (error) {
      showToast(texts.warmupErrorMessage, { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [
    remote,
    coachConfig,
    coachDoc,
    showToast,
    isComponentMountedRef,
    userDoc,
  ]);

  const handleConfirmWebhookSetup = useCallback(async () => {
    setIsLoading(true);
    await coachConfig.markWebhookSetupCompletion(userDoc.name);
    if (isComponentMountedRef.current) {
      setIsModalOpen(false);
      setIsLoading(false);
    }
    showToast(texts.webhookSetupSuccessMessage);
  }, [
    coachConfig,
    isComponentMountedRef,
    showToast,
    userDoc,
  ]);

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      <Container>
        {!coachConfig?.isEmailDomainCreated && (
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.emailPrefix}</InputLabel>
            <StyledTextField
              placeholder={texts.placeholder}
              value={coachEmailPrefix}
              onChange={(e) => setCoachEmailPrefix(e.target.value)}
              disabled={coachConfig?.isEmailDomainCreated}
            />
          </StyledTextFieldContainer>
        )}
        <ButtonContainer>
          {!coachConfig?.isEmailDomainCreated && (
            <SaveButton
              onClick={onCreateEmailDomain}
              $fitToContent
              disabled={isLoading}
            >
              {texts.createAccount}
            </SaveButton>
          )}
          {coachConfig?.isEmailDomainCreated && !coachConfig?.warmupEnabledAt && (
            <SaveButton onClick={onEnableWarmup} $fitToContent disabled={isLoading}>
              {texts.enableWarmup}
            </SaveButton>
          )}
          {isOnCall && coachConfig?.isEmailDomainCreated && !coachConfig?.isWebhookSetup && (
            <SaveButton
              onClick={() => setIsModalOpen(true)}
              $fitToContent
              disabled={isLoading}
            >
              {texts.completeWebhookSetup}
            </SaveButton>
          )}
        </ButtonContainer>
      </Container>
      <LoadingOverlay isLoading={isLoading} />
      <ConfirmDialog
        isOpen={isModalOpen}
        onConfirm={handleConfirmWebhookSetup}
        onCancel={() => setIsModalOpen(false)}
        dialogTexts={{
          title: texts.confirmationTitle,
          content: texts.confirmationContent,
          confirm: texts.confirm,
          cancel: texts.cancel,
        }}
      />
    </Card>
  );
};

CoachserverCreation.propTypes = {
  coachDoc: PropTypes.object,
  coachConfig: PropTypes.object,
};

CoachserverCreation.defaultProps = {
  coachDoc: {},
  coachConfig: {},
};

export default CoachserverCreation;
