import {
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { getClientMealPlanPagePath, getClientPagePath } from '../../utils/routes';

import NavigationContext, { NavigationRouteType } from '../context/NavigationContext';

const useToolsNavigation = () => {
  const { routeType } = useContext(NavigationContext);
  const history = useHistory();
  const { params: { userId } } = useRouteMatch();

  const pathname = useMemo(() => {
    let basePath;
    switch (routeType) {
      case NavigationRouteType.ADMIN:
        basePath = 'a';
        break;
      case NavigationRouteType.COACH:
        basePath = 'c';
        break;
      default:
        basePath = 'c';
    }
    return `/${basePath}/${userId}`;
  }, [
    routeType,
    userId,
  ]);

  const navigateTo = useCallback((path, other = {}) => (
    history.push({
      ...other,
      pathname: `${pathname}${path ? `/${path}` : ''}`,
    })
  ), [
    history,
    pathname,
  ]);

  const navigateBack = useCallback(() => {
    if (history.length > 0) {
      history.goBack();
    }
  }, [
    history,
  ]);

  const navigateToCoachTools = useCallback((coachId, path, other = {}) => (
    history.push({
      ...other,
      pathname: `/c/${coachId}${path ? `/${path}` : ''}`,
    })
  ), [
    history,
  ]);

  const navigateToConfigPage = useCallback((coachId, path, other = {}) => (
    history.push({
      ...other,
      pathname: `/c/${coachId}/config${path ? `/${path}` : ''}`,
    })
  ), [
    history,
  ]);

  const navigateToAnalyticsConfigPage = useCallback((coachId, path, other = {}) => (
    history.push({
      ...other,
      pathname: `/c/${coachId}/config/instagramSettings${path ? `/${path}` : ''}`,
    })
  ), [
    history,
  ]);

  const navigateToClientPage = useCallback((clientId, other = {}) => (
    history.push({
      ...other,
      pathname: getClientPagePath(userId, clientId),
    })
  ), [
    userId,
    history,
  ]);

  const navigateToClientMealPlanPage = useCallback((clientId, other = {}) => (
    history.push({
      ...other,
      pathname: getClientMealPlanPagePath(userId),
      search: `user=${clientId}`,
    })
  ), [
    userId,
    history,
  ]);

  const navigateToRecipe = useCallback((recipeId) => (
    window.open(`/a/${userId}/recipes/${recipeId}`, '_blank')
  ), [
    userId,
  ]);

  const navigateToNutritionPlanSupport = useCallback((nutritionPlanId, other = {}) => (
    history.push({
      ...other,
      pathname: `/a/${userId}/nutritionPlans/${nutritionPlanId}`,
    })
  ), [
    userId,
    history,
  ]);

  const navigateToForm = useCallback((formId, other = {}) => (
    history.push({
      ...other,
      pathname: `/a/${userId}/forms/${formId}`,
    })
  ), [
    userId,
    history,
  ]);

  return useMemo(() => ({
    navigateToRecipe,
    navigateTo,
    navigateToCoachTools,
    navigateToNutritionPlanSupport,
    navigateToConfigPage,
    navigateToClientPage,
    navigateToForm,
    navigateToClientMealPlanPage,
    navigateBack,
    navigateToAnalyticsConfigPage,
    pathname,
  }), [
    navigateToRecipe,
    navigateTo,
    navigateToCoachTools,
    navigateToNutritionPlanSupport,
    navigateToConfigPage,
    navigateToClientPage,
    navigateToForm,
    navigateToClientMealPlanPage,
    navigateBack,
    navigateToAnalyticsConfigPage,
    pathname,
  ]);
};

export default useToolsNavigation;
