import pages, { pagesConfig } from '../../config/pages';

const internalUserMenuItems = [
  // CRX menu items
  {
    pageId: pages.CRX_COACH_ONBOARDING,
    title: pagesConfig[pages.CRX_COACH_ONBOARDING].title,
  },
  {
    pageId: pages.ANALYTICS,
    title: pagesConfig[pages.ANALYTICS].title,
  },
  {
    pageId: pages.CRX_COMMISSIONS,
    title: pagesConfig[pages.CRX_COMMISSIONS].title,
  },
  {
    pageId: pages.FORMS,
    title: pagesConfig[pages.FORMS].title,
  },
  {
    pageId: pages.CRX_USER_MANAGEMENT,
    title: pagesConfig[pages.CRX_USER_MANAGEMENT].title,
  },
  {
    pageId: pages.CRX_COACH_CONFIGS,
    title: pagesConfig[pages.CRX_COACH_CONFIGS].title,
  },
  // AC menu items
  {
    pageId: pages.AC_DASHBOARD,
    title: pagesConfig[pages.AC_DASHBOARD].title,
  },
  // IS menu items
  {
    pageId: pages.INSIDE_SALES_LEADS,
    title: pagesConfig[pages.INSIDE_SALES_LEADS].title,
  },
  {
    pageId: pages.INSIDE_SALES,
    title: pagesConfig[pages.INSIDE_SALES].title,
  },
  {
    pageId: pages.INSIDE_SALES_COMMISSIONS,
    title: pagesConfig[pages.INSIDE_SALES_COMMISSIONS].title,
  },
  {
    pageId: pages.INSIDE_SALES_CONTRACTS_MANAGER,
    title: pagesConfig[pages.INSIDE_SALES_CONTRACTS_MANAGER].title,
  },
  {
    pageId: pages.COMMISSIONS,
    title: pagesConfig[pages.COMMISSIONS].title,
  },
  {
    pageId: pages.IS_USER_MANAGEMENT,
    title: pagesConfig[pages.IS_USER_MANAGEMENT].title,
  },
  {
    pageId: pages.IS_COACH_CONFIGS,
    title: pagesConfig[pages.IS_COACH_CONFIGS].title,
  },
  // Support menu items
  {
    pageId: pages.RECIPES,
    title: pagesConfig[pages.RECIPES].title,
  },
  {
    pageId: pages.RECIPE_EDITOR,
    title: pagesConfig[pages.RECIPE_EDITOR].title,
  },
  {
    pageId: pages.REUSABLE_CONTRACTS,
    title: pagesConfig[pages.REUSABLE_CONTRACTS].title,
  },
  {
    pageId: pages.USER_MANAGEMENT,
    title: pagesConfig[pages.USER_MANAGEMENT].title,
  },
  {
    pageId: pages.AC_EXPERIMENTS,
    title: pagesConfig[pages.AC_EXPERIMENTS].title,
  },
  {
    pageId: pages.CPS_COMMISSIONS,
    title: pagesConfig[pages.CPS_COMMISSIONS].title,
  },
  {
    pageId: pages.CAM_COMMISSIONS,
    title: pagesConfig[pages.CAM_COMMISSIONS].title,
  },
  {
    pageId: pages.INTERNAL_ASSIGNMENTS,
    title: pagesConfig[pages.INTERNAL_ASSIGNMENTS].title,
  },
];

export {
  internalUserMenuItems,
};
