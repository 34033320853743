import React, { useEffect, useState, useCallback } from 'react';
import RoleTable from '../../../components/RoleTable';
import PermissionTable from '../../../components/PermissionTable';
import UserSelect from '../../Analytics/components/UserSelect';
import User from '../../../../Model/User';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Container } from './styles';

const UserPermissionSelect = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserDoc, setSelectedUserDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch userClaim.admin=true list for drop down
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const userDocList = await User.getInternalUsers();
        const transformedUsers = userDocList.map((doc) => {
          if (doc && doc.data) {
            const { firstName, lastName } = doc.data;
            const username = lastName ? `${firstName} ${lastName}` : firstName;
            return {
              userId: doc.id,
              userName: username,
            };
          }
          return null;
        });
        setAdminUsers(transformedUsers);
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const onUserSelect = useCallback(async (user) => {
    setIsLoading(true);
    if (user) {
      setSelectedUser(user);
      const userDoc = await User.getById(user.id);
      setSelectedUserDoc(userDoc);
      setIsLoading(false);
      return;
    }
    setSelectedUser(null);
    setSelectedUserDoc(null);
    setIsLoading(false);
  }, []);

  return (
    <Container>
      <UserSelect
        selectedUser={selectedUser}
        userList={adminUsers}
        onChange={onUserSelect}
      />
      <RoleTable selectedUserDoc={selectedUserDoc} />
      <PermissionTable selectedUserDoc={selectedUserDoc} />
      <LoadingOverlay isLoading={isLoading} />
    </Container>
  );
};

export default UserPermissionSelect;
