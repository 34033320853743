import React from 'react';
import {
  EmojiPeople as EmojiPeopleIcon,
  Dashboard as DashboardIcon,
  AssignmentInd as CoachOnboardingIcon,
  RestaurantMenu as RestaurantMenuIcon,
  List as RecipeListIcon,
  Timeline as AnalyticsIcon,
  TrackChanges as TrackChangesIcon,
  Receipt as ReceiptIcon,
  HistoryEdu as FormBuilderIcon,
  AccountCircle as ContractManagerIcon,
  Feed as ReusableContractsIcon,
  RequestQuote as CommissionsIcon,
  Build as ConfigIcon,
  PersonRounded as UserIcon,
  Psychology as PsychologyIcon,
} from '@mui/icons-material';

import texts from './texts.json';
import {
  ActivityFeedIcon,
  ChatIcon,
  MealPlannerIcon,
  YourClientIcon,
  WorkoutsManagerIcon,
  LeadIcon,
  ConfigurationIcon,
} from './styles';

const pages = {
  MEAL_PLANNER: 'MEAL_PLANNER',
  ACTIVITY_FEED: 'ACTIVITY_FEED',
  CHAT: 'CHAT',
  WORKOUTS_MANAGER: 'WORKOUTS_MANAGER',
  LEADS: 'LEADS',
  CONFIG: 'CONFIG',
  CRX_DASHBOARD: 'CRX_DASHBOARD',
  CRX_COACH_ONBOARDING: 'CRX_COACH_ONBOARDING',
  CRX_COMMISSIONS: 'CRX_COMMISSIONS',
  NUTRITION_PLANS: 'NUTRITION_PLANS',
  RECIPES: 'RECIPES',
  RECIPE_EDITOR: 'RECIPE_EDITOR',
  INSIDE_SALES_LEADS: 'INSIDE_SALES_LEADS',
  INSIDE_SALES: 'INSIDE_SALES',
  INSIDE_SALES_COMMISSIONS: 'INSIDE_SALES_COMMISSIONS',
  INSIDE_SALES_CONTRACTS_MANAGER: 'CONTRACTS_MANAGER',
  MANAGE_CLIENTS: 'MANAGE_CLIENTS',
  ANALYTICS: 'ANALYTICS',
  FORMS: 'FORMS',
  REUSABLE_CONTRACTS: 'REUSABLE_CONTRACTS',
  AC_DASHBOARD: 'AC_DASHBOARD',
  AC_EXPERIMENTS: 'AC_EXPERIMENTS',
  COMMISSIONS: 'COMMISSIONS',
  CRX_USER_MANAGEMENT: 'CRX_USER_MANAGEMENT',
  IS_USER_MANAGEMENT: 'IS_USER_MANAGEMENT',
  CRX_COACH_CONFIGS: 'CRX_COACH_CONFIGS',
  IS_COACH_CONFIGS: 'IS_COACH_CONFIGS',
  INTERNAL_ASSIGNMENTS: 'INTERNAL_ASSIGNMENTS',
  CPS_COMMISSIONS: 'CPS_COMMISSIONS',
  CAM_COMMISSIONS: 'CAM_COMMISSIONS',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
};

const pagesConfig = {
  [pages.MEAL_PLANNER]: {
    title: texts.mealPlanner,
    icon: <MealPlannerIcon />,
    path: 'mealPlanner',
  },
  [pages.ACTIVITY_FEED]: {
    title: texts.activityFeed,
    icon: <ActivityFeedIcon />,
    path: 'feed',
  },
  [pages.CHAT]: {
    title: texts.chat,
    icon: <ChatIcon />,
    path: 'chat',
  },
  [pages.WORKOUTS_MANAGER]: {
    title: texts.workoutsManager,
    icon: <WorkoutsManagerIcon />,
    path: 'workoutsManager',
  },
  [pages.LEADS]: {
    title: texts.leads,
    icon: <LeadIcon />,
    path: 'leads',
  },
  [pages.CONFIG]: {
    title: texts.configuration,
    icon: <ConfigurationIcon />,
    path: 'config',
  },
  [pages.CRX_DASHBOARD]: {
    title: texts.crxDashboard,
    icon: <DashboardIcon />,
    path: 'dashboard',
  },
  [pages.CRX_COACH_ONBOARDING]: {
    title: texts.crxCoachOnboarding,
    icon: <CoachOnboardingIcon />,
    path: 'coachOnboarding',
  },
  [pages.CRX_COMMISSIONS]: {
    title: texts.crxCommissions,
    icon: <ReceiptIcon />,
    path: 'crxCommissions',
  },
  [pages.CPS_COMMISSIONS]: {
    title: texts.cpsCommissions,
    icon: <ReceiptIcon />,
    path: 'cpsCommissions',
  },
  [pages.CAM_COMMISSIONS]: {
    title: texts.camCommissions,
    icon: <ReceiptIcon />,
    path: 'camCommissions',
  },
  [pages.RECIPES]: {
    title: texts.recipes,
    icon: <RecipeListIcon />,
    path: 'recipes',
  },
  [pages.RECIPE_EDITOR]: {
    title: texts.recipeEditor,
    icon: <RestaurantMenuIcon />,
    path: 'recipeEditor',
  },
  [pages.INSIDE_SALES_LEADS]: {
    title: texts.INSIDE_SALES_LEADS,
    icon: <EmojiPeopleIcon />,
    path: 'leads',
  },
  [pages.INSIDE_SALES]: {
    title: texts.INSIDE_SALES,
    icon: <TrackChangesIcon />,
    path: 'sales',
  },
  [pages.INSIDE_SALES_COMMISSIONS]: {
    title: texts.INSIDE_SALES_COMMISSIONS,
    icon: <ReceiptIcon />,
    path: 'isCommissions',
  },
  [pages.MANAGE_CLIENTS]: {
    title: texts.yourClients,
    icon: <YourClientIcon />,
    path: 'manageClients',
  },
  [pages.ANALYTICS]: {
    title: texts.analytics,
    icon: <AnalyticsIcon />,
    path: 'analytics',
  },
  [pages.INSIDE_SALES_CONTRACTS_MANAGER]: {
    title: texts.INSIDE_SALES_CONTRACTS_MANAGER,
    icon: <ContractManagerIcon />,
    path: 'isContractsManager',
  },
  [pages.FORMS]: {
    title: texts.forms,
    icon: <FormBuilderIcon />,
    path: 'forms',
  },
  [pages.REUSABLE_CONTRACTS]: {
    title: texts.reusableContracts,
    icon: <ReusableContractsIcon />,
    path: 'reusableContracts',
  },
  [pages.AC_DASHBOARD]: {
    title: texts.acDashboard,
    icon: <DashboardIcon />,
    path: 'acDashboard',
  },
  [pages.AC_EXPERIMENTS]: {
    title: texts.acExperiments,
    icon: <PsychologyIcon />,
    path: 'experiments',
  },
  [pages.COMMISSIONS]: {
    title: texts.commissions,
    icon: <CommissionsIcon />,
    path: 'commissions',
  },
  [pages.CRX_COACH_CONFIGS]: {
    title: texts.crxCoachConfigs,
    icon: <ConfigIcon />,
    path: 'crxCoachConfigs',
  },
  [pages.IS_COACH_CONFIGS]: {
    title: texts.iSCoachConfigs,
    icon: <ConfigIcon />,
    path: 'ISCoachConfigs',
  },
  [pages.CRX_USER_MANAGEMENT]: {
    title: texts.cRXUsers,
    icon: <UserIcon />,
    path: 'crxUserManagement',
  },
  [pages.IS_USER_MANAGEMENT]: {
    title: texts.iSUsers,
    icon: <UserIcon />,
    path: 'isUserManagement',
  },
  [pages.INTERNAL_ASSIGNMENTS]: {
    title: texts.internalAssignments,
    icon: <UserIcon />,
    path: 'internalAssignments',
  },
  [pages.USER_MANAGEMENT]: {
    title: texts.userManagement,
    icon: <UserIcon />,
    path: 'userManagement',
  },
};

export default pages;

export {
  pagesConfig,
};
