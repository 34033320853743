import styled from 'styled-components';
import { Typography } from '@mui/material';

import FormikInput from '../../../../../../components/v2/FormikInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

const SingleRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ShortFormInput = styled(FormikInput)`
  width: 30%;
`;

const LongFormInput = styled(FormikInput)`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  padding-left: 10px;
  margin-bottom: ${(props) => (props.$withMargin ? '10px' : '0px')};
`;

export {
  Container,
  SingleRowContainer,
  ShortFormInput,
  LongFormInput,
  StyledTypography,
};
