import { Collection } from 'firestorter';
import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';
import BaseDocument from './BaseDocument';

/**
 * Class representing coach information used by IS users.
 * This model stores coach information impotent for IS users when engaging with a lead.
 *
 * @class CoachInfo
 * @extends BaseDocument
 */
class CoachInfo extends BaseDocument {
/**
 * Retrieves the coach information note.
 * If no note is available, it returns an empty string.
 *
 * @returns {string} The stored coach information note.
 */
  get coachInfoNote() {
    return this.data.coachInfoNote || '';
  }

  /**
 * Updates the coach information with the provided value.
 *
 * @param {string} data - The new coach information to be stored.
 * @returns {Promise<void>} A promise that resolves when the update operation is complete.
 */
  async updateCoachInfoNote(data) {
    await this.updateFields({ coachInfoNote: data });
  }

  /**
   * Creates a new CoachInfo document under a specific coachId.
   *
   * @param {string} coachId - The ID of the coach.
   * @param {string} data - The new coach information to be stored.
   * @returns {Promise<string>} The created document.
   */
  static async createCoachInfo(coachId, data) {
    const coachInfoCollection = new Collection(
      format(firestorePaths.COACH_INFO_DOC, { [pathPlaceholder.COACH_ID]: coachId }),
      { createDocument: (source, options) => new CoachInfo(source, options) },
    );
    await coachInfoCollection.add({
      coachInfoNote: data,
    });
    await CoachInfo.initCollection(coachInfoCollection);

    return coachInfoCollection.docs[0];
  }

  /**
   * Retrieves a specific CoachInfo document by coach ID.
   *
   * @param {string} coachId - The ID of the coach.
   * @returns {CoachInfo} The CoachInfo document instance.
   */
  static async getCoachInfoById(coachId) {
    const coachInfoCollection = new Collection(
      format(firestorePaths.COACH_INFO_DOC, { [pathPlaceholder.COACH_ID]: coachId }),
      { createDocument: (source, options) => new CoachInfo(source, options) },
    );
    await CoachInfo.initCollection(coachInfoCollection);

    if (coachInfoCollection.docs.length > 0) {
      return coachInfoCollection.docs[0];
    }
    return null;
  }
}

export { CoachInfo };
