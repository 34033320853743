import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import Button from '../../../../components/v2/Button';

export const Container = styled.div`
  padding: 24px;
`;

export const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const URLInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid ${(props) => (props.error ? '#ff4d4f' : '#d9d9d9')};
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${(props) => (props.error ? '#ff4d4f' : '#40a9ff')};
    box-shadow: 0 0 0 2px ${(props) => (props.error ? 'rgba(255, 77, 79, 0.2)' : 'rgba(24, 144, 255, 0.2)')};
  }
`;

export const SubmitButton = styled(Button)`
  min-width: 160px;
`;

export const ResultsContainer = styled.div`
  margin-top: 24px;
`;

export const ErrorMessage = styled.div`
  color: #ff4d4f;
  margin-top: 16px;
`;

export const ClientInfo = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;

  h3 {
    color: #262626;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
`;

export const ClientLink = styled.a`
  color: #1890ff;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: #40a9ff;
  }
`;

export const InsightSection = styled.div`
  margin-bottom: 24px;
  background: #fafafa;
  border-radius: 8px;
  padding: 16px;

  h3 {
    margin-bottom: 16px;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const InsightItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 8px 0;
  
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const InsightEmoji = styled.span`
  font-size: 16px;
  line-height: 1.4;
  flex-shrink: 0;
`;

export const InsightContent = styled.div`
  color: #595959;
  font-size: 14px;
  line-height: 1.5;
  flex: 1;
  position: relative;
  padding-right: 80px;
`;

export const InsightDate = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  color: #8c8c8c;
  font-size: 12px;
`;

export const InsightEvidence = styled.div`
  color: #8c8c8c;
  font-size: 13px;
  font-style: italic;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed #f0f0f0;
  line-height: 1.4;
  
  strong {
    font-weight: 600;
    font-style: normal;
    color: #595959;
  }
`;

export const QuestionsSection = styled.div`
  margin: 24px 0;
  background-color: #f9f0ff;
  border-radius: 8px;
  padding: 16px 20px;
  
  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    color: #722ed1;
    font-size: 16px;
  }
`;

export const QuestionItem = styled.div`
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionText = styled.div`
  font-weight: 600;
  color: #262626;
  margin-bottom: 8px;
  font-size: 14px;
`;

export const AnswerText = styled.div`
  color: #262626;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;
`;

export const ReasoningText = styled.div`
  color: #595959;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 8px;
  padding-left: 12px;
  border-left: 2px solid #d9d9d9;
`;

export const QuestionsAddressedWrapper = styled.div`
  margin-top: 12px;
  font-size: 13px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
  padding: 8px 12px;
`;

export const QuestionsAddressedTitle = styled.div`
  font-weight: 600;
  color: #52c41a;
  margin-bottom: 6px;
`;

export const QuestionAddressedItem = styled.div`
  display: flex;
  margin-bottom: 6px;
  padding-left: 8px;
  
  &:before {
    content: "✓";
    color: #52c41a;
    margin-right: 6px;
    font-weight: bold;
  }
`;

export const ChatSection = styled.div`
  margin-bottom: 24px;

  h3 {
    margin-bottom: 16px;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const ChatContent = styled.div`
  background: #fafafa;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;

  > div {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ChatMessage = styled.div`
  color: #262626;
  line-height: 1.5;
  margin-bottom: 4px;
  position: relative;
  padding-right: ${(props) => (props.hasButton ? '80px' : '0')};

  strong {
    color: #8c8c8c;
    font-weight: 500;
  }
`;

export const ChatMeta = styled.div`
  color: #8c8c8c;
  font-size: 12px;
`;

export const ProposedMessage = styled.div`
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

  ${ChatMessage} {
    padding-right: 80px;

    strong {
      color: #1890ff;
      font-weight: 600;
    }
  }
`;

export const CopyButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: ${(props) => (props.success ? '#52c41a' : '#1890ff')};
  color: white;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  transition: all 0.3s;

  &:hover {
    background: ${(props) => (props.success ? '#73d13d' : '#40a9ff')};
  }
`;

export const JsonSection = styled.div`
  margin-top: 32px;
  border-top: 1px solid #f0f0f0;
  padding-top: 24px;

  h3 {
    margin-bottom: 16px;
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: #1890ff;
      color: white;
      border: none;
      padding: 4px 12px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 12px;
      font-weight: normal;

      &:hover {
        background: #40a9ff;
      }
    }
  }
`;

export const JsonContent = styled.pre`
  background: #1e1e1e;
  color: #d4d4d4;
  padding: 16px;
  border-radius: 4px;
  overflow: auto;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  font-size: 13px;
  line-height: 1.4;
  max-height: 400px;
`;

export const LoadingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin-top: 24px;

  p {
    margin-top: 16px;
    color: #595959;
    font-size: 14px;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: #1890ff;
`;
