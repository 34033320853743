import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun, reaction } from 'mobx';
import { compose } from 'recompose';

import useComponentMounted from '../../hooks/useComponentMounted';
import { leadId, contractId } from '../../utils/onboarding';
import UserContract from '../../Model/UserContract';
import { ContractStatus } from '../../CoachTools/utils/userContract';
import UserContractContext from './UserContractContext';

const UserContractContextProvider = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [contractDoc, setContractDoc] = useState(null);
  const [newTermsAvailable, setNewTermsAvailable] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const loadContract = async () => {
      let contractDocument;
      let contractCollection;

      if (contractId) {
        const document = new UserContract(contractId);
        await document.init();
        contractDocument = document;
      } else if (leadId) {
        contractCollection = await UserContract.getContractsByLeadId(leadId);
      }

      let documentToUse = null;
      // The autorun is used here to capture contract changes and update the context accordingly
      autorun(() => {
        if (contractDocument && contractDocument.exists && contractDocument.status === ContractStatus.PENDING) {
          /*
            Reusable contracts are using the same status as the user contract. PENDING means the contract is not signed
            yet, so we can use it for generating USER contracts from this one.
          */
          documentToUse = contractDocument;
        } else if (contractCollection) {
          /*
            We are looking for the first contract that is not signed yet. If there is no such contract,
            we are looking for the first contract that is in progress. If there is no such contract,
            we are looking for the first contract that failed to show the user with respective pages.
          */
          documentToUse = contractCollection.docs.find((doc) => doc.status === ContractStatus.PENDING)
            || contractCollection.docs.find((doc) => doc.status === ContractStatus.IN_PROGRESS);
        }

        if (documentToUse && documentToUse.exists) {
          if (isComponentMountedRef.current) {
            setContractDoc(documentToUse);
          }
        }
      });

      if (isComponentMountedRef) {
        setIsReady(true);
      }

      // Reaction to detect contract updates and set refresh state
      if (documentToUse) {
        const disposer = reaction(
          () => documentToUse?.updatedAt,
          (newUpdatedAt, prevUpdatedAt) => {
            if (newUpdatedAt !== prevUpdatedAt) {
              setNewTermsAvailable((prev) => !prev || prev);
            }
          },
        );

        return () => disposer();
      }

      return undefined;
    };

    if (!isReady) {
      loadContract();
    }
  }, [
    isReady,
    isComponentMountedRef,
  ]);

  const context = useMemo(() => ({
    isReady,
    leadId,
    contractDoc,
    newTermsAvailable,
  }), [
    isReady,
    contractDoc,
    newTermsAvailable,
  ]);

  return (
    <UserContractContext.Provider value={context}>
      {children}
    </UserContractContext.Provider>
  );
};

UserContractContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(UserContractContextProvider);
