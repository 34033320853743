import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useQuickSearch from '../../../hooks/useQuickSearch';

import LabelCheckbox from '../LabelCheckbox';
import FormsTableToolbar from './FormsTableToolbar';
import {
  Container,
  DataGridContainer,
  StyledDataGrid,
} from './styles';

import texts from './texts';

const FormsTable = ({
  rows,
  ActionsCellComponent,
  extraActionsCellProps,
  showArchivedForms,
  handleArchivedToggle,
  showLeadForms,
  handleLeadFormToggle,
  showPostPaymentForms,
  handlePostPaymentFormToggle,
}) => {
  const columns = useMemo(() => [
    {
      field: 'coach',
      headerName: texts.headers.coach,
      quickSearch: true,
      flex: 6,
    },
    {
      field: 'formName',
      headerName: texts.headers.formName,
      quickSearch: true,
      flex: 10,
    },
    {
      field: 'type',
      headerName: texts.headers.formType,
      flex: 4,
    },
    {
      field: 'id',
      headerName: texts.headers.formId,
      flex: 6,
    },
    {
      field: 'action',
      headerName: texts.headers.action,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableReorder: true,
      flex: 15,
      minWidth: 300,
      cellClassName: 'action-column',
      renderCell: ({ row }) => (<ActionsCellComponent row={row} {...extraActionsCellProps} />),
    },
  ], [extraActionsCellProps]);

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(rows, columns);

  return (
    <Container>
      <DataGridContainer>
        <StyledDataGrid
          rows={quickSearchRows}
          columns={columns}
          rowHeight={60}
          pagination
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
          components={{
            Toolbar: FormsTableToolbar,
          }}
          componentsProps={{
            toolbar: {
              ...toolbarProps,
              placeholder: texts.toolbarPlaceholder,
              filterTools: [
                {
                  Component: LabelCheckbox,
                  id: 'archive-filter',
                  props: {
                    description: texts.archivedCheckbox,
                    onChange: handleArchivedToggle,
                    isChecked: showArchivedForms,
                  },
                },
                {
                  Component: LabelCheckbox,
                  id: 'lead-form-filter',
                  props: {
                    description: texts.leadFormCheckbox,
                    onChange: handleLeadFormToggle,
                    isChecked: showLeadForms,
                  },
                },
                {
                  Component: LabelCheckbox,
                  id: 'post-payment-form-filter',
                  props: {
                    description: texts.postPaymentFormCheckbox,
                    onChange: handlePostPaymentFormToggle,
                    isChecked: showPostPaymentForms,
                  },
                },
              ],
            },
          }}
        />
      </DataGridContainer>
    </Container>
  );
};

FormsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  ActionsCellComponent: PropTypes.elementType.isRequired,
  extraActionsCellProps: PropTypes.object,
  showArchivedForms: PropTypes.bool.isRequired,
  handleArchivedToggle: PropTypes.func.isRequired,
  showLeadForms: PropTypes.bool.isRequired,
  handleLeadFormToggle: PropTypes.func.isRequired,
  showPostPaymentForms: PropTypes.bool.isRequired,
  handlePostPaymentFormToggle: PropTypes.func.isRequired,
};

FormsTable.defaultProps = {
  extraActionsCellProps: {},
};

export default compose(
  observer,
)(FormsTable);
