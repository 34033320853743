import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';

import { ReactComponent as CreateIcon } from '../../../assets/icons/v2/creation-plus-circle.svg';
import DialogRoundedModal from '../../../components/DialogRoundedModal';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import RadioButtonGroup from '../RadioButtonGroup';
import LabelCheckbox from '../LabelCheckbox';
import { StyledTextField } from '../../../components/Inputs';
import InputLabel from '../../../components/v2/InputLabel';
import useUserDoc from '../../hooks/useUserDoc';
import useComponentMounted from '../../../hooks/useComponentMounted';
import useToast from '../../hooks/useToast';
import useSessionStore from '../../../hooks/useSessionStore';
import LoadingOverlay from '../LoadingOverlay';
import ToDo, { ToDoType } from '../../Model/ToDo';

import {
  ModalContent,
  FieldContainer,
} from './styles';
import texts from './texts';

const EMPTY_VALUE = '';
const DEFAULT_ORDER_INDEX = -1;

const todoOptions = Object.values(ToDoType).map((type) => ({
  label: texts.todoTypes[type],
  value: type,
}));

const ToDoModal = ({
  clientId,
  showModal,
  onClose,
}) => {
  const { userId } = useSessionStore();
  const {
    isReady,
    userDoc,
  } = useUserDoc(userId);

  const [toDoItem, setToDoItem] = useState(EMPTY_VALUE);
  const [toDoType, setToDoType] = useState(ToDoType.GENERAL);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(EMPTY_VALUE);
  const [keepWindowOpen, setKeepWindowOpen] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const onSaveChanges = useCallback(async () => {
    try {
      if (!toDoItem) {
        setError(texts.itemIsRequired);
        return;
      }
      setError(EMPTY_VALUE);
      setIsLoading(true);

      if (isReady) {
        await ToDo.addDoc({
          item: toDoItem,
          type: toDoType,
          isCompleted: false,
          createdByUserName: userDoc.name,
          ...(userDoc.avatarUrl && { createdUserAvatar: userDoc.avatarUrl }),
          createdAt: new Date(),
          user: clientId,
          orderIndex: DEFAULT_ORDER_INDEX,
        });
      }

      if (isComponentMountedRef.current) {
        showToast(texts.successfullySaved);
        setToDoItem(EMPTY_VALUE);
        if (!keepWindowOpen) {
          onClose();
        }
      }
    } catch (err) {
      showToast(format(texts.fail, { error: err }), { error: true });
    } finally {
      setIsLoading(false);
    }
  }, [
    clientId,
    toDoItem,
    toDoType,
    isComponentMountedRef,
    isReady,
    userDoc,
    showToast,
    onClose,
    keepWindowOpen,
  ]);

  return (
    <DialogRoundedModal
      title={texts.title}
      fullWidth
      open={showModal}
      onClose={onClose}
    >
      <ModalContent>
        <FieldContainer>
          <InputLabel>{texts.toDoItem}</InputLabel>
          <StyledTextField
            value={toDoItem}
            onChange={(e) => setToDoItem(e.target.value)}
            error={!!error}
            helperText={error}
            placeholder={texts.toDoPlaceholder}
          />
        </FieldContainer>
        <FieldContainer>
          <InputLabel>{texts.toDoType}</InputLabel>
          <RadioButtonGroup
            options={todoOptions}
            selectedOption={toDoType}
            onOptionChange={setToDoType}
          />
        </FieldContainer>
        <LabelCheckbox
          description={texts.keepWindowOpen}
          isChecked={keepWindowOpen}
          onChange={() => setKeepWindowOpen((prev) => !prev)}
        />
        <PrimaryButton
          icon={<CreateIcon />}
          onClick={onSaveChanges}
        >
          {texts.addToDo}
        </PrimaryButton>
      </ModalContent>
      <LoadingOverlay isLoading={isLoading} />
    </DialogRoundedModal>
  );
};

ToDoModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ToDoModal;
