import { LeadCallOutcome } from '../../../../../../../../Model/Lead';

const texts = {
  leadCallOutcome: {
    [LeadCallOutcome.WON]: 'Won',
    [LeadCallOutcome.NOT_ANSWERED]: 'Not Answered',
    [LeadCallOutcome.LOST]: 'Lost',
    [LeadCallOutcome.FOLLOW_UP]: 'Follow Up',
  },
  recordOutcome: 'Record Outcome',
  saveRecord: 'Save Record',
  outcomeNote: 'Outcome Note',
  leadOutcome: 'Lead Outcome',
  success: 'Lead call outcome saved successfully',
  somethingWentWrong: 'Something went wrong, Please try again later',
};

export default texts;
