import styled from 'styled-components';
import colors from '../../../../styles/colors';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.gamma};
`;

export {
  Container,
};
