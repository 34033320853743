import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import moment from 'moment';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import CoachConfig, {
  WorkoutReminderDays,
} from '../../../../../../Model/CoachConfig';
import useToast from '../../../../../../hooks/useToast';
import Select from '../../../../../../../components/Select/Select';
import RadioButtonGroup from '../../../../../../components/RadioButtonGroup';
import { SettingOption } from '../../utils';
import LoadingOverlay from '../../../../../../components/LoadingOverlay';

import {
  SettingContainer as Container,
  HeaderContainer,
  SettingsActionContainer,
  TitleContainer,
  Title,
  SubTitle,
  ActionLabel,
  RestrictionMessage,
} from './styles';
import texts from './texts.json';

const reminderOptions = Object.entries(WorkoutReminderDays).map(([key, value]) => ({
  value,
  label: value === 1 ? `${value} Day` : `${value} Days`,
  key,
}));

const WorkoutNotification = ({ coachConfig }) => {
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const [reminderDays, setReminderDays] = useState(coachConfig?.daysToRemindWorkoutAssign);
  const [isFutureStartNotificationEnabled, setIsFutureStartNotificationEnabled] = useState(
    coachConfig?.isFutureStartNotificationEnabled,
  );
  const [isLoading, setIsLoading] = useState(false);
  const changeDisabledUntilDate = useMemo(() => {
    if (!coachConfig?.changedDate) return null;

    return moment(coachConfig.changedDate)
      .add(1, 'days')
      .toDate();
  }, [coachConfig.changedDate]);

  const isDisabled = useMemo(() => (
    changeDisabledUntilDate ? moment().isSameOrBefore(changeDisabledUntilDate) : false), [changeDisabledUntilDate]);

  const message = useMemo(() => {
    if (isDisabled) {
      return format(texts.changeRestrictionMessage, { in: moment().to(changeDisabledUntilDate) });
    }
    return texts.warningMessage;
  }, [isDisabled, changeDisabledUntilDate]);

  const handleWorkoutReminderDaysChange = useCallback(async (event) => {
    setIsLoading(true);
    try {
      if (coachConfig) {
        await coachConfig.setDaysToRemindWorkoutAssign(
          coachConfig?.daysToRemindWorkoutAssign,
          event.target.value,
          new Date(),
        );
      }
      if (isComponentMountedRef.current) {
        setReminderDays(event.target.value);
      }
      showToast(texts.changeReminderDaysSuccessful);
    } catch (error) {
      showToast(format(texts.changeReminderDaysFailed, { error }), { error: true });
    } finally {
      setIsLoading(false);
    }
  }, [
    coachConfig,
    isComponentMountedRef,
    showToast,
  ]);

  const handleNotificationForFutureStartChange = useCallback(async (enabled) => {
    setIsLoading(true);
    try {
      await coachConfig.setIsFutureStartNotificationEnabled(enabled);
      if (isComponentMountedRef.current) {
        setIsFutureStartNotificationEnabled(enabled);
      }
      showToast(texts.successSetNotification);
    } catch (error) {
      showToast(format(texts.setNotificationFailed, { error }), { error: true });
    } finally {
      setIsLoading(false);
    }
  }, [
    coachConfig,
    isComponentMountedRef,
    showToast,
  ]);

  return (
    <>
      <Container>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.notificationSettings}</Title>
            <SubTitle>{texts.notificationSettingsDescription}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        <SettingsActionContainer>
          <ActionLabel>{texts.workoutReminderTitle}</ActionLabel>
          <Select
            options={reminderOptions}
            onChange={handleWorkoutReminderDaysChange}
            value={reminderDays}
            disabled={isDisabled}
          />
        </SettingsActionContainer>
        <RestrictionMessage>
          {message}
        </RestrictionMessage>
        <SettingsActionContainer>
          <ActionLabel>{texts.notificationForFutureStart}</ActionLabel>
          <RadioButtonGroup
            options={[
              {
                label: texts.enabled,
                value: SettingOption.ENABLED,
              },
              {
                label: texts.disabled,
                value: SettingOption.DISABLED,
              },
            ]}
            selectedOption={isFutureStartNotificationEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
            onOptionChange={(value) => handleNotificationForFutureStartChange(value === SettingOption.ENABLED)}
          />
        </SettingsActionContainer>
      </Container>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

WorkoutNotification.propTypes = {
  coachConfig: PropTypes.instanceOf(CoachConfig).isRequired,
};
export default compose(
  observer,
)(WorkoutNotification);
