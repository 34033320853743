import React, { useState } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import DialogRoundedModal from '../../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../../components/Button/ActionButtons';
import Role from '../../../../../../Model/Role';
import useToast from '../../../../../hooks/useToast';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import {
  InputContainer,
  StyledTextLabel,
  StyledFormInput,
  ButtonContainer,
} from './styles';
import texts from './texts.json';

const AddNewRole = ({
  open,
  onClose,
  setRoles,
  roles,
  isLoading,
  setIsLoading,
  setSelectedRole,
  setSelectedRoleDoc,
}) => {
  const [newRoleName, setNewRoleName] = useState('');
  const [roleError, setRoleError] = useState('');
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const handleAddRole = async () => {
    if (!newRoleName.trim()) {
      setRoleError(texts.roleRequired);
      return;
    }

    setIsLoading(true);
    const roleExists = roles.some((role) => role.label.toUpperCase() === newRoleName.toUpperCase());
    if (roleExists) {
      setRoleError(texts.roleExists);
      setIsLoading(false);
      return;
    }

    try {
      const newRoleDoc = await Role.createRole(newRoleName);
      const newRole = { id: newRoleDoc.id, label: newRoleName };
      setRoles((prevRoles) => [...prevRoles, newRole]);
      if (isComponentMountedRef.current) {
        setSelectedRole(newRole);
        setSelectedRoleDoc(newRoleDoc);
      }
      showToast(texts.successAddingRole);
      setNewRoleName('');
      onClose();
    } catch (error) {
      showToast(format(texts.errorAddingRole, { error }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  return (
    <DialogRoundedModal
      open={open}
      onClose={onClose}
      title={texts.addNewRole}
      fullWidth
      maxWidth="sm"
      actionButtons={(
        <ButtonContainer>
          <SaveButton variant="contained" onClick={handleAddRole} disabled={isLoading}>
            {texts.addButton}
          </SaveButton>
          <CancelButton onClick={onClose}>{texts.cancelButton}</CancelButton>
        </ButtonContainer>
      )}
    >
      <InputContainer>
        <StyledTextLabel>{texts.roleName}</StyledTextLabel>
        <StyledFormInput
          autoFocus
          value={newRoleName}
          onChange={(e) => setNewRoleName(e.target.value)}
          placeholder={texts.roleName}
          error={!!roleError}
          helperText={roleError}
        />
      </InputContainer>
    </DialogRoundedModal>
  );
};

AddNewRole.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setRoles: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  setSelectedRoleDoc: PropTypes.func.isRequired,
};
export default AddNewRole;
