import History from './History';
import CoachInfo from './CoachInfo';
import texts from './texts.json';

const tabs = {
  HISTORY: 'HISTORY',
  COACH_INFO: 'COACH_INFO',
};

const getTabsConfig = (isISView) => ({
  [tabs.HISTORY]: {
    label: texts.tabLabels[tabs.HISTORY],
    component: History,
  },
  ...(isISView ? {
    [tabs.COACH_INFO]: {
      label: texts.tabLabels[tabs.COACH_INFO],
      component: CoachInfo,
    },
  } : {}
  ),
});

export {
  tabs,
  getTabsConfig,
};
