import texts from './texts.json';

const sendDesktopNotification = () => {
  // Check if the browser supports notifications
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(texts.notification.title, {
        body: texts.notification.body,
        icon: '/logo192.png',
      });
      // focus the tab
      notification.onclick = () => {
        window.focus();
      };
      // remove the notification after 10 seconds
      setTimeout(() => {
        notification.close();
      }, 10000);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          sendDesktopNotification();
        }
      });
    }
  }
};

export {
  sendDesktopNotification,
};
