import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { DataGrid } from '@mui/x-data-grid';
import AddPermissionModal from '../PermissionAddModal/AddPermissionModal';
import ConfirmDialog from '../ConfirmDialog';
import LoadingOverlay from '../LoadingOverlay';
import useToast from '../../hooks/useToast';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import { Title } from '../../../components/v2/Card';
import useComponentMounted from '../../../hooks/useComponentMounted';
import { internalUserMenuItems } from '../PermissionAddModal/utils';

import {
  StyledButton,
  HeaderRow,
  StyledCard,
} from './styles';

import texts from './texts.json';

const PermissionTable = ({
  selectedUserDoc,
  selectedRoleDoc,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPermissionModal, setShowAddPermissionModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState('');
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const isRoles = !!selectedRoleDoc;

  // format permission object to table rows
  const formattedPermissions = useMemo(() => {
    if (!selectedUserDoc && !selectedRoleDoc) return [];
    return Object.entries((isRoles ? selectedRoleDoc.permissions : selectedUserDoc.customPermissions) || {})
      .map(([feature, permission]) => {
        const entry = internalUserMenuItems.find((e) => e.pageId === feature);
        return {
          id: entry?.title || feature,
          permission,
        };
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoles, selectedRoleDoc?.permissions, selectedUserDoc?.customPermissions]);

  const handleRemovePermission = async () => {
    setIsLoading(true);
    try {
      const selectedFeatureId = internalUserMenuItems.find((e) => e.title === selectedFeature).pageId;

      if (isRoles) {
        await selectedRoleDoc.setPermissions(selectedFeatureId, '', true);
      } else {
        await selectedUserDoc.setPermissions(selectedFeatureId, '', true);
      }
      showToast(texts.successRemovePermission);
    } catch (error) {
      showToast(format(texts.errorRemovePermission, { error }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setShowConfirmationModal(false);
        setSelectedFeature('');
        setIsLoading(false);
      }
    }
  };

  const handleRemoveClick = async (featureToRemove) => {
    setSelectedFeature(featureToRemove);
    setShowConfirmationModal(true);
  };

  const onAddPermissionClick = useCallback(() => {
    if (!selectedUserDoc && !selectedRoleDoc) {
      showToast(texts.selectUserorRole, { error: true });
      setShowAddPermissionModal(false);
      return;
    }
    setShowAddPermissionModal(true);
  }, [selectedRoleDoc, selectedUserDoc, showToast]);

  const columns = [
    {
      field: 'id',
      headerName: texts.headers.feature,
      flex: 20,
    },
    {
      field: 'permission',
      headerName: texts.headers.permissions,
      flex: 20,
    },
    {
      field: 'actions',
      headerName: texts.headers.actions,
      flex: 20,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <PrimaryButton
          onClick={() => handleRemoveClick(params.row.id)}
          variant="info"
          size="medium"
        >
          {texts.removePermission}
        </PrimaryButton>
      ),
    },
  ];

  return (
    <StyledCard>
      <HeaderRow>
        <Title>{texts.title}</Title>
        <StyledButton
          variant="contained"
          onClick={onAddPermissionClick}
        >
          {texts.assignNewPermission}
        </StyledButton>
      </HeaderRow>
      <DataGrid
        rows={formattedPermissions}
        columns={columns}
        rowHeight={50}
        pagination
        autoPageSize
      />
      {showAddPermissionModal && (
      <AddPermissionModal
        onClose={setShowAddPermissionModal}
        userDoc={selectedUserDoc}
        roleDoc={selectedRoleDoc}
        assignedPermissions={formattedPermissions}
      />
      )}

      {showConfirmationModal && (
        <ConfirmDialog
          isOpen={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={() => handleRemovePermission()}
          dialogTexts={{
            title: format(texts.removeRolePermissionConfirm,
              { feature: selectedFeature, name: isRoles ? selectedRoleDoc.name : selectedUserDoc?.firstName }),
          }}
        />
      )}

      <LoadingOverlay isLoading={isLoading} />
    </StyledCard>
  );
};

PermissionTable.propTypes = {
  selectedUserDoc: PropTypes.object,
  selectedRoleDoc: PropTypes.object,
};
PermissionTable.defaultProps = {
  selectedUserDoc: null,
  selectedRoleDoc: null,
};

export default compose(
  observer,
)(PermissionTable);
