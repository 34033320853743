import styled from 'styled-components';
import {
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material';

import LoadingPage from '../../../../components/LoadingPage';
import { StyledTextField } from '../../../../components/Inputs';

const StyledTableContainer = styled(TableContainer)`
  padding: 0 8px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  padding-top: 30px;
  font-size: 14px;
  align-items: end;
`;

const StyledTableCell = styled(TableCell)`
  padding: 15px;
  text-transform: capitalize;
  text-align: center;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 50%;
`;

const TextField = styled(StyledTextField)`
  width: 150px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 480px;
  margin-top: 15px;
  align-items: center;
  gap: 15px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
`;

const NoFeesText = styled(Typography)`
  width: 100%;
  font-size: 16px;
  text-align: center;
`;

export {
  StyledTableContainer,
  StyledTableCell,
  StyledLoadingPage,
  StyledContainer,
  FieldContainer,
  TextField,
  ButtonContainer,
  NoFeesText,
};
