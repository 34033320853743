import QuestionType from '../../questionType';

import InputQuestion from './components/InputQuestion';
import NameQuestion from './components/NameQuestion';
import OptionsQuestion from './components/OptionsQuestion';
import LengthQuestion from './components/LengthQuestion';
import WeightQuestion from './components/WeightQuestion';

const QuestionElement = {
  [QuestionType.TEXT]: InputQuestion,
  [QuestionType.NUMBER]: InputQuestion,
  [QuestionType.OPTIONS]: OptionsQuestion,
  [QuestionType.EMAIL]: InputQuestion,
  [QuestionType.PHONE]: InputQuestion,
  [QuestionType.NAME]: NameQuestion,
  [QuestionType.LENGTH]: LengthQuestion,
  [QuestionType.WEIGHT]: WeightQuestion,
  [QuestionType.DATE]: InputQuestion,
};

export default QuestionElement;
