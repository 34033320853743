import styled from 'styled-components';
import { Typography, IconButton } from '@mui/material';
import { Draggable } from 'react-smooth-dnd';

import { Description } from '../../../../components/v2/Card';
import Avatar from '../../../../components/Avatar';
import colors from '../../../../styles/colors';

const draggableContainerStyle = {
  display: 'flex',
};

const StyledDraggable = styled(Draggable)`
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 8px 0;
  background-color: ${colors.base.alpha};
  border: 1px solid ${colors.shades.secondary7};
  gap: 2px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledAvatar = styled(Avatar)`
  width: 25px;
  height: 25px;
`;

const AvatarContainer = styled.div`
  margin-right: 10px;
`;

const CheckmarkSpan = styled.span`
  border-radius: 14px;
  width: 24px;
  height: 24px;

  &:hover {
    ${({ $isActive }) => (
    $isActive && `
      border: 1.2px solid ${colors.shades.secondary3};
      cursor: pointer;
    `)}
  }
`;

const StyledDescription = styled(Description)`
  font-weight: 400;
  align-self: center;
`;

const CreatedTime = styled(Typography)`
  font-size: 10px;
`;

const StyledReorderRoundedIcon = styled(IconButton)`
  width: 30px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export {
  StyledDraggable,
  CardContainer,
  StyledAvatar,
  AvatarContainer,
  StyledDescription,
  StyledReorderRoundedIcon,
  CheckmarkSpan,
  CreatedTime,
  draggableContainerStyle,
};
