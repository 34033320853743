import styled from 'styled-components';
import { Typography } from '@mui/material';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Timer = styled(Typography)`
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
`;

export {
  ModalContent,
  Timer,
};
