import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useSessionStore from '../../../../hooks/useSessionStore';
import Coach from '../../../../Model/Coach';
import CoachConfig from '../../../Model/CoachConfig';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { CardsContainer } from '../../../../components/v2/Card';
import NonISCoachView from '../NonISCoachView';
import CoachserverCreation from './CoachserverCreation';
import AutomatedMessages from './AutomatedMessages';
import DisqualificationSettings from './DisqualificationSettings';
import LeadSubmissionSettings from './LeadSubmissionSettings';
import InstantLeadCalls from './InstantLeadCalls';

const LeadConfigurations = ({ coach: { id: coachId } }) => {
  const [coachDoc, setCoachDoc] = useState(null);
  const [coachConfig, setCoachConfig] = useState(null);
  const isComponentMountedRef = useComponentMounted();
  const { isOnCall } = useSessionStore();

  useEffect(() => {
    const init = async () => {
      setCoachDoc(null);
      if (coachId) {
        const coachDocument = await Coach.getCoachById(coachId);
        const coachConfigDoc = await CoachConfig.getCoachConfigByCoachId(coachId);
        if (isComponentMountedRef.current) {
          setCoachDoc(coachDocument);
          setCoachConfig(coachConfigDoc);
        }
      }
    };
    init();
  }, [coachId, isComponentMountedRef]);

  if (coachDoc && !coachDoc.isEnrolledInISProgram) {
    return <NonISCoachView coachDoc={coachDoc} />;
  }

  return (
    <CardsContainer $fullWidth>
      <AutomatedMessages coachDoc={coachDoc} />
      <DisqualificationSettings coachDoc={coachDoc} />

      {/* Display Coachserver Creation component in the following scenarios:
        1. When coach  doesn't have a coachserver email address.
        2. When email domain is created but warmup is not enabled yet
        3. For the Eng oncall users when email domain is created, warmup is enabled,
          but webhook is not mark as set up yet
      */}
      {(!coachConfig?.coachEmailAddress
        || (coachConfig?.isEmailDomainCreated && !coachConfig?.warmupEnabledAt)
        || (coachConfig?.isEmailDomainCreated && !!coachConfig?.warmupEnabledAt
          && isOnCall && !coachConfig?.isWebhookSetup)
      ) && (
        <CoachserverCreation
          coachDoc={coachDoc}
          coachConfig={coachConfig}
        />
      )}
      <LeadSubmissionSettings coachId={coachDoc?.id} />
      <InstantLeadCalls coachId={coachDoc?.id} />
    </CardsContainer>
  );
};

LeadConfigurations.propTypes = {
  coach: PropTypes.object,
};

LeadConfigurations.defaultProps = {
  coach: {},
};

export default compose(
  observer,
)(LeadConfigurations);
