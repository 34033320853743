import styled from 'styled-components';

import { Title, HeaderRow } from '../../../components/v2/Header';
import LoadingPage from '../../../components/LoadingPage';

const StyledContainer = styled.div`
  overflow-y: auto;
  margin-bottom: 20px;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 20vh;
`;

const EmptyToDoText = styled(Title)`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
  font-size: 20px;
  margin: 15px 0;
`;

const StyledHeaderRow = styled(HeaderRow)`
  width: 100%;
  margin: 22px 0;
  align-items: center;
`;

const ButtonContainer = styled(HeaderRow)`
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 0;
`;

export {
  StyledContainer,
  StyledLoadingPage,
  EmptyToDoText,
  StyledHeaderRow,
  StyledTitle,
  ButtonContainer,
};
