import styled from 'styled-components';
import { DialogContent } from '@mui/material';

const Title = styled.h2``;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 400px;
  padding: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 12px;
`;

export {
  Title,
  StyledDialogContent,
  ButtonContainer,
};
