/**
 * Get Zoho Mail organization details.
 *
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @returns {Promise<Object>} The organization details.
 */
const getZohoMailOrganizationDetails = async (remote) => {
  const response = await remote('getZohoOrganizationDetails', null, { method: 'GET' });
  if (!response.ok) {
    throw new Error('Failed to get Zoho organization details');
  }
  const data = await response.json();
  return data.data;
};

/**
 * Create an email domain and enable IMAP settings.
 *
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @param {string} emailPrefix - The email prefix for the new coach email.
 * @param {string} coachName - The name of the coach.
 * @param {string} coachId - The ID of the coach.
 * @returns {Promise<Object>} An object indicating the success status.
 */
const createEmailDomain = async (remote, emailPrefix, coachName, coachId, updatedByUserName) => {
  const response = await remote('createEmailDomain', {
    emailPrefix, coachName, coachId, updatedByUserName,
  });
  if (!response.ok) {
    throw new Error('Failed to create email domain');
  }
  return { success: true };
};

/**
 * Initiate warmup process for the email domain.
 *
 * @param {firebase.remote.RemoteType} remote - The Firebase remote object for Firebase-related operations.
 * @param {string} email - The email prefix for the new coach email.
 * @param {string} coachName - The name of the coach.
 * @param {string} coachId - The ID of the coach.
 * @returns {Promise<Object>} An object indicating the success status.
 */
const initiateWarmup = async (remote, email, coachName, coachId, updatedByUserName) => {
  const response = await remote('initiateWarmup', {
    email, coachName, coachId, updatedByUserName,
  });
  if (!response.ok) {
    throw new Error('Failed to initiate warmup');
  }
  return { success: true };
};

export {
  getZohoMailOrganizationDetails,
  createEmailDomain,
  initiateWarmup,
};
