import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import UserContext from '../../../../../context/UserContext';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import InstagramUser from '../../../../../Model/InstagramUser';
import InstagramConfiguration from './InstagramConfiguration';
import { Container } from './styles';

const InstagramSettings = () => {
  const { userId: coachId } = useContext(UserContext);
  const isComponentMountedRef = useComponentMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [instaUser, setInstaUser] = useState(undefined);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const instaUserDoc = await InstagramUser.getInstaUserByCoachId(coachId);
      if (isComponentMountedRef.current) {
        setInstaUser(instaUserDoc ?? undefined);
        setIsLoading(false);
      }
    };
    if (coachId) {
      init();
    }
  }, [coachId, isComponentMountedRef]);

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
        <InstagramConfiguration instaUser={instaUser} />
      )}
    </Container>
  );
};

export default compose(observer)(InstagramSettings);
