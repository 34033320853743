import React from 'react';
import PropTypes from 'prop-types';
import {
  FastField,
  useFormikContext,
} from 'formik';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import fieldName, { questionFieldName } from '../../../../formFields';
import QuestionType from '../../../../questionType';
import texts from '../../../../texts';
import {
  Container,
  SingleRowContainer,
  ShortFormInput,
  LongFormInput,
} from '../styles';

const InputsWithoutPlaceholder = [
  QuestionType.DATE,
  QuestionType.PHONE,
];

const InputQuestion = ({ index }) => {
  const { values, setFieldValue } = useFormikContext();

  const fieldBaseName = `${fieldName.FIELDS}.${index}`;

  return (
    <Container>
      <SingleRowContainer>
        <ShortFormInput
          name={`${fieldBaseName}.${questionFieldName.CUSTOM_CLASS}`}
          label={texts.labels[questionFieldName.CUSTOM_CLASS]}
        />
        <ShortFormInput
          name={`${fieldBaseName}.${questionFieldName.ID}`}
          label={texts.labels[questionFieldName.ID]}
        />
        <FastField name={`${fieldBaseName}.${questionFieldName.REQUIRED}`}>
          {({ field }) => (
            <FormControlLabel
              key={`${fieldBaseName}-${index}-${questionFieldName.REQUIRED}`}
              control={<Checkbox checked={field.value} />}
              label={texts.labels[questionFieldName.REQUIRED]}
              name={questionFieldName.REQUIRED}
              onChange={(event) => {
                setFieldValue(`${fieldBaseName}.${questionFieldName.REQUIRED}`, event.target.checked);
              }}
            />
          )}
        </FastField>
      </SingleRowContainer>
      <LongFormInput
        name={`${fieldBaseName}.${questionFieldName.LABEL}`}
        label={texts.labels[questionFieldName.LABEL]}
      />
      {/* A placeholder field is not needed for several question types as they have their own placeholders */}
      {!InputsWithoutPlaceholder.includes(values[fieldName.FIELDS][index][questionFieldName.TYPE]) && (
        <LongFormInput
          name={`${fieldBaseName}.${questionFieldName.PLACEHOLDER}`}
          label={texts.labels[questionFieldName.PLACEHOLDER]}
        />
      )}
    </Container>
  );
};

InputQuestion.propTypes = {
  index: PropTypes.number.isRequired,
};

export default InputQuestion;
