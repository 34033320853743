import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import colors from '../../../styles/colors';

const MediaItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const MediaItemError = styled.div`
  padding: 20px;
  font-size: 14px;
  color: ${colors.base.danger};
  align-self: start;
`;

const MediaImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const MediaVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const MediaDataLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const MediaDataContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 30px 10px 20px;
  scrollbar-width: thin;
`;

const CarouselControls = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  border-top: 1px solid ${colors.base.gamma};
`;

const CarouselControlButton = styled(Button)``;

const DataSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 3px;
  & a {
    color: ${colors.shades.primary7};
  }
`;

const DataLabel = styled(Typography)`
  color: ${colors.shades.primary5};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`;

const UserInfo = styled.div`
  padding: 10px 0;
  display: flex;
  gap: 8px;
  align-items: start;

  > div {
    line-height: 1.1;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;

export {
  MediaItemWrapper,
  MediaItemError,
  MediaImage,
  MediaVideo,
  MediaDataContainer,
  CarouselControls,
  CarouselControlButton,
  DataLabel,
  DataSection,
  DataSections,
  MediaDataLayout,
  UserInfo,
};
