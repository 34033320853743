import {
  Typography,
} from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../../../styles/colors';

const Text = styled(Typography)`
    margin-bottom: 5px;
  `;

const SettingContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${colors.base.alpha};
  `;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 20px 0;
  `;

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;

const Title = styled(Text)`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
  `;

const SubTitle = styled(Text)`
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 300;
    color: ${colors.shades.gamma7};
  `;

const RestrictionMessage = styled(Text)`
    font-size: 13px;
    margin-top: 20;
    font-weight: 300;
    color: ${colors.shades.secondary4};
`;

const SettingsActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  `;

const ActionLabel = styled(Text)`
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 400;
    color: ${colors.shades.primary1};
  `;

export {
  SettingContainer,
  HeaderContainer,
  TitleContainer,
  Title,
  SubTitle,
  RestrictionMessage,
  SettingsActionContainer,
  ActionLabel,
};
