import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as YellowCheck } from '../../assets/icons/yellow-circle-check.svg';
import { Container, Circle } from './styles';

const Checkmark = ({ checked, className }) => (
  <Container className={className}>
    {checked ? <YellowCheck /> : <Circle />}
  </Container>
);

Checkmark.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
};

Checkmark.defaultProps = {
  checked: false,
  className: '',
};

export default styled(Checkmark)``;
