import React, { useState, useEffect, useContext } from 'react';

import {
  CardsContainer,
  Card,
  Container,
} from '../../../../../components/v2/Card';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import CoachConfig from '../../../../Model/CoachConfig';
import UserContext from '../../../../../context/UserContext';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import WorkoutNotification from './components/WorkoutNotification';

const NotificationSettings = () => {
  const [coachConfig, setCoachConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { userId: coachId } = useContext(UserContext);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const coachConfigDoc = await CoachConfig.getCoachConfigByCoachId(coachId);
      if (isComponentMountedRef.current) {
        setCoachConfig(coachConfigDoc);
        setIsLoading(false);
      }
    };
    if (coachId) {
      init();
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  return (
    <CardsContainer>
      <Card>
        <Container>
          <LoadingOverlay isLoading={isLoading} />
          {coachConfig && <WorkoutNotification coachConfig={coachConfig} />}
        </Container>
      </Card>
    </CardsContainer>
  );
};

export default NotificationSettings;
