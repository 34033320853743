import { isPossiblePhoneNumber } from 'react-phone-number-input';

const isValidPhoneNumber = (phoneNumber) => (
  !!phoneNumber && isPossiblePhoneNumber(phoneNumber)
);

const isValidEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return !!email && pattern.test(email);
};

export {
  isValidPhoneNumber,
  isValidEmail,
};
