import { SalesStagesOptions } from './utils';

/* eslint-disable max-len */
const texts = {
  filters: {
    [SalesStagesOptions.ALL]: 'All Leads',
    [SalesStagesOptions.NEW_LEADS]: 'New Leads',
    [SalesStagesOptions.FIRST_MESSAGE]: '1st Message',
    [SalesStagesOptions.SECOND_MESSAGE]: '2nd Message',
    [SalesStagesOptions.IN_DIALOGUE]: 'In Dialogue',
    [SalesStagesOptions.CALENDLY_SCHEDULED]: 'Calendly Scheduled',
    [SalesStagesOptions.FINAL_STATUS]: 'Final Status',
    [SalesStagesOptions.DUPLICATE]: 'Duplicates',
    [SalesStagesOptions.INVALID_CONTACT]: 'Invalid Contact',
    [SalesStagesOptions.MANUAL]: 'Manual',
    [SalesStagesOptions.FOLLOW_UP]: 'Follow Up',
  },
  onCallLabel: 'On-Call',
};
/* eslint-enable max-len */

export default texts;
