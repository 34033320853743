import React, { useState } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import Checkbox from '@mui/material/Checkbox';

import DialogRoundedModal from '../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../components/Button/ActionButtons';
import useToast from '../../hooks/useToast';
import LoadingOverlay from '../LoadingOverlay';
import AutoComplete from '../AutoComplete';
import { internalUserMenuItems } from './utils';

import {
  ButtonContainer,
  InputContainer,
  StyledTextLabel,
  TagContainer,
  StyledFormControlLabel,
} from './styles';
import texts from './texts.json';

const AccessType = {
  EDIT: 'EDIT',
};

const AddPermissionModal = ({
  onClose,
  userDoc,
  roleDoc,
  assignedPermissions,
}) => {
  const [selectedFeature, setSelectedFeature] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const isRoles = !!roleDoc;

  const selectedFeatureOptions = internalUserMenuItems.map((entry) => ({
    id: entry.pageId,
    label: entry.title,
  }));

  const permissionOptions = Object.values(AccessType);

  const onAddNewPermission = async () => {
    if (!selectedFeature || selectedPermissions.length === 0) {
      showToast(texts.selectFeatureAndPermission, { error: true });
      setIsLoading(false);
      return;
    }

    // Check if the permission already assigned
    const alreadyAssigned = assignedPermissions.some(
      (perm) => perm.id === selectedFeature.label && perm.permission.some((p) => selectedPermissions.includes(p)),
    );
    if (alreadyAssigned) {
      showToast(format(texts.permissionAlreadyAssigned), { error: true });
      return;
    }

    setIsLoading(true);
    try {
      if (isRoles) {
        await roleDoc.setPermissions(selectedFeature.id, selectedPermissions);
      } else {
        await userDoc.setPermissions(selectedFeature.id, selectedPermissions);
      }
      showToast(texts.success);
    } catch (error) {
      showToast(format(texts.savingError, { error }), { error: true });
    } finally {
      setIsLoading(false);
      onClose(false);
    }
  };

  const handlePermissionChange = (permission) => {
    setSelectedPermissions((prev) => (prev.some((p) => p === permission)
      ? prev.filter((p) => p !== permission)
      : [...prev, permission]));
  };

  return (
    <>
      <DialogRoundedModal
        open
        onClose={() => (onClose(false))}
        title={texts.addPermission}
        fullWidth
        maxWidth="sm"
        actionButtons={(
          <ButtonContainer>
            <SaveButton variant="contained" onClick={onAddNewPermission}>
              {texts.addButton}
            </SaveButton>
            <CancelButton onClick={() => (onClose(false))}>{texts.cancelButton}</CancelButton>
          </ButtonContainer>
        )}
        modalContentWrapperPosition="static"
      >
        <InputContainer>
          <StyledTextLabel>{texts.selectedFeature}</StyledTextLabel>
          <AutoComplete
            options={selectedFeatureOptions}
            label={texts.selectPermission}
            onChange={setSelectedFeature}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </InputContainer>

        <InputContainer>
          <StyledTextLabel>{texts.access}</StyledTextLabel>
          <TagContainer>
            {permissionOptions.map((permission) => (
              <StyledFormControlLabel
                key={permission}
                control={(
                  <Checkbox
                    checked={selectedPermissions.includes(permission)}
                    onChange={() => handlePermissionChange(permission)}
                  />
              )}
                label={texts.accessType[permission]}
              />
            ))}
          </TagContainer>
        </InputContainer>
      </DialogRoundedModal>
      <LoadingOverlay isLoading={isLoading} />
    </>

  );
};

AddPermissionModal.propTypes = {

  onClose: PropTypes.func.isRequired,
  userDoc: PropTypes.object,
  roleDoc: PropTypes.object,
  assignedPermissions: PropTypes.array,
};

AddPermissionModal.defaultProps = {
  userDoc: {},
  roleDoc: {},
  assignedPermissions: [],
};

export default AddPermissionModal;
