import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';
import { Switch, FormControlLabel } from '@mui/material';

import useComponentMounted from '../../../hooks/useComponentMounted';
import useNavigation from '../../../hooks/useNavigation';
import UserContext from '../../../context/UserContext';
import Lead, {
  InitialLeadState,
  AllLeadStates,
  AllSalesStages,
} from '../../Model/Lead';
import LeadsContext from '../../context/LeadsContext';
import ExternalCoachContext from '../../context/ExternalCoachContext';
import CoachesListContext from '../../context/CoachesListContext';
import { LeadContractModal } from '../ContractModal';
import LoadingOverlay from '../LoadingOverlay';
import ColoredHeader from '../ColoredHeader';
import PageContent from '../PageContent';
import FilterButtons from '../FilterButtons';
import DownloadCSVButton from '../DownloadCSVButton';
import DateFilter from '../DateFilter';
import LeadsModal from './components/LeadModal';
import StateFilter from './components/StateFilter';
import NotesModal from './components/NotesModal';
import LeadsTable, {
  LeadsTableType,
} from './components/LeadsTable';
import DisqualifyModal from './components/DisqualifyModal';

import {
  SalesStagesOptions,
  salesStagesFilterChecks,
  FinalStatusSalesStages,
} from './utils';
import {
  Container,
  Title,
  Description,
  HeaderRow,
} from './styles';
import texts from './texts';
import InsideSalesSelectors from './components/InsideSalesSelectors';

const EMPTY_ROWS = [];
const BASE_FILE_NAME = 'leads';
const CSV_HEADERS = [
  'id',
  'submitDate',
  'name',
  'email',
  'gender',
  'phoneNumber',
  'country',
  'state',
  'source',
];

const Leads = ({
  title,
  description,
  enableCoachSelect,
  isISView,
}) => {
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showUnqualifiedModal, setShowUnqualifiedModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Filters states
  const [activeSalesStageFilter, setActiveSalesStageFilter] = useState(
    isISView ? SalesStagesOptions.NEW_LEADS : SalesStagesOptions.ALL,
  );
  const [selectedLeadStates, setSelectedLeadStates] = useState(isISView ? AllLeadStates : InitialLeadState);
  // we only show sales stage filters for IS final filter view
  const [selectedSalesStages, setSelectedSalesStages] = useState(isISView ? FinalStatusSalesStages : AllSalesStages);

  const {
    leads,
    loadingLeads,
    filteredLeads,
    selectedPeriod,
    setSelectedPeriod,
  } = useContext(LeadsContext);

  const {
    userDoc,
  } = useContext(UserContext);

  const {
    externalCoachDoc,
  } = useContext(ExternalCoachContext);

  const { coachesCollection: { docs: coachListDocs } } = useContext(CoachesListContext);

  const isComponentMountedRef = useComponentMounted();

  /*
    The coachesList holds the name of the coaches mapped from their IDs. This way we can render multiple rows belonging
    to different coaches.
  */
  const coachesList = useMemo(() => (
    coachListDocs.reduce((acc, coach) => {
      acc[coach.id] = coach.name;
      return acc;
    }, {})
  ), [coachListDocs]);

  const selectNewCoach = useCallback((coach) => {
    if (coach) {
      setIsLoading(true);
    }
  }, []);

  const onOnboardingLinkClick = () => {
    setShowContractModal(true);
  };

  const onNotesClick = () => {
    setShowNotesModal(true);
  };

  /*
    The leads table should only be rendered if we have data available. That could happen in these two situations:
      1. The coach select is enabled and a coach has been selected.
      2. The coach select is not enabled, but the external coach document is ready to be used.
  */
  const shouldRenderTable = enableCoachSelect || !!externalCoachDoc;

  useEffect(() => {
    if (!loadingLeads) {
      setIsLoading(false);
    }
  }, [loadingLeads]);

  // Extract Lead ID from URL
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlLeadId = urlParams.get('leadId');

  // Find lead document using URL ID and set it as selected
  useEffect(() => {
    const getLead = async () => {
      const leadDoc = await Lead.getLeadById(urlLeadId);
      // Since the lead id is coming from an url param, we should check if it exists
      if (isComponentMountedRef.current && leadDoc) {
        setSelectedLead(leadDoc);
        setShowQuestionsModal(true);
      }
    };
    if (urlLeadId && !isLoading) {
      getLead();
    }
  }, [
    urlLeadId,
    isLoading,
    isComponentMountedRef,
  ]);

  const { clearSearchParams } = useNavigation();

  const handleLeadModalClose = () => {
    clearSearchParams();
    setShowQuestionsModal(false);
  };

  // Group rows by filter, so it's easier to switch between button filters
  const [rowsBySalesStage, salesStagesCounters] = useMemo(() => {
    const groupedRows = {};
    const counters = {};
    leads.forEach((lead) => {
      Object.values(SalesStagesOptions).forEach((filter) => {
        if (!groupedRows[filter]) {
          groupedRows[filter] = [];
          counters[filter] = 0;
        }
        if (salesStagesFilterChecks[filter](lead)) {
          groupedRows[filter].push(lead);
          counters[filter] += 1;
        }
      });
    });
    return [groupedRows, counters];
  }, [
    leads,
  ]);

  const shouldRenderRows = !isLoading && !!rowsBySalesStage[activeSalesStageFilter];

  return (
    <Container>
      <ColoredHeader>
        <HeaderRow>
          <div>
            <Title>{title}</Title>
            {!!description && <Description>{description}</Description>}
          </div>
          <DownloadCSVButton
            csvHeaders={CSV_HEADERS}
            rows={filteredLeads}
            fileName={BASE_FILE_NAME}
            disabled={!filteredLeads.length}
          />
        </HeaderRow>
        <HeaderRow>
          {!!enableCoachSelect && (
            <InsideSalesSelectors
              onCoachChange={selectNewCoach}
            />
          )}
          {/* we only show stage filters for coach view & IS final filter view */}
          {(!isISView || activeSalesStageFilter === SalesStagesOptions.FINAL_STATUS) && (
            <StateFilter
              initialStates={isISView ? FinalStatusSalesStages : InitialLeadState}
              onSelect={isISView ? setSelectedSalesStages : setSelectedLeadStates}
              isISView={isISView}
            />
          )}
          {isISView && userDoc.instaCallIS && (
            <FormControlLabel
              control={(
                <Switch
                  checked={userDoc.isISOnCall}
                  onChange={async (event) => {
                    await userDoc.updateIsISOnCall(event.target.checked);
                  }}
                  color="primary"
                />
              )}
              label={texts.onCallLabel}
            />
          )}
          <DateFilter
            selectedPeriod={selectedPeriod}
            onChange={setSelectedPeriod}
          />
        </HeaderRow>
        {(shouldRenderTable && isISView) && (
          <HeaderRow>
            <FilterButtons
              filterOptions={SalesStagesOptions}
              optionCounters={salesStagesCounters}
              onSelectFilter={setActiveSalesStageFilter}
              optionTexts={texts.filters}
              activeOption={activeSalesStageFilter}
              fullWidth
              // we don't want to show the count badge for "All" and "Final Status" options in the filter buttons
              optionsWithoutBadges={[SalesStagesOptions.ALL, SalesStagesOptions.FINAL_STATUS]}
            />
          </HeaderRow>
        )}
      </ColoredHeader>
      <PageContent>
        {shouldRenderTable && (
          <>
            <LeadsTable
              rows={shouldRenderRows ? rowsBySalesStage[activeSalesStageFilter] : EMPTY_ROWS}
              selectLead={setSelectedLead}
              setShowUnqualifiedModal={setShowUnqualifiedModal}
              setShowQuestionsModal={setShowQuestionsModal}
              onOnboardingLinkClick={onOnboardingLinkClick}
              selectedLeadStates={selectedLeadStates}
              // we show sales stages filter only for IS view and when the "Final Status" tab is selected
              selectedSalesStages={(isISView && activeSalesStageFilter === SalesStagesOptions.FINAL_STATUS)
                ? selectedSalesStages
                : AllSalesStages}
              type={isISView ? LeadsTableType.IS_LEADS : LeadsTableType.LEADS}
              userId={userDoc.id}
              userName={userDoc.name}
              onNotesClick={onNotesClick}
              coachesList={coachesList}
              selectedPeriod={selectedPeriod}
              activeSalesStageFilter={activeSalesStageFilter}
            />
          </>
        )}
        {selectedLead && (
          <>
            <LeadsModal
              lead={selectedLead}
              showModal={showQuestionsModal}
              onClose={handleLeadModalClose}
              userId={userDoc.id}
              userName={userDoc.name}
              isInsideSalesView={isISView}
              coach={{ id: selectedLead.coach, label: coachesList[selectedLead.coach] }}
            />
            <NotesModal
              lead={selectedLead}
              showModal={showNotesModal}
              onClose={() => setShowNotesModal(false)}
            />
            {!!showContractModal && (
              <LeadContractModal
                lead={selectedLead}
                coachId={selectedLead.coach}
                showModal={showContractModal}
                onClose={() => {
                  setShowContractModal(false);
                  setSelectedLead(null);
                }}
              />
            )}
            <DisqualifyModal
              lead={selectedLead}
              showModal={showUnqualifiedModal}
              userId={userDoc.id}
              userName={userDoc.name}
              onClose={() => setShowUnqualifiedModal(false)}
            />
          </>
        )}
      </PageContent>
      <LoadingOverlay isLoading={loadingLeads || isLoading} />
    </Container>
  );
};

Leads.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  enableCoachSelect: PropTypes.bool,
  isISView: PropTypes.bool,
};

Leads.defaultProps = {
  description: '',
  enableCoachSelect: false,
  isISView: false,
};

export default compose(
  observer,
)(Leads);
