import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import format from 'string-template';
import moment from 'moment';

import { ReactComponent as ReorderRoundedIcon } from '../../../../assets/icons/v2/drag.svg';
import Checkmark from '../../../../components/Checkmark';
import { InfoTag } from '../../../../components/Tags';
import { ToDoType } from '../../../Model/ToDo';
import useToast from '../../../hooks/useToast';
import { DraggableID } from '../utils';

import {
  StyledDraggable,
  CardContainer,
  AvatarContainer,
  StyledAvatar,
  StyledDescription,
  StyledReorderRoundedIcon,
  CreatedTime,
  CheckmarkSpan,
  draggableContainerStyle,
} from './styles';
import texts from './texts.json';

const ToDoItem = ({ todoItem, fromQAP }) => {
  const {
    item,
    type,
    isCompleted,
    createdAt,
    createdUserAvatar,
    createdByUserName,
  } = todoItem;

  const { showToast } = useToast();

  const handleCompleted = useCallback(async () => {
    try {
      if (!isCompleted) {
        await todoItem.setIsCompleted();
        showToast(format(texts.savedSuccessfully, { item }));
      }
    } catch (error) {
      showToast(format(texts.fail, { error }), { error: true });
    }
  }, [
    todoItem,
    isCompleted,
    item,
    showToast,
  ]);

  return (
    <StyledDraggable style={draggableContainerStyle}>
      <CardContainer>
        {!isCompleted && (
          <StyledReorderRoundedIcon className={DraggableID}>
            <ReorderRoundedIcon />
          </StyledReorderRoundedIcon>
        )}
        <CheckmarkSpan onClick={handleCompleted} $isActive={!isCompleted}>
          <Checkmark checked={isCompleted} />
        </CheckmarkSpan>
        <StyledDescription>{item}</StyledDescription>
      </CardContainer>
      <CardContainer>
        {type === ToDoType.CHECKIN && <InfoTag>{texts.checkIn}</InfoTag>}
        {fromQAP && (
          <>
            <CreatedTime>{moment.utc(createdAt.toDate()).local().fromNow()}</CreatedTime>
            <Tooltip title={format(texts.createdBy, { createdBy: createdByUserName })} arrow>
              <AvatarContainer>
                <StyledAvatar image={createdUserAvatar} name={createdByUserName} />
              </AvatarContainer>
            </Tooltip>
          </>
        )}
      </CardContainer>
    </StyledDraggable>
  );
};

ToDoItem.propTypes = {
  todoItem: PropTypes.object.isRequired,
  fromQAP: PropTypes.bool,
};

ToDoItem.defaultProps = {
  fromQAP: false,
};

export default ToDoItem;
