import styled from 'styled-components';
import colors from '../../../../../../../styles/colors';

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${colors.shades.gamma5};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: 5%;
  word-wrap: break-word; 
  white-space: normal;
  width: 95%;
`;

export {
  InfoSection,
  InfoContainer,
};
