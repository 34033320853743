import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { useRouteMatch } from 'react-router-dom';

import useToast from '../../../../../hooks/useToast';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import Avatar from '../../../../../../components/Avatar';
import AvatarSizeType from '../../../../../../components/Avatar/AvatarSizes';
import { getClientPagePath } from '../../../../../../utils/routes';
import { SubTitle } from '../../../../../../components/v2/Header';
import { StyledLink } from '../../../../../components/UserProfileCard/styles';

import {
  ModalContent,
  Container,
} from './styles';
import texts from './texts.json';

const ReviewRequestModal = ({
  clientConfigDoc,
  activity,
  showModal,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const {
    params: {
      userId: coachId,
    },
  } = useRouteMatch();

  const {
    user: userId,
    userName,
    userAvatar,
  } = activity;

  const onUpdateChanges = useCallback(async (isReviewEnabled) => {
    try {
      setIsLoading(true);
      await clientConfigDoc.setRequestAppReview(isReviewEnabled);
      showToast(format(isReviewEnabled
        ? texts.reviewRequestEnabled : texts.reviewRequestDisabled, { clientName: userName }));
    } catch (error) {
      showToast(format(texts.fail, { error }), { error: true });
    } finally {
      setIsLoading(false);
      onClose();
    }
  }, [
    clientConfigDoc,
    onClose,
    showToast,
    userName,
  ]);

  return (
    <>
      <ConfirmDialog
        isOpen={showModal}
        onCancel={() => onUpdateChanges(false)}
        onConfirm={() => onUpdateChanges(true)}
        dialogTexts={{
          title: texts.title,
          confirm: texts.yes,
          cancel: texts.no,
          content: (
            <ModalContent>
              <Container>
                <Avatar
                  image={userAvatar}
                  name={userName}
                  avatarSize={AvatarSizeType.MEDIUM}
                />
                <StyledLink to={getClientPagePath(coachId, userId)} target="_blank">
                  {userName}
                </StyledLink>
              </Container>
              <SubTitle>{texts.description}</SubTitle>
            </ModalContent>
          ),
        }}
      />
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

ReviewRequestModal.propTypes = {
  clientConfigDoc: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReviewRequestModal;
