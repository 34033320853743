import {
  React, useContext, useState, useEffect, useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FirebaseContext from '../../../../context/FirebaseContext';
import InstagramMediaContext, { initialValues } from './InstagramMediaContext';

export const InstagramMediaContextProvider = ({ children }) => {
  const [mediaId, setMediaId] = useState();
  const [mediaUrl, setMediaUrl] = useState(initialValues.mediaUrl);
  const [isLoading, setIsLoading] = useState(initialValues.isLoading);
  const [error, setError] = useState(initialValues.error);
  const mediaCache = useRef(new Map());
  const {
    firebase: { storage },
  } = useContext(FirebaseContext);

  useEffect(() => {
    const fetchMediaUrl = async () => {
      if (!mediaId) return;

      if (mediaCache.current.has(mediaId)) {
        setMediaUrl(mediaCache.current.get(mediaId));
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const mediaRef = storage.ref().child(`instagramMedia/${mediaId}`);
        const url = await mediaRef.getDownloadURL();

        mediaCache.current.set(mediaId, url);
        setMediaUrl(url);
      } catch (e) {
        setMediaUrl(null);
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMediaUrl();
  }, [mediaId, storage]);

  const contextValue = useMemo(() => ({
    mediaUrl,
    isLoading,
    error,
    setMediaId,
  }), [mediaUrl, isLoading, error]);

  return (
    <InstagramMediaContext.Provider value={contextValue}>
      {children}
    </InstagramMediaContext.Provider>
  );
};

InstagramMediaContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(InstagramMediaContextProvider);
