import React, { useContext } from 'react';

import NavigationContext, { NavigationRouteType } from '../context/NavigationContext';

import CoachToolsLayout from './CoachToolsLayout';
import AdminToolsLayout from './AdminToolsLayout';

const withToolsLayout = (Component) => (props) => {
  const { routeType } = useContext(NavigationContext);
  let Layout;
  if (routeType === NavigationRouteType.ADMIN) {
    Layout = AdminToolsLayout;
  } else {
    Layout = CoachToolsLayout;
  }
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

export {
  withToolsLayout,
};
