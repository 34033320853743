const feedType = {
  WORKOUT_COMPLETE: 'WORKOUT_COMPLETE',
  CUSTOM_WORKOUT: 'CUSTOM_WORKOUT',
  NEED_NEW_WORKOUTS: 'NEED_NEW_WORKOUTS',
  TRACKED_ACTIVITY: 'TRACKED_ACTIVITY',
  DAILY_SUMMARY: 'DAILY_SUMMARY',
  ARCHIVED: 'ARCHIVED',
  CHECK_IN: 'CHECK_IN',
  CLIENT_STATUS: 'CLIENT_STATUS',
  SPECIAL: 'SPECIAL',
  WORKOUT_FEEDBACK_REQUEST: 'WORKOUT_FEEDBACK_REQUEST',
  NEED_NEW_MEAL_PLAN: 'NEED_NEW_MEAL_PLAN',
};

// NOTE: Feed ordering is temporarily disabled with S2-3357 except for
// check-ins and workout feedback requests only.
// Feed activity types related to the work item re-ordering
const feedOrderingActivityTypes = [
  feedType.CHECK_IN,
  // feedType.NEED_NEW_MEAL_PLAN,
  // feedType.NEED_NEW_WORKOUTS,
  feedType.WORKOUT_FEEDBACK_REQUEST,
];

const mainFeedTypes = [
  feedType.CHECK_IN,
  feedType.NEED_NEW_WORKOUTS,
  feedType.NEED_NEW_MEAL_PLAN,
  feedType.WORKOUT_FEEDBACK_REQUEST,
  feedType.CLIENT_STATUS,
  feedType.DAILY_SUMMARY,
  feedType.SPECIAL,
];

const footerFeedTypes = [
  feedType.ARCHIVED,
];

export default feedType;

export {
  mainFeedTypes,
  footerFeedTypes,
  feedOrderingActivityTypes,
};
