import { Button } from '@mui/material';
import styled from 'styled-components';
import { Card } from '../../../components/v2/Card';

const StyledCard = styled(Card)`
  height:50%;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-between;
  height: 50px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 25%;
`;

export {
  StyledButton,
  HeaderRow,
  StyledCard,
};
