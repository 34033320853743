import { Collection } from 'firestorter';

import Collections from '../utils/collections';
import { firestorePaths } from '../utils/paths';
import BaseDocument from './BaseDocument';
import Plan from './Plan';
import Package from './Package';

const FeatureId = {
  NUTRITION_PLAN: 'NUTRITION_PLAN',
  WORKOUT_VIDEO_FEEDBACK: 'WORKOUT_VIDEO_FEEDBACK',
  CHECK_IN_NOTIFICATION: 'CHECK_IN_NOTIFICATION',
  ASSISTANT_COACH: 'ASSISTANT_COACH',
  CHAT: 'CHAT',
};

class Product extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.PRODUCT}/${id}`, opts);
  }

  get name() {
    return this.data.name;
  }

  get description() {
    return this.data.description;
  }

  get coach() {
    return this.data.coach;
  }

  get isS2Product() {
    return !!this.data.isS2Product;
  }

  get baseFee() {
    return this.data.baseFee;
  }

  get isHidden() {
    return !!this.data.isHidden;
  }

  get isDefault() {
    return !!this.data.isDefault;
  }

  get postPaymentForm() {
    return this.data.postPaymentForm;
  }

  get features() {
    return this.data.features || [];
  }

  isFeatureEnabled = (featureId, defaultValue = true) => {
    const feature = this.features.find(({ id }) => id === featureId);

    // If the feature is not found, it is enabled by default.
    if (!feature) {
      return defaultValue;
    }
    return !!feature.enabled;
  }

  get isMealPlanEnabled() {
    return this.isFeatureEnabled(FeatureId.NUTRITION_PLAN);
  }

  get isVideoFeedBackEnabled() {
    return this.isFeatureEnabled(FeatureId.WORKOUT_VIDEO_FEEDBACK, false);
  }

  get isAssistantCoachEnabled() {
    return this.isFeatureEnabled(FeatureId.ASSISTANT_COACH, false);
  }

  get isChatEnabled() {
    return this.isFeatureEnabled(FeatureId.CHAT, false);
  }

  get isCheckInFeedBackEnabled() {
    return this.isFeatureEnabled(FeatureId.CHECK_IN_NOTIFICATION, false);
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  archive() {
    return this.updateFields({
      isArchived: true,
      archivedAt: new Date(),
    });
  }

  unarchive() {
    return this.updateFields({
      isArchived: false,
    });
  }

  getFeaturesConfig = () => {
    const configByID = {
      [FeatureId.NUTRITION_PLAN]: this.isMealPlanEnabled,
      [FeatureId.WORKOUT_VIDEO_FEEDBACK]: this.isVideoFeedBackEnabled,
      [FeatureId.ASSISTANT_COACH]: this.isAssistantCoachEnabled,
      [FeatureId.CHAT]: this.isChatEnabled,
      [FeatureId.CHECK_IN_NOTIFICATION]: this.isCheckInFeedBackEnabled,
    };

    const config = Object.entries(configByID).reduce((acc, [id, isEnabled]) => {
      if (isEnabled) {
        acc.enabled.push(id);
      } else {
        acc.disabled.push(id);
      }
      return acc;
    }, { enabled: [], disabled: [] });

    return config;
  }

  async getPlans() {
    const priceCollection = new Collection(`${this.path}/price`, {
      createDocument: (source, options) => new Plan(source, options),
    });

    await priceCollection.fetch();

    return priceCollection;
  }

  async getPlanById(planId) {
    const priceDoc = new Plan(`${this.path}/price/${planId}`);

    await priceDoc.init();

    return priceDoc;
  }

  static async getProductByCoachId(coachId) {
    const productCollection = new Collection(firestorePaths.PRODUCT, {
      createDocument: ({ id }, options) => new Product(id, options),
      query: (query) => query
        .where('coach', '==', coachId)
      ,
    });
    await Product.initCollection(productCollection);

    return productCollection;
  }

  static async getAllProducts() {
    const productCollection = new Collection(firestorePaths.PRODUCT, {
      createDocument: ({ id }, options) => new Product(id, { ...options, disableObserverRefCount: true }),
    });
    await Product.initCollection(productCollection);
    return productCollection;
  }

  async getPackages() {
    const packageCollection = new Collection(`${this.path}/package`, {
      createDocument: (source, options) => new Package(source, options),
    });

    await packageCollection.fetch();
    return packageCollection;
  }
}

export default Product;
export {
  FeatureId,
};
