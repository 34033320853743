import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';
import CoachHistory from './CoachHistory';

const FormState = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};

const FormType = {
  LEAD: 'LEAD',
  POST_PAYMENT: 'POST_PAYMENT',
};

class Form extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.FORMS}/${id}`, opts);
  }

  get formName() {
    return this.data.formName;
  }

  get coach() {
    return this.data.coach;
  }

  get coachId() {
    return this.data.coachId;
  }

  get hiddenFields() {
    return this.data.hiddenFields;
  }

  get fields() {
    return this.data.fields;
  }

  get endScreenContent() {
    return this.data.endScreenContent;
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  get type() {
    return this.data.type;
  }

  /**
   * Gets the forms.
   *
   * @param {boolean} fetchArchivedForms - Whether or not to fetch archived forms
   * @returns {Promise<Collection>} The collection of forms from firestore
   */
  static async getForms(fetchArchivedForms = false) {
    const formsCollection = new Collection(Collections.FORMS, {
      createDocument: ({ id }, opts) => new Form(id, opts),
      query: (ref) => {
        let query = ref.orderBy('coach', 'asc');
        if (!fetchArchivedForms) {
          query = query.where('isArchived', '==', false);
        }
        return query;
      },
    });
    await formsCollection.fetch();
    return formsCollection;
  }

  static async addForm(data) {
    const formsCollection = new Collection(Collections.FORMS);
    const { id } = await formsCollection.add(data);
    return id;
  }

  /**
   * Archives a form.
   * @return {Promise<void>}
   */
  async archive() {
    return this.updateFields({
      isArchived: true,
    });
  }

  /**
   * Unarchives a form.
   * @return {Promise<void>}
   */
  async unarchive() {
    return this.updateFields({
      isArchived: false,
    });
  }

  /**
   * Retrieves all active forms of a specific type associated with a given coach ID.
   * @param {string} coachId - The ID of the coach whose forms are to be retrieved.
   * @param {string} formType - The type of form to retrieve (e.g., FormType.POST_PAYMENT, FormType.LEAD).
   * @returns {Promise<Array<Form>>} - A promise that resolves to an array of documents representing the forms.
   */
  static async getFormsByCoachId(coachId, formType) {
    const formsCollection = new Collection(Collections.FORMS, {
      createDocument: ({ id }, opts) => new Form(id, opts),
      query: (ref) => ref
        .where('coachId', '==', coachId)
        .where('isArchived', '==', false)
        .where('type', '==', formType),
    });
    await formsCollection.fetch();
    return formsCollection.docs;
  }

  /**
 * Archives all lead forms associated with a specific coach and logs the action in history.
 *
 * @param {string} coachId - The ID of the coach whose lead forms are to be archived.
 * @param {string} authUserId - The ID of the user performing the archive action.
 * @param {string} authUserName - The name of the user performing the archive action.
 * @param {string} historyType - The type of history event to be logged (e.g., "ARCHIVE").
 * @throws {Error} If no lead forms are available (either none created or all archived).
 * @returns {Promise<void>} A promise that resolves once all lead forms are archived and history records are created.
 */
  static async archiveLeadForms(coachId, authUserId, authUserName, historyType) {
    const leadForms = await this.getFormsByCoachId(coachId, FormType.LEAD);
    if (!leadForms || leadForms.length === 0) {
      throw new Error('No lead forms available - none created or all archived.');
    }
    await Promise.all(
      leadForms.map(async (form) => {
        await form.archive();
        await CoachHistory.addCoachHistoryItem({
          type: historyType,
          coachId: form.data.coachId,
          actionedBy: authUserId,
          actionedByUserName: authUserName,
        });
      }),
    );
  }
}

export default Form;
export { FormState, FormType };
