import format from 'string-template';

import { firestorePaths, pathPlaceholder } from '../utils/paths';
import BaseDocument from './BaseDocument';

class UserConfig extends BaseDocument {
  get onboardingSkipped() {
    return !!this.data.onboardingSkipped;
  }

  get lastUTCOffset() {
    return this.data.lastUTCOffset;
  }

  get requestAppReview() {
    return this.data.requestAppReview;
  }

  skipOnboarding = () => (
    this.updateFields({
      onboardingSkipped: true,
    })
  );

  setCampaignId = (campaignId) => (
    this.updateFields({
      campaignId,
    })
  );

  setRequestAppReview = (isReviewEnabled) => (
    this.updateFields({
      requestAppReview: isReviewEnabled,
    })
  );

  static async getConfigById(userId) {
    const userConfigDoc = new UserConfig(format(firestorePaths.USER_CONFIG_DOC, {
      [pathPlaceholder.USER_ID]: userId,
    }));
    await userConfigDoc.init();

    return userConfigDoc;
  }
}

export default UserConfig;
