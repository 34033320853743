import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';

import useStorage from '../../../hooks/useStorage';
import useComponentMounted from '../../../hooks/useComponentMounted';
import { storagePaths, pathPlaceholder } from '../../../utils/firebasePaths';
import LoadingOverlay from '../../components/LoadingOverlay';
import FormsModel, { FormState as State, FormType } from '../../Model/Form';
import ConfirmationModal from '../../../components/v2/ConfirmationModal';
import FormsTable from '../../components/FormsTable';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import useToast from '../../hooks/useToast';
import LoggedInUserContext from '../../../context/LoggedInUserContext';
import FormBuilder from '../../components/FormBuilder';
import CoachesListContext from '../../context/CoachesListContext';

import CoachSelectModal from './CoachSelectModal';
import TypeSelectModal from './TypeSelectModal';
import ActionContainer from './ActionContainer';
import {
  Container,
  HeaderContainer,
  Title,
  StyledCreationButton,
} from './styles';
import texts from './texts.json';

const Forms = () => {
  const [formState, setFormState] = useState(null);
  const [formsCollection, setformsCollection] = useState({ docs: [] });
  const [selectedForm, setSelectedForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showArchivedForms, setShowArchivedForms] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showTypeSelection, setShowTypeSelection] = useState(false);
  const [formType, setFormType] = useState(null);
  const [showLeadForms, setShowLeadForms] = useState(true);
  const [showPostPaymentForms, setShowPostPaymentForms] = useState(true);

  const isComponentMountedRef = useComponentMounted();
  const { navigateToForm } = useToolsNavigation();
  const { uploadJSON, getJSONData } = useStorage();
  const { showToast } = useToast();

  const {
    userId: createdBy,
  } = useContext(LoggedInUserContext);
  const { coachesCollection } = useContext(CoachesListContext);

  const handleBackClick = () => {
    setFormState(null);
  };

  const handleTypeSelection = (type) => {
    setFormType(type);
    setShowTypeSelection(false);
    setFormState(State.NEW);
  };

  const handleFormDuplicateClick = (formId) => {
    const formDoc = formsCollection.docs.find((doc) => doc.id === formId);
    setSelectedForm(formDoc);
    setShowModal(true);
  };

  const handleFormArchiveClick = (formId) => {
    const formDoc = formsCollection.docs.find((doc) => doc.id === formId);
    setSelectedForm(formDoc);
    setShowConfirmation(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedForm({});
  };

  const duplicateForm = async (coach) => {
    const { data, id: existingFormId } = selectedForm;
    // We need to set the new coach name and id before saving the document
    const docData = {
      coach: coach.name,
      coachId: coach.id,
      formName: data.formName,
      type: data.type,
      createdBy,
      isArchived: false,
    };

    // Check if the coach already has a post payment form
    if (data.type === FormType.POST_PAYMENT) {
      const activeForms = await FormsModel.getFormsByCoachId(coach.id, FormType.POST_PAYMENT);
      if (activeForms.length >= 1) {
        showToast(texts.validation.postPaymentFormExists, { error: true });
        return;
      }
    }

    // Fetch form data from the storage JSON file
    const existingFormStoragePath = format(storagePaths.COACH_FORM_COLLECTION, {
      [pathPlaceholder.COACH_ID]: data.coachId,
    });
    const formData = await getJSONData(`${existingFormStoragePath}/${existingFormId}`);
    const {
      fields,
      hiddenFields,
      endScreenContent,
    } = formData;

    // Create the form config object with all the combined data
    const configData = {
      ...docData,
      fields,
      // Fields exclusive to lead forms
      ...(data.type === FormType.LEAD && {
        hiddenFields,
        endScreenContent,
      }),
    };

    // Add the form doc to firestore
    const newFormId = await FormsModel.addForm(docData);

    // The form config will be saved in storage for public access
    const formConfigString = JSON.stringify(configData);
    const newStoragePath = format(storagePaths.COACH_FORM_COLLECTION, {
      [pathPlaceholder.COACH_ID]: coach.id,
    });
    await uploadJSON(formConfigString, newStoragePath, newFormId);

    showToast(texts.duplicationSuccesful);
    handleModalClose();
  };

  const handleConfirmArchival = async () => {
    setIsLoading(true);
    setShowConfirmation(false);

    try {
      if (selectedForm.isArchived) {
        // Don't allow to unarchive if the coach already has an active post payment form
        if (selectedForm.type === FormType.POST_PAYMENT) {
          const activeForms = await FormsModel.getFormsByCoachId(selectedForm.coachId, FormType.POST_PAYMENT);
          if (activeForms.length >= 1) {
            showToast(texts.validation.postPaymentUnarchival, { error: true });
            return;
          }
        }

        await selectedForm.unarchive();
      } else {
        // Don't allow to archive the only post payment form of a active coach
        if (selectedForm.type === FormType.POST_PAYMENT) {
          const isCoachActive = coachesCollection.docs.find((coach) => coach.id === selectedForm.coachId);
          if (isCoachActive) {
            const activeForms = await FormsModel.getFormsByCoachId(selectedForm.coachId, FormType.POST_PAYMENT);
            if (activeForms.length === 1) {
              showToast(texts.validation.postPaymentArchival, { error: true });
              return;
            }
          }
        }

        await selectedForm.archive();
      }
    } catch (error) {
      showToast(error.message, { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const collection = await FormsModel.getForms(showArchivedForms);

      if (isComponentMountedRef.current) {
        setformsCollection(collection);
        setIsLoading(false);
      }
    };

    init();
  }, [isComponentMountedRef, showArchivedForms]);

  const filteredForms = useMemo(() => (
    formsCollection.docs.filter((form) => {
      if (showLeadForms && showPostPaymentForms) {
        return true;
      }
      if (showLeadForms) {
        return form.type === FormType.LEAD;
      }
      if (showPostPaymentForms) {
        return form.type === FormType.POST_PAYMENT;
      }
      return false;
    })
  ), [formsCollection, showLeadForms, showPostPaymentForms]);

  const renderFormsTable = () => (
    <>
      <HeaderContainer>
        <Title>{texts.formsTitle}</Title>
        <StyledCreationButton onClick={() => setShowTypeSelection(true)}>
          {texts.newForm}
        </StyledCreationButton>
      </HeaderContainer>
      <FormsTable
        rows={filteredForms}
        ActionsCellComponent={ActionContainer}
        extraActionsCellProps={{
          navigateToForm,
          handleFormDuplicateClick,
          setIsLoading,
          handleFormArchiveClick,
        }}
        actionHeader={texts.actionHeader}
        handleArchivedToggle={setShowArchivedForms}
        showArchivedForms={showArchivedForms}
        handleLeadFormToggle={setShowLeadForms}
        showLeadForms={showLeadForms}
        handlePostPaymentFormToggle={setShowPostPaymentForms}
        showPostPaymentForms={showPostPaymentForms}
      />
      <CoachSelectModal
        showModal={showModal}
        onClose={handleModalClose}
        onConfirm={duplicateForm}
      />
    </>
  );

  return (
    <>
      {formState && formState === State.NEW
        ? <FormBuilder onBackClick={handleBackClick} type={formType} />
        : (
          <Container>
            {renderFormsTable()}
          </Container>
        )}
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => handleConfirmArchival()}
        title={
          selectedForm.isArchived
            ? format(texts.confirmationTitle.unarchive)
            : format(texts.confirmationTitle.archive)
        }
        description={
          selectedForm.isArchived
            ? format(texts.confirmationMessage.unarchive)
            : format(texts.confirmationMessage.archive)
        }
      />
      <LoadingOverlay isLoading={isLoading} />
      <TypeSelectModal
        showModal={showTypeSelection}
        onClose={() => setShowTypeSelection(false)}
        onConfirm={handleTypeSelection}
      />
    </>
  );
};

export default compose(
  observer,
)(Forms);
