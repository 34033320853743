import React, { useState } from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { DataGrid } from '@mui/x-data-grid';
import ConfirmDialog from '../ConfirmDialog';
import LoadingOverlay from '../LoadingOverlay';
import useToast from '../../hooks/useToast';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import { Title } from '../../../components/v2/Card';
import useComponentMounted from '../../../hooks/useComponentMounted';
import AssignRole from '../AssignRoleModal/AssignRole';
import {
  StyledButton,
  HeaderRow,
  StyledCard,
} from './styles';
import texts from './texts.json';

const RoleTable = ({ selectedUserDoc }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const formattedRoles = selectedUserDoc?.roles?.map((role, index) => ({ id: index, role })) || [];

  const handleRemoveRole = async () => {
    setIsLoading(true);
    try {
      await selectedUserDoc.setRoles(selectedRole, true);
      showToast(texts.successRemoveRole);
    } catch (error) {
      showToast(format(texts.errorRemoveRole, { error }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setShowConfirmationModal(false);
        setSelectedRole(null);
        setIsLoading(false);
      }
    }
  };

  const handleRemoveClick = (roleToRemove) => {
    setSelectedRole(roleToRemove);
    setShowConfirmationModal(true);
  };

  const handleAddRoleClick = () => {
    if (!selectedUserDoc) {
      showToast(texts.selectUserError, { error: true });
      setShowAddRoleModal(false);
      return;
    }
    setShowAddRoleModal(true);
  };

  const columns = [
    { field: 'role', headerName: texts.headers.role, flex: 20 },
    {
      field: 'actions',
      headerName: texts.headers.actions,
      flex: 20,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <PrimaryButton
          onClick={() => handleRemoveClick(params.row.role)}
          variant="info"
          size="medium"
        >
          {texts.removeRole}
        </PrimaryButton>
      ),
    },
  ];

  return (
    <StyledCard>
      <HeaderRow>
        <Title>{texts.title}</Title>
        <StyledButton variant="contained" onClick={handleAddRoleClick}>
          {texts.assignNewRole}
        </StyledButton>
      </HeaderRow>
      <DataGrid rows={formattedRoles} columns={columns} rowHeight={50} pagination autoPageSize />
      {showAddRoleModal && (
        <AssignRole
          open={showAddRoleModal}
          onClose={() => setShowAddRoleModal(false)}
          selectedUserDoc={selectedUserDoc}
          setIsLoading={setIsLoading}
          showToast={showToast}
        />
      )}
      {showConfirmationModal && (
        <ConfirmDialog
          isOpen={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleRemoveRole}
          dialogTexts={{
            title: format(texts.removeRoleConfirm, {
              role: selectedRole,
              userName: selectedUserDoc?.firstName,
            }),
          }}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </StyledCard>
  );
};

RoleTable.propTypes = {
  selectedUserDoc: PropTypes.object,
};

RoleTable.defaultProps = {
  selectedUserDoc: null,
};

export default compose(observer)(RoleTable);
