import UserPermissionSelect from './UserPermissionSelect';
import Roles from './Roles';
import texts from './texts.json';

const tabs = {
  USER_SELECT: 'USER_SELECT',
  ROLES: 'ROLES',
};

const tabsConfig = {
  [tabs.USER_SELECT]: {
    label: texts.tabLabels[tabs.USER_SELECT],
    component: UserPermissionSelect,
  },
  [tabs.ROLES]: {
    label: texts.tabLabels[tabs.ROLES],
    component: Roles,
  },
};

export {
  tabs,
  tabsConfig,
};
