import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import texts from './texts.json';
import { StyledPaper } from './styles';

const ConfirmDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  dialogTexts,
}) => (
  <Dialog
    open={isOpen}
    PaperComponent={StyledPaper}
  >
    <DialogTitle>
      {dialogTexts.title}
    </DialogTitle>
    <DialogContent>
      {isValidElement(dialogTexts.content)
        ? dialogTexts.content
        : (
          <DialogContentText>
            {dialogTexts.content || ''}
          </DialogContentText>
        )}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onConfirm}
        color="success"
      >
        {dialogTexts.confirm || texts.confirm}
      </Button>
      {!!onCancel && (
        <Button
          onClick={onCancel}
          color="warning"
        >
          {dialogTexts.cancel || texts.cancel}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  dialogTexts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    confirm: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
};

ConfirmDialog.defaultProps = {
  onCancel: null,
};

export default ConfirmDialog;
