import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

import DialogModal from '../../../../components/DialogModal';
import Button from '../../../../components/Button';
import User from '../../../../Model/User';
import InternalAssignment from '../../../../Model/InternalAssignment';
import LoadingOverlay from '../../../components/LoadingOverlay';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';

import { Title, StyledDialogContent, ButtonContainer } from './styles';

const AddAssignmentModal = ({
  role,
  coachName,
  coachId,
  isOpen,
  onClose,
  internalAssignments,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [internalUsers, setInternalUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isComponentMountedRef = useComponentMounted();
  const { userId: loggedInUserId } = useContext(LoggedInUserContext);

  useEffect(() => {
    const fetchInternalUsers = async () => {
      setIsLoading(true);
      const users = await User.getInternalUsers();
      if (isComponentMountedRef.current) {
        const unAssignedUsers = users.filter(
          (user) => !internalAssignments.some((assignment) => assignment.userId === user.id),
        );
        setInternalUsers(unAssignedUsers);
        setIsLoading(false);
      }
    };
    fetchInternalUsers();
  }, [isComponentMountedRef, internalAssignments]);

  const handleConfirm = async () => {
    setIsLoading(true);
    const user = internalUsers.find((u) => u.id === selectedUserId);
    await InternalAssignment.addInternalAssignment(coachId, user.id, user.firstName, role, loggedInUserId);
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <DialogModal
      open={isOpen}
      onClose={onClose}
    >
      <StyledDialogContent>
        <Title>{`Assign ${role} user to ${coachName}`}</Title>
        <Autocomplete
          options={internalUsers.map((user) => ({
            label: `${user.name} (${user.email})`,
            value: user.id,
          }))}
          onChange={(_, { value: userId }) => setSelectedUserId(userId)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select user to assign" />
          )}
        />

        <ButtonContainer>
          <Button
            secondary
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!selectedUserId}>Confirm</Button>
        </ButtonContainer>
      </StyledDialogContent>
      <LoadingOverlay isLoading={isLoading} />
    </DialogModal>
  );
};

AddAssignmentModal.propTypes = {
  role: PropTypes.string.isRequired,
  coachName: PropTypes.string.isRequired,
  coachId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  internalAssignments: PropTypes.array.isRequired,
};

export default AddAssignmentModal;
