import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import format from 'string-template';

import {
  CardsContainer,
  Card,
  SubTitle,
  Description,
} from '../../../../components/v2/Card';
import { SaveButton } from '../../../../components/Button/ActionButtons';
import useToast from '../../../hooks/useToast';
import { isCRXAdmin } from '../../../../utils/postHog';
import ExternalCoach from '../../../../Model/ExternalCoach';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { FeeConfig, FeeType } from '../../../utils/userContract';
import CoachesListContext from '../../../context/CoachesListContext';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';

import {
  StyledTableCell,
  StyledTableContainer,
  StyledLoadingPage,
  StyledContainer,
  FieldContainer,
  ButtonContainer,
  TextField,
  NoFeesText,
} from './styles';
import texts from './texts.json';

const MAX_FEE_LOWER_LIMIT = 50;
const MAX_FEE_UPPER_LIMIT = 60;
const BASE_FEE_LOWER_LIMIT = 20;
const BASE_FEE_UPPER_LIMIT = 30;
const EMPTY_VALUE = '';

const Fees = ({ coach }) => {
  const [externalCoachDoc, setExternalCoachDoc] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicMaxFee, setDynamicMaxFee] = useState(EMPTY_VALUE);
  const [dynamicBaseFee, setDynamicBaseFee] = useState(EMPTY_VALUE);

  const {
    coachesCollection: { docs: coachDocs },
  } = useContext(CoachesListContext);
  const { userDoc } = useContext(LoggedInUserContext);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const isCRXUser = isCRXAdmin();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const ExternalCoachDocument = new ExternalCoach(coach.id);
      await ExternalCoachDocument.init();

      if (isComponentMountedRef.current) {
        setExternalCoachDoc(ExternalCoachDocument);
        setIsLoading(false);
      }
    };

    init();
  }, [
    coach.id,
    coachDocs,
    isComponentMountedRef,
  ]);

  const coachDoc = useMemo(() => coachDocs.find(({ id }) => id === coach.id), [coach.id, coachDocs]);

  const {
    feesConfig,
    isEnrolledInISProgram,
    isACEnabled,
  } = coachDoc || {};

  const baseFee = feesConfig?.baseFee?.value !== undefined
    ? feesConfig.baseFee.value
    : externalCoachDoc?.baseFee;
  const insideSalesFee = feesConfig?.insideSalesFee?.value !== undefined
    ? feesConfig.insideSalesFee.value
    : FeeConfig.INSIDE_SALES.value;
  const assistantCoachFee = feesConfig?.assistantCoachFee?.value !== undefined
    ? feesConfig.assistantCoachFee.value
    : FeeConfig.ASSISTANT_COACH.value;

  const serviceFee = insideSalesFee + assistantCoachFee;

  useEffect(() => {
    if (feesConfig?.maxFee !== undefined) {
      setDynamicMaxFee(feesConfig.maxFee);
    } else {
      setDynamicMaxFee(EMPTY_VALUE);
    }

    if (baseFee) {
      setDynamicBaseFee(baseFee);
    } else {
      setDynamicBaseFee(EMPTY_VALUE);
    }
  }, [
    coach.id,
    feesConfig.maxFee,
    baseFee,
  ]);

  /**
   * Determines whether the fee change is allowed.
   *
   * - The user must be a CRX admin.
   * - The total fee (baseFee + serviceFee) must meet the required minimum limit (`MAX_FEE_LOWER_LIMIT`).
   * - Inside sales and assistant coach fees must be at least their predefined values (15%).
   * - If no base fee is set, the fee change is still allowed for CRX admins.
   *
   * @Returns `true` if fee modifications are allowed, otherwise `false`.
   */
  const isFeeChangeAllowed = useMemo(() => {
    const totalFee = baseFee + serviceFee;
    if (
      (isCRXUser
      && ((
        totalFee >= MAX_FEE_LOWER_LIMIT
        && insideSalesFee >= FeeConfig.INSIDE_SALES.value
        && assistantCoachFee >= FeeConfig.ASSISTANT_COACH.value)
        || !baseFee))
    ) return true;

    return false;
  }, [
    isCRXUser,
    insideSalesFee,
    assistantCoachFee,
    baseFee,
    serviceFee,
  ]);

  /**
  * Handles changes to the maximum fee percentage input field.
  *
  * - Ensures the new max fee stays within defined limits (`MAX_FEE_LOWER_LIMIT` and `MAX_FEE_UPPER_LIMIT`).
  * - Calculates the base fee dynamically based on the new max fee while ensuring it stays within its allowed range.
  * - Updates both `dynamicMaxFee` and `dynamicBaseFee` states accordingly.
  *
  * @param {number} value - Entered by the user.
  */
  const handleMaxFeeChange = useCallback((value) => {
    const newMaxFee = Math.min(MAX_FEE_UPPER_LIMIT, Math.max(MAX_FEE_LOWER_LIMIT, Number(value)));
    const calculatedBaseFee = Math.min(BASE_FEE_UPPER_LIMIT, Math.max(BASE_FEE_LOWER_LIMIT, newMaxFee - serviceFee));

    setDynamicMaxFee(newMaxFee);
    setDynamicBaseFee(calculatedBaseFee);
  }, [serviceFee]);

  const onSaveChanges = useCallback(async () => {
    try {
      if (isCRXUser) {
        setIsLoading(true);

        const updatedFees = {
          baseFee: {
            type: FeeType.PERCENTAGE,
            value: dynamicBaseFee,
          },
          insideSalesFee: {
            type: FeeType.PERCENTAGE,
            value: FeeConfig.INSIDE_SALES.value,
          },
          assistantCoachFee: {
            type: FeeType.PERCENTAGE,
            value: FeeConfig.ASSISTANT_COACH.value,
          },
          maxFee: dynamicMaxFee,
        };

        await coachDoc.setFeesConfig(updatedFees, userDoc.name);
        showToast(texts.success);
      }
    } catch (error) {
      showToast(format(texts.saveFailed, { error }), { error: true });
    } finally {
      setIsLoading(false);
    }
  }, [
    coachDoc,
    isCRXUser,
    dynamicMaxFee,
    dynamicBaseFee,
    showToast,
    userDoc,
  ]);

  if (isLoading) {
    return <StyledLoadingPage />;
  }

  return (
    <CardsContainer $fullWidth>
      <Card>
        <Tooltip title={(!isFeeChangeAllowed) && texts.support}>
          <FieldContainer>
            <Description>{texts.maxFee}</Description>
            <TextField
              type={texts.number}
              value={dynamicMaxFee}
              inputProps={{ min: MAX_FEE_LOWER_LIMIT, max: MAX_FEE_UPPER_LIMIT }}
              onChange={(e) => handleMaxFeeChange(e.target.value)}
              disabled={!isFeeChangeAllowed}
            />
            <Description>{texts.percentage}</Description>
            <SubTitle>{texts.feesRange}</SubTitle>
          </FieldContainer>
        </Tooltip>
        <StyledContainer>
          {dynamicBaseFee ? (
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{texts.category}</StyledTableCell>
                    <StyledTableCell>{texts.fee}</StyledTableCell>
                    <StyledTableCell>{texts.isEnrolled}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>{texts.baseFee}</StyledTableCell>
                    <StyledTableCell>{format(texts.feeValue, { fee: dynamicBaseFee })}</StyledTableCell>
                    <StyledTableCell>{texts.emptyCell}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>{texts.ISFee}</StyledTableCell>
                    <StyledTableCell>
                      {isEnrolledInISProgram
                        ? format(texts.feeValue, { fee: insideSalesFee })
                        : texts.notApplicable}
                    </StyledTableCell>
                    <StyledTableCell>{isEnrolledInISProgram ? texts.yes : texts.no}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>{texts.ACFee}</StyledTableCell>
                    <StyledTableCell>
                      {isACEnabled
                        ? format(texts.feeValue, { fee: assistantCoachFee })
                        : texts.notApplicable}
                    </StyledTableCell>
                    <StyledTableCell>{isACEnabled ? texts.yes : texts.no}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          ) : (
            <NoFeesText>{texts.noBaseFee}</NoFeesText>
          )}
          {isCRXUser && (
            <Tooltip title={!isFeeChangeAllowed && texts.support}>
              <ButtonContainer>
                <SaveButton
                  onClick={onSaveChanges}
                  $fitToContent
                  disabled={!isFeeChangeAllowed || !dynamicMaxFee}
                >
                  {texts.saveChanges}
                </SaveButton>
              </ButtonContainer>
            </Tooltip>
          )}
        </StyledContainer>
      </Card>
    </CardsContainer>
  );
};

Fees.propTypes = {
  coach: PropTypes.object.isRequired,
};

export default Fees;
