import styled from 'styled-components';
import ReactQuill from 'react-quill';

import colors from '../../styles/colors';

import 'react-quill/dist/quill.snow.css';

const StyledEditor = styled(ReactQuill)`
  background-color: ${colors.base.alpha};
  .ql-editor{
    min-height: 135px;
  }
`;

export {
  StyledEditor,
};
